import {Api} from 'shared/services/axios-config';
import {IInvitation} from 'shared/services/api/invitation/Invitation';
import {IEstablishment} from 'shared/services/api/establishment/Establishment';
import {ISection} from './Quiz';
import {format} from 'date-fns';
import {IResultOfAction} from 'shared/services/axios-config/interceptors';

export interface IQuiz {
  id: string;
  idQuestionaryType: string;
  nameQuestionaryType: string;
  name: string;
  title: string;
  showProgressBar: boolean;
  description: string;
  finalMessage: string;
  activeInvitations: number;
  inactiveInvitations: number;
  establishment: IEstablishment[];
  ativo: boolean;
}

export interface IQuizModalDetail {
  nomeQuestionario: string;
  nomeTipoQuestionario: string;
  descricao: string;
  estabelecimentos: IEstablishment[];
  convites: IInvitation[];
}

export interface IQuizDetail {
  id: number;
  idTipoQuestionario: number;
  nomeTipoQuestionario: string;
  nomeQuestionario: string;
  tituloQuestionario: string;
  descricao: string;
  logotipoUrl: string;
  mostraBarraProgresso: boolean;
  mensagemFinal: string;
  estabelecimentos: IEstablishment[];
  secoes: ISection[];
}

const getAll = async (
  page?: string,
  filter?: string,
): Promise<IResultOfAction<IQuiz[]>> => {
  try {
    const {data} = await Api.get(`/questionarios`);

    const quiz = data.map((data: any) => ({
      id: data.Id,
      idQuestionaryType: data.IdTipoQuestionario,
      nameQuestionaryType: data.NomeTipoQuestionario,
      name: data.NomeQuestionario,
      title: data.TituloQuestionario,
      showProgressBar: data.MostraBarraProgresso,
      description: data.Descricao,
      finalMessage: data.MensagemFinal,
      activeInvitations: data.NumeroConvitesAtivos,
      inactiveInvitations: data.NumeroConvitesInativos,
      establishment: data.Estabelecimentos.map((estab: any) => ({
        id: estab.IdEstabelecimento,
        name: estab.NomeEstabelecimento,
        initials: estab.Sigla,
      })),
      ativo: data.Ativo,
    }));

    const response: IResultOfAction<IQuiz[]> = {
      success: true,
      message: '',
      data: quiz,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const getModalDetailById = async (
  id: string,
): Promise<IResultOfAction<IQuizModalDetail>> => {
  try {
    const {data} = await Api.get(`/questionarios/${id}/detalhe`);

    const quizDetail = {
      nomeQuestionario: data.NomeQuestionario,
      nomeTipoQuestionario: data.NomeTipoQuestionario,
      descricao: data.Descricao,
      estabelecimentos: data.Estabelecimentos.map((estab: any) => ({
        id: estab.IdEstabelecimento,
        name: estab.NomeEstabelecimento,
        initials: estab.Sigla,
      })),
      convites: data.Convites.map((conv: any) => ({
        id: conv.Id,
        descricao: conv.Descricao,
        dataHoraInicio: format(new Date(conv.DataHoraInicio), 'dd/MM/yyyy'),
        dataHoraFim: format(new Date(conv.DataHoraFim), 'dd/MM/yyyy'),
        aceitandoRespostas: conv.AceitandoRespostas,
        linkConvite: conv.LinkConvite,
        permitirAcesso: conv.PermitirAcesso,
        status: conv.Status,
        mensagemDeErro: conv.MensagemDeErro,
        resposta: conv.Resposta,
        dataHoraBloqueio: conv.DataHoraBloqueio,
        publicosAlvo: conv.PublicosAlvo.map((pa: any) => ({
          id: pa.Id,
          nome: pa.Nome,
        })),
      })),
    };

    const response: IResultOfAction<IQuizModalDetail> = {
      success: true,
      message: '',
      data: quizDetail,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

export const QuizListService = {
  getAll,
  getModalDetailById,
};
