import React, {createContext, useEffect, useState} from 'react';
import {feedback} from 'shared/services';

import {IPaper, PaperService} from 'shared/services/api/paper/Paper';

interface IPapersContextData {
  papers: IPaper[];
}

export const PapersContext = createContext<IPapersContextData>(
  {} as IPapersContextData,
);

export const PapersContextProvider: React.FC = ({children}) => {
  const [papers, setPapers] = useState<IPaper[]>([]);

  useEffect(() => {
    PaperService.getPapers().then((result) => {
      if (result.success) {
        setPapers(result.data);
      } else {
        feedback(result.message || 'Erro ao carregar os papéis...', 'error');
        setPapers([]);
      }
    });
  }, []);

  return (
    <PapersContext.Provider
      value={{
        papers,
      }}>
      {children}
    </PapersContext.Provider>
  );
};
