import React, {memo} from 'react';

import {DisplayMode} from '../../DisplayMode';
import {TimePreview} from './TimePreview';
import {TimeConfig} from './TimeConfig';
interface ITimeProps {
  displayMode: DisplayMode;
}

export const Time: React.FC<ITimeProps> = memo(({displayMode}) => {
  return (
    <>
      {displayMode === DisplayMode.config && <TimeConfig />}
      {displayMode === DisplayMode.preview && <TimePreview />}
    </>
  );
});
