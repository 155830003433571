import React, {memo} from 'react';

import {IOption} from 'shared/services/api/quiz/Quiz';
import {DisplayMode} from '../../DisplayMode';
import {GridMultipleAnswerConfig} from './GridMultipleAnswerConfig';
import {GridMultipleAnswerPreview} from './GridMultipleAnswerPreview';
interface IGridMultipleAnswerProps {
  displayMode: DisplayMode;
  columnOptions: IOption[];
  rowOptions: IOption[];
  onChangeRows: (value: IOption[]) => void;
  onChangeColumns: (value: IOption[]) => void;
}

export const GridMultipleAnswer: React.FC<IGridMultipleAnswerProps> = memo(
  ({displayMode, columnOptions, rowOptions, onChangeRows, onChangeColumns}) => {
    return (
      <>
        {displayMode === DisplayMode.config && (
          <GridMultipleAnswerConfig
            rowOptions={rowOptions}
            onChangeRows={onChangeRows}
            columnOptions={columnOptions}
            onChangeColumns={onChangeColumns}
          />
        )}
        {displayMode === DisplayMode.preview && (
          <GridMultipleAnswerPreview
            rowOptions={rowOptions}
            columnOptions={columnOptions}
          />
        )}
      </>
    );
  },
);
