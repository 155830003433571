import React, {memo, useEffect, useState} from 'react';
import {Box, TextField, Grid} from '@mui/material';
import {parseISO} from 'date-fns';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider, DatePicker} from '@mui/x-date-pickers';
import ptbr from 'dayjs/locale/pt-br';

interface IDatePreviewProps {
  value: string;
  comment: string;
  isCompleted: boolean;
  hasComment?: boolean;
  setValue: (value: string) => void;
  setComment: (comment: string) => void;
}

export const DatePreview: React.FC<IDatePreviewProps> = memo(
  ({value, comment, isCompleted, hasComment, setValue, setComment}) => {
    const [valueToShow, setValueToShow] = useState<Date | null>(null);

    const handleComment = (e: React.ChangeEvent<HTMLInputElement>) => {
      setComment(e.target.value);
    };

    const formatDate = (date: Date) => {
      const newDate = new Date(date);
      let d = newDate,
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      return [year, month, day].join('-');
    };

    useEffect(() => {
      if (value !== '') {
        setValueToShow(parseISO(value));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDate = (selectedDate: Date | null) => {
      setValueToShow(selectedDate);
    };

    const handleDayOfWeekFormatter = (day: string) => {
      const formatedDays = {
        D: 'Dom',
        '2': 'Seg',
        '3': 'Ter',
        '4': 'Qua',
        '5': 'Qui',
        '6': 'Sex',
        S: 'Sab',
      };

      return formatedDays[day.charAt(0) as keyof typeof formatedDays];
    };

    return (
      <Grid item xs={12} sm={12} md={8} lg={8}>
        <Box>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={ptbr}>
            <DatePicker
              showToolbar={false}
              dayOfWeekFormatter={handleDayOfWeekFormatter}
              disabled={isCompleted}
              label="Data"
              value={valueToShow}
              inputFormat="DD/MM/YYYY"
              onChange={handleDate}
              PopperProps={{
                placement: 'right',
              }}
              onAccept={(date) => {
                setValue(formatDate(date as Date));
              }}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  error={false}
                  inputProps={{...params.inputProps, readOnly: true}}
                />
              )}
            />
          </LocalizationProvider>
        </Box>
        {((hasComment && !isCompleted) || (comment !== '' && isCompleted)) && (
          <Box marginTop={3} justifyContent="center" display="flex">
            <TextField
              fullWidth
              multiline
              rows={2}
              disabled={isCompleted}
              color="primary"
              label="Comentário"
              variant="outlined"
              placeholder="Adicionar um comentário"
              value={comment}
              onChange={handleComment}
            />
          </Box>
        )}
      </Grid>
    );
  },
);
