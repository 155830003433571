import React, {memo} from 'react';

import {Box, TextField} from '@mui/material';

interface ITimePreviewProps {
  hasComment?: boolean;
}

export const TimePreview: React.FC<ITimePreviewProps> = memo(({hasComment}) => {
  return (
    <Box>
      <TextField variant="outlined" disabled type="time" defaultValue="08:00" />
      {hasComment && (
        <Box marginTop={3}>
          <TextField
            variant="outlined"
            disabled
            placeholder="Deixe um comentário"
          />
        </Box>
      )}
    </Box>
  );
});
