import {memo} from 'react';
import {TTypeOfQuestion} from 'shared/types/QuestionTypes';
import {
  SimpleTextValidation,
  ParagraphTextValidation,
  GridMultipleAnswerValidation,
  MultipleAnswerValidation,
  DateValidation,
  TimeValidation,
} from './validationQuestionTypes';

export interface IValidation<T = string | number> {
  typeOfValidation: TTypeOfValidation;
  suggestionValue?: string;
  operator: string;
  value: T;
  valueEnd?: T;
  customErrorMessage: string;
}

export type TTypeOfValidation =
  | 'texto'
  | 'numero'
  | 'expressao-regular'
  | 'tamanho-caracteres'
  | 'selecione';

interface IValidationProps {
  typeOfQuestionToValidade: TTypeOfQuestion;
  value: IValidation;
  onChange: (value: IValidation) => void;
}

export const Validation: React.FC<IValidationProps> = memo(
  ({typeOfQuestionToValidade, value, onChange}) => {
    switch (typeOfQuestionToValidade) {
      case 'simple-text':
        return <SimpleTextValidation value={value} onChange={onChange} />;
      case 'paragraph-text':
        return <ParagraphTextValidation value={value} onChange={onChange} />;
      case 'grid-multiple-answer':
        return (
          <GridMultipleAnswerValidation value={value} onChange={onChange} />
        );
      case 'multiple-answer':
        return <MultipleAnswerValidation value={value} onChange={onChange} />;
      case 'date':
        return <DateValidation value={value} onChange={onChange} />;
      case 'time':
        return <TimeValidation value={value} onChange={onChange} />;

      default:
        return <></>;
    }
  },
);
