import {useEffect, useMemo, useState, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {
  Box,
  Grid,
  Card,
  Icon,
  Paper,
  Table,
  Button,
  TableRow,
  Container,
  TableBody,
  TableCell,
  TableHead,
  IconButton,
  Typography,
  CardContent,
  FormControl,
  InputAdornment,
  TableContainer,
  TextField,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogActions,
} from '@mui/material';
import {Pagination, Skeleton} from '@mui/lab';
import CalendarIcon from '@mui/icons-material/CalendarToday';
import SearchIcon from '@mui/icons-material/Search';
import {useTitle} from 'shared/hooks';
import {Environment} from 'shared/Env';
import {NotFoundText} from 'shared/components';
import {useStyles} from './styles';
import {LogoGVdasa, NoData} from 'shared/assets/index';
import {SubTheme} from './themes/SubTheme';
import {useStudentQuiz} from './hooks';
import {StudentQuestionListService} from 'shared/services/api/student-quiz/QuestionList';
import {downloadPDF} from 'shared/components/donwload-from-base-64/DownloadFromBase64';
import {feedback} from 'shared/services/alert';

export const StudentQuizList: React.FC = () => {
  const {activeQuizes, nonActiveQuizes, isLoadingQuizList} = useStudentQuiz();
  const styles = useStyles();
  const navigate = useNavigate();
  const {setTitle} = useTitle();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [idPessoaConvite, setIdPessoaConvite] = useState('');
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setTitle('Questionários');
  }, [setTitle]);

  const handleOpenModal = useCallback(() => {
    setOpenModal(true);
  }, [setOpenModal]);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, [setOpenModal]);

  const normalizeText = useCallback((value: string) => {
    const resultFilter = value
      .toLocaleLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

    return resultFilter;
  }, []);

  const rowsPerPage = useMemo(() => {
    return Environment.LIMITE_DE_LINHAS;
  }, []);

  const activeQuizList = useMemo(() => {
    return activeQuizes.filter(
      (item) =>
        normalizeText(item.descricaoConvite).includes(normalizeText(search)) ||
        normalizeText(item.tituloQuestionario).includes(normalizeText(search)),
    );
  }, [normalizeText, activeQuizes, search]);

  const notActiveQuizList = useMemo(() => {
    return nonActiveQuizes.filter(
      (item) =>
        normalizeText(item.descricaoConvite).includes(normalizeText(search)) ||
        normalizeText(item.tituloQuestionario).includes(normalizeText(search)),
    );
  }, [normalizeText, nonActiveQuizes, search]);

  const cardColumns = useMemo(() => {
    if (activeQuizList.length === 1) {
      return 12;
    } else if (activeQuizList.length === 2) {
      return 6;
    } else {
      return 4;
    }
  }, [activeQuizList.length]);

  const handlePageChange = useCallback(
    (event: React.ChangeEvent<unknown>, value: number) => {
      setPage(value);
    },
    [],
  );

  const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  }, []);

  const handleNavigateToSections = (
    colorToSetBeforeGo: string,
    pIdPessoaConvite: string,
  ) => {
    localStorage.setItem(
      'corQuestionario',
      colorToSetBeforeGo !== '' ? colorToSetBeforeGo : '018781',
    );
    setTimeout(() => {
      navigate(`/convite/${pIdPessoaConvite}`);
    }, 100);
  };

  const handlePrintQuizReceipt = useCallback(() => {
    StudentQuestionListService.getComprovante(idPessoaConvite).then(
      (result) => {
        if (result.success) {
          downloadPDF(result.data, 'comprovante');
        } else {
          feedback(
            result.message || 'Erro ao realizar o download do comprovante',
            'error',
          );
        }
      },
    );
  }, [idPessoaConvite]);

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" color="primary">
            <strong>Questionários ativos</strong>
          </Typography>

          <Grid item sm={12} md={6} lg={6}>
            <Box>
              <Box marginTop={2} />
              <FormControl variant="outlined" fullWidth>
                <Paper>
                  <TextField
                    style={{padding: 0, margin: 0}}
                    fullWidth
                    variant="outlined"
                    placeholder="Pesquise por nome ou descrição do questionário"
                    value={search}
                    onChange={handleSearch}
                    InputProps={{
                      style: {
                        height: 40,
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          {isLoadingQuizList ? (
                            <CircularProgress size={30} />
                          ) : (
                            <SearchIcon color="disabled" />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Paper>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        {isLoadingQuizList ? (
          <Grid item xs={12} sm={12} md={6} lg={4} marginBottom={2}>
            <Card>
              <Skeleton variant="rectangular" width="100%" height={144} />
              <CardContent>
                <Skeleton width="80%" height={40} />
                <Skeleton width="60%" />
                <Skeleton width="60%" />
              </CardContent>
            </Card>
          </Grid>
        ) : activeQuizList && activeQuizList.length ? (
          activeQuizList.map((item) => {
            return (
              <Grid
                key={item.idPessoaConvite}
                item
                xs={12}
                sm={12}
                md={6}
                lg={cardColumns}>
                <SubTheme color={item.corQuestionario || '018781'}>
                  <Card style={{height: '100%'}}>
                    {item.banner ? (
                      <Box position="relative" zIndex={1}>
                        <Box
                          className={styles.banner}
                          style={{
                            backgroundImage: `url(${item.banner})`,
                            backgroundColor: `#${item.corQuestionario}`,
                          }}
                        />
                      </Box>
                    ) : (
                      <Box
                        position="relative"
                        className={styles.banner}
                        style={{backgroundColor: `#${item.corQuestionario}`}}
                        display="flex"
                        alignItems="center">
                        <Container>
                          <img
                            src={LogoGVdasa.default}
                            alt="Logo GVdasa"
                            style={{maxWidth: `11rem`}}
                          />
                        </Container>
                      </Box>
                    )}
                    <Box margin={2}>
                      <Typography
                        noWrap
                        gutterBottom
                        color="primary"
                        variant="h5"
                        component="div">
                        {item.tituloQuestionario}
                      </Typography>
                      <Box>
                        <Typography
                          noWrap
                          gutterBottom
                          color="textPrimary"
                          variant="body1">
                          {item.descricaoQuestionario}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          noWrap
                          gutterBottom
                          color="textPrimary"
                          variant="body2">
                          {item.descricaoConvite}
                        </Typography>
                      </Box>

                      <Box marginTop={2} display="flex" gap={1}>
                        <CalendarIcon color="action" />
                        <Typography variant="subtitle1" color="textPrimary">
                          Disponível até {item.finalizaEm}
                        </Typography>
                      </Box>
                    </Box>
                    <Box margin={1}>
                      <Button
                        disableRipple
                        disableTouchRipple
                        color="primary"
                        onClick={() => {
                          handleNavigateToSections(
                            item.corQuestionario || '',
                            item.idPessoaConvite,
                          );
                        }}>
                        <span style={{textDecoration: 'underline'}}>
                          Responder
                        </span>
                      </Button>
                    </Box>
                  </Card>
                </SubTheme>
              </Grid>
            );
          })
        ) : (
          <>
            <Box width={'100%'} maxWidth={'500px'} margin={'0 auto'}>
              <img
                style={{width: '100%'}}
                src={NoData.default}
                alt="Sem dados"
              />
            </Box>
            <Box width={'100%'} textAlign={'center'}>
              <NotFoundText />
            </Box>
          </>
        )}
        {notActiveQuizList.length > 0 && (
          <>
            <Grid item xs={12}>
              <Box marginTop={4}>
                <Typography variant="h5" color="primary">
                  <strong>Outros questionários</strong>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Título
                      </TableCell>
                      <TableCell component="th" scope="row">
                        Convite
                      </TableCell>
                      <TableCell component="th" scope="row">
                        Período
                      </TableCell>
                      <TableCell component="th" scope="row">
                        Situação
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        width="10%">
                        Opções
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {notActiveQuizList
                      .slice(
                        (Number(page) - 1) * rowsPerPage,
                        (Number(page) - 1) * rowsPerPage + rowsPerPage,
                      )
                      .map((item, i) => {
                        return (
                          <TableRow key={i} hover>
                            <TableCell component="th" scope="row">
                              {item.tituloQuestionario}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {item.descricaoConvite}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {`${item.iniciaEm} a ${item.finalizaEm}`}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {item.statusConvite}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                              width="10%">
                              <IconButton
                                size="small"
                                onClick={() =>
                                  navigate(
                                    `/convite/${item.idPessoaConvite}/visualizar`,
                                  )
                                }>
                                <Icon title="Visualizar questionário">
                                  visibility
                                </Icon>
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  setIdPessoaConvite(item.idPessoaConvite);
                                  handleOpenModal();
                                }}>
                                <Icon title="Imprimir comprovante de participação">
                                  print_rounded
                                </Icon>
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
                {notActiveQuizList.length > rowsPerPage && (
                  <Box margin={1}>
                    <Pagination
                      page={Number(page)}
                      count={Math.ceil(
                        notActiveQuizList.filter(
                          (item) =>
                            normalizeText(item.descricaoConvite).includes(
                              normalizeText(search),
                            ) ||
                            normalizeText(item.tituloQuestionario).includes(
                              normalizeText(search),
                            ),
                        ).length / rowsPerPage,
                      )}
                      onChange={handlePageChange}
                    />
                  </Box>
                )}
              </TableContainer>
            </Grid>
          </>
        )}
      </Grid>

      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogContent>
          <Box marginBottom={1} marginRight={8}>
            <Typography>Fazer o download do comprovante?</Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleCloseModal}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handlePrintQuizReceipt}>
            Baixar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};
