import React, {memo, useCallback, useState} from 'react';
import {
  TextField,
  Grid,
  Typography,
  ClickAwayListener,
  Box,
  useTheme,
} from '@mui/material';
import {SelectQuestionType} from './SelectQuestionType';
import {QuestionActions} from './QuestionActions';
import {DisplayMode} from './DisplayMode';
import {TTypeOfQuestion} from 'shared/types/QuestionTypes';
import {IOption} from 'shared/services/api/quiz/Quiz';
import {TypeOfQuestion} from './TypeOfQuestion';
import {IValidation, Validation} from '../validation/Validation';
import {useQuestion, useQuiz} from 'pages/quiz/hooks';
import {useField} from '@unform/core';

interface IQuestionProps {
  sectionOrder: number;
  questionOrder: number;
}

export const Question: React.FC<IQuestionProps> = memo(
  ({sectionOrder, questionOrder}) => {
    const {error: nameError, clearError: nameClearError} = useField(
      `sections[${sectionOrder - 1}].questions[${questionOrder - 1}].name`,
    );

    const {error: descriptionError, clearError: descriptionClearError} =
      useField(
        `sections[${sectionOrder - 1}].questions[${
          questionOrder - 1
        }].description`,
      );

    const theme = useTheme();

    const {setIsEditingQuiz} = useQuiz();

    const {
      deleteQuestion,
      duplicateQuestion,
      getQuestionHasShuffleAnswer,
      setQuestionHasShuffleAnswer,
      getQuestionIsRequired,
      setQuestionIsRequired,
      getQuestionHasValidation,
      setQuestionHasValidation,
      getQuestionHasComment,
      setQuestionHasComment,
      getQuestionHasWeight,
      setQuestionHasWeight,
      getQuestionName,
      setQuestionName,
      getQuestionDescription,
      setQuestionDescription,
      getQuestionValidation,
      setQuestionValidation,
      getQuestionRows,
      setQuestionRows,
      getQuestionColumn,
      setQuestionColumn,
      getQuestionType,
      setQuestionType,
      getQuestionIsSelected,
      setQuestionIsSelected,
    } = useQuestion(sectionOrder, questionOrder);

    const [mode, setMode] = useState<DisplayMode>(DisplayMode.config);

    const [type, setType] = useState<TTypeOfQuestion>(getQuestionType());

    const [columnOptions, setColumnOptions] = useState<IOption[]>(
      getQuestionColumn(),
    );
    const [rowOptions, setRowOptions] = useState<IOption[] | undefined>(
      getQuestionRows(),
    );

    const [validation, setValidation] = useState<IValidation>(
      getQuestionValidation(),
    );

    const [hasShuffleAnswer, setHasShuffleAnswer] = useState(
      getQuestionHasShuffleAnswer(),
    );
    const [hasValidation, setHasValidation] = useState(
      getQuestionHasValidation(),
    );
    const [isRequired, setIsRequired] = useState(getQuestionIsRequired());
    const [hasComment, setHasComment] = useState(getQuestionHasComment());
    const [description, setDescription] = useState(getQuestionDescription());
    const [hasWeight, setHasWeight] = useState(getQuestionHasWeight());
    const [name, setName] = useState(getQuestionName());
    const [isSelected, setIsSelected] = useState(getQuestionIsSelected());

    const onChangeIsRequired = useCallback(
      (checked: boolean) => {
        setIsRequired(checked);
        setQuestionIsRequired(checked);
        setIsEditingQuiz(true);
      },
      [setQuestionIsRequired, setIsEditingQuiz],
    );

    const onChangeIsSelected = useCallback(
      (value: boolean) => {
        setIsSelected(value);
        setQuestionIsSelected(value);
      },
      [setQuestionIsSelected],
    );

    const onChangehasShuffleAnswer = useCallback(
      (hasShuffleAnswer: boolean) => {
        setHasShuffleAnswer(hasShuffleAnswer);
        setQuestionHasShuffleAnswer(hasShuffleAnswer);
        setIsEditingQuiz(true);
      },
      [setQuestionHasShuffleAnswer, setIsEditingQuiz],
    );

    const onChangehasValidation = useCallback(
      (hasValidation: boolean) => {
        setHasValidation(hasValidation);
        setQuestionHasValidation(hasValidation);
        setIsEditingQuiz(true);
      },
      [setQuestionHasValidation, setIsEditingQuiz],
    );

    const onChangeHasComment = useCallback(
      (hasComment: boolean) => {
        setHasComment(hasComment);
        setQuestionHasComment(hasComment);
        setIsEditingQuiz(true);
      },
      [setQuestionHasComment, setIsEditingQuiz],
    );

    const onChangeDescription = useCallback(
      (description: string) => {
        setDescription(description);
        setQuestionDescription(description);
        setIsEditingQuiz(true);
        descriptionError && descriptionClearError();
      },
      [
        setQuestionDescription,
        descriptionError,
        descriptionClearError,
        setIsEditingQuiz,
      ],
    );

    const onChangeName = useCallback(
      (name: string) => {
        setName(name);
        setQuestionName(name);
        setIsEditingQuiz(true);
        nameError && nameClearError();
      },
      [nameClearError, nameError, setQuestionName, setIsEditingQuiz],
    );

    const onChangeHasWeight = useCallback(
      (hasWeight: boolean) => {
        setHasWeight(hasWeight);
        setQuestionHasWeight(hasWeight);
        setIsEditingQuiz(true);
      },
      [setQuestionHasWeight, setIsEditingQuiz],
    );

    const onClickPreview = useCallback((value: DisplayMode) => {
      setMode(value);
    }, []);

    const handleDuplicate = useCallback(() => {
      duplicateQuestion();
      setIsEditingQuiz(true);
    }, [duplicateQuestion, setIsEditingQuiz]);

    const handleDelete = useCallback(() => {
      deleteQuestion();
      setIsEditingQuiz(true);
    }, [deleteQuestion, setIsEditingQuiz]);

    const handleChangeValidation = useCallback(
      (validation: IValidation) => {
        setValidation(validation);
        setQuestionValidation(validation);
        setIsEditingQuiz(true);
      },
      [setQuestionValidation, setIsEditingQuiz],
    );

    const handleChangeType = useCallback(
      (type: TTypeOfQuestion) => {
        setType(type);
        setQuestionType(type);
        setIsEditingQuiz(true);
      },
      [setQuestionType, setIsEditingQuiz],
    );

    const handleChangeRowsOptions = useCallback(
      (rows: IOption[], isInitialFix?: boolean) => {
        setRowOptions(rows);
        setQuestionRows(rows);

        if (!isInitialFix) {
          setIsEditingQuiz(true);
        }
      },
      [setQuestionRows, setIsEditingQuiz],
    );

    const handleChangeColumnOptions = useCallback(
      (columns: IOption[]) => {
        setColumnOptions(columns);
        setQuestionColumn(columns);
        setIsEditingQuiz(true);
      },
      [setQuestionColumn, setIsEditingQuiz],
    );

    const handleResetQuestion = useCallback(() => {
      setMode(DisplayMode.config);
      handleChangeValidation({
        typeOfValidation: 'selecione',
        operator: 'selecione',
        customErrorMessage: '',
        value: '',
        valueEnd: '',
      });
      onChangehasValidation(false);
      onChangeHasComment(false);
      onChangeHasWeight(false);
      onChangehasShuffleAnswer(false);
      onChangeIsRequired(false);
      handleChangeColumnOptions([] as IOption[]);
      handleChangeRowsOptions([] as IOption[]);
    }, [
      handleChangeColumnOptions,
      handleChangeRowsOptions,
      handleChangeValidation,
      onChangeHasComment,
      onChangeHasWeight,
      onChangeIsRequired,
      onChangehasShuffleAnswer,
      onChangehasValidation,
    ]);

    return (
      <ClickAwayListener
        disableReactTree
        onClickAway={() => onChangeIsSelected(false)}>
        <Box
          p={3}
          paddingRight={0}
          marginLeft={-4.25}
          onClick={() => onChangeIsSelected(true)}
          onBlur={() => onChangeIsSelected(false)}
          style={{
            transition: 'border-color 0.3s ease',
            borderLeft: isSelected
              ? `10px solid ${theme.palette.secondary.main}`
              : `10px solid transparent`,
          }}>
          <Box>
            <Grid container spacing={3}>
              <Grid item={true} xs={12}>
                <Typography
                  variant={'subtitle1'}
                  style={{fontWeight: 'bold'}}
                  color={isSelected ? 'primary' : 'textPrimary'}>
                  Questão {questionOrder}
                </Typography>
              </Grid>
              <Grid item={true} xs={12} sm={8}>
                <TextField
                  variant="outlined"
                  label={`Questão ${questionOrder}`}
                  value={name}
                  onChange={(e) => onChangeName(e.target.value)}
                  placeholder="Questão"
                  fullWidth
                  multiline
                  required
                  error={!!nameError}
                  helperText={nameError || name.length + '/510'}
                  inputProps={{
                    maxLength: 510,
                  }}
                />
              </Grid>
              <Grid item={true} xs={4} sm={4} gap={0}>
                <SelectQuestionType
                  resetQuestion={handleResetQuestion}
                  onChangeSelectQuestionType={handleChangeType}
                  selectedQuestionTypeId={type}
                />
              </Grid>

              <Grid item={true} xs={12}>
                <TextField
                  variant="outlined"
                  label="Descrição da questão"
                  placeholder="Descrição da questão"
                  multiline
                  value={description}
                  onChange={(e) => onChangeDescription(e.target.value)}
                  helperText={description.length + '/1020'}
                  inputProps={{
                    maxLength: 1020,
                  }}
                  fullWidth
                />
              </Grid>

              <Grid item={true} xs={12}>
                <TypeOfQuestion
                  type={type}
                  displayMode={mode}
                  columnOptions={columnOptions}
                  rowOptions={rowOptions}
                  onChangeColumns={handleChangeColumnOptions}
                  onChangeRows={handleChangeRowsOptions}
                />
                {hasValidation && (
                  <Box marginTop={5}>
                    <Validation
                      value={validation}
                      onChange={handleChangeValidation}
                      typeOfQuestionToValidade={type}
                    />
                  </Box>
                )}
              </Grid>

              <Grid item={true} xs={12}>
                <QuestionActions
                  mode={mode}
                  typeOfQuestion={type}
                  onClickPreview={onClickPreview}
                  isMandatory={isRequired}
                  onChangeIsMandatory={onChangeIsRequired}
                  disabledPreview={false}
                  hasComment={hasComment}
                  hasValidation={hasValidation}
                  hasShuffleAnswer={hasShuffleAnswer}
                  hasWeight={hasWeight}
                  onChangeHasComment={onChangeHasComment}
                  onChangeHasShuffleAnswer={onChangehasShuffleAnswer}
                  onChangeHasValidation={onChangehasValidation}
                  onChangeHasWeight={onChangeHasWeight}
                  onClickDuplicate={handleDuplicate}
                  onDeleteQuestion={handleDelete}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </ClickAwayListener>
    );
  },
);
