import {memo, useCallback, useMemo} from 'react';
import {Box, Select, TextField, MenuItem} from '@mui/material';
import {IValidation, TTypeOfValidation} from '../Validation';
import InputMask from 'react-input-mask';

interface IGridMultipleAnswerValidationProps {
  value: IValidation;
  onChange: (value: IValidation) => void;
}

export const GridMultipleAnswerValidation: React.FC<IGridMultipleAnswerValidationProps> =
  memo(({value, onChange}) => {
    const operators = useMemo(() => {
      return [
        {
          id: 'limite-minimo',
          chave: 'Limite mínimo de respostas',
          suggestion: 'Digite um número',
        },
        {
          id: 'limite-maximo',
          chave: 'Limite máximo de respostas',
          suggestion: 'Digite um número',
        },
      ];
    }, []);

    const handleChangeOperator = useCallback(
      (operator: TTypeOfValidation) => {
        value.operator = operator;
        value.suggestionValue = operators.filter(
          (operator) => operator.id === value.operator,
        )[0]?.suggestion;

        onChange({...value});
      },
      [onChange, operators, value],
    );

    const handleChangeErrorMessage = useCallback(
      (errorMessage: string) => {
        value.customErrorMessage = errorMessage;
        onChange({...value});
      },
      [onChange, value],
    );

    const handleChangeValue = useCallback(
      (validationValue: string | number) => {
        value.value = validationValue;
        onChange({...value});
      },
      [onChange, value],
    );

    return (
      <Box display="flex">
        <Box mr={2}>
          <Select disabled variant="outlined" value={'numero'}>
            <MenuItem value={'numero'}>Número</MenuItem>
          </Select>
        </Box>
        <Box mr={2}>
          <Select
            variant="outlined"
            value={value.operator}
            onChange={(e) =>
              handleChangeOperator(e.target.value as TTypeOfValidation)
            }>
            <MenuItem value={'selecione'}>Selecione</MenuItem>
            {operators.map((operator) => {
              return (
                <MenuItem key={operator.id} value={operator.id}>
                  {operator.chave}
                </MenuItem>
              );
            })}
          </Select>
        </Box>

        <Box mr={2}>
          <InputMask
            maskPlaceholder={null}
            mask={'99999999999'}
            placeholder={value.suggestionValue}
            onChange={(e) =>
              handleChangeValue(
                Number(e.target.value) < 0 ? '0' : e.target.value,
              )
            }
            value={value.value}>
            <TextField
              variant="outlined"
              InputProps={{
                inputProps: {min: 0},
              }}
            />
          </InputMask>
        </Box>
        <Box minWidth={350}>
          <TextField
            variant="outlined"
            fullWidth
            value={value.customErrorMessage}
            onChange={(e) => handleChangeErrorMessage(e.target.value)}
            placeholder="Mensagem de erro personalizado"
          />
        </Box>
      </Box>
    );
  });
