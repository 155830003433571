import React, {memo, useEffect, useState} from 'react';

import {Box, Typography, useTheme} from '@mui/material';

import {useQuiz} from '../../hooks/useQuiz';
import {Section} from './Section';
import {sectionsObservable} from 'pages/quiz/observables/Observable';

export const SectionsWrapper: React.FC = memo(() => {
  const theme = useTheme();

  const {getSectionsNameOrder} = useQuiz();

  const [sections, setSections] = useState(getSectionsNameOrder());

  useEffect(() => {
    const listener = sectionsObservable.subscribe(() => {
      setSections([...getSectionsNameOrder()]);
    });
    return () => listener.unsubscribe();
  }, [getSectionsNameOrder]);

  const sectionRender = () => {
    return (
      <>
        {sections?.map((section, index) => (
          <Section
            key={section.id}
            sectionOrder={section.order}
            isDefaultExpanded={index === 0}
          />
        ))}
      </>
    );
  };

  return (
    <>
      {sections.length > 0 ? (
        sectionRender()
      ) : (
        <Box
          borderRadius={10}
          marginTop={3}
          boxShadow={3}
          display="flex"
          padding={3}
          justifyContent="center"
          alignItems="center"
          style={{backgroundColor: theme.palette.common.white}}>
          <Typography variant={'subtitle1'} align="center">
            Utilize a barra lateral para começar o seu questionário, criando
            seções e questões.
          </Typography>
        </Box>
      )}
    </>
  );
});
