import {Box} from '@mui/material';
import {Skeleton} from '@mui/lab';
import React, {memo} from 'react';

export const AnswerSummarySkeleton: React.FC = memo(() => {
  return (
    <>
      <Skeleton variant="rectangular" width="100%" height={50} />
      <Box marginBottom={3} />
      <Box
        marginBottom={3}
        display="flex"
        justifyContent="center"
        alignItems="center"
        gap={'24px'}>
        <Skeleton variant="rectangular" width={'25%'} height={200} />
        <Skeleton variant="rectangular" width={'25%'} height={200} />
        <Skeleton variant="rectangular" width={'25%'} height={200} />
        <Skeleton variant="rectangular" width={'25%'} height={200} />
      </Box>
      <Box
        marginBottom={10}
        display="flex"
        justifyContent="center"
        alignItems="center"
        gap={'24px'}>
        <Skeleton variant="rectangular" width={'50%'} height={392} />
        <Skeleton variant="rectangular" width={'50%'} height={392} />
      </Box>
      <Box
        width={'100%'}
        marginBottom={3}
        display="flex"
        justifyContent="flex-end"
        alignItems="center">
        <Skeleton variant="rectangular" width={153} height={36} />
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center"></Box>
    </>
  );
});
