import React, {useEffect, useState} from 'react';
import {AppBar, Tabs, Tab, Box, Container} from '@mui/material';
import {useTitle} from 'shared/hooks';
import {Theme} from '@mui/material/styles';
import {makeStyles} from '@mui/styles';
import {AnswerSummary} from './components/answerSummary/AnswerSummary';
import {IndividualAnswers} from './components/individualAnswers/IndividualAnswers';

const useStyles = makeStyles((theme: Theme) => ({
  tabs: {
    flexGrow: 1,
    backgroundColor: 'transparent',

    '& > header': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },
  tabChild: {
    '& > div': {
      padding: '24px 0',
    },
  },
  tabButtonElement: {
    textTransform: 'none',
    padding: '6px 0',
  },
  tabsParent: {
    '& > div > div': {
      gap: '16px',
    },
  },
  doNotShowOnPrint: {
    '@media print': {
      display: 'none',
    },
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const TabPanel: React.FC<TabPanelProps> = (props: TabPanelProps) => {
  const classes = useStyles();
  const {children, value, index, ...other} = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box className={classes.tabChild}>{children}</Box>}
    </Box>
  );
};

export const InviteReportsDetail: React.FC = () => {
  const {setTitle} = useTitle();
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    setTitle('Relatórios');
  }, [setTitle]);

  return (
    <Container>
      <Box style={{}} className={classes.tabs}>
        <AppBar position="static" className={classes.doNotShowOnPrint}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="Abas de relatórios"
            indicatorColor="primary"
            textColor="secondary"
            className={classes.tabsParent}>
            <Tab
              label="Resumo das Respostas"
              id={'tab-0'}
              className={classes.tabButtonElement}
            />
            <Tab
              label="Respostas Individuais"
              id={'tab-1'}
              className={classes.tabButtonElement}
            />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <AnswerSummary />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <IndividualAnswers />
        </TabPanel>
      </Box>
    </Container>
  );
};
