import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Box, Icon, Button, CircularProgress, Typography} from '@mui/material';
import {NotFoundText} from 'shared/components';
import {FinalDialog} from './FinalDialog';
import {QuestionSlide} from './QuestionSlide';
import {RenderQuestion} from './RenderQuestion';
import {IStudentQuestion} from 'shared/services/api/student-quiz/QuestionList';
import {useQuiz} from 'pages/quiz/hooks';

interface IQuestionWrapper {
  questions: IStudentQuestion[];
  onChangeQuestion: (questionIndex: number) => void;
}

export const PreviewQuestionWrapper: React.FC<IQuestionWrapper> = ({
  questions,
  onChangeQuestion,
}) => {
  const {getFinalMessage} = useQuiz();
  const navigate = useNavigate();
  const [currentQuestion, setCurrentQuestion] = useState<IStudentQuestion>(
    {} as IStudentQuestion,
  );
  const [questionPosition, setQuestionPosition] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [changeSlide, setChangeSlide] = useState(0);
  const [nextQuestionDirection, setNextQuestionDirection] = useState<
    'previous' | 'next'
  >('next');

  useEffect(() => {
    setCurrentQuestion({...questions[questionPosition]});
  }, [questionPosition, questions]);

  const handlePrevious = useCallback(() => {
    setIsLoading(true);

    setNextQuestionDirection('previous');
    setChangeSlide((state) => (state > 0 ? state - 1 : state));
    setQuestionPosition((oldState) => (oldState > 0 ? oldState - 1 : oldState));
    onChangeQuestion(
      questionPosition > 0 ? questionPosition - 1 : questionPosition,
    );

    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, [onChangeQuestion, questionPosition]);

  const handleNext = useCallback(() => {
    setIsLoading(true);

    setNextQuestionDirection('next');
    setChangeSlide((state) => (state < questions.length ? state + 1 : state));
    setQuestionPosition((oldState) =>
      oldState < questions.length ? oldState + 1 : oldState,
    );
    onChangeQuestion(
      questionPosition < questions.length
        ? questionPosition + 1
        : questionPosition,
    );

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [questions, onChangeQuestion, questionPosition]);

  return (
    <>
      {questions.length ? (
        <QuestionSlide
          changeSlide={changeSlide}
          nextQuestionDirection={nextQuestionDirection}>
          <RenderQuestion
            hasError={false}
            isQuizCompleted={true}
            currentQuestion={currentQuestion}
            setCurrentQuestion={setCurrentQuestion}
          />
        </QuestionSlide>
      ) : (
        <NotFoundText />
      )}

      <Box display="flex" gap={2} marginTop={3} marginBottom={5}>
        <Button
          style={{minWidth: '150px'}}
          disabled={questionPosition === 0}
          color="primary"
          variant="outlined"
          onClick={handlePrevious}>
          Anterior
        </Button>
        <Button
          style={{minWidth: '150px'}}
          color="primary"
          variant="contained"
          disabled={isLoading || questionPosition === questions.length}
          onClick={handleNext}>
          Próxima
          {isLoading && (
            <CircularProgress
              size={24}
              style={{position: 'absolute', right: 15}}
            />
          )}
        </Button>
      </Box>
      {questionPosition === questions.length && (
        <FinalDialog
          open={questionPosition === questions.length}
          icon={
            <Icon fontSize="large" color="inherit">
              sentiment_satisfied_alt
            </Icon>
          }
          title="Obrigado por responder este questionário.">
          <Box marginBottom={3}>
            <Typography color="primary" variant="h5">
              {getFinalMessage()}
            </Typography>
          </Box>
          <Button
            style={{minWidth: '250px'}}
            color="primary"
            variant="contained"
            onClick={() => navigate(-2)}>
            Voltar para o GVquest
          </Button>
        </FinalDialog>
      )}
    </>
  );
};
