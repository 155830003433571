import React, {useEffect, useState} from 'react';
import {
  Box,
  Icon,
  Grid,
  Container,
  IconButton,
  Typography,
  CircularProgress,
} from '@mui/material';
import {Skeleton} from '@mui/lab';
import {Progress} from './components/Progress';
import {useStyles} from './styles';
import {IStudentQuestion} from 'shared/services/api/student-quiz/QuestionList';
import {useQuiz} from 'pages/quiz/hooks/useQuiz';
import {PreviewQuestionWrapper} from './components/PreviewQuestionWrapper';
import {useNavigate} from 'react-router-dom';
import {ReadMore} from 'shared/components';

export const PreviewQuestionList: React.FC = () => {
  const {questionsToPreview, quizSectionPreview, currentSectionPreviewIndex} =
    useQuiz();
  const styles = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    if (Object.keys(quizSectionPreview).length === 0) {
      navigate('/questionarios');
    } else {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [navigate, quizSectionPreview]);

  return (
    <Box className={styles.root}>
      <Box
        width={'100%'}
        padding={'10px'}
        style={{backgroundColor: '#414141', color: 'white'}}>
        <Box display={'flex'} alignItems={'center'} gap={10}>
          <Icon>warning_amber_outlined</Icon>
          <Typography component={'b'}>
            Questionário em modo de visualização prévia. Algumas informações
            estão indisponíveis.
          </Typography>
        </Box>
      </Box>
      <Container className={styles.container}>
        <Box display="flex" className={styles.card}>
          {isLoading ? (
            <Box>
              <Skeleton width="40%" height={35} />
              <Box marginBottom={1} />
              <Skeleton width="40%" height={21} />
              <Box marginBottom={1} />
              <Skeleton width="40%" height={21} />
            </Box>
          ) : (
            <Box width={'95%'}>
              <ReadMore
                cutAt={80}
                text={
                  Object.keys(quizSectionPreview).length > 0
                    ? quizSectionPreview.secoes[currentSectionPreviewIndex]
                        .nomeSecao
                    : ''
                }
                color="primary"
                variant="h5"
                style={{fontWeight: 700, wordBreak: 'break-all'}}
              />
              <Typography color="primary" variant="body1" gutterBottom>
                {'{descrição do multiplicador}'}
              </Typography>
              <Typography color="textSecondary" variant="body1">
                {'{nome professor/coordenador}'}
              </Typography>
            </Box>
          )}
          <Box width={'5%'}>
            <IconButton
              size="medium"
              onClick={() => {
                navigate(-1);
              }}>
              <Icon>close</Icon>
            </IconButton>
          </Box>
        </Box>
        <Box marginY={5} />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={8}>
            {isLoading ? (
              <CircularProgress size={30} />
            ) : (
              <PreviewQuestionWrapper
                questions={questionsToPreview as IStudentQuestion[]}
                onChangeQuestion={(questionIndex) => {
                  setCurrentQuestionIndex(questionIndex);
                }}
              />
            )}
          </Grid>
        </Grid>
        <Box marginTop={11} />
      </Container>
      <Box className={styles.footer}>
        <Container>
          <Grid container justifyContent="flex-start" alignItems="center">
            <Grid item xs={6} sm={5} md={3}>
              <div>
                <Typography className={styles.colorWhite}>
                  {'{descrição do multiplicador}'}
                </Typography>
                <Typography
                  className={styles.colorWhite}
                  style={{
                    fontWeight: 400,
                    width: 'calc(100% - 30px)',
                    display: 'inline-block',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}>
                  <strong>
                    {Object.keys(quizSectionPreview).length > 0 &&
                      quizSectionPreview.secoes[currentSectionPreviewIndex]
                        .nomeSecao}
                  </strong>
                </Typography>
              </div>
            </Grid>
            <Grid item xs={6} md={6} className={styles.gridProgress}>
              <Box margin={2} className={styles.colorWhite}>
                <Progress
                  count={questionsToPreview?.length as number}
                  currentPosition={currentQuestionIndex}
                  text="respondidas"
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              sm={7}
              md={7}
              className={styles.gridAnsweredQuestions}>
              <Typography align="right" className={styles.colorWhite}>
                {`${currentQuestionIndex} de ${
                  questionsToPreview?.length as number
                } respondidas`}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};
