import React, {useMemo, useCallback, memo} from 'react';
import {Grid, useTheme} from '@mui/material';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import {v4 as uuid} from 'uuid';

import {IOption} from 'shared/services/api/quiz/Quiz';
import {Options} from './Options';

interface IMultipleAnswerConfigProps {
  value: IOption[];
  onChange: (value: IOption[]) => void;
}

export const MultipleAnswerConfig: React.FC<IMultipleAnswerConfigProps> = memo(
  ({value, onChange}) => {
    const theme = useTheme();

    const options = useMemo(() => {
      if (value.length === 0) {
        Array.from(Array(1).keys()).forEach(() => {
          value.push({
            id: `newOption__${uuid()}`,
            description: '',
            order: value.length,
          });
        });
      }

      return value.sort((a, b) => a.order - b.order);
    }, [value]);

    const handleAddOption = useCallback(
      (index: number) => {
        options.splice(index + 1, 0, {
          id: `newOption__${uuid()}`,
          description: '',
          order: index + 1,
        });
        options.forEach((option, index) => {
          option.order = index;
        });
        onChange([...options]);
      },
      [onChange, options],
    );

    const handleRemoveOption = useCallback(
      (index: number) => {
        options.splice(index, 1);
        options.forEach((option, index) => {
          option.order = index;
        });
        onChange([...options]);
      },
      [onChange, options],
    );

    const handleDescriptionChange = useCallback(
      (index: number, text: string) => {
        options[index].description = text;
        onChange([...options]);
      },
      [onChange, options],
    );

    const handleOnDragEnd = useCallback(
      (result: DropResult) => {
        if (!result.destination) return;

        const [reorderedItem] = options.splice(result.source.index, 1);
        options.splice(result.destination.index, 0, reorderedItem);
        options.forEach((option, index) => {
          option.order = index;
        });

        onChange([...options]);
      },
      [onChange, options],
    );

    return (
      <Grid item xs={8}>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <div>
            <Droppable droppableId={'droppable-1'}>
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {value.map((option, index) => (
                    <Draggable
                      key={`unique-answer-option-${option.order}`}
                      draggableId={'draggable-' + option.order}
                      index={index}>
                      {(provided, {isDragging}) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...provided.draggableProps.style,
                            background: theme.palette.background.paper,
                            boxShadow: isDragging
                              ? theme.shadows[4]
                              : undefined,
                            paddingRight: isDragging
                              ? theme.spacing(3)
                              : theme.spacing(0),
                            paddingTop: isDragging
                              ? theme.spacing(1.5)
                              : theme.spacing(2),
                          }}>
                          <Options
                            key={option.order}
                            option={option}
                            quantityOfOptions={value.length}
                            handleAddOption={handleAddOption}
                            handleRemoveOption={handleRemoveOption}
                            handleDescriptionChange={handleDescriptionChange}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </DragDropContext>
      </Grid>
    );
  },
);
