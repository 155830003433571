import {Box, Typography, useTheme} from '@mui/material';
import {memo} from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';

import {IOption} from 'shared/services/api/quiz/Quiz';
import {OptionRow} from './OptionRow';

interface IRowWrapperProps {
  rowList: IOption[];
  onDragEnd: (result: DropResult) => void;
  handleAddRow: (index: number) => void;
  handleRemoveRow: (index: number) => void;
  handleUpdateDescription: (index: number, text: string) => void;
}

export const RowWrapper: React.FC<IRowWrapperProps> = memo(
  ({
    rowList,
    onDragEnd,
    handleAddRow,
    handleRemoveRow,
    handleUpdateDescription,
  }) => {
    const theme = useTheme();

    return (
      <Box>
        <Box marginBottom={3}>
          <Typography>Linhas</Typography>
        </Box>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="lines">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {rowList.map((option, index) => {
                  return (
                    <Draggable
                      key={`GridUniqueAnswer-LineOrder-${option.order}`}
                      draggableId={`draggable line ${option.order}`}
                      index={index}>
                      {(provided, {isDragging}) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...provided.draggableProps.style,
                            background: theme.palette.background.paper,
                            boxShadow: isDragging
                              ? theme.shadows[4]
                              : undefined,
                            paddingRight: isDragging
                              ? theme.spacing(3)
                              : theme.spacing(0),
                            paddingTop: isDragging
                              ? theme.spacing(1.5)
                              : theme.spacing(2),
                          }}>
                          <OptionRow
                            option={option}
                            quantityOfLines={rowList.length}
                            handleAddRow={handleAddRow}
                            handleRemoveRow={handleRemoveRow}
                            handleUpdateDescription={handleUpdateDescription}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
    );
  },
);
