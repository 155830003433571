import React, {useEffect} from 'react';
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from 'react-router-dom';

import {
  QuestionaryTypesListing,
  QuestionaryTypesDetail,
  QuizList,
  QuizDetail,
  InviteReportsListing,
  InviteReportsDetail,
  StudentQuizList,
  StudentAnsweredQuiz,
  StudentSectionList,
  StudentQuestionList,
  PermissionsList,
  PermissionsDetail,
  ProfilesListing,
  ProfilesDetail,
  PreviewSectionList,
  PreviewQuestionList,
  CentralConvitesListagem,
} from 'pages';
import {QuizContextProvider} from 'pages/quiz/contexts/QuizContext';
import {StudentQuestionsProvider} from 'pages/student-quiz/contexts/StudentQuestionsContext';
import {PermissionProvider} from 'pages/permissions/contexts/PermissionContext';
import {useUser} from 'shared/hooks';
import {ReportsProvider} from 'pages/reports/contexts/ReportsContext';
import {StudentQuizProvider} from 'pages/student-quiz/contexts/StudentQuizContext';

export const AppRoutes: React.FC = () => {
  const {allowedRoutes} = useUser();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (allowedRoutes.length > 0) {
      if (
        allowedRoutes.filter(
          (caminho) =>
            location.pathname.includes(caminho.path) ||
            location.pathname.includes('/convite') ||
            location.pathname.includes('/questionario-preview'),
        ).length === 0
      ) {
        navigate('/pagina-inicial');
      }
    }
  }, [allowedRoutes, navigate, location.pathname]);

  return (
    <Routes>
      <Route
        path={'/tipos-questionarios'}
        element={<QuestionaryTypesListing />}
      />
      <Route
        path={'/tipos-questionarios/detalhe/:id'}
        element={<QuestionaryTypesDetail />}
      />

      <Route path={'/questionarios'} element={<QuizList />} />
      <Route path={'/questionarios/:page'} element={<QuizList />} />
      <Route
        path={'/questionarios/detalhe/:id'}
        element={<QuizContextProvider children={<QuizDetail />} />}
      />
      <Route path={'/questionarios/:page/:search'} element={<QuizList />} />

      <Route path={'/relatorios'} element={<InviteReportsListing />} />
      <Route path={'/relatorios/:page'} element={<InviteReportsListing />} />
      <Route
        path={'/relatorios/detalhe/:idConvite'}
        element={<ReportsProvider children={<InviteReportsDetail />} />}
      />

      <Route
        path={'/convite/:idPessoaConvite/secoes/:idSecao/questoes'}
        element={
          <StudentQuestionsProvider children={<StudentQuestionList />} />
        }
      />

      <Route
        path={'/convite/:id/visualizar'}
        element={<StudentAnsweredQuiz />}
      />
      <Route
        path={'/convite/:id'}
        element={<StudentQuizProvider children={<StudentSectionList />} />}
      />

      <Route
        path={'/pagina-inicial'}
        element={<StudentQuizProvider children={<StudentQuizList />} />}
      />

      <Route
        path={'/permissoes'}
        element={<PermissionProvider children={<PermissionsList />} />}
      />
      <Route
        path={'/permissoes/detalhe/:id'}
        element={<PermissionProvider children={<PermissionsDetail />} />}
      />

      <Route path={'/perfis'} element={<ProfilesListing />} />
      <Route path={'/perfis/:page'} element={<ProfilesListing />} />
      <Route path={'/perfis/detalhe/:id'} element={<ProfilesDetail />} />

      <Route
        path={'/questionario-preview'}
        element={<QuizContextProvider children={<PreviewSectionList />} />}
      />
      <Route
        path={'/questionario-preview/questoes'}
        element={<QuizContextProvider children={<PreviewQuestionList />} />}
      />
      <Route path={'/central-convites'} element={<CentralConvitesListagem />} />

      <Route path={'*'} element={<Navigate to="/pagina-inicial" />} />
    </Routes>
  );
};
