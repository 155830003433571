import React, {useMemo, useState, useCallback, useEffect} from 'react';
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
} from '@mui/material';
import {HorizontalBarChart} from '../charts';
import SearchIcon from '@mui/icons-material/Search';
import {IPerson} from 'shared/services/api/reports/IndividualAnswers';
import {useDebounce} from 'shared/hooks';
import {Environment} from 'shared/Env';
import {Pagination} from '@mui/lab';
import {RemainingParticipantsSkeleton} from './RemainingParticipantsSkeleton';

export const RemainingParticipants: React.FC = () => {
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [remainingParticipants, setRemainingParticipants] = useState<IPerson[]>(
    [],
  );
  const [participantsSearch, setParticipantsSearch] = useState<IPerson[]>([]);
  const {debounce} = useDebounce();
  const [page, setPage] = useState(1);

  const itemsPerPage = useMemo(() => {
    return Environment.LIMITE_DE_LINHAS;
  }, []);

  const barColors = useMemo(() => {
    const countLabels = 3;

    const colors = [
      '#FFB10B',
      '#335DAB',
      '#BAC94A',
      '#36A698',
      '#767A7A',
      '#002C5D',
      '#36A698',
      '#D18EAE',
      '#E36843',
      '#235E5A',
    ];

    const randomColors = colors.sort(() => 0.5 - Math.random());

    return randomColors.slice(0, countLabels);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    setRemainingParticipants([]);
    setParticipantsSearch([]);
    setIsLoading(false);
  }, []);

  const removeAccentuation = useCallback((value: string) => {
    const resultFilter = value
      .toLocaleLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

    return resultFilter;
  }, []);

  const handlePageChange = useCallback((event: any | null, page: number) => {
    setPage(page);
  }, []);

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value);

      debounce(() => {
        const valor = removeAccentuation(e.target.value);

        const resultFilter = participantsSearch.filter(
          (item) =>
            removeAccentuation(item.descricaoCurso).indexOf(valor) > -1 ||
            removeAccentuation(item.nome).indexOf(valor) > -1 ||
            removeAccentuation(item.papel).indexOf(valor) > -1,
        );

        setRemainingParticipants(resultFilter);

        if (e.target.value === '') {
          setRemainingParticipants(participantsSearch);
        }

        setPage(1);
      });
    },
    [debounce, removeAccentuation, participantsSearch],
  );

  if (isLoading) {
    return (
      <>
        <RemainingParticipantsSkeleton />
      </>
    );
  }

  return (
    <Box width={'100%'}>
      <Box>
        <Typography variant="subtitle1">
          Pessoas que ainda não responderam por papel
        </Typography>
      </Box>
      <Box width={'100%'} maxWidth={'700px'} marginBottom={3}>
        <HorizontalBarChart
          labels={['Aluno', 'Professor', 'Usuário']}
          datasets={[
            {
              label: 'Respondido',
              categoryPercentage: 1,
              barPercentage: 0.6,
              backgroundColor: barColors,
              borderColor: barColors,
              borderWidth: 1,
              hoverBackgroundColor: barColors,
              hoverBorderColor: barColors,
              data: [199, 85, 51],
            },
          ]}
          options={{
            maintainAspectRatio: true,
            responsive: true,
          }}
          width={600}
          height={300}
          showDataLabels={true}
        />
      </Box>
      <Box width={'100%'}>
        <Box width={'100%'} maxWidth={'500px'} marginBottom={1}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="pesquisar">Pesquisar pessoas</InputLabel>
            <OutlinedInput
              id="search-participants"
              type={'text'}
              value={search}
              onChange={handleSearch}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton aria-label="search participants" edge="end">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>
        <TableContainer component={Paper}>
          <Table size="medium">
            <TableHead>
              <TableRow>
                <TableCell component="th" scope="row">
                  Nome
                </TableCell>
                <TableCell component="th" scope="row">
                  Matrícula
                </TableCell>
                <TableCell component="th" scope="row">
                  Curso
                </TableCell>
                <TableCell component="th" scope="row">
                  Papel
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {remainingParticipants.length > 0 ? (
                remainingParticipants
                  ?.slice(
                    (page - 1) * itemsPerPage,
                    (page - 1) * itemsPerPage + itemsPerPage,
                  )
                  .map((person) => (
                    <TableRow hover>
                      <TableCell component="th" scope="row">
                        {person.nome}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {person.matricula === '0' ? '-' : person.matricula}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {person.descricaoCurso || '-'}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {person.papel || '-'}
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell component="td" scope="row">
                    <Typography component={'h3'} color={'primary'}>
                      Nenhum dado encontrado ou todas as pessoas já responderam.
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {remainingParticipants.length > itemsPerPage && (
            <Box margin={1}>
              <Pagination
                page={page}
                count={Math.ceil(remainingParticipants.length / itemsPerPage)}
                onChange={handlePageChange}
              />
            </Box>
          )}
        </TableContainer>
      </Box>
    </Box>
  );
};
