import {useEffect, useState} from 'react';
import {
  Box,
  Paper,
  useTheme,
  Container,
  Typography,
  useMediaQuery,
  Avatar,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import {useParams, useNavigate} from 'react-router-dom';
import {useTitle} from 'shared/hooks';
import {feedback} from 'shared/services';
import {ActionTab, NotFoundText, ReadMore} from 'shared/components';
import {LogoGVdasa} from 'shared/assets/index';
import {RenderQuestion} from 'pages/student-quiz/components/RenderQuestion';
import {AnsweredQuizSkeleton} from 'pages/student-quiz/AnsweredQuizSkeleton';

import {
  IPerson,
  ISection,
  IIndividualAnswers,
} from 'shared/services/api/reports/IndividualAnswers';
import {AnsweredQuizService} from 'shared/services/api/student-quiz/AnsweredQuiz';

import {useStyles} from './styles';

export const StudentAnsweredQuiz: React.FC = () => {
  const {id = '0'} = useParams<'id'>();
  const {setTitle} = useTitle();
  const theme = useTheme();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [answeredQuiz, setAnsweredQuiz] = useState<IIndividualAnswers>();

  const [sectionList, setSectionList] = useState<ISection[]>([]);
  const [person, setPerson] = useState<IPerson>({
    nome: '',
    matricula: '',
    papel: '',
    descricaoCurso: '',
  });
  const styles = useStyles();
  const isSmallScreen = useMediaQuery((tema) => theme.breakpoints.down('sm'));

  useEffect(() => {
    setTitle('Visualizar questionário');

    AnsweredQuizService.getById(id).then((response) => {
      const {data, success, message} = response;

      if (success) {
        setAnsweredQuiz(data);
        setSectionList(data.secoes);
        setPerson(data.pessoa);
      } else {
        feedback(message, 'error');
      }
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    });
  }, [id, setTitle]);

  return (
    <Container className={styles.answeredQuizContainer}>
      {isLoading ? (
        <AnsweredQuizSkeleton />
      ) : (
        <Box
          overflow="hidden"
          width={'100%'}
          component={!isSmallScreen ? Paper : undefined}>
          {answeredQuiz ? (
            <>
              <Box position="relative">
                {answeredQuiz.bannerBase64 ? (
                  <>
                    {answeredQuiz.logoBase64 &&
                      answeredQuiz.logoBase64 !== '' && (
                        <Box position="absolute" bottom={15} left={15}>
                          <Avatar
                            alt="Logo"
                            src={answeredQuiz.logoBase64}
                            style={{
                              border: `1px solid ${theme.palette.background.paper}`,
                              width: theme.spacing(12.5),
                              height: theme.spacing(12.5),
                            }}>
                            G
                          </Avatar>
                        </Box>
                      )}
                    <Box
                      style={{
                        backgroundImage: `url(${answeredQuiz.bannerBase64})`,
                        backgroundPosition: 'center center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                      }}
                      paddingTop={25}
                    />
                  </>
                ) : (
                  <Box
                    display="flex"
                    alignItems="center"
                    height={200}
                    style={{backgroundColor: theme.palette.primary.main}}>
                    {answeredQuiz.logoBase64 &&
                    answeredQuiz.logoBase64 !== '' ? (
                      <Box position="absolute" bottom={15} left={15}>
                        <Avatar
                          alt="Logo"
                          src={answeredQuiz.logoBase64}
                          style={{
                            border: `1px solid ${theme.palette.background.paper}`,
                            width: theme.spacing(12.5),
                            height: theme.spacing(12.5),
                          }}>
                          G
                        </Avatar>
                      </Box>
                    ) : (
                      <Container>
                        <img
                          src={LogoGVdasa.default}
                          alt="Logo GVdasa"
                          style={{
                            maxWidth: `11rem`,
                          }}
                        />
                      </Container>
                    )}
                  </Box>
                )}
              </Box>

              <Container marginTop={6} component={Box}>
                <ReadMore
                  cutAt={200}
                  text={answeredQuiz.tituloQuestionario}
                  variant="h4"
                  color="primary"
                />
                <ReadMore text={answeredQuiz.descricao} cutAt={200} />
              </Container>

              <Container marginTop={4} marginBottom={6} component={Box}>
                <Box display="flex" gap={1} alignItems="center">
                  <PersonIcon />
                  <Typography variant={'h5'}>
                    {person.nome} - {person.papel}
                  </Typography>
                </Box>
                {person.descricaoCurso && (
                  <Box display="flex" gap={1} alignItems="center">
                    <SchoolOutlinedIcon fontSize="small" />
                    <Typography>{person.descricaoCurso}</Typography>
                  </Box>
                )}
                {person.matricula && (
                  <Box display="flex" gap={1} alignItems="center">
                    <DescriptionOutlinedIcon fontSize="small" />
                    <Typography>Matrícula {person.matricula}</Typography>
                  </Box>
                )}
                <Box marginBottom={1} />
                <Box display="flex" gap={1} alignItems="center">
                  <EventAvailableOutlinedIcon fontSize="small" />
                  <Typography style={{fontSize: '15px'}}>
                    Respondido em {answeredQuiz.respondidoEm}
                  </Typography>
                </Box>
              </Container>
            </>
          ) : (
            <NotFoundText />
          )}
          {sectionList.map((section, index) => (
            <Box key={`section-${index}`}>
              <Box
                width="100%"
                paddingY={1}
                paddingX={3}
                bgcolor={theme.palette.secondary.main}>
                <ReadMore
                  cutAt={95}
                  text={section.nomeSecao}
                  variant={'h6'}
                  style={{color: '#fff'}}
                />
              </Box>
              {section.multiplicadores.map((multiplicador, index) => (
                <Box key={`multiplier-${index}`}>
                  {multiplicador.nome && (
                    <Box
                      width="100%"
                      paddingY={1}
                      paddingX={3}
                      bgcolor={theme.palette.secondary.light}>
                      <Typography style={{fontSize: '15px', color: '#fff'}}>
                        {multiplicador.nome}
                      </Typography>
                    </Box>
                  )}
                  <Box
                    width="100%"
                    padding={3}
                    display="flex"
                    gap={6}
                    flexDirection="column">
                    {multiplicador.questoes.map((question, index) => (
                      <RenderQuestion
                        key={`question-${index}`}
                        setCurrentQuestion={() => {}}
                        currentQuestion={question}
                      />
                    ))}
                  </Box>
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      )}
      <ActionTab
        onCancel={() => {
          navigate('/pagina-inicial');
        }}
        onCancelLabel="Voltar"
      />
    </Container>
  );
};
