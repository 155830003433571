import React from 'react';
import { Line } from 'react-chartjs-2';
import { useStyles } from './styles';
import {IChartProps} from './ChartType';

export const LineChart: React.FC<IChartProps> = ({
  labels,
  datasets,
  options,
  width,
  height,
  showDataLabels
}) => {
  const classes = useStyles();

  return (
    <div className={classes.chartWrapper}>
      <Line
        data={{labels, datasets}}
        width={width}
        height={height}
        options={{
          ...options,
          layout: {
            padding: {
              left: 1,
              right: 40,
              top: 20,
              bottom: 20
            }
          },
          type: 'line',
          scales: {
            x: {
              grid: {
                display: false
              },
            },
            y: {
              grid: {
                display: false
              },
            }
          },
          plugins: {
            datalabels: {
              display: showDataLabels,
              color: "#33C7BD",
              align: "end",
              padding: {
                right: 2
              },
              labels: {
                padding: { top: 10 },
                title: {
                  font: {
                    weight: "bold"
                  }
                },
                value: {
                  color: "green"
                }
              },
              formatter: function (value: any) {
                return "\n" + value;
              }
            }
          }
        }}
      />
    </div>
  );
};
