import React, {useState, useEffect, useMemo, useCallback} from 'react';
import {
  FormControl,
  Grid,
  InputAdornment,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Container,
  Box,
  Typography,
  Icon,
  Theme,
  Tooltip,
  TextField,
  CircularProgress,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {makeStyles} from '@mui/styles';
import {useTitle, useDebounce} from 'shared/hooks';
import {Pagination, ToggleButton, ToggleButtonGroup} from '@mui/lab';
import {useNavigate} from 'react-router-dom';
import {Environment} from 'shared/Env';
import {
  IPermissionsList,
  PermissionsListService,
} from 'shared/services/api/permissions/PermissionsList';
import {PermissionsListSkeleton} from './PermissionsListSkeleton';
import {ErrorPage, NotFoundText} from 'shared/components';
import {feedback} from 'shared/services';
import {usePermissions} from './hooks/usePermissions';

const useStyles = makeStyles((theme: Theme) => ({
  toggleButton: {
    '&.MuiToggleButton-root.Mui-selected': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));

export const PermissionsList: React.FC = () => {
  const {setCurrentUserCode} = usePermissions();
  const [userPermList, setUserPerList] = useState<IPermissionsList[]>([]);
  const [searchUserPermissions, setSearchUserPermissions] = useState<
    IPermissionsList[]
  >([]);
  const {setTitle} = useTitle();
  const [search, setSearch] = useState('');
  const [toggleButtonValue, setToggleButtonValue] = useState(0);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const {debounce} = useDebounce();
  const [page, setPage] = useState(1);
  const [hasCriticalError, setHasCriticalError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const rowsPerPage = useMemo(() => {
    return Environment.LIMITE_DE_LINHAS;
  }, []);

  useEffect(() => {
    setTitle('Usuários');
  }, [setTitle]);

  useEffect(() => {
    setIsLoading(true);
    PermissionsListService.getAll().then((result) => {
      if (result.success) {
        setUserPerList(result.data);
        setSearchUserPermissions(result.data);
        setIsLoading(false);
      } else {
        if (result.message.includes('ServiceUnavailable')) {
          setHasCriticalError(true);
          setErrorMessage(
            result.message.substring(result.message.indexOf('|') + 1),
          );
        }
        feedback(
          result.message || 'Erro ao carregar permissões de usuário',
          'error',
        );
        setIsLoading(false);
      }
    });
  }, []);

  const allUserPermissions = useMemo(() => {
    const result = searchUserPermissions;
    return result;
  }, [searchUserPermissions]);

  const enabledUserPermissions = useMemo(() => {
    const result = searchUserPermissions.filter((item) => item.active === true);
    return result;
  }, [searchUserPermissions]);

  const disabledUserPermissions = useMemo(() => {
    const result = searchUserPermissions.filter(
      (item) => item.active === false,
    );
    return result;
  }, [searchUserPermissions]);

  const handleButtonsFiltering = useCallback(
    (buttonValue: number) => {
      switch (buttonValue) {
        case 1:
          setUserPerList([...enabledUserPermissions]);
          break;
        case 2:
          setUserPerList([...disabledUserPermissions]);
          break;

        default:
          setUserPerList([...allUserPermissions]);
          break;
      }
      setPage(1);
    },
    [allUserPermissions, disabledUserPermissions, enabledUserPermissions],
  );

  const handleToggleButtonChange = (
    event: React.MouseEvent<HTMLElement>,
    value: number,
  ) => {
    setToggleButtonValue(value);
    handleButtonsFiltering(value);
  };

  const handlePageChange = useCallback(
    (event: React.ChangeEvent<unknown>, value: number) => {
      setPage(value);
    },
    [],
  );

  const removeAccentuation = useCallback((value: string) => {
    const resultFilter = value
      .toLocaleLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

    return resultFilter;
  }, []);

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsLoading(true);
      setSearch(e.target.value);

      debounce(() => {
        const valor = removeAccentuation(e.target.value);

        const result = searchUserPermissions.filter(
          (item) =>
            removeAccentuation(item.name).indexOf(valor) > -1 ||
            removeAccentuation(item.login).indexOf(valor) > -1,
        );

        if (e.target.value === '') {
          setUserPerList(result);
        } else {
          setUserPerList(result);
        }

        setPage(1);
        setIsLoading(false);
      });
    },
    [debounce, removeAccentuation, searchUserPermissions],
  );

  if (hasCriticalError) {
    return (
      <Container>
        <ErrorPage message={errorMessage} />
      </Container>
    );
  }

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid container item xs={12} spacing={0} alignItems={'center'}>
          <Grid item xs={6}>
            <FormControl variant="outlined" fullWidth>
              <Paper>
                <TextField
                  style={{padding: 0, margin: 0}}
                  fullWidth
                  variant="outlined"
                  placeholder="Pesquise pelo nome do perfil"
                  value={search}
                  onChange={handleSearch}
                  InputProps={{
                    style: {
                      height: 40,
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        {isLoading ? (
                          <CircularProgress size={30} />
                        ) : (
                          <SearchIcon color="disabled" />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Paper>
            </FormControl>
          </Grid>
          <Grid
            container
            item
            xs={6}
            justifyContent={'flex-end'}
            alignItems={'center'}>
            <ToggleButtonGroup
              size="small"
              value={toggleButtonValue}
              exclusive
              onChange={handleToggleButtonChange}>
              <ToggleButton
                disabled={allUserPermissions.length > 0 ? false : true}
                color="primary"
                value={0}
                className={classes.toggleButton}>
                Todos
              </ToggleButton>
              <ToggleButton
                disabled={enabledUserPermissions.length > 0 ? false : true}
                color="primary"
                value={1}
                className={classes.toggleButton}>
                Somente habilitados
              </ToggleButton>
              <ToggleButton
                disabled={disabledUserPermissions.length > 0 ? false : true}
                color="primary"
                value={2}
                className={classes.toggleButton}>
                Não habilitados
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </Grid>
        <Grid container item xs={12} marginBottom={1}>
          {isLoading ? (
            <PermissionsListSkeleton />
          ) : (
            <TableContainer component={Paper} elevation={3}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Login
                    </TableCell>
                    <TableCell component="th" scope="row">
                      Nome
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      width={100}
                      align="right">
                      Ações
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userPermList.length ? (
                    userPermList
                      ?.slice(
                        (page - 1) * rowsPerPage,
                        (page - 1) * rowsPerPage + rowsPerPage,
                      )
                      .map((userPer) => (
                        <TableRow hover key={userPer.userCode}>
                          <TableCell component="th" scope="row">
                            <Box display={'flex'} gap={2} alignItems={'center'}>
                              <Icon
                                color={
                                  userPer.active ? 'secondary' : 'disabled'
                                }
                                fontSize={'small'}>
                                {userPer.active ? 'check_circle' : 'circle'}
                              </Icon>
                              <Typography>{userPer.login}</Typography>
                            </Box>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {userPer.name}
                          </TableCell>
                          <TableCell component="th" scope="row" align="right">
                            <Tooltip
                              title={
                                userPer.active
                                  ? 'Gerenciar permissões'
                                  : 'Adicionar permissões'
                              }>
                              <IconButton
                                disabled={false}
                                size={'small'}
                                onClick={() => {
                                  if (userPer.id !== null) {
                                    navigate(
                                      `/permissoes/detalhe/${userPer.userCode}`,
                                    );
                                  } else {
                                    localStorage.setItem(
                                      'currentUserCode',
                                      String(userPer.userCode),
                                    );
                                    setCurrentUserCode(
                                      String(userPer.userCode),
                                    );
                                    setTimeout(() => {
                                      navigate(`/permissoes/detalhe/0`);
                                    }, 100);
                                  }
                                }}>
                                <Icon fontSize={'small'}>
                                  {userPer.active ? 'edit' : 'person_add'}
                                </Icon>
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))
                  ) : (
                    <TableRow>
                      <TableCell>
                        <NotFoundText />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              {rowsPerPage && userPermList.length > 0 && (
                <Box
                  marginTop={1}
                  marginBottom={1}
                  marginLeft={1}
                  marginRight={1}
                  display={'flex'}
                  justifyContent={'end'}>
                  <Pagination
                    page={page}
                    count={Math.ceil(userPermList.length / rowsPerPage)}
                    onChange={handlePageChange}
                  />
                </Box>
              )}
            </TableContainer>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
