import React, {useEffect, useState} from 'react';
import {
  Container,
  Box,
  Grid,
  FormControl,
  InputAdornment,
  Theme,
  AppBar,
  Tabs,
  Tab,
  Paper,
  TextField,
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {useTitle} from 'shared/hooks';
import {usePermissions} from './hooks/usePermissions';
import {PermissionsDetailQuest} from './components/PermissionsDetailQuest';
import {PermissionsDetailPublicoAlvo} from './components/PermissionsDetailPublicoAlvo';
import {ActionTab} from 'shared/components';
import {useNavigate} from 'react-router-dom';
import {PermissionsDetailSkeleton} from './PermissionsDetailSkeleton';
import PersonIcon from '@mui/icons-material/Person';

const useStyles = makeStyles((theme: Theme) => ({
  tabs: {
    flexGrow: 1,
    backgroundColor: 'transparent',

    '& > header': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },
  tabChild: {
    '& > div': {
      padding: '24px 0',
    },
  },
  tabButtonElement: {
    padding: '6px 0',
  },
  tabsParent: {
    '& > div > div': {
      gap: '16px',
    },
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const PermissionsTabPanel: React.FC<TabPanelProps> = (
  props: TabPanelProps,
) => {
  const classes = useStyles();
  const {children, value, index, ...other} = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box className={classes.tabChild}>{value === index && children}</Box>
      )}
    </Box>
  );
};

export const PermissionsDetail: React.FC = () => {
  const {userFullName, isLoadingPermissions, savePermissions} =
    usePermissions();
  const {setTitle} = useTitle();
  const [value, setValue] = useState(0);
  const classes = useStyles();
  const navigate = useNavigate();

  const [estabsFromUserChoice, setEstabsFromUserChoice] = useState<number[]>(
    [],
  );

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    setTitle('Gerenciar permissões de usuário');
  }, [setTitle]);

  return (
    <Container>
      {isLoadingPermissions ? (
        <PermissionsDetailSkeleton />
      ) : (
        <>
          <Box width={'100%'} marginBottom={3}>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <FormControl variant="outlined" fullWidth>
                  <Paper>
                    <TextField
                      style={{padding: 0, margin: 0}}
                      fullWidth
                      variant="outlined"
                      placeholder="Pesquise pelo nome do perfil"
                      disabled
                      value={isLoadingPermissions ? 'Nome' : userFullName}
                      InputProps={{
                        style: {
                          height: 40,
                        },
                        startAdornment: (
                          <InputAdornment position="start">
                            <PersonIcon color="primary" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Paper>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          <Box className={classes.tabs}>
            <AppBar position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="Abas de permissões"
                indicatorColor="primary"
                textColor="secondary"
                className={classes.tabsParent}>
                <Tab
                  label="Quest"
                  id={'tab-0'}
                  className={classes.tabButtonElement}
                />
                <Tab
                  label="Público-alvo"
                  id={'tab-1'}
                  className={classes.tabButtonElement}
                />
              </Tabs>
            </AppBar>
            <PermissionsTabPanel value={value} index={0}>
              <PermissionsDetailQuest />
            </PermissionsTabPanel>
            <PermissionsTabPanel value={value} index={1}>
              <PermissionsDetailPublicoAlvo
                estabsFromUserChoice={estabsFromUserChoice}
                onChangeEstabsFromUserChoice={(value) => {
                  setEstabsFromUserChoice(value);
                }}
              />
            </PermissionsTabPanel>
          </Box>
          <ActionTab
            onCancel={() => {
              navigate('/permissoes');
            }}
            onSave={() => {
              savePermissions(estabsFromUserChoice);
            }}
            onCancelLabel={'Voltar'}
            onSaveLabel={'Salvar'}
          />
        </>
      )}
    </Container>
  );
};
