import React, {memo} from 'react';
import {MenuItem, MenuList, Paper, Box, Icon} from '@mui/material';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import ViewAgendaOutlinedIcon from '@mui/icons-material/ViewAgendaOutlined';
import SwapVerticalCircleOutlinedIcon from '@mui/icons-material/SwapVerticalCircleOutlined';
import BackArrowIcon from '@mui/icons-material/ArrowBackOutlined';
import PreviewIcon from '@mui/icons-material/VisibilityOutlined';
import {Tooltip} from 'shared/components';
import {useQuiz} from '../hooks/useQuiz';
import {useNavigate} from 'react-router-dom';

interface IDetailActionsProps {
  isOnQuestTab: boolean;
  openModalReorder: (value: boolean) => void;
}

export const DetailActions: React.FC<IDetailActionsProps> = memo(
  ({isOnQuestTab, openModalReorder}) => {
    const {
      addSection,
      addQuestion,
      saveQuiz,
      hasInviteSent,
      previewQuiz,
      isEditingQuiz,
      saveQuizWithInvite,
      isSavingQuiz,
    } = useQuiz();
    const navigate = useNavigate();

    return (
      <>
        <Paper variant={'outlined'}>
          <MenuList>
            <MenuItem
              onClick={() => addQuestion()}
              disabled={!isOnQuestTab || hasInviteSent}>
              <Tooltip text="Adicionar questão" position="right">
                <AddCircleOutlineRoundedIcon />
              </Tooltip>
            </MenuItem>

            <MenuItem
              onClick={addSection}
              disabled={!isOnQuestTab || hasInviteSent}>
              <Tooltip text="Adicionar seção" position="right">
                <ViewAgendaOutlinedIcon />
              </Tooltip>
            </MenuItem>

            <MenuItem
              onClick={() => openModalReorder(true)}
              disabled={!isOnQuestTab || hasInviteSent}>
              <Tooltip text="Reordenar seções" position="right">
                <SwapVerticalCircleOutlinedIcon />
              </Tooltip>
            </MenuItem>

            <MenuItem onClick={previewQuiz} disabled={hasInviteSent}>
              <Tooltip text="Visualização prévia" position="right">
                <PreviewIcon />
              </Tooltip>
            </MenuItem>

            <MenuItem
              onClick={hasInviteSent ? saveQuizWithInvite : saveQuiz}
              disabled={isSavingQuiz}>
              {isEditingQuiz ? (
                <Box position="relative">
                  <Tooltip
                    text="Você tem alterações não salvas!"
                    position="right">
                    <SaveOutlinedIcon />
                  </Tooltip>
                  <Icon
                    color="error"
                    style={{fontSize: '10px', position: 'absolute'}}>
                    circle_filled
                  </Icon>
                </Box>
              ) : (
                <Tooltip text="Salvar" position="right">
                  <SaveOutlinedIcon />
                </Tooltip>
              )}
            </MenuItem>
          </MenuList>
        </Paper>
        <Paper variant={'outlined'}>
          <MenuItem onClick={() => navigate('/questionarios')}>
            <Tooltip text="Voltar" position="right">
              <BackArrowIcon style={{marginTop: '8px'}} />
            </Tooltip>
          </MenuItem>
        </Paper>
      </>
    );
  },
);
