import React from 'react';

import {Box, Typography} from '@mui/material';

export const NotFoundText: React.FC = () => {
    return (
        <Box padding={2}>
            <Typography color="secondary">
                Nenhum registro encontrado...
            </Typography>
        </Box>
    );
};
