import React, {memo} from 'react';

import {Box, TextField} from '@mui/material';

interface IDatePreviewProps {
  hasComment?: boolean;
}

export const DatePreview: React.FC<IDatePreviewProps> = memo(({hasComment}) => {
  return (
    <Box>
      <TextField
        variant="outlined"
        disabled
        type="date"
        placeholder="DD/MM/AAAA"
      />
      {hasComment && (
        <Box marginTop={3}>
          <TextField
            variant="outlined"
            type="text"
            disabled
            placeholder="Deixe um comentário"
          />
        </Box>
      )}
    </Box>
  );
});
