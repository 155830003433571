import React, {useCallback, useMemo, memo} from 'react';
import {
  Box,
  Radio,
  TextField,
  RadioGroup,
  Typography,
  FormControlLabel,
  Grid,
  useMediaQuery,
} from '@mui/material';

import {IOptions} from 'shared/services/api/student-quiz/QuestionList';

interface ISatisfactionPercentagePreviewProps {
  options: IOptions[];
  comment: string;
  hasComment?: boolean;
  isCompleted: boolean;
  setComment: (comment: string) => void;
  onChange: (options: IOptions[]) => void;
}

export const SatisfactionPercentagePreview: React.FC<ISatisfactionPercentagePreviewProps> =
  memo(
    ({
      options,
      comment,
      hasComment,
      isCompleted,
      setComment,
      onChange,
    }: ISatisfactionPercentagePreviewProps) => {
      const isMobile = useMediaQuery('(max-width:600px)');

      const selectedId = useMemo(() => {
        return options.find((option) => option.selecionado)?.id || false;
      }, [options]);

      const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
          const currentSelectedId = (event.target as HTMLInputElement).value;

          const lastItemSelected: number = options.findIndex(
            (option) => option.selecionado,
          );

          if (lastItemSelected !== -1)
            options[lastItemSelected].selecionado = false;

          const currentItemSelected: number = options.findIndex(
            (option) => option.id === currentSelectedId,
          );

          options[currentItemSelected].selecionado = true;

          onChange(options);
        },
        [onChange, options],
      );

      const handleComment = (e: React.ChangeEvent<HTMLInputElement>) => {
        setComment(e.target.value);
      };

      return (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <>
            <RadioGroup
              sx={{display: 'flex', flexDirection: isMobile ? 'column' : 'row'}}
              value={selectedId}
              onChange={handleChange}>
              {options.map((option, index) => {
                return (
                  <Box
                    display={'flex'}
                    justifyContent={'flex-start'}
                    alignItems={'center'}>
                    <FormControlLabel
                      labelPlacement={isMobile ? 'end' : 'top'}
                      key={option.id}
                      label={
                        <>
                          <Typography marginLeft={0.5} align="center">
                            {option.descricao || `Opção ${index + 1}`}
                          </Typography>
                        </>
                      }
                      value={option.id}
                      disabled={isCompleted}
                      control={<Radio color="primary" />}
                    />
                  </Box>
                );
              })}
            </RadioGroup>
            {((hasComment && !isCompleted) ||
              (comment !== '' && isCompleted)) && (
              <Box marginTop={3} justifyContent="center" display="flex">
                <TextField
                  fullWidth
                  multiline
                  rows={2}
                  disabled={isCompleted}
                  color="primary"
                  label="Comentário"
                  variant="outlined"
                  placeholder="Adicionar um comentário"
                  value={comment}
                  onChange={handleComment}
                />
              </Box>
            )}
          </>
        </Grid>
      );
    },
  );
