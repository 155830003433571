import {Api} from 'shared/services/axios-config';
import {IResultOfAction} from 'shared/services/axios-config/interceptors';

export type IStatusConvite =
  | 'EM_ELABORACAO'
  | 'ACEITANDO_RESPOSTAS'
  | 'BLOQUEADO_PARA_RESPOSTAS'
  | 'CONVITE_ENCERRADO'
  | 'AGUARDANDO_LIBERACAO'
  | 'LIBERACAO_INICIADA'
  | 'ERRO_LIBERACAO'
  | 'REPROCESSAMENTO_DO_PUBLICO_ALVO'
  | 'PUBLICO_ALVO_SEM_DESTINATARIOS';

export interface IConvite {
  Id: string;
  Descricao: string;
  IdQuestionario: string;
  NomeQuestionario: string;
  QuestionarioAtivo: boolean;
  DataHoraInicio: string;
  DataHoraFim: string;
  AceitandoRespostas: boolean;
  LinkConvite: string;
  PermitirAcesso: boolean;
  Status: IStatusConvite;
  Resposta: number;
  DataHoraBloqueio: string;
  MensagemDeErro: string | null;
}

export interface IHistoricoConvite {
  Id: string;
  EtapaDeProcessamento: string;
  Status: IStatusConvite;
  DataHora: string;
  MensagemErro: string | null;
}

const getAll = async (): Promise<IResultOfAction<IConvite[]>> => {
  try {
    const data = await Api.get('/convites/geral');

    const response: IResultOfAction<IConvite[]> = {
      success: data.status === 200,
      message: data.statusText,
      data: data.data,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const getHistoricoConvite = async (
  idConvite: string,
): Promise<IResultOfAction<IHistoricoConvite[]>> => {
  try {
    const data = await Api.get(`convites/historico/${idConvite}`);

    const response: IResultOfAction<IHistoricoConvite[]> = {
      success: data.status === 200,
      message: data.statusText,
      data: data.data,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

export const CentralConvitesService = {
  getAll,
  getHistoricoConvite,
};
