import React from 'react';
import {
  Box,
  Grid
} from '@mui/material';
import {Skeleton} from '@mui/lab';

export const ProfilesDetailSkeleton: React.FC = () => {
  return (
    <>
      <Box width={'100%'} marginBottom={3}>
        <Box width={'100%'} maxWidth={'50%'}>
          <Skeleton variant="rectangular" width="100%" height={56} />
        </Box>
      </Box>
      <Box width={'100%'}>
        <Box width={'100%'} maxWidth={'70%'}>
          <Skeleton variant="rectangular" width="100%" height={56} />
        </Box>
      </Box>
      <Box marginTop={4}>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={4}>
            <Skeleton variant="rectangular" width="100%" height={32} />
            <Grid item xs={12}>
              <Box marginTop={2} />
              <Skeleton width="100%" variant="text" />
              <Box marginTop={2} />
              <Skeleton width="100%" variant="text" />
              <Box marginTop={2} />
              <Skeleton width="100%" variant="text" />
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Box height={'48px'} />
            <Grid item xs={12}>
              <Skeleton width="100%" variant="text" />
              <Box marginTop={2} />
              <Skeleton width="100%" variant="text" />
              <Box marginTop={2} />
              <Skeleton width="100%" variant="text" />
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Box height={'48px'} />
            <Grid item xs={12}>
              <Skeleton width="100%" variant="text" />
              <Box marginTop={2} />
              <Skeleton width="100%" variant="text" />
              <Box marginTop={2} />
              <Skeleton width="100%" variant="text" />
            </Grid>
          </Grid>
        </Grid>
        <Box marginTop={4}></Box>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={4}>
            <Skeleton variant="rectangular" width="100%" height={32} />
            <Grid item xs={12}>
              <Box marginTop={2} />
              <Skeleton width="100%" variant="text" />
              <Box marginTop={2} />
              <Skeleton width="100%" variant="text" />
              <Box marginTop={2} />
              <Skeleton width="100%" variant="text" />
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Box height={'48px'} />
            <Grid item xs={12}>
              <Skeleton width="100%" variant="text" />
              <Box marginTop={2} />
              <Skeleton width="100%" variant="text" />
              <Box marginTop={2} />
              <Skeleton width="100%" variant="text" />
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Box height={'48px'} />
            <Grid item xs={12}>
              <Skeleton width="100%" variant="text" />
              <Box marginTop={2} />
              <Skeleton width="100%" variant="text" />
              <Box marginTop={2} />
              <Skeleton width="100%" variant="text" />
            </Grid>
          </Grid>
        </Grid>
        <Box marginTop={4}></Box>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={4}>
            <Skeleton variant="rectangular" width="100%" height={32} />
            <Grid item xs={12}>
              <Box marginTop={2} />
              <Skeleton width="100%" variant="text" />
              <Box marginTop={2} />
              <Skeleton width="100%" variant="text" />
              <Box marginTop={2} />
              <Skeleton width="100%" variant="text" />
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Box height={'48px'} />
            <Grid item xs={12}>
              <Skeleton width="100%" variant="text" />
              <Box marginTop={2} />
              <Skeleton width="100%" variant="text" />
              <Box marginTop={2} />
              <Skeleton width="100%" variant="text" />
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Box height={'48px'} />
            <Grid item xs={12}>
              <Skeleton width="100%" variant="text" />
              <Box marginTop={2} />
              <Skeleton width="100%" variant="text" />
              <Box marginTop={2} />
              <Skeleton width="100%" variant="text" />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
