import {Box, Divider} from '@mui/material';
import React, {memo, useCallback, useMemo} from 'react';
import {DropResult} from 'react-beautiful-dnd';
import {v4 as uuid} from 'uuid';

import {IOption} from 'shared/services/api/quiz/Quiz';
import {ColumnWrapper} from './ColumnWrapper';
import {RowWrapper} from './RowWrapper';

interface IGridMultipleAnswerConfigProps {
  columnOptions: IOption[];
  rowOptions: IOption[];
  onChangeRows: (value: IOption[]) => void;
  onChangeColumns: (value: IOption[]) => void;
}

export const GridMultipleAnswerConfig: React.FC<IGridMultipleAnswerConfigProps> =
  memo(({columnOptions, rowOptions, onChangeRows, onChangeColumns}) => {
    const optionsCol = useMemo(() => {
      if (columnOptions.length === 0) {
        Array.from(Array(3).keys()).forEach(() => {
          columnOptions.push({
            id: `newOption__${uuid()}`,
            description: '',
            order: columnOptions.length,
          });
        });
      }

      return columnOptions.sort((a, b) => a.order - b.order);
    }, [columnOptions]);

    const optionsRow = useMemo(() => {
      if (rowOptions.length === 0) {
        Array.from(Array(2).keys()).forEach(() => {
          rowOptions.push({
            id: `newOption__${uuid()}`,
            description: '',
            order: rowOptions.length,
          });
        });
      }

      return rowOptions.sort((a, b) => a.order - b.order);
    }, [rowOptions]);

    const handleColumnDescriptionChange = useCallback(
      (index: number, text: string) => {
        optionsCol[index].description = text;
        onChangeColumns([...optionsCol]);
      },
      [onChangeColumns, optionsCol],
    );

    const handleRowDescriptionChange = useCallback(
      (index: number, text: string) => {
        optionsRow[index].description = text;
        onChangeRows([...optionsRow]);
      },
      [onChangeRows, optionsRow],
    );

    const handleValueChange = useCallback(
      (index: number, value: string) => {
        optionsCol[index].iconOrColor = value;
        onChangeColumns([...optionsCol]);
      },
      [onChangeColumns, optionsCol],
    );

    const handleAddColumn = useCallback(
      (index: number) => {
        optionsCol.splice(index + 1, 0, {
          id: `newOption__${uuid()}`,
          description: '',
          order: index + 1,
          iconOrColor: '',
        });
        optionsCol.forEach((option, index) => {
          option.order = index;
        });
        onChangeColumns([...optionsCol]);
      },
      [onChangeColumns, optionsCol],
    );

    const handleRemoveColumn = useCallback(
      (index: number) => {
        optionsCol.splice(index, 1);
        optionsCol.forEach((option, index) => {
          option.order = index;
        });
        onChangeColumns([...optionsCol]);
      },
      [onChangeColumns, optionsCol],
    );

    const handleAddRow = useCallback(
      (index: number) => {
        optionsRow.splice(index + 1, 0, {
          id: `newOption__${uuid()}`,
          description: '',
          order: index + 1,
          iconOrColor: '',
        });
        optionsRow.forEach((option, index) => {
          option.order = index;
        });
        onChangeRows([...optionsRow]);
      },
      [onChangeRows, optionsRow],
    );

    const handleRemoveRow = useCallback(
      (index: number) => {
        optionsRow.splice(index, 1);
        optionsRow.forEach((option, index) => {
          option.order = index;
        });
        onChangeRows([...optionsRow]);
      },
      [onChangeRows, optionsRow],
    );

    const handleOnDragEndColumn = useCallback(
      (result: DropResult) => {
        if (!result.destination) return;

        const [reorderedItem] = optionsCol.splice(result.source.index, 1);
        optionsCol.splice(result.destination.index, 0, reorderedItem);
        optionsCol.forEach((option, index) => {
          option.order = index;
        });

        onChangeColumns([...optionsCol]);
      },
      [onChangeColumns, optionsCol],
    );

    const handleOnDragEndRow = useCallback(
      (result: DropResult) => {
        if (!result.destination) return;

        const [reorderedItem] = optionsRow.splice(result.source.index, 1);
        optionsRow.splice(result.destination.index, 0, reorderedItem);
        optionsRow.forEach((option, index) => {
          option.order = index;
        });

        onChangeRows([...optionsRow]);
      },
      [onChangeRows, optionsRow],
    );

    return (
      <Box display="flex" justifyContent="space-between">
        <RowWrapper
          rowList={optionsRow}
          handleAddRow={handleAddRow}
          handleRemoveRow={handleRemoveRow}
          handleUpdateDescription={handleRowDescriptionChange}
          onDragEnd={handleOnDragEndRow}
        />

        <Box>
          <Divider orientation="vertical" />
        </Box>

        <ColumnWrapper
          columnList={optionsCol}
          handleAddColumn={handleAddColumn}
          handleRemoveColumn={handleRemoveColumn}
          handleUpdateDescription={handleColumnDescriptionChange}
          onChangeSelect={handleValueChange}
          onDragEnd={handleOnDragEndColumn}
        />
      </Box>
    );
  });
