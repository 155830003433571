import React, {createContext, useEffect, useState} from 'react';
import {feedback} from 'shared/services';
import {
  EstablishmentService,
  IEstablishmentTree,
} from 'shared/services/api/establishment/Establishment';
import {useParams, useNavigate} from 'react-router-dom';
import {
  IEstablishmentsProfiles,
  IPermissionsProfiles,
  IPermissions,
  PermissionsDetailService,
} from 'shared/services/api/permissions/PermissionsDetail';

interface IPermissionsContextData {
  userFullName: string;
  establishmentProfiles: IEstablishmentsProfiles[];
  setEstablishmentProfiles: (ep: IEstablishmentsProfiles[]) => void;
  permissionsProfiles: IPermissionsProfiles[];
  establishments: IEstablishmentTree[];
  isLoadingPermissions: boolean;
  userPermissions: IPermissions;
  savePermissions: (value?: number[]) => void;
  userSelectedEstablishments: number[];
  setUserSelectedEstablishments: (userSelectedEstablishments: number[]) => void;
  userSelectedStructuredEstablishments: string[];
  setUserSelectedStructuredEstablishments: (
    userSelectedStructuredEstablishments: string[],
  ) => void;
  enabledInPublicoAlvo: boolean;
  setEnabledInPublicoAlvo: (enabledInPublicoAlvo: boolean) => void;
  userSubmitted: boolean;
  setUserSubmitted: (userSubmitted: boolean) => void;
  setCurrentUserCode: (userCode: string) => void;
  currentUserCode: string;
}

export const UserPermissionContext = createContext<IPermissionsContextData>(
  {} as IPermissionsContextData,
);

export const PermissionProvider: React.FC = ({children}) => {
  const [permissionsProfiles, setPermissionsProfiles] = useState<
    IPermissionsProfiles[]
  >([]);
  const [establishments, setEstablishments] = useState<IEstablishmentTree[]>(
    [],
  );
  const [userFullName, setUserFullName] = useState('');
  const [establishmentProfiles, setEstablishmentProfiles] = useState<
    IEstablishmentsProfiles[]
  >([]);
  const {id = '0'} = useParams<'id'>();
  const DEFAULT_ID = '00000000-0000-0000-0000-000000000000';
  const [isLoadingPermissions, setIsLoadingPermissions] = useState(false);
  const [userPermissions, setUserPermissions] = useState<IPermissions>(
    {} as IPermissions,
  );

  const [userSelectedEstablishments, setUserSelectedEstablishments] = useState<
    number[]
  >([]);
  const [
    userSelectedStructuredEstablishments,
    setUserSelectedStructuredEstablishments,
  ] = useState<string[]>([]);
  const [enabledInPublicoAlvo, setEnabledInPublicoAlvo] = useState(false);
  const [userSubmitted, setUserSubmitted] = useState(false);
  const navigate = useNavigate();
  const [currentUserCode, setCurrentUserCode] = useState(
    localStorage.getItem('currentUserCode') || '0',
  );

  useEffect(() => {
    setIsLoadingPermissions(true);
    PermissionsDetailService.getAll().then((result) => {
      if (result.success) {
        setPermissionsProfiles(result.data);
      } else {
        feedback(
          result.message || 'Erro ao carregar perfis de permissões.',
          'error',
        );
      }
      setTimeout(() => {
        setIsLoadingPermissions(false);
      }, 500);
    });

    EstablishmentService.getAllTreeFormat().then((result) => {
      if (result.success) {
        setEstablishments(result.data);
      } else {
        feedback(
          result.message || 'Erro ao carregar estabelecimentos.',
          'error',
        );
      }
      setTimeout(() => {
        setIsLoadingPermissions(false);
      }, 500);
    });
  }, []);

  useEffect(() => {
    if (id && id !== '0') {
      PermissionsDetailService.getById(id).then((result) => {
        if (result.success) {
          setUserFullName(result.data.userName);
          setEstablishmentProfiles(result.data.establishmentProfiles);
          setUserPermissions(result.data);

          if (result.data.publicoAlvo !== null) {
            setUserSelectedEstablishments(
              result.data.publicoAlvo.Estabelecimentos,
            );
            setUserSelectedStructuredEstablishments(
              result.data.publicoAlvo.EstabelecimentosEstruturado || [],
            );
            setEnabledInPublicoAlvo(result.data.publicoAlvo.Habilitado);
          }
        } else {
          feedback(
            result.message || 'Erro ao carregar permissões de usuário',
            'error',
          );
        }
        setTimeout(() => {
          setIsLoadingPermissions(false);
        }, 500);
      });
    }

    if (id === '0' && currentUserCode !== '0') {
      PermissionsDetailService.getById(currentUserCode).then((result) => {
        if (result.success) {
          setUserFullName(result.data.userName);
          setEstablishmentProfiles(result.data.establishmentProfiles);
          setUserPermissions(result.data);

          if (result.data.publicoAlvo !== null) {
            setUserSelectedEstablishments(
              result.data.publicoAlvo.Estabelecimentos,
            );
            setUserSelectedStructuredEstablishments(
              result.data.publicoAlvo.EstabelecimentosEstruturado || [],
            );
            setEnabledInPublicoAlvo(result.data.publicoAlvo.Habilitado);
          }

          setTimeout(() => {
            setIsLoadingPermissions(false);
          }, 500);
        } else {
          feedback(
            result.message || 'Erro ao carregar permissões de usuário',
            'error',
          );
        }
      });
    }
  }, [id, currentUserCode]);

  const savePermissions = (value?: number[]) => {
    if (
      !enabledInPublicoAlvo ||
      (enabledInPublicoAlvo &&
        ((value?.length ? value.length > 0 : false) ||
          userSelectedEstablishments.length > 0))
    ) {
      setUserSubmitted(true);
      feedback('Salvando permissões...', 'loading');
      const newUserPermissions: IPermissions = {
        ...userPermissions,
        establishmentProfiles: establishmentProfiles,
        publicoAlvo: {
          Habilitado: enabledInPublicoAlvo,
          Estabelecimentos: value
            ? value.length > 0
              ? value
              : userSelectedEstablishments
            : userSelectedEstablishments,
        },
      };
      setUserPermissions(newUserPermissions);

      if (userPermissions.Id !== DEFAULT_ID) {
        PermissionsDetailService.upgradeById(
          newUserPermissions.userCode.toString(),
          newUserPermissions,
        ).then((result) => {
          if (result.success) {
            feedback('Permissões atualizadas com sucesso!', 'success');
          } else {
            feedback(
              result.message || 'Erro ao atualizar permissões de usuário.',
              'error',
            );
          }
        });
      } else {
        PermissionsDetailService.create(newUserPermissions).then((result) => {
          if (result.success) {
            feedback('Permissões salvas com sucesso!', 'success');
            navigate(`/permissoes/detalhe/${currentUserCode}`);
          } else {
            feedback(
              result.message || 'Erro ao criar permissões para o usuário.',
              'error',
            );
          }
        });
      }
    } else {
      feedback(
        'Selecione ao menos 1 estabelecimento para o Público-alvo',
        'error',
      );
    }
  };

  return (
    <UserPermissionContext.Provider
      value={{
        userSubmitted: userSubmitted,
        setUserSubmitted,
        setEnabledInPublicoAlvo,
        setUserSelectedEstablishments,
        userSelectedEstablishments: userSelectedEstablishments,
        userSelectedStructuredEstablishments,
        setUserSelectedStructuredEstablishments,
        enabledInPublicoAlvo: enabledInPublicoAlvo,
        setEstablishmentProfiles,
        userPermissions,
        savePermissions,
        isLoadingPermissions: isLoadingPermissions,
        userFullName: userFullName,
        establishmentProfiles: establishmentProfiles,
        permissionsProfiles: permissionsProfiles,
        establishments: establishments,
        currentUserCode,
        setCurrentUserCode,
      }}>
      {children}
    </UserPermissionContext.Provider>
  );
};
