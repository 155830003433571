import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {
  FormControl,
  Grid,
  InputAdornment,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Container,
  TextField,
  CircularProgress,
} from '@mui/material';
import Search from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import {Skeleton} from '@mui/lab';
import {feedback, confirm} from 'shared/services';
import {useTitle} from 'shared/hooks';
import {
  QuestionaryTypesService,
  IQuestionaryTypes,
} from 'shared/services/api/questionary-types/QuestionaryTypes';
import {ActionButtons, NotFoundText} from 'shared/components';

export const QuestionaryTypesListing: React.FC = () => {
  const navigate = useNavigate();
  const {setTitle} = useTitle();
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [questionaryData, setQuestionaryData] = useState<IQuestionaryTypes[]>(
    [],
  );
  const [completedQuestionaryData, setCompletedQuestionaryData] = useState<
    IQuestionaryTypes[]
  >([]);

  useEffect(() => {
    setTitle('Tipos de questionários');
  }, [setTitle]);

  useEffect(() => {
    setIsLoading(true);
    QuestionaryTypesService.getAll().then((result) => {
      if (result.success) {
        setQuestionaryData(result.data.data);
        setCompletedQuestionaryData(result.data.data);
        setIsLoading(false);
      } else {
        setQuestionaryData([]);
        setCompletedQuestionaryData([]);
        setIsLoading(false);
        feedback(
          result.message || 'Erro ao carregar tipos de questionário.',
          'error',
        );
      }
    });
  }, []);

  useEffect(() => {
    const result = completedQuestionaryData?.filter(
      (item) => item.nome.toLowerCase().indexOf(search.toLowerCase()) > -1,
    );

    setQuestionaryData(result);
  }, [search, completedQuestionaryData]);

  const handleDelete = useCallback((id: string) => {
    const handleConfirmDelete = () => {
      QuestionaryTypesService.deleteById(id).then((result) => {
        if (result.success) {
          setQuestionaryData((oldRows) =>
            oldRows?.filter((row) => row.id !== id),
          );
          feedback(
            result.message || 'Tipo de questionário excluído com sucesso!',
            'success',
          );
        } else {
          feedback(
            result.message || 'Erro ao excluir tipo de questionário.',
            'error',
          );
        }
      });
    };

    confirm(
      'Tem certeza que deseja excluir o registro?',
      'delete',
      handleConfirmDelete,
    );
  }, []);

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid container item xs={12} spacing={0}>
          <Grid container item xs={6}>
            <FormControl variant="outlined" fullWidth>
              <Paper>
                <TextField
                  style={{padding: 0, margin: 0}}
                  fullWidth
                  variant="outlined"
                  placeholder="Pesquise por nome do tipo de questionário"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  InputProps={{
                    style: {
                      height: 40,
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        {isLoading ? (
                          <CircularProgress size={30} />
                        ) : (
                          <Search color="disabled" />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Paper>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={6}
            container
            justifyContent={'flex-end'}
            alignItems={'center'}>
            <Grid item xs={3}>
              <Button
                size="medium"
                fullWidth
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => {
                  navigate('/tipos-questionarios/detalhe/new');
                }}>
                Novo
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} marginBottom={1}>
          <TableContainer component={Paper} elevation={3}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Tipo de questionário
                  </TableCell>
                  <TableCell component="th" scope="row">
                    Descrição
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    width={100}
                    align="right">
                    Ações
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <>
                    <TableRow hover>
                      <TableCell component="th" scope="row">
                        <Skeleton
                          variant="rectangular"
                          width="auto"
                          height={25}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton
                          variant="rectangular"
                          width="auto"
                          height={25}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton
                          variant="rectangular"
                          width="auto"
                          height={25}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell component="th" scope="row">
                        <Skeleton
                          variant="rectangular"
                          width="auto"
                          height={25}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton
                          variant="rectangular"
                          width="auto"
                          height={25}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton
                          variant="rectangular"
                          width="auto"
                          height={25}
                        />
                      </TableCell>
                    </TableRow>
                  </>
                ) : questionaryData?.length > 0 ? (
                  questionaryData?.map((row) => (
                    <TableRow hover key={row.id}>
                      <TableCell component="th" scope="row">
                        {row.nome}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.descricao}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        width={100}
                        align="right">
                        <ActionButtons
                          rowId={row.id}
                          canEdit={true}
                          canDelete={true}
                          onClickEdit={() =>
                            navigate(`/tipos-questionarios/detalhe/${row.id}`)
                          }
                          onClickDelete={() => handleDelete(row.id)}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <>
                    <TableRow>
                      <TableCell component="th" scope="row" colSpan={3}>
                        <NotFoundText />
                      </TableCell>
                    </TableRow>
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Container>
  );
};
