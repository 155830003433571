import {DatePicker} from '@mui/x-date-pickers';
import {useISOTimeFormatter} from '../keyboard-timepicker/KeyboardTimepicker';
import {TextField} from '@mui/material';

interface IKeyboardDatepickerProps {
  inputFormat?: string;
  label?: string;
  inputErro?: boolean;
  value: string;

  handleChange: (value: string, e: any) => void;
}

export const KeyboardDatepicker: React.FC<IKeyboardDatepickerProps> = ({
  inputFormat = 'DD/MM/YYYY',
  label = '',
  inputErro = false,
  value,
  handleChange,
}) => {
  const {getISOTimeWithoutGMT} = useISOTimeFormatter();

  const handleDayOfWeekFormatter = (day: string) => {
    const formatedDays = {
      D: 'Dom',
      '2': 'Seg',
      '3': 'Ter',
      '4': 'Qua',
      '5': 'Qui',
      '6': 'Sex',
      S: 'Sab',
    };

    return formatedDays[day.charAt(0) as keyof typeof formatedDays];
  };

  const handleDateChange = (e: Date) => {
    handleChange(value, e);
  };

  return (
    <DatePicker
      disableMaskedInput
      dayOfWeekFormatter={handleDayOfWeekFormatter}
      inputFormat={inputFormat}
      label={label}
      value={getISOTimeWithoutGMT(value)}
      onChange={(e: any) => handleDateChange(e)}
      renderInput={(params: any) => (
        <TextField
          {...params}
          error={inputErro}
          inputProps={{...params.inputProps, readOnly: true}}
        />
      )}
    />
  );
};
