import React, {memo} from 'react';

import {Box, TextField} from '@mui/material';

export const SimpleTextPreview: React.FC = memo(() => {
  return (
    <Box>
      <TextField
        variant="outlined"
        disabled
        fullWidth
        placeholder="Sua resposta"
      />
    </Box>
  );
});
