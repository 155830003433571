import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  DialogActions,
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import React, {useEffect, useState} from 'react';
import {downloadPDF} from 'shared/components/donwload-from-base-64/DownloadFromBase64';
import {feedback} from 'shared/services';
import {
  ISubjects,
  ISubjectsFilter,
  AnswerSummaryService,
  ICourseTeacher,
} from 'shared/services/api/reports/AnswerSummary';

interface IProp {
  idConvite: string;
}

const useStyles = makeStyles(() => {
  return {
    noPaddingTop: {
      paddingTop: 0,
    },
    noPaddingBottom: {
      paddingBottom: 0,
    },
  };
});

export const ModalSubjectSummary: React.FC<IProp> = ({idConvite}) => {
  const {noPaddingTop, noPaddingBottom} = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [subjectFilter, setSubjectFilter] = useState<ISubjectsFilter>({
    Disciplinas: [],
  });
  const [isCheckboxAll, setIsCheckboxAll] = useState(true);
  const [subjectCheckboxHandler, setSubjectCheckboxHandler] = useState<
    ISubjects[]
  >([{Id: 0, Nome: '', Professores: []}]);

  const handleBySubjectsFilter = async () => {
    try {
      const response = await AnswerSummaryService.AnswerSumarrySubject(
        idConvite,
      );
      setSubjectFilter({...subjectFilter, Disciplinas: response.Disciplinas});
    } catch (error) {
      feedback('erro', 'error');
    }
  };

  useEffect(() => {
    handleBySubjectsFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (openModal) {
      setSubjectCheckboxHandler(subjectFilter.Disciplinas);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  useEffect(() => {
    isAllCheckVerify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subjectCheckboxHandler]);

  const handleSubjectCheckboxClick = (subject: ISubjects) => {
    const isChecked = isSubjectCheckedVerify(subject.Id);
    const newArray = JSON.parse(
      JSON.stringify(subjectCheckboxHandler),
    ) as ISubjects[];

    if (isChecked) {
      setSubjectCheckboxHandler(
        newArray.filter((disciplina) => disciplina.Id !== subject.Id),
      );
    } else {
      newArray.push({
        Id: subject.Id,
        Nome: subject.Nome,
        Professores: subject.Professores,
      });
      setSubjectCheckboxHandler(newArray);
    }
  };

  const isSubjectCheckedVerify = (subjectId: number) => {
    return subjectCheckboxHandler.some(
      (disciplina) => disciplina.Id === subjectId,
    );
  };

  const isAllCheckVerify = () => {
    const isAllSubjectsChecked =
      subjectFilter.Disciplinas.length === subjectCheckboxHandler.length;

    setIsCheckboxAll(isAllSubjectsChecked);
  };

  const allCheckClickHandler = () => {
    isCheckboxAll
      ? setSubjectCheckboxHandler([])
      : setSubjectCheckboxHandler(subjectFilter.Disciplinas);
  };

  const handlePostSubjectPrint = async (subjects: ISubjects[]) => {
    feedback('Carregando', 'loading');
    try {
      for (const subject of subjectCheckboxHandler) {
        const subjectPost = {
          IdConvite: idConvite,
          Disciplinas: {
            Nome: subject.Nome,
            Professores: subject.Professores.map((item) => item.Id),
          },
        };
        const response = await AnswerSummaryService.postSubjectPrint(
          subjectPost,
        );
        feedback('PDF abaixado com sucesso', 'success');
        downloadPDF(
          response.data,
          `relatorio-filtro-${subject.Nome.toLocaleLowerCase().replaceAll(
            '.',
            '',
          )}`,
        );
      }
    } catch (error) {
      feedback('Não foi possivel imprimir', 'error');
    }
  };

  const handleCloseModal = () => {
    handlePostSubjectPrint(subjectCheckboxHandler);
    setOpenModal(false);
  };

  const handleTeacherCheckboxClick = (
    subject: ISubjects,
    teacher: ICourseTeacher,
  ) => {
    const isChecked = isTeacherCheckerVerify(subject.Id, teacher.Nome);
    const newArray = JSON.parse(
      JSON.stringify(subjectCheckboxHandler),
    ) as ISubjects[];

    if (!newArray.some((disciplina) => disciplina.Nome === subject.Nome)) {
      newArray.push({
        Id: subject.Id,
        Nome: subject.Nome,
        Professores: [{Nome: teacher.Nome, Id: teacher.Id}],
      } as ISubjects);
      setSubjectCheckboxHandler(newArray);
    } else {
      setSubjectCheckboxHandler(
        newArray.map((disciplina) => {
          if (disciplina.Nome === subject.Nome) {
            isChecked
              ? (disciplina.Professores = disciplina.Professores.filter(
                  (prof) => prof.Nome !== teacher.Nome,
                ))
              : disciplina.Professores.push({
                  Nome: teacher.Nome,
                  Id: teacher.Id,
                });
          }
          return disciplina;
        }),
      );
    }
  };

  const isTeacherCheckerVerify = (subjectId: number, profName: string) => {
    if (isSubjectCheckedVerify(subjectId)) {
      return subjectCheckboxHandler
        .filter((disciplina) => disciplina.Id === subjectId)[0]
        .Professores.some((teacher) => teacher.Nome === profName);
    }
    return false;
  };

  return (
    <>
      {subjectFilter.Disciplinas.length > 0 ? (
        <>
          <Button
            onClick={() => {
              setOpenModal(true);
            }}
            variant="outlined"
            startIcon={<PictureAsPdfIcon />}
            color="primary"
            size="medium">
            Por disciplina
          </Button>
          <Dialog
            maxWidth="md"
            open={openModal}
            onClose={() => {
              setOpenModal(false);
            }}>
            <DialogTitle className={noPaddingBottom}>
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}>
                <Box width={'100%'}>
                  <Typography color="primary" variant="h6">
                    Imprimir Resultados Por Disciplina
                  </Typography>
                </Box>

                <IconButton
                  aria-label="close"
                  onClick={() => {
                    setOpenModal(false);
                  }}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </DialogTitle>
            <DialogTitle>
              <Typography>
                <b>Selecione as disciplinas desejadas</b>
              </Typography>
            </DialogTitle>

            <DialogContent className={noPaddingTop}>
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'start'}>
                <Checkbox
                  checked={isCheckboxAll}
                  color="primary"
                  onClick={() => allCheckClickHandler()}
                />
                <Typography variant="subtitle1">Selecionar todos</Typography>
              </Box>
              <Box width={'800px'} paddingBottom={3}>
                <Box
                  width={'100%'}
                  display="flex"
                  alignItems={'start'}
                  flexDirection={'column'}>
                  {subjectFilter.Disciplinas.map((disciplina) => {
                    return (
                      <Box
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'start'}>
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'start'}>
                          <Checkbox
                            checked={isSubjectCheckedVerify(disciplina.Id)}
                            color="primary"
                            onClick={() =>
                              handleSubjectCheckboxClick(disciplina)
                            }
                          />
                          <Typography variant="subtitle1">
                            {disciplina.Nome}
                          </Typography>
                        </Box>
                        <Box>
                          {disciplina.Professores.map((prof) => {
                            return (
                              <Box
                                width={'100%'}
                                marginLeft={'15%'}
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'start'}>
                                <Checkbox
                                  checked={isTeacherCheckerVerify(
                                    disciplina.Id,
                                    prof.Nome,
                                  )}
                                  color="primary"
                                  size="small"
                                  onClick={() =>
                                    handleTeacherCheckboxClick(disciplina, prof)
                                  }
                                />
                                <Typography variant="subtitle2">
                                  {prof.Nome}
                                </Typography>
                              </Box>
                            );
                          })}
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </DialogContent>
            <DialogActions>
              <Box
                width={'100%'}
                display={'flex'}
                justifyContent={'end'}
                alignItems={'center'}>
                <Button
                  onClick={handleCloseModal}
                  variant="contained"
                  color="primary"
                  size="medium">
                  Imprimir
                </Button>
              </Box>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
