import React, {memo} from 'react';

import {DisplayMode} from '../../DisplayMode';
import {SimpleTextConfig} from './SimpleTextConfig';
import {SimpleTextPreview} from './SimpleTextPreview';

export interface ISimpleTextProps {
  displayMode: DisplayMode;
}

export const SimpleText: React.FC<ISimpleTextProps> = memo(({displayMode}) => {
  return (
    <>
      {displayMode === DisplayMode.config && <SimpleTextConfig />}
      {displayMode === DisplayMode.preview && <SimpleTextPreview />}
    </>
  );
});
