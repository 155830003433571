import React from 'react';
import {
  Container,
  Box,
} from '@mui/material';
import {Gears, Progress} from './components';

interface ISectionSplashScreenProps {
  color: string;
}

export const SectionSplashScreen: React.FC<ISectionSplashScreenProps> = ({
  color
}) => {
  return (
    <Container>
      <Box width={'100%'}>
        <Gears color={color} />
        <Progress color={color} />
      </Box>
    </Container>
  );
};
