import {Api} from 'shared/services/axios-config';
import {IResultOfAction} from 'shared/services/axios-config/interceptors';

export interface IInviteReport {
  ativo: boolean;
  id: string;
  nomeQuestionario: string;
  tipoQuestionario: string;
  descricao: string;
  convites: {
    id: string;
    descricao: string;
    dataHoraInicio: string;
    dataHoraFim: string;
    linkConvite: string;
    ativo: boolean;
    resposta: number;
  }[];
}

const getAll = async (): Promise<IResultOfAction<IInviteReport[]>> => {
  try {
    const {data} = await Api.get(`/relatorios`);

    const invitationReportList: IInviteReport[] = data.map((data: any) => ({
      id: data.Id,
      ativo: data.Ativo,
      nomeQuestionario: data.NomeQuestionario,
      tipoQuestionario: data.NomeTipoQuestionario,
      descricao: data.Descricao,
      convites: data.Convites.map((inv: any) => ({
        id: inv.Id,
        descricao: inv.Descricao,
        dataHoraInicio: inv.DataHoraInicio,
        dataHoraFim: inv.DataHoraFim,
        linkConvite: inv.LinkConvite,
        ativo: inv.Ativo,
        resposta: inv.Resposta,
      })),
    }));

    const response: IResultOfAction<IInviteReport[]> = {
      data: invitationReportList,
      success: true,
      message: '',
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

export const ReportsListingService = {
  getAll,
};
