import {TableRow, TableCell} from '@mui/material';
import {Skeleton} from '@mui/lab';
import React, {memo} from 'react';

export const ParticipantsListSkeleton: React.FC = memo(() => {
  return (
    <>
      <TableRow hover>
        <TableCell component="th" scope="row">
          <Skeleton variant="rectangular" width="40px" height={25} />
        </TableCell>
        <TableCell component="th" scope="row">
          <Skeleton variant="rectangular" width="auto" height={25} />
        </TableCell>
        <TableCell component="th" scope="row">
          <Skeleton variant="rectangular" width="auto" height={25} />
        </TableCell>
        <TableCell component="th" scope="row">
          <Skeleton variant="rectangular" width="auto" height={25} />
        </TableCell>
        <TableCell component="th" scope="row">
          <Skeleton variant="rectangular" width="auto" height={25} />
        </TableCell>
      </TableRow>
      <TableRow hover>
        <TableCell component="th" scope="row">
          <Skeleton variant="rectangular" width="40px" height={25} />
        </TableCell>
        <TableCell component="th" scope="row">
          <Skeleton variant="rectangular" width="auto" height={25} />
        </TableCell>
        <TableCell component="th" scope="row">
          <Skeleton variant="rectangular" width="auto" height={25} />
        </TableCell>
        <TableCell component="th" scope="row">
          <Skeleton variant="rectangular" width="auto" height={25} />
        </TableCell>
        <TableCell component="th" scope="row">
          <Skeleton variant="rectangular" width="auto" height={25} />
        </TableCell>
      </TableRow>
    </>
  );
});
