import {
  Dialog,
  Button,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  Checkbox,
  DialogActions,
  IconButton,
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import React, {useCallback, useEffect, useState} from 'react';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import {
  AnswerSummaryService,
  ICourseTeacher,
} from 'shared/services/api/reports/AnswerSummary';
import {feedback} from 'shared/services';
import {downloadPDF} from 'shared/components/donwload-from-base-64/DownloadFromBase64';

interface IProps {
  idConvite: string;
}

const useStyles = makeStyles(() => {
  return {
    noPaddingTop: {
      paddingTop: 0,
    },
    noPaddingBottom: {
      paddingBottom: 0,
    },
  };
});

export const ModalTeacherSummary: React.FC<IProps> = ({idConvite}) => {
  const {noPaddingTop, noPaddingBottom} = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [teachers, setTeachers] = useState<ICourseTeacher[]>();
  const [base64, setbase64] = useState('');

  useEffect(() => {
    async function getAnswerSumarryTeachers() {
      try {
        const response = await AnswerSummaryService.AnswerSumarryTeachers(
          idConvite,
        );
        setTeachers(response);
        setSelectedNames(response.map((response) => response.Id));
      } catch (error: any) {}
    }
    getAnswerSumarryTeachers();
  }, [idConvite, openModal]);

  const [selectedNames, setSelectedNames] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    if (selectedNames.length === teachers?.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNames]);

  const handleSelectTeacher = (id: number) => {
    setSelectedNames((namesSelected) =>
      namesSelected.includes(id)
        ? namesSelected.filter((nameId) => nameId !== id)
        : [...namesSelected, id],
    );
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);

    if (isChecked) {
      const allIds: any = teachers?.map((nameObj) => nameObj.Id);
      setSelectedNames(allIds);
    } else {
      setSelectedNames([]);
    }
  };

  const printProfessores = useCallback(async () => {
    feedback('Carregando', 'loading');
    try {
      const response = await AnswerSummaryService.postTeacherPrint({
        IdConvite: String(idConvite),
        Professores: selectedNames,
      });
      setbase64(response.data);

      feedback('PDF abaixado com sucesso', 'success');
    } catch (error) {
      feedback('Não foi possivel imprimir', 'error');
    }
  }, [idConvite, selectedNames]);

  useEffect(() => {
    if (base64.length > 0) {
      downloadPDF(base64, 'Resultado por professor.pdf');
    }
  }, [base64]);

  return (
    <>
      {teachers?.length ? (
        <>
          <Button
            onClick={() => setOpenModal(true)}
            startIcon={<PictureAsPdfIcon />}
            size="medium"
            variant="outlined"
            color="primary">
            Por Professor
          </Button>

          <Dialog
            maxWidth="md"
            open={openModal}
            onClose={() => {
              setOpenModal(false);
            }}>
            <DialogTitle className={noPaddingBottom}>
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}>
                <Box width={'100%'}>
                  <Typography color="primary" variant="h6">
                    Imprimir Resultados Por Professor
                  </Typography>
                </Box>

                <IconButton
                  aria-label="close"
                  onClick={() => {
                    setOpenModal(false);
                  }}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </DialogTitle>

            <DialogTitle>
              <Box>
                <Typography>
                  <b>Selecione os professores</b>
                </Typography>
              </Box>
            </DialogTitle>

            <DialogContent className={noPaddingTop}>
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'start'}>
                <Checkbox
                  checked={selectAll}
                  color="primary"
                  onChange={handleSelectAll}
                />
                <Typography variant="subtitle1">Selecionar todos</Typography>
              </Box>
              <Box width={'800px'} paddingBottom={3}>
                <Box
                  width={'100%'}
                  display="flex"
                  alignItems={'start'}
                  flexDirection={'column'}>
                  {teachers?.map((item) => {
                    return (
                      <Box
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'start'}>
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'start'}>
                          <Checkbox
                            checked={selectedNames.includes(item.Id)}
                            color="primary"
                            onClick={() => {
                              handleSelectTeacher(item.Id);
                            }}
                          />
                          <Typography variant="subtitle1">
                            {item.Nome}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </DialogContent>
            <DialogActions>
              <Box
                width={'100%'}
                display={'flex'}
                justifyContent={'end'}
                alignItems={'center'}>
                <Button
                  onClick={printProfessores}
                  variant="contained"
                  color="primary"
                  size="medium">
                  Imprimir
                </Button>
              </Box>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
