import {Api} from 'shared/services';
import {IResultOfAction} from 'shared/services/axios-config/interceptors';

export interface IPermissionsList {
  id: string;
  login: string;
  name: string;
  active: boolean;
  userCode: number;
}

const getAll = async (): Promise<IResultOfAction<IPermissionsList[]>> => {
  try {
    const {data} = await Api.get('/permissoes/usuarios');

    const userPermissions: IPermissionsList[] = data.map((user: any) => ({
      id: user.IdUsuario,
      login: user.Login,
      name: user.Nome,
      active: user.Ativo,
      userCode: user.CodigoUsuario,
    }));

    const response: IResultOfAction<IPermissionsList[]> = {
      success: true,
      message: '',
      data: userPermissions,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

export const PermissionsListService = {
  getAll,
};
