import React from 'react';
import {Box, Theme} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {ReactComponent as PersonSvg} from './svg/person.svg';

interface IPersonProps {
  color: string;
}

const useStyles = makeStyles<Theme, IPersonProps>(() => ({
  personWrapper: {
    '& svg': {
      width: '100%',
      maxWidth: '300px',

      '& g': {
        '& path, polygon, circle': {
          '&.colorToChange': {
            fill: (props: IPersonProps) => `#${props.color} !important`,
          },
        },
      },
    },
  },
}));

export const SvgPerson: React.FC<IPersonProps> = ({color}) => {
  const classes = useStyles({color});

  return (
    <>
      <Box width={'100%'} className={classes.personWrapper}>
        <PersonSvg />
      </Box>
    </>
  );
};
