import React, {useState, useCallback} from 'react';
import {
  Grid,
  Typography,
  Card,
  CardContent,
  IconButton,
  Icon,
  Box,
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
} from '@mui/material';
import {AnswersTypesRenderer} from './AnswersTypesRenderer';
import {Theme} from '@mui/material/styles';
import {makeStyles} from '@mui/styles';
import {
  AnswerSummaryService,
  IAnswerSummaryQuestion,
  IGroupedByPropertyType,
} from 'shared/services/api/reports/AnswerSummary';
import CloseIcon from '@mui/icons-material/Close';
import {ModalContentRenderer} from './ModalContentRenderer';
import {useParams} from 'react-router-dom';
import {feedback} from 'shared/services';
import {LoadingSkeletonRenderer} from './modalComponents/LoadingSkeletonRenderer';
import {RemainingParticipants} from './RemainingParticipants';
import {useReports} from 'pages/reports/hooks/useReports';

const useStyles = makeStyles((theme: Theme) => ({
  questionContainer: {
    width: '100%',
    backgroundColor: theme.palette.common.white,
    padding: 24,
    '@media print': {
      display: 'block',
      clear: 'both',
      breakAfter: 'page',
      pageBreakAfter: 'always',
      float: 'none',
    },
  },
  answerBox: {
    marginBottom: 16,
    '&:nth-last-child(1)': {
      marginBottom: 0,
    },
  },
  doNotShowOnPrint: {
    '@media print': {
      display: 'none',
    },
  },
}));

export const Answers: React.FC<IAnswerSummaryQuestion> = ({
  id,
  enunciado,
  descricao,
  tipoQuestao,
  detalhes,
  respostasRecebidas,
  comentarios,
  respostasPendentes,
}) => {
  const {answerSummarySectionFilters} = useReports();
  const classes = useStyles();
  const [openReceivedAnswers, setOpenReceivedAnswers] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const {idConvite = '0'} = useParams<'idConvite'>();
  const [modalContent, setModalContent] = useState<IGroupedByPropertyType>(
    {} as IGroupedByPropertyType,
  );
  const [showAsText, setShowAsText] = useState(false);
  const [openRemainingParticipants, setOpenRemainingParticipants] =
    useState(false);

  const groupBy = (collection: any, property: string) => {
    const groupedByDynamicProperty = collection.reduce(function (
      prev: any,
      current: any,
    ) {
      (prev[current[property]] = prev[current[property]] || []).push(current);
      return prev;
    },
    {});

    return groupedByDynamicProperty;
  };

  const hasToGroup = (questionType: string, multiplyClass: boolean) => {
    switch (questionType) {
      case 'simple-text':
        return multiplyClass;

      case 'paragraph-text':
        return multiplyClass;

      case 'date':
        return multiplyClass;

      case 'time':
        return multiplyClass;

      case 'linear-scale':
        return true;

      case 'satisfaction-percentage':
        return true;

      case 'unique-answer':
        return true;

      case 'multiple-answer':
        return true;

      case 'grid-multiple-answer':
        return true;

      case 'grid-unique-answer':
        return true;

      default:
        return false;
    }
  };

  const handleExtraFilters = useCallback(() => {
    let extraFilters = '';
    const arrayOfFilters: string[] = [];
    if (answerSummarySectionFilters.coordenador !== '') {
      arrayOfFilters.push(
        `IdCoordenador eq ${answerSummarySectionFilters.coordenador}`,
      );
    }
    if (answerSummarySectionFilters.curso !== '') {
      arrayOfFilters.push(`IdCurso eq ${answerSummarySectionFilters.curso}`);
    }
    if (answerSummarySectionFilters.disciplina !== '') {
      arrayOfFilters.push(
        `IdDisciplina eq ${answerSummarySectionFilters.disciplina}`,
      );
    }
    if (answerSummarySectionFilters.papel !== '') {
      arrayOfFilters.push(
        `IdGrupoPapel eq ${answerSummarySectionFilters.papel}`,
      );
    }
    if (answerSummarySectionFilters.secao !== '') {
      arrayOfFilters.push(`IdSecao eq ${answerSummarySectionFilters.secao}`);
    }
    if (answerSummarySectionFilters.tipoQuestao !== '') {
      arrayOfFilters.push(
        `TipoQuestao eq '${answerSummarySectionFilters.tipoQuestao}'`,
      );
    }

    if (answerSummarySectionFilters.turma !== '') {
      arrayOfFilters.push(`IdTurma eq ${answerSummarySectionFilters.turma}`);
    }

    arrayOfFilters.join(' and ');

    return extraFilters;
  }, [answerSummarySectionFilters]);

  const handleClickOpen = useCallback(
    (propertyToGroup: string, modalType: string) => {
      setLoadingModal(true);
      setOpenReceivedAnswers(true);

      let filters = '';
      if (modalType === 'comments') {
        filters = "and Comentario ne ''";
      }

      filters += handleExtraFilters();

      AnswerSummaryService.getQuestionByOData(idConvite, id, filters).then(
        (response) => {
          if (response.success) {
            setModalContent(
              hasToGroup(tipoQuestao, response.data.multiplicarDisciplina)
                ? groupBy(response.data, propertyToGroup)
                : response.data,
            );
            setTimeout(() => {
              setLoadingModal(false);
            }, 500);
          } else {
            feedback(
              response.message || 'Erro ao carregar informaçãos da questão.',
              'error',
            );
            setOpenReceivedAnswers(false);
          }
        },
      );
    },
    [idConvite, id, tipoQuestao, handleExtraFilters],
  );

  const handleClose = () => {
    setOpenRemainingParticipants(false);
    setOpenReceivedAnswers(false);
  };

  return (
    <Box className={classes.answerBox}>
      <Card className={classes.questionContainer} key={id}>
        <Typography variant={'h5'}>{enunciado}</Typography>
        <Grid
          container
          item
          xs={12}
          spacing={0}
          className={classes.doNotShowOnPrint}>
          <Grid item xs={4}>
            <Box width={'100%'} display={'flex'} alignItems={'center'}>
              <Typography component={'b'}>Ver respostas recebidas</Typography>
              <IconButton
                aria-label="launch"
                size="small"
                onClick={() => {
                  setShowAsText(false);
                  handleClickOpen(
                    tipoQuestao === 'grid-unique-answer' ||
                      tipoQuestao === 'grid-multiple-answer' ||
                      tipoQuestao === 'date' ||
                      tipoQuestao === 'time' ||
                      tipoQuestao === 'simple-text' ||
                      tipoQuestao === 'paragraph-text'
                      ? 'codigoUsuario'
                      : 'respostaLinha',
                    'received',
                  );
                }}
                disabled={false}>
                <Icon fontSize={'small'}>launch</Icon>
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box width={'100%'} display={'flex'} alignItems={'center'}>
              <Typography component={'b'}>Ver comentários</Typography>
              <IconButton
                aria-label="launch"
                size="small"
                onClick={() => {
                  setShowAsText(true);
                  handleClickOpen('codigoUsuario', 'comments');
                }}
                disabled={false}>
                <Icon fontSize={'small'}>launch</Icon>
              </IconButton>
            </Box>
          </Grid>
        </Grid>
        <CardContent>
          <AnswersTypesRenderer
            questionType={tipoQuestao}
            question={{
              id,
              enunciado,
              descricao,
              tipoQuestao,
              detalhes,
              respostasRecebidas,
              comentarios,
              respostasPendentes,
            }}
          />
        </CardContent>
      </Card>
      <Dialog fullScreen open={openRemainingParticipants} onClose={handleClose}>
        <DialogTitle id="modal-detail-title">
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}>
            <Box width={'100%'}>
              <Typography color="primary" variant="h6">
                Pessoas que ainda não responderam
              </Typography>
            </Box>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <RemainingParticipants />
        </DialogContent>
      </Dialog>
      <Dialog fullScreen open={openReceivedAnswers} onClose={handleClose}>
        <DialogTitle id="modal-detail-title">
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}>
            <Box width={'100%'}>
              <Typography color="primary" variant="h6">
                {enunciado}
              </Typography>
            </Box>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent>
          {loadingModal ? (
            <LoadingSkeletonRenderer
              questionType={tipoQuestao}
              showAsText={showAsText}
            />
          ) : (
            <ModalContentRenderer
              questionType={tipoQuestao}
              content={modalContent}
              gridConfigs={detalhes}
              showAsText={showAsText}
            />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};
