import React, {useEffect, useState} from 'react';
import {
  Container,
  Typography,
  Box,
  Button,
  useTheme,
  Icon,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Avatar,
} from '@mui/material';
import {Close} from '@mui/icons-material';
import {ParticipantsListSelect} from './ParticipantsListSelect';
import {IndividualAnswersSection} from './IndividualAnswersSection';
import {Skeleton} from '@mui/lab';
import {LogoGVdasa} from 'shared/assets';
import {useReports} from 'pages/reports/hooks/useReports';
import {ActionTab, ReadMore, CentralWarning} from 'shared/components';
import {useLocation, useNavigate} from 'react-router-dom';
import {format} from 'date-fns';

export const IndividualAnswers: React.FC = () => {
  const {
    individualAnswersCurrentParticipant,
    individualAnswersParticipants,
    individualAnswers,
    hasIndividualAnswersParticipants,
    selectedParticipant,
    setSelectedParticipant,
    individualAnswersSections,
    isLoading,
    setIndividualAnswersCurrentParticipant,
  } = useReports();
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const {state} = useLocation();
  const navigate = useNavigate();
  const [isLoadingContent, setIsLoadingContent] = useState(isLoading);

  const isValidDate = (pDate: string) => {
    const paramToDate = new Date(pDate);
    return paramToDate instanceof Date && !isNaN(paramToDate.getTime());
  };

  useEffect(() => {
    setIsLoadingContent(false);
  }, []);

  return (
    <Container>
      {!hasIndividualAnswersParticipants ? (
        <Box width={'100%'} marginBottom={5} marginTop={5}>
          <Box
            width={'100%'}
            minHeight={50}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'flex-start'}
          />
          <Box width={'100%'}>
            <CentralWarning
              variant={'warning'}
              children={'Não há participantes neste questionário...'}
            />
          </Box>
        </Box>
      ) : (
        <>
          <Box
            width={'100%'}
            height={'100%'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'flex-start'}
            marginBottom={'20px'}>
            {isLoadingContent ? (
              <Skeleton variant="text" width={'45%'} />
            ) : (
              <ReadMore
                cutAt={95}
                text={individualAnswers.tituloQuestionario}
                variant={'h6'}
              />
            )}
          </Box>
          <Box width={'100%'}>
            {individualAnswers.bannerBase64 &&
            individualAnswers.bannerBase64 !== '' ? (
              <Box position="relative">
                {individualAnswers.logoBase64 &&
                  individualAnswers.logoBase64 !== '' && (
                    <Box position="absolute" bottom={15} left={15}>
                      <Avatar
                        alt="Logo"
                        src={individualAnswers.logoBase64}
                        style={{
                          border: `1px solid ${theme.palette.background.paper}`,
                          width: theme.spacing(12.5),
                          height: theme.spacing(12.5),
                        }}>
                        G
                      </Avatar>
                    </Box>
                  )}
                <Box
                  style={{
                    backgroundImage: `url(${individualAnswers.bannerBase64})`,
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundColor: theme.palette.primary.main,
                  }}
                  paddingTop={25}
                />
              </Box>
            ) : (
              <Box
                position="relative"
                width={'100%'}
                height={theme.spacing(25)}
                bgcolor={theme.palette.primary.main}
                style={{
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                }}
                display="flex"
                alignItems="center">
                {individualAnswers.logoBase64 &&
                individualAnswers.logoBase64 !== '' ? (
                  <Box position="absolute" bottom={15} left={15}>
                    <Avatar
                      alt="Logo"
                      src={individualAnswers.logoBase64}
                      style={{
                        border: `1px solid ${theme.palette.background.paper}`,
                        width: theme.spacing(12.5),
                        height: theme.spacing(12.5),
                      }}>
                      G
                    </Avatar>
                  </Box>
                ) : (
                  <Container>
                    <img
                      src={LogoGVdasa.default}
                      alt="GVdasa Inteligência Educacional"
                      style={{maxWidth: `11rem`}}
                    />
                  </Container>
                )}
              </Box>
            )}
            <Box padding={'40px 20px 0'}>
              {isLoadingContent ? (
                <>
                  <Skeleton variant="text" width={'45%'} />
                  <Box marginBottom={4} />
                  <Skeleton variant="text" width={'65%'} />
                  <Skeleton variant="text" width={'60%'} />
                  <Skeleton variant="text" width={'73%'} />
                </>
              ) : (
                <>
                  <ReadMore
                    cutAt={95}
                    text={individualAnswers.tituloQuestionario}
                    variant="h4"
                    color="primary"
                  />
                  <Box marginBottom={4} />
                  <ReadMore
                    cutAt={200}
                    text={individualAnswers.descricao}
                    color={'textSecondary'}
                  />
                </>
              )}
            </Box>
          </Box>
          <Box
            width={'100%'}
            display={'flex'}
            justifyContent={'flex-start'}
            alignItems={'flex-start'}
            gap={'20px'}
            marginTop={'50px'}
            padding={'0 20px'}>
            <Box>
              {isLoadingContent ? (
                <>
                  <Skeleton variant="text" width={300} />
                  <Box marginBottom={1} />
                  <Skeleton variant="text" width={150} />
                  <Skeleton variant="text" width={120} />
                </>
              ) : (
                <>
                  <Typography variant={'h5'}>
                    {individualAnswersCurrentParticipant?.nome}
                  </Typography>
                  <Typography style={{fontSize: '15px'}}>
                    {individualAnswersCurrentParticipant?.papel}
                    {individualAnswersCurrentParticipant &&
                    individualAnswersCurrentParticipant?.descricaoCurso
                      ? ` - ${individualAnswersCurrentParticipant?.descricaoCurso}`
                      : ''}
                  </Typography>
                  {isValidDate(individualAnswers.respondidoEm) && (
                    <Typography style={{fontSize: '15px'}}>
                      Respondido em:{' '}
                      {format(
                        new Date(individualAnswers.respondidoEm),
                        'dd/MM/yyyy HH:mm',
                      )}
                    </Typography>
                  )}
                </>
              )}
            </Box>
            <Box>
              {isLoadingContent ? (
                <Box marginLeft={'auto'}>
                  <Skeleton variant="rectangular" width={199} height={42} />
                </Box>
              ) : (
                <Button
                  size="large"
                  variant="outlined"
                  color="primary"
                  onClick={() => setOpen(true)}>
                  Ver outro participante
                </Button>
              )}
            </Box>
          </Box>
          <Box width={'100%'}>
            <Box width={'100%'} marginTop={'40px'}>
              {isLoadingContent ? (
                <Skeleton variant="rectangular" width="100%" height={500} />
              ) : individualAnswersSections.length === 0 ? (
                <Box width={'100%'} padding={'0 20px'}>
                  <CentralWarning
                    variant={'warning'}
                    children={
                      'Nenhuma seção disponível para este participante.'
                    }
                  />
                </Box>
              ) : individualAnswers.respostaAnonima ? (
                <Box width={'100%'} padding={'0 20px'}>
                  <Box display={'flex'} alignItems={'center'} gap={10}>
                    <Icon color="secondary" fontSize="large">
                      visibility_off_outlined
                    </Icon>
                    <Typography variant={'h6'} color={'secondary'}>
                      Este participant respondeu anonimamente...
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <IndividualAnswersSection
                  sectionList={individualAnswersSections}
                />
              )}
            </Box>
          </Box>
          <Dialog
            fullWidth
            maxWidth="md"
            onClose={() => setOpen(false)}
            open={open}>
            <DialogTitle id="modal-detail-title">
              <Box
                width={'100%'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}>
                <Typography color="primary" variant="h6">
                  Selecionar participante
                </Typography>
                <IconButton aria-label="close" onClick={() => setOpen(false)}>
                  <Close />
                </IconButton>
              </Box>
            </DialogTitle>
            <DialogContent>
              <ParticipantsListSelect
                participants={individualAnswersParticipants}
                onChangeParticipant={(currentPart: any) => {
                  setIsLoadingContent(true);
                  setIndividualAnswersCurrentParticipant(currentPart);
                  setSelectedParticipant(currentPart.id);
                  setOpen(false);
                  setTimeout(() => {
                    setIsLoadingContent(false);
                  }, 1500);
                }}
                selectedParticipant={selectedParticipant}
              />
            </DialogContent>
          </Dialog>
        </>
      )}
      <ActionTab
        onCancel={() => {
          navigate('/relatorios', {state: state, replace: true});
        }}
        onCancelLabel="Voltar">
        {(isLoadingContent ||
          (individualAnswersSections.length > 0 &&
            hasIndividualAnswersParticipants)) && (
          <Button
            size="large"
            variant="outlined"
            color="primary"
            onClick={() => setOpen(true)}>
            Ver outro participante
          </Button>
        )}
      </ActionTab>
    </Container>
  );
};
