import {createTheme} from '@mui/material/styles';
import {ptBR} from '@mui/material/locale';

export const LightTheme = createTheme(
  {
    components: {
      MuiMenuItem: {
        styleOverrides: {
          root: {
            '&:hover': {
              backgroundColor: '#01B9AD',
              color: '#FFFFFF',
            },
            '&$selected': {
              backgroundColor: '#EEEEEE',
              color: '#616161',
              '&:hover': {
                backgroundColor: '#33C7BD',
                color: '#FFFFFF',
              },
            },
          },
        },
      },

      MuiTableHead: {
        styleOverrides: {
          root: {
            backgroundColor: '#E0E0E0',
          },
        },
      },
      MuiCheckbox: {
        defaultProps: {
          color: 'primary',
        },
      },

      MuiButton: {
        defaultProps: {
          size: 'small',
        },
      },

      MuiIconButton: {
        defaultProps: {
          size: 'small',
        },
      },

      MuiListItem: {
        defaultProps: {
          dense: true,
        },
      },

      MuiFab: {
        defaultProps: {
          size: 'small',
        },
      },
      MuiTable: {
        defaultProps: {
          size: 'small',
        },
      },
      MuiToolbar: {
        defaultProps: {
          variant: 'dense',
        },
      },
    },
    palette: {
      primary: {
        main: '#018781',
        light: '#339F9A',
        dark: '#005E5A',
        contrastText: '#FFFFFF',
      },
      secondary: {
        main: '#01B9AD ',
        light: '#33C7BD',
        dark: '#008179',
        contrastText: '#FFFFFF',
      },
      error: {
        main: '#F50000',
        light: '#F73333',
        dark: '#AB0000',
      },
      warning: {
        main: '#FFAC33',
        light: '#FF9800',
        dark: '#B26A00',
      },
      success: {
        main: '#33B673',
        light: '#00A451',
        dark: '#007238',
      },
      info: {
        main: '#018781',
        light: '#339F9A',
        dark: '#005E5A',
      },
      text: {
        primary: '#414141',
      },
      background: {
        default: '#F8F8F8',
        paper: '#FFFFFF',
      },
    },
  },
  ptBR,
);
