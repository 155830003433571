import React, {memo, useCallback, useEffect, useState} from 'react';
import {Box, TextField, Grid} from '@mui/material';
import {TimePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';

interface ITimePreviewProps {
  value: string;
  comment: string;
  isCompleted: boolean;
  hasComment?: boolean;
  setValue: (value: string) => void;
  setComment: (comment: string) => void;
}

export const TimePreview: React.FC<ITimePreviewProps> = memo(
  ({value, comment, isCompleted, hasComment, setValue, setComment}) => {
    const [valueToShow, setValueToShow] = useState<Date | null>(null);

    const handleComment = (e: React.ChangeEvent<HTMLInputElement>) => {
      setComment(e.target.value);
    };

    const formatDate = (date: Date) => {
      let d = date,
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      return [year, month, day].join('-');
    };

    const formatTime = (date: Date) => {
      const newdate = new Date(date);
      let d = newdate,
        hours = '' + d.getHours(),
        minutes = '' + d.getMinutes();

      if (hours.length < 2) hours = '0' + hours;
      if (minutes.length < 2) minutes = '0' + minutes;

      return [hours, minutes].join(':');
    };

    useEffect(() => {
      if (value !== '') {
        const today = formatDate(new Date());
        const dateTime = `${today} ${value}`;
        const correctDateTime = new Date(dateTime);
        setValueToShow(correctDateTime);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleTime = useCallback(
      (selectedDate: Date | null) => {
        setValueToShow(selectedDate);
      },
      [setValueToShow],
    );

    return (
      <Grid item xs={12} sm={12} md={8} lg={8}>
        <Box>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              showToolbar={false}
              disabled={isCompleted}
              ampm={false}
              label="Horário"
              value={valueToShow}
              inputFormat="HH:mm"
              onChange={handleTime}
              onAccept={(date) => {
                setValue(formatTime(date as Date));
              }}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  error={false}
                  inputProps={{...params.inputProps, readOnly: true}}
                />
              )}
            />
          </LocalizationProvider>
        </Box>
        {((hasComment && !isCompleted) || (comment !== '' && isCompleted)) && (
          <Box marginTop={3} justifyContent="center" display="flex">
            <TextField
              fullWidth
              multiline
              rows={2}
              disabled={isCompleted}
              color="primary"
              label="Comentário"
              variant="outlined"
              placeholder="Adicionar um comentário"
              value={comment}
              onChange={handleComment}
            />
          </Box>
        )}
      </Grid>
    );
  },
);
