import React, {memo} from 'react';

import {DisplayMode} from '../../DisplayMode';
import {DateConfig} from './DateConfig';
import {DatePreview} from './DatePreview';
interface IDateProps {
  displayMode: DisplayMode;
}

export const Date: React.FC<IDateProps> = memo(({displayMode}) => {
  return (
    <>
      {displayMode === DisplayMode.config && <DateConfig />}
      {displayMode === DisplayMode.preview && <DatePreview />}
    </>
  );
});
