import React, {useCallback, useMemo, memo} from 'react';
import {
  Box,
  Icon,
  Paper,
  Radio,
  TextField,
  RadioGroup,
  Typography,
  FormControlLabel,
  Grid,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import {
  SentimentSatisfied,
  SentimentDissatisfied,
  SentimentSatisfiedAlt,
  SentimentVerySatisfied,
  SentimentVeryDissatisfied,
} from '@mui/icons-material';
import {IOptions} from 'shared/services/api/student-quiz/QuestionList';

interface ILinearScalePreviewProps {
  options: IOptions[];
  comment: string;
  hasComment?: boolean;
  isCompleted: boolean;
  setComment: (comment: string) => void;
  onChange: (options: IOptions[]) => void;
}

export const LinearScalePreview: React.FC<ILinearScalePreviewProps> = memo(
  ({
    options,
    comment,
    hasComment,
    isCompleted,
    setComment,
    onChange,
  }: ILinearScalePreviewProps) => {
    const theme = useTheme();

    const isMobile = useMediaQuery('(max-width:600px)');

    const isEmoji = (index: number) => {
      const regexHexCode = /[0-9A-Fa-f]{6}/g;
      const option = options[index];
      if (!option || !option.iconeCor) {
        return false;
      }
      const iconeCor = option.iconeCor;
      if (iconeCor.split(',')[1] === '') {
        return true;
      }
      if (regexHexCode.test(iconeCor.split(',')[1])) {
        return true;
      }
      if (regexHexCode.test(iconeCor.split(',')[0])) {
        return false;
      }

      return true;
    };

    const selectedId = useMemo(() => {
      return options.find((option) => option.selecionado)?.id || false;
    }, [options]);

    const renderEmoji = useCallback(
      (position: number) => {
        const option = options[position];

        switch (option.iconeCor.split(',')[0]) {
          case 'SentimentVeryDissatisfied':
            return (
              <SentimentVeryDissatisfied
                htmlColor={option.iconeCor.split(',')[1]}
                color={option.iconeCor.split(',')[1] ? undefined : 'primary'}
                fontSize={'large'}
              />
            );

          case 'SentimentDissatisfied':
            return (
              <SentimentDissatisfied
                htmlColor={option.iconeCor.split(',')[1]}
                color={option.iconeCor.split(',')[1] ? undefined : 'primary'}
                fontSize={'large'}
              />
            );

          case 'SentimentSatisfied':
            return (
              <SentimentSatisfied
                htmlColor={option.iconeCor.split(',')[1]}
                color={option.iconeCor.split(',')[1] ? undefined : 'primary'}
                fontSize={'large'}
              />
            );

          case 'SentimentSatisfiedAlt':
            return (
              <SentimentSatisfiedAlt
                htmlColor={option.iconeCor.split(',')[1]}
                color={option.iconeCor.split(',')[1] ? undefined : 'primary'}
                fontSize={'large'}
              />
            );

          case 'SentimentVerySatisfied':
            return (
              <SentimentVerySatisfied
                htmlColor={option.iconeCor.split(',')[1]}
                color={option.iconeCor.split(',')[1] ? undefined : 'primary'}
                fontSize={'large'}
              />
            );

          default:
            return <Icon fontSize={'large'}></Icon>;
        }
      },
      [options],
    );

    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const currentSelectedId = (event.target as HTMLInputElement).value;

        const lastItemSelected: number = options.findIndex(
          (option) => option.selecionado,
        );

        if (lastItemSelected !== -1)
          options[lastItemSelected].selecionado = false;

        const currentItemSelected: number = options.findIndex(
          (option) => option.id === currentSelectedId,
        );

        options[currentItemSelected].selecionado = true;

        onChange(options);
      },
      [onChange, options],
    );

    const handleComment = (e: React.ChangeEvent<HTMLInputElement>) => {
      setComment(e.target.value);
    };

    return (
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <>
          <RadioGroup
            sx={{display: 'flex', flexDirection: isMobile ? 'column' : 'row'}}
            value={selectedId}
            onChange={handleChange}>
            {options.map((option, index) => {
              return (
                <Box
                  display={'flex'}
                  justifyContent={'flex-start'}
                  alignItems={'center'}>
                  <FormControlLabel
                    labelPlacement={isMobile ? 'end' : 'top'}
                    key={option.id}
                    label={
                      <>
                        {isEmoji(index) ? (
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                            textAlign="center">
                            {renderEmoji(index)}
                            <Typography marginLeft={0.5} align="center">
                              {option.descricao || `Opção ${index + 1}`}
                            </Typography>
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                            component={Paper}
                            padding={1}
                            borderRadius={10}
                            minWidth={65}
                            style={{
                              backgroundColor:
                                option.iconeCor === '' ||
                                option.iconeCor === null
                                  ? 'transparent !important'
                                  : option.iconeCor,
                              color:
                                option.iconeCor === '' ||
                                option.iconeCor === null
                                  ? theme.palette.grey[800]
                                  : theme.palette.common.white,
                            }}>
                            <Typography align="center">
                              {option.descricao || `Opção ${index + 1}`}
                            </Typography>
                          </Box>
                        )}
                      </>
                    }
                    value={option.id}
                    disabled={isCompleted}
                    control={<Radio color="primary" />}
                  />
                </Box>
              );
            })}
          </RadioGroup>
          {((hasComment && !isCompleted) ||
            (comment !== '' && isCompleted)) && (
            <Box marginTop={3} justifyContent="center" display="flex">
              <TextField
                fullWidth
                multiline
                rows={2}
                disabled={isCompleted}
                color="primary"
                label="Comentário"
                variant="outlined"
                placeholder="Adicionar um comentário"
                value={comment}
                onChange={handleComment}
              />
            </Box>
          )}
        </>
      </Grid>
    );
  },
);
