import React, {createContext, useState, useEffect} from 'react';
import { feedback } from 'shared/services';
import {
  IStudentQuiz,
  StudentQuizListService,
} from 'shared/services/api/student-quiz/QuizList';
import {useLocation} from 'react-router-dom';

interface IBannerLogoColorIdentifiers {
    [key: string]: {
      bannerBase64: string;
      quizColor: string;
    };
  }

interface IStudentQuizContextData {
  isLoadingQuizList: boolean;
  activeQuizes: IStudentQuiz[];
  nonActiveQuizes: IStudentQuiz[];
  bannerColorLogoAvailable: IBannerLogoColorIdentifiers;
}

export const StudentQuizContext = createContext<IStudentQuizContextData>(
  {} as IStudentQuizContextData,
);

export const StudentQuizProvider: React.FC = ({children}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [activeQuizes, setActiveQuizes] = useState<IStudentQuiz[]>([]);
  const [nonActiveQuizes, setNonActiveQuizes] = useState<IStudentQuiz[]>([]);
  const [bannerColorLogoAvailable, setBannerColorLogoAvailable] = useState<IBannerLogoColorIdentifiers>({} as IBannerLogoColorIdentifiers);
  const location = useLocation();

  useEffect(() => {
    if(location.pathname.includes('/pagina-inicial')) {
      setIsLoading(true);
      StudentQuizListService.getAll().then((resultOfaction) => {
        if (resultOfaction.success) {
          setActiveQuizes(resultOfaction.data.convitesAResponder);
  
          var bannersColorsLogos: IBannerLogoColorIdentifiers = {};
  
          resultOfaction.data.convitesAResponder.forEach((convite) => {
            if (convite.banner || convite.corQuestionario) {
              bannersColorsLogos[convite.idPessoaConvite] = {
                  bannerBase64: convite.banner || '',
                  quizColor: convite.corQuestionario || '018781'
              }
            }
          });
          setBannerColorLogoAvailable(bannersColorsLogos);
  
          setNonActiveQuizes(resultOfaction.data.convitesRespondidos);
  
          setIsLoading(false);
        } else {
          feedback(
            resultOfaction.message || 'Erro ao carregar convites recebidos.',
            'error',
          );
          setIsLoading(false);
        }
      });
    }
  }, [location.pathname]);

  return (
    <StudentQuizContext.Provider
      value={{
        isLoadingQuizList: isLoading,
        activeQuizes,
        nonActiveQuizes,
        bannerColorLogoAvailable
      }}>
      {children}
    </StudentQuizContext.Provider>
  );
};
