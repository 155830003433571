import {AppBar} from '@mui/material';
import {Theme, styled} from '@mui/material/styles';
import {makeStyles} from '@mui/styles';

export const StyledAppBar = styled(AppBar)`
  z-index: ${(props) => props.theme.zIndex.drawer};
  height: ${(props) => props.theme.spacing(8)};
  box-shadow: none;
  @media print {
    display: none;
  }
`;

export const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    height: theme.spacing(8),
    boxShadow: 'none',
    '@media print': {
      display: 'none',
    },
  },
  rightInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: theme.spacing(4),
  },
  menuButton: {
    marginRight: theme.spacing(0.5),
    padding: 4,
  },
  listItem: {
    height: theme.spacing(7),
    color: '#FFF',
  },

  iconMenu: {
    color: '#FFF',
  },
  imgLogo: {
    width: 125,
    objectFit: 'contain',
  },
}));
