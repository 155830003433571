import React, {memo, useCallback, useEffect, useState} from 'react';

import {AppBar, Tabs as MuiTabs, Tab, Box, useTheme} from '@mui/material';

import {About} from './About';
import {Invite} from './invite/Invite';
import {EstabSelectionTree} from './establishmentTree/EstabSelectionTree';
import {useParams} from 'react-router';
import {Theme} from '@mui/material/styles';
import {makeStyles} from '@mui/styles';
import {useQuiz} from '../hooks';
import {quizObservable} from '../observables/Observable';
import {Personalization} from './personalization/Personalization';

const useStyles = makeStyles((theme: Theme) => ({
  tabChild: {
    '& > div': {
      padding: '24px 0',
    },
  },
  tabButtonElement: {
    minWidth: 'auto',
    padding: '6px 24px',
  },
}));

interface ITabPanelProps {
  index: any;
  value: any;
}

interface ITabsProps {
  questCurrentTab: (isQuestTab: boolean) => void;
}

const TabPanel: React.FC<ITabPanelProps> = ({
  children,
  value,
  index,
  ...other
}) => {
  const theme = useTheme();

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      style={{
        background: theme.palette.common.white,
        borderBottomRightRadius: theme.spacing(1),
        borderBottomLeftRadius: theme.spacing(1),
      }}
      {...other}>
      {value === index && <Box p={0}>{children}</Box>}
    </Box>
  );
};

export const Tabs: React.FC<ITabsProps> = memo(({questCurrentTab}) => {
  const theme = useTheme();
  const {id = '0'} = useParams<'id'>();
  const [value, setValue] = useState(id === '0' ? 'one' : 'two');
  const {getEstablishments} = useQuiz();
  const [anyEstabSelected, setAnyEstabSelected] = useState(
    getEstablishments().length > 0,
  );

  useEffect(() => {
    const listener = quizObservable.subscribe(() => {
      setAnyEstabSelected(getEstablishments().length > 0);
    });
    return () => listener.unsubscribe();
  }, [getEstablishments]);
  const classes = useStyles();
  const handleChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: string) => {
      setValue(newValue);

      questCurrentTab(newValue === 'two');
    },
    [questCurrentTab],
  );

  return (
    <Box
      flexGrow={'1'}
      boxShadow={4}
      style={{
        borderRadius: theme.spacing(1),
      }}>
      <AppBar
        position="static"
        color="inherit"
        style={{
          boxShadow: 'none',
          borderBottom: `1px solid ${theme.palette.grey[300]}`,
          borderTopLeftRadius: theme.spacing(1),
          borderTopRightRadius: theme.spacing(1),
        }}>
        <MuiTabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary">
          <Tab
            className={classes.tabButtonElement}
            value="one"
            label="ESTABELECIMENTO"
          />
          <Tab
            className={classes.tabButtonElement}
            value="two"
            label="QUESTIONÁRIO"
            disabled={!anyEstabSelected}
          />
          <Tab
            className={classes.tabButtonElement}
            value="three"
            label="PERSONALIZAÇÃO"
            disabled={false}
          />
          <Tab
            className={classes.tabButtonElement}
            value="four"
            label="CONVITES"
            disabled={id === '0' ? true : false}
          />
        </MuiTabs>
      </AppBar>
      <TabPanel value={value} index="one">
        <EstabSelectionTree />
      </TabPanel>
      <TabPanel value={value} index="two">
        <About />
      </TabPanel>
      <TabPanel value={value} index="three">
        <Personalization />
      </TabPanel>
      <TabPanel value={value} index="four">
        <Invite />
      </TabPanel>
    </Box>
  );
});
