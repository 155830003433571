import React, {memo} from 'react';
import {Grid, OutlinedInput, Box, IconButton} from '@mui/material';
import {DragHandle, Add, Remove} from '@mui/icons-material';
import {IOption} from 'shared/services/api/quiz/Quiz';
import {useQuiz} from 'pages/quiz/hooks';

interface IOptionRowProps {
  option: IOption;
  quantityOfLines: number;
  handleAddRow: (index: number) => void;
  handleRemoveRow: (index: number) => void;
  handleUpdateDescription: (index: number, text: string) => void;
}

export const OptionRow: React.FC<IOptionRowProps> = memo(
  ({
    option,
    quantityOfLines,
    handleAddRow,
    handleRemoveRow,
    handleUpdateDescription,
  }) => {
    const {hasInviteSent} = useQuiz();

    return (
      <Grid container spacing={2} alignItems="center" justifyContent="flex-start">
        <Grid item={true} xs={1}>
          <DragHandle fontSize="small" />
        </Grid>
        <Grid item={true} xs={8}>
          <OutlinedInput
            placeholder={`Opção ${option.order}`}
            fullWidth
            value={option.description}
            onChange={(event) =>
              handleUpdateDescription(option.order, event.target.value)
            }
          />
        </Grid>
        <Grid item={true} xs={2}>
          <Box display="flex">
            <IconButton disabled={hasInviteSent} onClick={() => handleAddRow(option.order)}>
              <Add />
            </IconButton>
            <IconButton
              onClick={() => handleRemoveRow(option.order)}
              disabled={quantityOfLines === 1 || hasInviteSent}>
              <Remove />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    );
  },
);
