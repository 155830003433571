import React, {useEffect, useState, createContext, useCallback} from 'react';
import {Theme, CssBaseline} from '@mui/material';
import {ThemeProvider} from '@mui/material';
import {LightTheme} from '../themes';

interface IThemeContextData {
  toggleTheme(): void;
  theme: Theme;
}

const ThemeContext = createContext<IThemeContextData>({} as IThemeContextData);

export const ThemesProvider: React.FC = ({children}) => {
  useEffect(() => {
    setThemeData((oldState) => ({
      ...oldState,
      theme: LightTheme,
    }));
  }, []);

  const handleToggleTheme = useCallback(() => {
    setThemeData((oldState) => ({
      ...oldState,
      theme: LightTheme,
    }));
  }, []);

  const [themeData, setThemeData] = useState<IThemeContextData>({
    toggleTheme: handleToggleTheme,
    theme: LightTheme,
  });

  return (
    <ThemeContext.Provider value={themeData}>
      <CssBaseline />
      <ThemeProvider theme={themeData.theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};
