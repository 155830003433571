import {Box, Skeleton} from '@mui/material';

export const LoadingModal = () => {
  return (
    <Box>
      <Skeleton variant="rounded" width={800} height={64} />
      <Skeleton variant="rounded" width={800} height={64} />
      <Skeleton variant="rounded" width={800} height={64} />
      <Skeleton variant="rounded" width={800} height={64} />
      <Skeleton variant="rounded" width={800} height={64} />
    </Box>
  );
};
