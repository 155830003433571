import React from 'react';
import {Box} from '@mui/material';
import {
  DateTimeTextAnswer,
  LinearScaleUniqueMultipleAnswer,
  GridUniqueMultipleAnswer,
} from './modalComponents/index';
import {
  IGroupedByPropertyType,
  IQuestionDetail,
  IQuestionODataType,
} from 'shared/services/api/reports/AnswerSummary';
import {SatisfactionPercentageAnswer} from './modalComponents/satisfaction-percentage/SatisfactionPercentageAnswer';

export interface IModalContentRenderer {
  questionType: string;
  content: IGroupedByPropertyType | IQuestionODataType[];
  gridConfigs?: IQuestionDetail[];
  showAsText?: boolean;
}

export const ModalContentRenderer: React.FC<IModalContentRenderer> = ({
  questionType,
  content,
  gridConfigs,
  showAsText,
}) => {
  switch (questionType) {
    case 'simple-text':
      return <DateTimeTextAnswer content={content as IQuestionODataType[]} />;

    case 'paragraph-text':
      return <DateTimeTextAnswer content={content as IQuestionODataType[]} />;

    case 'time':
      return <DateTimeTextAnswer content={content as IQuestionODataType[]} />;

    case 'date':
      return <DateTimeTextAnswer content={content as IQuestionODataType[]} />;

    case 'linear-scale':
      return (
        <LinearScaleUniqueMultipleAnswer
          showAsText={showAsText}
          content={content as IGroupedByPropertyType}
        />
      );

    case 'satisfaction-percentage':
      return (
        <SatisfactionPercentageAnswer
          showAsText={showAsText}
          content={content as IGroupedByPropertyType}
        />
      );

    case 'unique-answer':
      return (
        <LinearScaleUniqueMultipleAnswer
          showAsText={showAsText}
          content={content as IGroupedByPropertyType}
        />
      );

    case 'multiple-answer':
      return (
        <LinearScaleUniqueMultipleAnswer
          showAsText={showAsText}
          content={content as IGroupedByPropertyType}
        />
      );

    case 'grid-multiple-answer':
      return (
        <GridUniqueMultipleAnswer
          content={content as IGroupedByPropertyType}
          configs={gridConfigs as IQuestionDetail[]}
        />
      );

    case 'grid-unique-answer':
      return (
        <GridUniqueMultipleAnswer
          content={content as IGroupedByPropertyType}
          configs={gridConfigs as IQuestionDetail[]}
        />
      );

    default:
      return <Box></Box>;
  }
};
