import {Api} from 'shared/services/axios-config';
import {IResultOfAction} from 'shared/services/axios-config/interceptors';

interface IQuestionaryTypesResponse {
  data: IQuestionaryTypes[];
  erpIntegracao: string;
}

export interface IQuestionaryTypes {
  id: string;
  nome: string;
  descricao: string;
}

const getAll = async (): Promise<
  IResultOfAction<IQuestionaryTypesResponse>
> => {
  try {
    const {data, headers} = await Api.get('/tiposQuestionarios');

    const tiposQuestionarios = data.map((tipo: any) => ({
      id: tipo.Id,
      nome: tipo.Nome,
      descricao: tipo.Descricao,
    }));

    const response: IResultOfAction<IQuestionaryTypesResponse> = {
      success: true,
      message: '',
      data: {
        data: tiposQuestionarios,
        erpIntegracao: headers['erpintegracao'] || '',
      },
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const getById = async (
  id: string | number,
): Promise<IResultOfAction<IQuestionaryTypes>> => {
  try {
    const {data} = await Api.get(`/tiposQuestionarios/${id}`);

    const tipoQuestionario = {
      id: data.Id,
      nome: data.Nome,
      descricao: data.Descricao,
    };

    const response: IResultOfAction<IQuestionaryTypes> = {
      success: true,
      message: '',
      data: tipoQuestionario,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const deleteById = async (id: string): Promise<IResultOfAction<any>> => {
  try {
    const {data} = await Api.delete(`/tiposQuestionarios/${id}`);

    const response: IResultOfAction<any> = {
      data: data.Data,
      success: data.Success,
      message: data.Message,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const create = async (
  questType: IQuestionaryTypes,
): Promise<IResultOfAction<any>> => {
  try {
    const bodyPost = {
      Id: questType.id,
      Nome: questType.nome,
      Descricao: questType.descricao,
    };

    const {data} = await Api.post('/tiposQuestionarios', bodyPost);

    const response: IResultOfAction<any> = {
      data: data.Data,
      success: data.Success,
      message: data.Message,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const updateById = async (
  id: string,
  questType: IQuestionaryTypes,
): Promise<IResultOfAction<any>> => {
  try {
    const putBody = {
      Id: questType.id,
      Nome: questType.nome,
      Descricao: questType.descricao,
    };

    const {data} = await Api.put(`/tiposQuestionarios/${id}`, putBody);

    const response: IResultOfAction<any> = {
      data: data.Data,
      success: data.Success,
      message: data.Message,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

export const QuestionaryTypesService = {
  getAll,
  getById,
  create,
  updateById,
  deleteById,
};
