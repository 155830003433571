import React, {useEffect, useMemo, useState} from 'react';
import {Box, Grid} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {HorizontalBarChart} from 'pages/reports/components/charts';
import {MultiselectCheckbox} from 'pages/reports/components/MultiselectCheckbox';
import {ISatisfactionTypeAnswer} from 'shared/services/api/reports/AnswerSummary';

const useStyles = makeStyles(() => ({
  doNotShowOnPrint: {
    '@media print': {
      display: 'none',
    },
  },
}));

interface LinearScaleUniqueMultipleChartProps {
  configs: ISatisfactionTypeAnswer[];
}

export const SatisfactionPercentageAnswer: React.FC<
  LinearScaleUniqueMultipleChartProps
> = ({configs}) => {
  const [chartLabels, setChartLabels] = useState<string[]>([]);
  const [datasetValues, setDatasetValues] = useState<number[]>([]);
  const classes = useStyles();

  const sumAnswers = (values: any) => {
    const groupedValues = values.map((val: any, i: number) => val.resposta);
    const sums = groupedValues[0].map((x: any, idx: number) =>
      groupedValues.reduce((sum: any, curr: any) => sum + curr[idx], 0),
    );
    return sums;
  };

  const groupBy = (collection: any, property: string) => {
    var i = 0,
      val,
      index,
      values = [],
      result = [];
    for (; i < collection.length; i++) {
      val = collection[i][property];
      index = values.indexOf(val);
      if (index > -1) result[index].push(collection[i]);
      else {
        values.push(val);
        result.push([collection[i]]);
      }
    }
    return result;
  };

  const checkboxFilters = useMemo(() => {
    var roleList: any = [];
    configs.forEach((config, i) => {
      config.papeis.reduce((r: any, item) => {
        roleList.push({
          resposta: item.numeroDeRespostas || 0,
          label: item.papel,
          checked: true,
        });
        return true;
      }, {});
    });

    const filtersList = groupBy(roleList, 'label');
    const result = filtersList.map((filter, i) => {
      var respostas: any = [];
      return filter.reduce((result: any, current: any) => {
        respostas.push(current.resposta);
        return Object.assign(result, {
          checked: true,
          label: current.label,
          resposta: respostas,
        });
      }, {});
    });

    return result;
  }, [configs]);

  useEffect(() => {
    const labels = configs.map((label) => label.resposta);
    setChartLabels(labels);
    setDatasetValues(
      configs.map((dataset) =>
        dataset.papeis.reduce((a, b) => {
          return a + (b.numeroDeRespostas || 0);
        }, 0),
      ),
    );
  }, [configs]);

  const barColors = useMemo(() => {
    const satisfiedColor = '#018781';
    const dissatisfiedColor = '#F4405C';

    const colors = configs.map((config) => {
      return config.satisfacao === 'Satisfeito'
        ? satisfiedColor
        : dissatisfiedColor;
    });

    return colors;
  }, [configs]);

  return (
    <Box
      width={'100%'}
      display={'flex'}
      justifyContent={'space-evenly'}
      alignItems={'flex-start'}>
      <Grid container spacing={0}>
        <Grid item xs={10}>
          <Box>
            <HorizontalBarChart
              labels={chartLabels}
              datasets={[
                {
                  label: 'Avaliações',
                  categoryPercentage: 1,
                  barPercentage: 0.6,
                  backgroundColor: barColors,
                  borderColor: barColors,
                  borderWidth: 1,
                  hoverBackgroundColor: barColors,
                  hoverBorderColor: barColors,
                  data: datasetValues,
                },
              ]}
              options={{
                maintainAspectRatio: true,
                responsive: true,
              }}
              width={600}
              height={300}
              showDataLabels={true}
            />
          </Box>
        </Grid>
        <Grid item xs={2} className={classes.doNotShowOnPrint}>
          <Box>
            <MultiselectCheckbox
              options={checkboxFilters}
              onChange={(values) => {
                !values.length
                  ? setDatasetValues([0])
                  : setDatasetValues(sumAnswers(values));
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
