import React, {memo} from 'react';

import {Box, Radio, Typography, OutlinedInput, Grid} from '@mui/material';

import {IOption} from 'shared/services/api/quiz/Quiz';
interface ISatisfactionPercentagePreviewProps {
  value: IOption[];
  hasComment?: boolean;
}

export const SatisfactionPercentagePreview: React.FC<ISatisfactionPercentagePreviewProps> =
  memo(({value, hasComment}: ISatisfactionPercentagePreviewProps) => {
    return (
      <>
        <Box
          style={{overflowX: 'scroll', whiteSpace: 'nowrap'}}
          display="flex"
          alignItems="center"
          justifyContent={'start'}>
          {value.map((option) => (
            <Box
              key={option.order + 'previewEmoji'}
              alignItems="center"
              alignContent="center"
              justifyContent={'center'}>
              <Box p={2} fontWeight="fontWeightBold">
                <Typography>
                  {option.description || 'Opção ' + option.order}
                </Typography>
              </Box>

              <Box display="flex" justifyContent="center">
                <Radio checked={false} disabled={true} />
              </Box>
            </Box>
          ))}
        </Box>
        {hasComment && (
          <Grid item xs={4}>
            <Box marginTop={10} justifyContent="center" display="flex">
              <OutlinedInput fullWidth placeholder="Comentar" disabled={true} />
            </Box>
          </Grid>
        )}
      </>
    );
  });
