import React, {useCallback, useMemo, useState, useEffect, useRef} from 'react';
import {
  Box,
  Grid,
  Radio,
  Switch,
  Checkbox,
  TextField,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Chip,
  Icon,
  Skeleton,
  Autocomplete,
  MenuItem,
} from '@mui/material';

import ptbr from 'dayjs/locale/pt-br';

import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';

import {
  IPublicoAlvo,
  IInvitation,
  InvitationService,
} from 'shared/services/api/invitation/Invitation';
import {feedback} from 'shared/services';
import {
  KeyboardTimepicker,
  useISOTimeFormatter,
} from 'shared/components/keyboard-timepicker/KeyboardTimepicker';
import {KeyboardDatepicker} from 'shared/components/keyboard-datepicker/KeyboardDatepicker';

interface IInviteDetailsProps {
  position: number;
  currentInvite: IInvitation;
  descriptionValidated: boolean;
  setIsEditing: (isEditing: number) => void;
  setCurrentInvite: (currentInvite: IInvitation) => void;
  handleError: (isError: boolean, message?: string) => void;
}

export const InviteDetails: React.FC<IInviteDetailsProps> = ({
  position,
  currentInvite,
  descriptionValidated,
  setIsEditing,
  setCurrentInvite,
  handleError,
}) => {
  const [isLoadingPublicoAlvo, setIsLoadingPublicoAlvo] = useState(false);
  const [publicoAlvo, setPublicoAlvo] = useState<IPublicoAlvo[]>([]);

  const {getISOTimeToStringWithoutGMT} = useISOTimeFormatter();

  const currentInvitePublicoAlvoClone = useRef<string[]>(
    currentInvite.publicosAlvo.map((obj) => obj.id),
  );

  const isPublicoAlvoWithoutAddressee = useMemo(() => {
    return currentInvite.status === 'PUBLICO_ALVO_SEM_DESTINATARIOS';
  }, [currentInvite.status]);

  const isBlockerForAnswers = useMemo(() => {
    return currentInvite.status === 'BLOQUEADO_PARA_RESPOSTAS';
  }, [currentInvite.status]);

  const isPublicoAlvoReprocessingFinished = useMemo(() => {
    return currentInvite.status === 'REPROCESSAMENTO_DO_PUBLICO_ALVO_CONCLUIDO';
  }, [currentInvite.status]);

  const isWaitingRelease = useMemo(() => {
    return currentInvite.status === 'AGUARDANDO_LIBERACAO';
  }, [currentInvite.status]);

  const isReleaseStarted = useMemo(() => {
    return currentInvite.status === 'LIBERACAO_INICIADA';
  }, [currentInvite.status]);

  const isPublicoAlvoReprocessing = useMemo(() => {
    return currentInvite.status === 'REPROCESSAMENTO_DO_PUBLICO_ALVO';
  }, [currentInvite.status]);

  const isAcceptingAnswers = useMemo(() => {
    return currentInvite.status === 'ACEITANDO_RESPOSTAS';
  }, [currentInvite.status]);

  const isInElaboration = useMemo(() => {
    return currentInvite.status === 'EM_ELABORACAO';
  }, [currentInvite.status]);

  const isInvitationClosed = useMemo(() => {
    return currentInvite.status === 'CONVITE_ENCERRADO';
  }, [currentInvite.status]);

  const handleDescricao = useCallback(
    (descricao: string) => {
      setCurrentInvite({
        ...currentInvite,
        descricao: descricao,
      });
      setIsEditing(position);
    },

    [currentInvite, setCurrentInvite, position, setIsEditing],
  );

  const handleAceitandoResposta = useCallback(
    (checked: boolean) => {
      setCurrentInvite({
        ...currentInvite,
        status: checked ? 'ACEITANDO_RESPOSTAS' : 'BLOQUEADO_PARA_RESPOSTAS',
        aceitandoRespostas: checked,
      });
      setIsEditing(position);
    },
    [currentInvite, setCurrentInvite, position, setIsEditing],
  );

  const handleAutocompleteTagBlock = (option: IPublicoAlvo) => {
    if (
      isAcceptingAnswers ||
      isWaitingRelease ||
      isReleaseStarted ||
      isPublicoAlvoReprocessing ||
      isBlockerForAnswers ||
      isPublicoAlvoReprocessingFinished
    ) {
      const isTagBlocked = currentInvitePublicoAlvoClone.current.some(
        (id: string) => id === option.id,
      );
      return isTagBlocked;
    }
    return !option.habilitado;
  };

  const handleBloquearPortal = useCallback(
    (checked: boolean) => {
      setCurrentInvite({
        ...currentInvite,
        permitirAcesso: checked,
      });
      setIsEditing(position);
    },
    [currentInvite, setCurrentInvite, position, setIsEditing],
  );

  const handleAddPublicoAlvo = (value: IPublicoAlvo) => {
    setCurrentInvite({
      ...currentInvite,
      publicosAlvo: [...currentInvite.publicosAlvo, value],
    });
    setIsEditing(position);
  };

  const handleDeletePublicoAlvo = (value: IPublicoAlvo) => {
    setCurrentInvite({
      ...currentInvite,
      publicosAlvo: [
        ...currentInvite.publicosAlvo.filter((item) => item.id !== value.id),
      ],
    });
    setIsEditing(position);
  };

  const handleResposta = useCallback(
    (e: any) => {
      setCurrentInvite({
        ...currentInvite,
        resposta: Number(e.target.value),
      });
      setIsEditing(position);
    },
    [currentInvite, setCurrentInvite, position, setIsEditing],
  );

  const handleDataHoraInicio = (dataHoraInicio: string, data: any) => {
    const timeISOString = getISOTimeToStringWithoutGMT(data);
    if (dataHoraInicio && data) {
      if (timeISOString) {
        setCurrentInvite({
          ...currentInvite,
          dataHoraInicio: timeISOString.toString(),
        });
      }
    }
    setIsEditing(position);
  };

  const handleDataHoraBloquear = (dataBloquear: string, data: any) => {
    const timeISOString = getISOTimeToStringWithoutGMT(data);
    if (dataBloquear && data) {
      if (timeISOString) {
        setCurrentInvite({
          ...currentInvite,
          dataHoraBloqueio: timeISOString.toString(),
        });
      }
    }
    setIsEditing(position);
  };

  const handleDataHoraFim = (dataHoraFim: string, data: any) => {
    const timeISOString = getISOTimeToStringWithoutGMT(data);
    if (dataHoraFim && data) {
      if (timeISOString) {
        setCurrentInvite({
          ...currentInvite,
          dataHoraFim: timeISOString.toString(),
        });
      }
    }
    setIsEditing(position);
  };

  useEffect(() => {
    setIsLoadingPublicoAlvo(true);
    InvitationService.getPublicoAlvo()
      .then((response) => {
        if (response.success) {
          setPublicoAlvo(response.data);
        } else {
          feedback(
            response.message || 'Erro ao carregar públicos-alvo.',
            'error',
          );
        }
      })
      .finally(() => {
        setIsLoadingPublicoAlvo(false);
      });
  }, []);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={ptbr}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              helperText={!descriptionValidated && 'Campo obrigatório'}
              error={!descriptionValidated}
              disabled={isInvitationClosed}
              fullWidth
              variant="outlined"
              label="Descrição do convite *"
              value={currentInvite.descricao}
              onChange={(e) => handleDescricao(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <Box width="100%" display="flex" justifyContent="flex-end">
              <FormControlLabel
                title={
                  isInElaboration
                    ? 'Faça liberação do convite primeiro'
                    : 'Aceitando respostas'
                }
                disabled={
                  !(
                    isBlockerForAnswers ||
                    isAcceptingAnswers ||
                    isPublicoAlvoWithoutAddressee
                  )
                }
                control={
                  <Switch
                    checked={currentInvite.aceitandoRespostas}
                    onChange={(e) => handleAceitandoResposta(e.target.checked)}
                    color="primary"
                  />
                }
                label="Aceitando respostas"
                labelPlacement="start"
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            {isLoadingPublicoAlvo ? (
              <Box>
                <Skeleton variant="rectangular" width={'100%'} height={56} />
              </Box>
            ) : (
              <Autocomplete
                multiple
                options={publicoAlvo}
                getOptionLabel={(option) => option.nome}
                disableClearable={true}
                value={currentInvite.publicosAlvo}
                filterSelectedOptions
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderOption={(_, option: IPublicoAlvo) => {
                  return (
                    <MenuItem
                      disabled={!option?.habilitado}
                      onClick={() => handleAddPublicoAlvo(option)}>
                      <Box component="li" display="flex" alignItems={'center'}>
                        {option?.nome}
                        {!option?.habilitado && (
                          <>
                            <Box display="flex" alignItems={'center'}>
                              <Icon>warning_amber_rounded</Icon>
                              <span>
                                Público-alvo não possuí pessoas ou está em
                                processamento.
                              </span>
                            </Box>
                          </>
                        )}
                      </Box>
                    </MenuItem>
                  );
                }}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip
                      color="primary"
                      label={option?.nome}
                      {...getTagProps({index})}
                      onDelete={() => handleDeletePublicoAlvo(option)}
                      disabled={handleAutocompleteTagBlock(option)}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    color="primary"
                    variant="outlined"
                    label="Público alvo *"
                  />
                )}
              />
            )}
          </Grid>
          <Grid item xs={3}>
            <KeyboardDatepicker
              label={'Data de início'}
              value={currentInvite.dataHoraInicio}
              handleChange={handleDataHoraInicio}
            />
          </Grid>
          <Grid item xs={3}>
            <KeyboardTimepicker
              label="Horário de início"
              value={currentInvite.dataHoraInicio}
              handleChange={handleDataHoraInicio}
            />
          </Grid>
          <Grid item xs={3}>
            <KeyboardDatepicker
              label={'Data de término'}
              value={currentInvite.dataHoraFim}
              handleChange={handleDataHoraFim}
            />
          </Grid>
          <Grid item xs={3}>
            <KeyboardTimepicker
              label="Horário de término"
              value={currentInvite.dataHoraFim}
              handleChange={handleDataHoraFim}
            />
          </Grid>
          <Grid item xs={6}>
            <FormLabel component="legend">Respostas *</FormLabel>
            <RadioGroup
              aria-label="respostas"
              value={currentInvite.resposta}
              onChange={(e) => handleResposta(e)}>
              <FormControlLabel
                disabled={isInvitationClosed || isAcceptingAnswers}
                key={1}
                value={1}
                control={<Radio color="primary" />}
                label={'Todas identificadas'}
              />
              <FormControlLabel
                disabled={isInvitationClosed || isAcceptingAnswers}
                key={2}
                value={2}
                control={<Radio color="primary" />}
                label={'Todas anônimas'}
              />
              <FormControlLabel
                disabled={isInvitationClosed || isAcceptingAnswers}
                key={3}
                value={3}
                control={<Radio color="primary" />}
                label={'Participante escolhe'}
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={ptbr}>
              <FormLabel component="legend">
                Bloquear acesso ao portal a partir de:
              </FormLabel>
              <Box
                marginTop={2}
                marginBottom={1}
                display="flex"
                justifyContent="space-between"
                gap={3}>
                <KeyboardDatepicker
                  label={'Data de bloqueio'}
                  value={currentInvite.dataHoraBloqueio}
                  handleChange={handleDataHoraBloquear}
                />
                <KeyboardTimepicker
                  label="Horário de bloqueio"
                  value={currentInvite.dataHoraBloqueio}
                  handleChange={handleDataHoraBloquear}
                />
              </Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={currentInvite.permitirAcesso}
                    color="primary"
                    onChange={(e) => handleBloquearPortal(e.target.checked)}
                  />
                }
                label="Não bloquear"
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </LocalizationProvider>
    </>
  );
};
