import React, {useState, useEffect} from 'react';
import {
  Container,
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  Grid,
} from '@mui/material';
import {EstabSelectionTreeSkeleton} from './establishment/EstabSelectionTreeSkeleton';
import {EstabSelectionTree} from './establishment/EstablishmentSelectionTree';
import {usePermissions} from '../hooks/usePermissions';
import {Skeleton} from '@mui/lab';

interface IProps {
  estabsFromUserChoice: number[];
  onChangeEstabsFromUserChoice: (value: number[]) => void;
}

export const PermissionsDetailPublicoAlvo: React.FC<IProps> = ({
  estabsFromUserChoice,
  onChangeEstabsFromUserChoice,
}) => {
  const {
    establishments,
    enabledInPublicoAlvo,
    setEnabledInPublicoAlvo,
    userSelectedEstablishments,
    userSelectedStructuredEstablishments,
  } = usePermissions();
  const [isLoading, setIsLoading] = useState(true);
  const [publicoAlvoEstabelecimentos, setPublicoAlvoEstabelecimentos] =
    useState(establishments);

  useEffect(() => {
    setTimeout(() => {
      setPublicoAlvoEstabelecimentos(establishments);
      setIsLoading(false);
    }, 500);
  }, [establishments]);

  const handleEnableUser = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnabledInPublicoAlvo(event.target.checked);
  };

  return (
    <Container>
      <Box width={'100%'}>
        <Box width={'100%'} marginBottom={3}>
          <Grid container item xs={12}>
            {isLoading ? (
              <Box marginBottom={1} width={400}>
                <Skeleton variant="text" width="50" />
              </Box>
            ) : (
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={false}
                    checked={enabledInPublicoAlvo}
                    onChange={handleEnableUser}
                  />
                }
                label={<strong>Habilitar usuário em Público-alvo</strong>}
              />
            )}
          </Grid>
        </Box>
        <Box>
          <Box width="100%">
            <Typography variant="subtitle1">
              <strong>Estabelecimentos</strong>
            </Typography>
          </Box>
          {isLoading ? (
            <EstabSelectionTreeSkeleton />
          ) : (
            <EstabSelectionTree
              establishments={publicoAlvoEstabelecimentos}
              selectedEstablishments={userSelectedEstablishments}
              selectedStructuredEstablishments={
                userSelectedStructuredEstablishments
              }
              estabsFromUserChoice={estabsFromUserChoice}
              onChangeEstabsFromUserChoice={onChangeEstabsFromUserChoice}
            />
          )}
        </Box>
      </Box>
    </Container>
  );
};
