import React from 'react';
import {Box} from '@mui/material';
import {DateTimeTextSkeleton} from './date-time-text/DateTimeTextSkeleton';
import {LinScaleUniqueMultipleSkeleton} from './lin-scale-unique-multiple/LinScaleUniqueMultipleSkeleton';
import {GridUniqueMultipleSkeleton} from './grid-unique-multiple/GridUniqueMultipleSkeleton';

export interface ILoadingSkeletonProps {
  questionType: string;
  showAsText: boolean;
}

export const LoadingSkeletonRenderer: React.FC<ILoadingSkeletonProps> = ({
  questionType,
  showAsText
}) => {
  switch (questionType) {
    case 'simple-text' || 'paragraph-text' || 'time' || 'date':
      return <DateTimeTextSkeleton />;

    case 'paragraph-text':
      return <DateTimeTextSkeleton />;

    case 'time':
      return <DateTimeTextSkeleton />;

    case 'date':
      return <DateTimeTextSkeleton />;

    case 'linear-scale':
      return showAsText ? <DateTimeTextSkeleton /> : <LinScaleUniqueMultipleSkeleton />;

    case 'unique-answer':
      return showAsText ? <DateTimeTextSkeleton /> : <LinScaleUniqueMultipleSkeleton />;

    case 'multiple-answer':
      return showAsText ? <DateTimeTextSkeleton /> : <LinScaleUniqueMultipleSkeleton />;

    case 'grid-multiple-answer':
      return <GridUniqueMultipleSkeleton />;
      
    case 'grid-unique-answer':
      return <GridUniqueMultipleSkeleton />;

    default:
      return <Box></Box>;
  }
};
