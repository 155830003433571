import React, {useCallback, useEffect, useState} from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Tooltip,
  Typography,
  Box,
  Pagination,
} from '@mui/material';
import {feedback} from 'shared/services';
import {
  CentralConvitesService,
  IHistoricoConvite,
} from 'shared/services/api/central-convites/CentralConvitesService';
import {StatusConvite} from './StatusConvite';
import {Close, HistoryRounded} from '@mui/icons-material';
import {format} from 'date-fns';
import {LoadingModal} from './LoadingModal';
import {Environment} from 'shared/Env';
import {NotFoundText} from 'shared/components';

interface IProps {
  id: string;
}

export const HistoricoModal: React.FC<IProps> = ({id}) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [historicos, setHistoricos] = useState<IHistoricoConvite[]>();
  const [page, setPage] = useState(1);

  const handleClose = () => {
    setOpen(false);
  };

  const handlePageChange = useCallback(
    (event: React.ChangeEvent<unknown>, value: number) => {
      setPage(value);
    },
    [],
  );

  useEffect(() => {
    if (open) {
      setIsLoading(true);
      CentralConvitesService.getHistoricoConvite(id)
        .then((result) => {
          if (result.success) {
            setHistoricos(result.data);
          } else {
            setHistoricos([]);
            feedback(
              result.message || 'Erro ao carregar histório do convite.',
              'error',
            );
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [id, open]);

  return (
    <>
      <Tooltip placement="top" title="Acessar histórico do convite">
        <IconButton onClick={() => setOpen(true)}>
          <HistoryRounded fontSize="small" />
        </IconButton>
      </Tooltip>
      {open && (
        <Dialog open={open} onClose={handleClose} maxWidth={'lg'}>
          <DialogTitle>
            <Box
              width={'100%'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}>
              <Typography color="primary">
                Histórico de processamento do convite
              </Typography>
              <IconButton aria-label="close" onClick={handleClose}>
                <Close />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            {isLoading && <LoadingModal />}

            {!isLoading && (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Etapa de processamento</TableCell>
                      <TableCell>Status do processamento</TableCell>
                      <TableCell>Data e hora</TableCell>
                      <TableCell>Mensagem de erro</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {historicos &&
                      historicos
                        .slice(
                          (page - 1) * Environment.LIMITE_DE_LINHAS,
                          (page - 1) * Environment.LIMITE_DE_LINHAS +
                            Environment.LIMITE_DE_LINHAS,
                        )
                        .map((historico) => (
                          <TableRow key={historico.Id}>
                            <TableCell>
                              {historico.EtapaDeProcessamento}
                            </TableCell>
                            <TableCell>
                              {<StatusConvite status={historico.Status} />}
                            </TableCell>
                            <TableCell>{`${format(
                              new Date(historico.DataHora),
                              'dd/MM/yyyy',
                            )}`}</TableCell>
                            <TableCell>
                              {historico.MensagemErro || '-'}
                            </TableCell>
                          </TableRow>
                        ))}
                    {historicos && historicos?.length <= 0 && (
                      <TableRow>
                        <TableCell colSpan={12}>
                          <NotFoundText />
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                {historicos && historicos?.length > 0 && (
                  <Box
                    marginTop={1}
                    marginBottom={1}
                    display={'flex'}
                    justifyContent={'end'}>
                    <Pagination
                      page={page}
                      count={Math.ceil(
                        historicos.length / Environment.LIMITE_DE_LINHAS,
                      )}
                      onChange={handlePageChange}
                    />
                  </Box>
                )}
              </TableContainer>
            )}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
