import React, {memo} from 'react';
import {Box, TextField, Grid} from '@mui/material';

interface ISimpleTextProps {
  textAnswer: string;
  comment: string;
  hasComment?: boolean;
  isCompleted: boolean;
  setTextAnswer: (textAnswer: string) => void;
  setComment: (comment: string) => void;
}

export const SimpleTextPreview: React.FC<ISimpleTextProps> = memo(
  ({
    textAnswer,
    comment,
    hasComment,
    isCompleted,
    setTextAnswer,
    setComment,
  }) => {
    const handleValue = (e: React.ChangeEvent<HTMLInputElement>) => {
      setTextAnswer(e.target.value);
    };

    const handleComment = (e: React.ChangeEvent<HTMLInputElement>) => {
      setComment(e.target.value);
    };

    return (
      <Grid item xs={12} sm={12} md={8} lg={8}>
        <Box>
          <TextField
            fullWidth
            multiline
            rows={1}
            disabled={isCompleted}
            variant="outlined"
            label="Resposta"
            value={textAnswer}
            onChange={handleValue}
            inputProps={{
              maxLength: 140
            }}
            helperText={`${textAnswer.length}/140`}
          />
        </Box>
        {((hasComment && !isCompleted) || (comment !== '' && isCompleted)) && (
          <Box marginTop={3} justifyContent="center" display="flex">
            <TextField
              fullWidth
              multiline
              rows={2}
              disabled={isCompleted}
              color="primary"
              label="Comentário"
              variant="outlined"
              placeholder="Adicionar um comentário"
              value={comment}
              onChange={handleComment}
            />
          </Box>
        )}
      </Grid>
    );
  },
);
