import {Api} from 'shared/services/axios-config';
import {IResultOfAction} from 'shared/services/axios-config/interceptors';
import {format} from 'date-fns';
import {
  IIndividualAnswers,
  IMultiplier,
  IQuestion,
  ISection,
} from '../reports/IndividualAnswers';

const getById = async (
  idPessoaConvite: string,
): Promise<IResultOfAction<IIndividualAnswers>> => {
  try {
    const {data} = await Api.get(
      `/ConvitesRecebidos/visualizacaoRespostas/${idPessoaConvite}`,
    );

    const object: IIndividualAnswers = {
      tituloQuestionario: data.TituloQuestionario,
      descricao: data.Descricao !== null ? data.Descricao.toUpperCase() : '',
      bannerBase64: data.BannerBase64
        ? `data:image/png;base64,${data.BannerBase64}`
        : undefined,
      respondidoEm: format(new Date(data.RespondidoEm), 'dd/MM/yyyy HH:mm'),
      statusPessoaConvite: data.StatusPessoaConvite,
      respostaAnonima: data.RespostasAnonimas,
      pessoa:
        data.Pessoa !== null
          ? {
              nome: data.Pessoa.Nome || '',
              papel: data.Pessoa.Papel.toUpperCase() || '',
              matricula: data.Pessoa.Matricula || '',
              descricaoCurso:
                data.Pessoa.DescricaoCurso !== null
                  ? data.Pessoa.DescricaoCurso.toUpperCase()
                  : '',
            }
          : {
              nome: '',
              papel: '',
              matricula: '',
              descricaoCurso: '',
            },
      secoes: data.Secoes.map(
        (secao: any): ISection => ({
          idSecao: secao.IdSecao,
          idPessoaConvite: secao.IdPessoaConvite,
          ordem: secao.Ordem,
          nomeSecao: secao.NomeSecao,
          idQuestionario: secao.IdQuestionario,
          multiplicadores: secao.Multiplicadores.map(
            (multiplicador: any): IMultiplier => ({
              codigo: multiplicador.Codigo,
              nome: multiplicador.Nome.toUpperCase(),
              professor: multiplicador.Professor,
              todasRespondidas: multiplicador.TodasRespondidas,
              questoes: multiplicador.Questoes.map(
                (questao: any): IQuestion => ({
                  idQuestao: questao.IdQuestao,
                  idResposta: questao.IdResposta,
                  enunciado: `${questao.Obrigatoria ? '*' : ''}${
                    questao.Ordem
                  }. ${questao.Enunciado}`,
                  descricao: questao.Descricao,
                  permiteComentario: questao.PermiteComentario,
                  comentario: questao.Comentario || '',
                  respondido: questao.Respondido,
                  tipoQuestao: questao.TipoQuestao,
                  ordem: questao.Ordem,
                  obrigatoria: questao.Obrigatoria,
                  respostaTexto: questao.RespostaTexto || '',
                  opcoesRespostaLinha: questao.OpcoesRespostaLinha.map(
                    (opcao: any) => ({
                      id: opcao.Id,
                      descricao: opcao.Descricao,
                      tipoIcone: opcao.TipoIcone,
                      iconeCor: opcao.IconeCor,
                      selecionado: opcao.Selecionado,
                      opcoesRespostaColuna: opcao.OpcoesRespostaColuna.map(
                        (opcao: any) => ({
                          id: opcao.Id,
                          descricao: opcao.Descricao,
                          tipoIcone: opcao.TipoIcone,
                          iconeCor: opcao.IconeCor,
                          selecionado: opcao.Selecionado,
                        }),
                      ),
                    }),
                  ),
                }),
              ),
            }),
          ),
        }),
      ),
    };

    const response: IResultOfAction<IIndividualAnswers> = {
      data: object,
      success: true,
      message: '',
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

export const AnsweredQuizService = {
  getById,
};
