import React, {useState, useCallback, useMemo, useEffect} from 'react';
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
  TableRow,
  TableCell,
  Paper,
  FormControl,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  TextField,
} from '@mui/material';
import {
  IGroupedByPropertyType,
  IQuestionODataType,
} from 'shared/services/api/reports/AnswerSummary';
import {Pagination} from '@mui/lab';
import {useDebounce} from 'shared/hooks';
import {Search} from '@mui/icons-material';
import {PersonInfo} from '../PersonInfo';

interface ILinearScaleUniqueMultipleQuestionContent {
  content: IGroupedByPropertyType;
  showAsText?: boolean;
}

interface IDynamicPagination {
  rowsPerPage: number;
  contentLength: number;
  onChangePage: (page: number) => void;
}

interface IDynamicContentPages {
  [key: string]: number;
}

interface IDynamicContentSearchText {
  [key: string]: string;
}

const DynamicPagination: React.FC<IDynamicPagination> = ({
  rowsPerPage = 5,
  contentLength,
  onChangePage,
}) => {
  const [page, setPage] = useState(1);

  const handlePageChange = useCallback(
    (event: React.ChangeEvent<unknown>, value: number) => {
      setPage(value);
      onChangePage(value);
    },
    [onChangePage],
  );

  return (
    <>
      {rowsPerPage && contentLength > 0 && (
        <Box marginTop={1} marginBottom={1} marginLeft={1} marginRight={1}>
          <Pagination
            page={page}
            count={Math.ceil(contentLength / rowsPerPage)}
            onChange={handlePageChange}
          />
        </Box>
      )}
    </>
  );
};

export const SatisfactionPercentageAnswer: React.FC<
  ILinearScaleUniqueMultipleQuestionContent
> = ({content, showAsText = false}) => {
  const [tableContent, setTableContent] =
    useState<IGroupedByPropertyType>(content);
  const [contentPages, setContentPages] = useState<IDynamicContentPages>(
    {} as IDynamicContentPages,
  );
  const [searchText, setSearchText] = useState<IDynamicContentSearchText>(
    {} as IDynamicContentSearchText,
  );
  const searchContent = useMemo<IGroupedByPropertyType>(() => {
    return content;
  }, [content]);
  const {debounce} = useDebounce();
  const [showComments, setShowComments] = useState(true);
  const [search, setSearch] = useState('');
  const [commentsByClass, setCommentsByClass] = useState<IQuestionODataType[]>(
    [],
  );

  const rowsPerPage = useMemo(() => {
    return 5;
  }, []);

  useEffect(() => {
    const allKeys = Object.keys(content).map((key) => key);
    const objectWithKeys = allKeys.reduce(
      // eslint-disable-next-line
      (prev: any, curr: any) => ((prev[curr] = 1), prev),
      {},
    );
    setContentPages(objectWithKeys);

    const searcTexthObject = Object.keys(objectWithKeys).reduce(
      // eslint-disable-next-line
      (prev: any, curr: any) => {
        prev[curr] = '';
        return prev;
      },
      {},
    );
    setSearchText(searcTexthObject);

    var arrayOfGroupedByCodeAndClass: any = [];
    Object.keys(content).forEach((contentKey, k) => {
      var helper: IGroupedByPropertyType = {};
      var result = content[contentKey].reduce(function (r: any, o: any) {
        var key = o.codigoUsuario + '-' + o.idTurma || o.nomeDisciplina;

        if (!helper[key]) {
          helper[key] = Object.assign({}, o);
          r.push(helper[key]);
        }

        return r;
      }, []);
      arrayOfGroupedByCodeAndClass.push(...result);
    });
    setCommentsByClass(arrayOfGroupedByCodeAndClass);
  }, [content]);

  const removeAccentuation = useCallback((value: string) => {
    const resultFilter = value
      .toLocaleLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

    return resultFilter;
  }, []);

  const handleSearchDynamic = useCallback(
    (
      e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
      currentKey: string,
    ) => {
      setSearchText({...searchText, [currentKey]: e.target.value});

      debounce(() => {
        const valor = removeAccentuation(e.target.value);

        const result = searchContent[currentKey].filter(
          (item) =>
            (item.nomePessoa &&
              removeAccentuation(item.nomePessoa).indexOf(valor) > -1) ||
            removeAccentuation(String(item.matricula)).indexOf(valor) > -1 ||
            removeAccentuation(item.curso).indexOf(valor) > -1 ||
            removeAccentuation(item.papel).indexOf(valor) > -1,
        );

        if (e.target.value === '') {
          setTableContent({
            ...tableContent,
            [currentKey]: searchContent[currentKey],
          });
        } else {
          setTableContent({...tableContent, [currentKey]: result});
        }

        setContentPages({...contentPages, [currentKey]: 1});
      });
    },
    [
      debounce,
      removeAccentuation,
      searchContent,
      contentPages,
      searchText,
      tableContent,
    ],
  );

  const handleSearchCommon = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value);

      debounce(() => {
        const valor = removeAccentuation(e.target.value);

        const resultedKey = Object.keys(searchContent).filter(
          (key) =>
            searchContent[key][0].nomePessoa &&
            removeAccentuation(searchContent[key][0].nomePessoa).indexOf(
              valor,
            ) > -1,
        );

        const contentToPush: any = {};
        if (e.target.value === '') {
          resultedKey.forEach((key) => {
            contentToPush[key] = searchContent[key];
          });
          setTableContent({...searchContent, ...contentToPush});
        } else {
          resultedKey.forEach((key) => {
            contentToPush[key] = searchContent[key];
          });
          setTableContent({...contentToPush});
        }
      });
    },
    [debounce, removeAccentuation, searchContent],
  );

  const isEmpty = (obj: any) => {
    return Object.keys(obj).length === 0;
  };

  return (
    <Box width={'100%'}>
      <Box width={'100%'} maxWidth={'1123px'} margin={'0 auto'} p={3}>
        {showAsText && (
          <Box width={'100%'} marginBottom={1}>
            <Box
              width={'100%'}
              display={'flex'}
              justifyContent={'flex-end'}
              alignItems={'center'}
              gap={20}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={false}
                    color="primary"
                    checked={showComments}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setShowComments(e.target.checked);
                    }}
                  />
                }
                label={<strong>Mostrar comentários</strong>}
              />
              <FormControl variant="outlined">
                <Paper>
                  <TextField
                    style={{padding: 0, margin: 0}}
                    fullWidth
                    variant="outlined"
                    placeholder="Pesquisar participantes"
                    value={search}
                    onChange={handleSearchCommon}
                    InputProps={{
                      style: {
                        height: 40,
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search color="disabled" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Paper>
              </FormControl>
            </Box>
          </Box>
        )}
        {!isEmpty(content) ? (
          Object.keys(tableContent).map((key, k) => (
            <Box width={'100%'} marginBottom={5}>
              {showAsText ? (
                <Box width={'100%'} marginBottom={5}>
                  <Box
                    width={'100%'}
                    key={`lin-scale-multiple-unique-person-answer-comment-${
                      tableContent[key][0].id
                    }-${Math.floor(Math.random() * k)}`}>
                    <PersonInfo
                      curso={tableContent[key][0].curso}
                      grupoPapel={tableContent[key][0].papel}
                      identifier={tableContent[key][0].id}
                      matricula={tableContent[key][0].matricula}
                      nomePessoa={tableContent[key][0].nomePessoa}
                    />
                    <Box width={'100%'} p={'0 0 0 64px'}>
                      <Box marginBottom={2} />
                      {tableContent[key][0].multiplicarDisciplina &&
                      !isEmpty(content) ? (
                        commentsByClass
                          .filter((f) => {
                            return (
                              f.codigoUsuario ===
                              tableContent[key][0].codigoUsuario
                            );
                          })
                          .map((filteredComments) => (
                            <>
                              <Box marginBottom={3} />
                              {filteredComments.nomeTurma && (
                                <Typography
                                  variant={'h5'}
                                  color={'textSecondary'}>
                                  Turma: {filteredComments.nomeTurma}
                                </Typography>
                              )}
                              <Typography
                                variant={'h6'}
                                color={'textSecondary'}>
                                Disciplina: {filteredComments.nomeDisciplina}
                              </Typography>
                              <Typography color={'textSecondary'}>
                                Resposta:
                              </Typography>
                              <Typography component={'p'}>
                                {filteredComments.respostaLinha}
                              </Typography>
                              <Box marginBottom={2} />
                              {showComments &&
                                filteredComments.comentario !== '' && (
                                  <>
                                    <Typography color={'textSecondary'}>
                                      Comentário:
                                    </Typography>
                                    <Typography component={'p'}>
                                      {filteredComments.comentario}
                                    </Typography>
                                  </>
                                )}
                            </>
                          ))
                      ) : tableContent[key][0].comentario !== '' &&
                        !isEmpty(content) ? (
                        <>
                          <Box marginBottom={3} />
                          <Typography color={'textSecondary'}>
                            Resposta:
                          </Typography>
                          <Typography component={'p'}>
                            {tableContent[key][0].respostaLinha}
                          </Typography>
                          <Box marginBottom={2} />
                          {showComments &&
                            tableContent[key][0].comentario !== '' && (
                              <>
                                <Typography color={'textSecondary'}>
                                  Comentário:
                                </Typography>
                                <Typography component={'p'}>
                                  {tableContent[key][0].comentario}
                                </Typography>
                              </>
                            )}
                        </>
                      ) : (
                        <Box>
                          <Typography color={'primary'} variant={'h4'}>
                            Nenhum comenário para ser mostrado.
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              ) : (
                <>
                  <Box
                    marginBottom={2}
                    width={'100%'}
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}>
                    <Typography variant={'h4'} color={'textSecondary'}>
                      {key}
                    </Typography>
                    <Box>
                      <FormControl variant="outlined">
                        <Paper>
                          <TextField
                            style={{padding: 0, margin: 0}}
                            fullWidth
                            variant="outlined"
                            placeholder="Pesquisar participantes"
                            value={searchText[key]}
                            onChange={(e) => {
                              handleSearchDynamic(e, key);
                            }}
                            InputProps={{
                              style: {
                                height: 40,
                              },
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Search color="disabled" />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Paper>
                      </FormControl>
                    </Box>
                  </Box>
                  <Box width={'100%'}>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Nome
                            </TableCell>
                            <TableCell component="th" scope="row">
                              Matrícula
                            </TableCell>
                            <TableCell component="th" scope="row">
                              Curso
                            </TableCell>
                            <TableCell component="th" scope="row">
                              Disciplina
                            </TableCell>
                            <TableCell component="th" scope="row">
                              Turma
                            </TableCell>
                            <TableCell component="th" scope="row">
                              Papel
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {tableContent[key]
                            .filter(
                              (value, index, self) =>
                                index ===
                                self.findIndex(
                                  (t) =>
                                    t.codigoUsuario === value.codigoUsuario,
                                ),
                            )
                            .slice(
                              (contentPages[key] - 1) * rowsPerPage,
                              (contentPages[key] - 1) * rowsPerPage +
                                rowsPerPage,
                            )
                            .map((question, k) => (
                              <TableRow
                                hover
                                key={`question-${k}-${question.id}-${question.idQuestao}-${question.idPessoaConvite}`}>
                                <TableCell component="th" scope="row">
                                  {question.nomePessoa !== null
                                    ? question.nomePessoa
                                    : 'Usuário Anônimo'}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {question.matricula === null ||
                                  question.matricula === 0
                                    ? '-'
                                    : question.matricula}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {question.curso || '-'}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {question.nomeDisciplina || '-'}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {question.nomeTurma || '-'}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {question.papel || '-'}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                      <DynamicPagination
                        rowsPerPage={rowsPerPage}
                        contentLength={
                          tableContent[key].filter(
                            (value, index, self) =>
                              index ===
                              self.findIndex(
                                (t) => t.codigoUsuario === value.codigoUsuario,
                              ),
                          ).length
                        }
                        onChangePage={(page) => {
                          setContentPages({...contentPages, [key]: page});
                        }}
                      />
                    </TableContainer>
                  </Box>
                </>
              )}
            </Box>
          ))
        ) : (
          <Box width={'100%'}>
            <Typography color={'primary'} variant={'h6'}>
              Nenhuma informação para ser mostrada...
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
