import React, {useCallback, useEffect, useState} from 'react';
import {
  Box,
  Table,
  Paper,
  Button,
  Dialog,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  DialogTitle,
  FormControl,
  DialogContent,
  TableContainer,
  DialogContentText,
  Icon,
  IconButton,
  Grid,
  TextField,
  Tooltip,
  CircularProgress,
  Divider,
  DialogActions,
  Chip,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
import {
  Delete,
  Contacts,
  FiberManualRecord,
  Add,
  Close,
  History,
} from '@mui/icons-material';
import {format, isWithinInterval, compareAsc} from 'date-fns';
import {feedback, confirm} from 'shared/services';
import {InviteDetails} from './InviteDetails';
import {
  IPessoa,
  IPublicoAlvo,
  InvitationService,
  IInvitation,
} from 'shared/services/api/invitation/Invitation';
import {useQuiz} from '../../hooks';
import {InviteTableSkeleton} from './InviteTableSkeleton';
import {NotFoundText} from 'shared/components';
import {InviteSkeleton} from './InviteSkeleton';
import {Autocomplete, Pagination} from '@mui/lab';
import {Alert, AlertTitle} from '@mui/material';
import {Environment} from 'shared/Env';
import DownloadIcon from '@mui/icons-material/CloudDownloadOutlined';
import WarningIcon from '@mui/icons-material/Warning';
import {useParams} from 'react-router';
import {InviteLog} from './InviteLog';
import LinkIcon from '@mui/icons-material/Link';
import axios from 'axios';

export const Invite: React.FC = () => {
  const {id = '0'} = useParams<'id'>();
  const [invites, setInvites] = useState<IInvitation[]>([]);

  const {getNameQuiz} = useQuiz();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingDetails, setIsLoadingDetails] = useState(true);
  const [isEditing, setIsEditing] = useState<boolean | number>(false);
  const [openModalPessoas, setOpenModalPessoas] = useState(false);
  const [openModalLiberar, setOpenModalLiberar] = useState(false);
  const [openModalMultiplicador, setOpenModalMultiplicador] = useState(false);
  const [currentInvite, setCurrentInvite] = useState<IInvitation>(
    {} as IInvitation,
  );
  const [descriptionValidated, setDescriptionValidated] = useState(true);
  const [publicoAlvo, setPublicoAlvo] = useState<IPublicoAlvo[]>([]);
  const [pessoas, setPessoas] = useState<IPessoa[]>([]);
  const [modalInvitesState, setModalInviteState] = useState(false);
  const [inviteGlobalIndex, setInviteGlobalIndex] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(Environment.LIMITE_DE_LINHAS);
  const [openHistory, setOpenHistory] = useState(false);
  const [openChooseBehavior, setOpenChooseBehavior] = useState(false);
  const [restartProcessChoice, setRestartProcessChoice] = useState(1);
  const [error, setError] = useState<{isError: boolean; message?: string}>({
    isError: false,
  });

  const handlePageChange = useCallback(
    (event: React.ChangeEvent<unknown>, value: number) => {
      setPage(value);
    },
    [],
  );

  const handleChangeProcessingChoice = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRestartProcessChoice(Number((event.target as HTMLInputElement).value));
  };

  useEffect(() => {
    if (id !== '0') {
      InvitationService.getAll(id, false).then((response) => {
        if (response.success) {
          setInvites(response.data);
        } else {
          feedback(response.message, 'error');
          setInvites([]);
        }
      });
    }

    InvitationService.getPublicoAlvo().then((response) => {
      setIsLoading(true);
      if (response.success) {
        setPublicoAlvo(response.data);
      } else {
        feedback(response.message || 'Erro ao carregar convites.', 'error');
      }
      setIsLoading(false);
    });
  }, [id]);

  useEffect(() => {
    const inviteListInterval = setInterval(() => {
      setIsLoading(true);
      if (id !== '0') {
        InvitationService.getAll(id, true)
          .then((response) => {
            if (response.success) {
              setInvites(response.data);
            } else {
              feedback(
                response.message || 'Erro ao carregar convites.',
                'error',
              );
              setInvites([]);
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }, 30000);

    return () => clearInterval(inviteListInterval);
  }, [id]);

  const handleCarregarPessoas = useCallback((idConvite: string) => {
    InvitationService.getPessoas(idConvite).then((response) => {
      setIsLoadingDetails(true);
      if (response.success) {
        setPessoas(response.data);
      } else {
        feedback(response.message, 'error');
      }
      setIsLoadingDetails(false);
    });
  }, []);

  const handleModalContent = useCallback(
    (index: number) => {
      setIsLoadingDetails(true);

      setCurrentInvite({...invites[index]});
      setTimeout(() => {
        setIsLoadingDetails(false);
      }, 1000);
    },
    [invites],
  );

  const handleLoadInviteLog = useCallback(
    (index: number) => {
      setCurrentInvite({...invites[index]});
      setTimeout(() => {
        setOpenHistory(true);
      }, 500);
    },
    [invites],
  );

  const handleCloseHistory = useCallback(() => {
    setOpenHistory(false);
  }, []);

  const resetAllValidate = useCallback(() => {
    setDescriptionValidated(true);
  }, []);

  const validateInvite = useCallback(() => {
    if (currentInvite.descricao === '') {
      setDescriptionValidated(false);
      feedback('Informe o nome do convite.', 'error');
      return false;
    } else if (currentInvite.publicosAlvo.length === 0) {
      feedback('Informe os públicos-alvo do convite.', 'error');
      return false;
    } else if (
      !currentInvite.permitirAcesso &&
      currentInvite.dataHoraBloqueio &&
      !isWithinInterval(new Date(currentInvite.dataHoraBloqueio), {
        start: new Date(currentInvite.dataHoraInicio),
        end: new Date(currentInvite.dataHoraFim),
      })
    ) {
      feedback(
        `A data para início de bloqueio deve estar dentro do período entre a ${format(
          new Date(currentInvite.dataHoraInicio),
          'dd/MM/yyyy HH:mm:ss',
        )} de início e ${format(
          new Date(currentInvite.dataHoraFim),
          'dd/MM/yyyy HH:mm:ss',
        )} de fim do convite.`,
        'error',
      );

      return false;
    } else if (
      currentInvite.status === 'CONVITE_ENCERRADO' &&
      compareAsc(new Date(), new Date(currentInvite.dataHoraFim)) === 1
    ) {
      feedback(
        'A data final informada é menor que hoje, portanto o convite segue encerrado. Para reabrir o convite, informe a data final maior que hoje.',
        'error',
      );
      return false;
    }

    return true;
  }, [currentInvite]);

  const handleSave = useCallback(() => {
    feedback('Salvando convite...', 'loading');
    resetAllValidate();
    const validation = validateInvite();

    if (validation) {
      if (currentInvite.status === 'CONVITE_ENCERRADO') {
        InvitationService.extendInviteDate(currentInvite)
          .then((response) => {
            if (response.success) {
              const list = invites;
              const position = list.findIndex(
                (inv) => inv.id === currentInvite.id,
              );
              list[position] = {
                ...currentInvite,
                status: 'AGUARDANDO_LIBERACAO',
                aceitandoRespostas: currentInvite.aceitandoRespostas,
              };
              setCurrentInvite({...list[position]});
              setInvites([...list]);
              feedback(
                response.message || 'Convite atualizado com sucesso!',
                'success',
              );
            } else {
              feedback(
                response.message || 'Erro ao prolongar data do convite.',
                'error',
              );
            }
          })
          .finally(() => {
            setModalInviteState(false);
            setOpenChooseBehavior(false);
            setIsEditing(false);
          });
      } else if (!currentInvite.id?.length) {
        InvitationService.create(currentInvite, id)
          .then((response) => {
            if (response.success) {
              setCurrentInvite({...currentInvite, id: response.data.Id});
              setInvites([
                ...invites,
                {
                  ...currentInvite,
                  id: response.data.Id,
                  linkConvite: response.data.LinkConvite,
                },
              ]);
              feedback(
                response.message || 'Convite criado com sucesso!',
                'success',
              );
            }
            if (
              response?.data?.data.ValidationType === 'PÚBLICO SEM DISCIPLINAS'
            ) {
              feedback(
                error.message ||
                  'Público-Alvo sem disciplinas não pode ser usado com multiplicador de disciplinas.',
                'error',
              );
              setOpenModalMultiplicador(true);
            } else {
              feedback(response.message || 'Erro ao criar convite.', 'error');
            }
          })
          .catch((error) => {
            if (
              axios.isAxiosError(error) &&
              error.response?.data?.validationType === 'PÚBLICO SEM DISCIPLINAS'
            ) {
              feedback(
                error.message ||
                  'Público-Alvo sem disciplinas não pode ser usado com multiplicador de disciplinas.',
                'error',
              );
              setOpenModalMultiplicador(true);
            }
          });
      } else {
        InvitationService.updateById(currentInvite, id)
          .then((response) => {
            if (response.success) {
              const list = invites;
              const position = list.findIndex(
                (inv) => inv.id === currentInvite.id,
              );
              list[position] = currentInvite;
              setInvites([...list]);
              feedback(
                response.message || 'Convite atualizado com sucesso!',
                'success',
              );
            } else {
              feedback(
                response.message || 'Erro ao atualizar convite.',
                'error',
              );
            }
          })
          .finally(() => {
            setModalInviteState(false);
            setOpenChooseBehavior(false);
            setIsEditing(false);
          });
      }
    }
  }, [
    resetAllValidate,
    validateInvite,
    currentInvite,
    invites,
    id,
    error.message,
  ]);
  const handleSaveBeforeRelease = useCallback(() => {
    if (error.isError && error.message) {
      feedback(error.message, 'error');
    } else {
      feedback('Salvando convite...', 'loading');
      resetAllValidate();
      const validation = validateInvite();

      if (validation) {
        if (!currentInvite.id?.length) {
          InvitationService.create(currentInvite, id).then((response) => {
            if (response.success) {
              setCurrentInvite({
                ...currentInvite,
                id: response.data.Id,
                linkConvite: response.data.LinkConvite,
              });
              setInvites([
                ...invites,
                {
                  ...currentInvite,
                  id: response.data.Id,
                  linkConvite: response.data.LinkConvite,
                },
              ]);
              setIsEditing(false);
              setOpenModalLiberar(true);
              feedback(
                response.message || 'Novo convite criado com sucesso!',
                'success',
              );
            } else {
              if (
                response.data.data.ValidationType === 'PÚBLICO SEM DISCIPLINAS'
              ) {
                feedback(
                  error.message ||
                    'Público-Alvo sem disciplinas não pode ser usado com multiplicador de disciplinas.',
                  'error',
                );
                setOpenModalMultiplicador(true);
              } else {
                feedback(
                  response.message || 'Erro ao criar novo convite.',
                  'error',
                );
              }
            }
          });
        } else {
          InvitationService.updateById(currentInvite, id).then((response) => {
            if (response.success) {
              setOpenModalLiberar(true);
              setIsEditing(false);
              const list = invites;
              const position = list.findIndex(
                (inv) => inv.id === currentInvite.id,
              );
              list[position] = currentInvite;
              setInvites([...list]);
              feedback(
                response.message || 'Convite atualizado com sucesso!',
                'success',
              );
            } else {
              feedback(
                response.message || 'Erro ao atualizar este convite.',
                'error',
              );
            }
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    validateInvite,
    resetAllValidate,
    currentInvite,
    invites,
    setInvites,
    id,
  ]);

  const handleReleaseInvite = useCallback(() => {
    feedback('Liberando convite...', 'loading');
    InvitationService.releaseInvite(currentInvite.id)
      .then((resp) => {
        if (resp.success) {
          const list = invites;
          const position = list.findIndex((inv) => inv.id === currentInvite.id);
          list[position] = {
            ...currentInvite,
            status: 'AGUARDANDO_LIBERACAO',
            aceitandoRespostas: currentInvite.aceitandoRespostas,
          };
          setCurrentInvite({...list[position]});
          setInvites([...list]);
          feedback(
            resp.message ||
              'Convite enviado para liberação. Isto pode levar alguns minutos.',
            'success',
          );
        } else {
          feedback(resp.message || 'Erro ao liberar convite.', 'error');
        }
      })
      .finally(() => {
        setOpenChooseBehavior(false);
      });
  }, [currentInvite, invites, setInvites]);

  const handleAddMoreAudience = useCallback(() => {
    feedback('Atualizando convite...', 'loading');
    const publicosAlvo = currentInvite.publicosAlvo.map(
      (publico) => publico.id,
    );
    InvitationService.updateInviteAudienceById(
      currentInvite.id,
      publicosAlvo,
    ).then((response) => {
      if (response.success) {
        handleReleaseInvite();
        feedback(
          response.message ||
            'Convite sendo atualizado. Isto pode levar alguns minutos.',
          'success',
        );
      } else {
        feedback(response.message || 'Erro ao liberar convite.', 'error');
      }
    });
  }, [currentInvite, handleReleaseInvite]);

  const handleDeleteInvite = useCallback(() => {
    InvitationService.deleteById(currentInvite.id).then((resp) => {
      if (resp.success) {
        feedback(
          resp.message || 'Convite foi excluído com sucesso.',
          'success',
        );
      } else {
        feedback(resp.message || 'Erro ao excluir o convite.', 'error');
      }
    });
  }, [currentInvite]);

  const handleDelete = useCallback(
    (index: number) => {
      const onConfirm = (position: number) => {
        setModalInviteState(false);

        const allInvites = [...invites];
        allInvites.splice(position, 1);

        setInvites(allInvites);
        handleDeleteInvite();
      };

      confirm(
        'Deseja remover o item?',
        'delete',
        () => {
          onConfirm(index);
        },
        undefined,
      );
    },
    [invites, setInvites, handleDeleteInvite],
  );

  const handleNewDates = useCallback((days: number) => {
    const resultDate = new Date();
    resultDate.setDate(resultDate.getDate() + days);
    return resultDate;
  }, []);

  const handleNewInvite = useCallback(() => {
    const list = invites;
    list.push({
      descricao: '',
      publicosAlvo: [] as IPublicoAlvo[],
      dataHoraInicio: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
      dataHoraFim: format(handleNewDates(15), "yyyy-MM-dd'T'HH:mm:ss"),
      dataHoraBloqueio: format(handleNewDates(7), "yyyy-MM-dd'T'HH:mm:ss"),
      status: 'EM_ELABORACAO',
      resposta: 1,
      aceitandoRespostas: false,
    } as IInvitation);

    setInvites([...list]);
    setModalInviteState(true);
    handleModalContent(invites.length - 1);
  }, [
    handleNewDates,
    handleModalContent,
    setModalInviteState,
    setInvites,
    invites,
  ]);

  const handleModalCancelar = useCallback(() => {
    setOpenModalLiberar(false);
  }, [setOpenModalLiberar]);

  const handleModalMultiplicador = useCallback(() => {
    setOpenModalMultiplicador(false);
  }, [setOpenModalMultiplicador]);

  const handleModalLiberar = useCallback(() => {
    handleReleaseInvite();
    setOpenModalLiberar(false);
  }, [setOpenModalLiberar, handleReleaseInvite]);

  // EM_ELABORACAO = 1,
  // ACEITANDO_RESPOSTAS = 2,
  // BLOQUEADO_PARA_RESPOSTAS = 3,
  // CONVITE_ENCERRADO = 4,
  // AGUARDANDO_LIBERACAO = 5,
  // LIBERACAO_INICIADA = 6,
  // ERRO_LIBERACAO = 7,
  // REPROCESSAMENTO_DO_PUBLICO_ALVO = 8
  // PUBLICO_ALVO_SEM_DESTINATARIOS = 9
  const formataSituacao = useCallback(
    (status: string, errorMessage: string) => {
      switch (status) {
        case 'EM_ELABORACAO':
          return (
            <>
              <FiberManualRecord style={{color: '#FFAC33'}} />
              <Typography>Em elaboração</Typography>
            </>
          );
        case 'ACEITANDO_RESPOSTAS':
          return (
            <>
              <FiberManualRecord style={{color: '#33b673'}} />
              <Typography>Aceitando respostas</Typography>
            </>
          );
        case 'AGUARDANDO_LIBERACAO':
          return (
            <>
              <CircularProgress
                size={16}
                style={{
                  color: '#335DAB',
                }}
              />
              <Tooltip title="Convite está processando a liberação. Não será possível editar até que o processo acabe.">
                <Typography>Aguardando liberação</Typography>
              </Tooltip>
            </>
          );
        case 'LIBERACAO_INICIADA':
          return (
            <>
              <CircularProgress
                size={16}
                style={{
                  color: '#33b673',
                }}
              />
              <Tooltip title="Convite está processando a liberação. Não será possível editar até que o processo acabe.">
                <Typography>Liberação iniciada</Typography>
              </Tooltip>
            </>
          );
        case 'BLOQUEADO_PARA_RESPOSTAS':
          return (
            <>
              <FiberManualRecord style={{color: '#f73333'}} />
              <Typography>Bloqueado para respostas</Typography>
            </>
          );
        case 'CONVITE_ENCERRADO':
          return (
            <>
              <FiberManualRecord style={{color: '#E0E0E0'}} />
              <Typography>Convite encerrado</Typography>
            </>
          );
        case 'ERRO_LIBERACAO':
          return (
            <>
              <Tooltip
                title={`Um erro ocorreu durante o processamento. Erro: ${errorMessage}`}>
                <WarningIcon color={'error'} />
              </Tooltip>
              <Typography>Erro na liberação</Typography>
            </>
          );
        case 'PUBLICO_ALVO_SEM_DESTINATARIOS':
          return (
            <>
              <FiberManualRecord style={{color: '#E0E0E0'}} />
              <Typography>Público alvo sem destinatário</Typography>
            </>
          );
        case 'REPROCESSAMENTO_DO_PUBLICO_ALVO':
          return (
            <>
              <CircularProgress
                size={16}
                style={{
                  color: '#335DAB',
                }}
              />
              <Tooltip title="Reprocessando público-alvo do convite.">
                <Typography>Reprocessando público-alvo</Typography>
              </Tooltip>
            </>
          );

        case 'REPROCESSAMENTO_DO_PUBLICO_ALVO_CONCLUIDO':
          return (
            <>
              <CircularProgress
                size={16}
                style={{
                  color: '#335DAB',
                }}
              />
              <Tooltip title="Reprocessando público-alvo do convite.">
                <Typography>
                  Reprocessamento do público-alvo concluído
                </Typography>
              </Tooltip>
            </>
          );
      }
    },
    [],
  );

  const openModal = useCallback(() => {
    setOpenModalPessoas(true);
  }, []);

  const handleContentDownload = useCallback(
    (data: string, filename: string, type: string) => {
      const file = new Blob([data], {type: type});
      const fileLink = document.createElement('a');
      const url = URL.createObjectURL(file);

      fileLink.href = url;
      fileLink.download = filename;
      document.body.appendChild(fileLink);
      fileLink.click();

      setTimeout(function () {
        document.body.removeChild(fileLink);
        window.URL.revokeObjectURL(url);
      }, 100);
    },
    [],
  );

  const handleDownloadPessoas = useCallback(() => {
    feedback('Carregando dados...', 'loading');
    InvitationService.getDownloadPessoasCSV(currentInvite.id).then((result) => {
      if (result.success) {
        feedback('Dados prontos para download.', 'success');
        const fileName = `lista_pessoas_${format(
          new Date(),
          'dd_MM_yyyy HH_mm_ss',
        )}`;

        handleContentDownload(result.data.fileContent, fileName, 'text/csv');
      } else {
        feedback(
          result.message || 'Erro ao carregar dados para download.',
          'error',
        );
      }
    });
  }, [currentInvite, handleContentDownload]);

  const handleUserInviteChoice = useCallback(
    (choice: number) => {
      if (choice === 1) {
        handleAddMoreAudience();
      } else {
        handleSave();
      }
    },
    [handleSave, handleAddMoreAudience],
  );

  //AQUI
  const handleSaveInvite = () => {
    if (error.isError && error.message) {
      feedback(error.message, 'error');
    } else {
      !currentInvite.aceitandoRespostas ||
      (currentInvite.status.toString() === 'EM_ELABORACAO' &&
        currentInvite.status.toString() !== 'ACEITANDO_RESPOSTAS' &&
        'REPROCESSAMENTO_DO_PUBLICO_ALVO')
        ? handleSave()
        : setOpenChooseBehavior(true);
    }
  };

  return (
    <Box p={3}>
      <Dialog
        open={modalInvitesState}
        fullWidth
        maxWidth="md"
        onClose={() => {
          if (isEditing !== false) {
            confirm(
              'Deseja salvar as alterações antes de sair?',
              'confirmation',
              handleSave,
              () => {
                setIsEditing(false);
              },
            );
          } else {
            setModalInviteState(false);
          }
          if (id !== '0') {
            setIsLoading(true);
            InvitationService.getAll(id, false).then((response) => {
              if (response.success) {
                setInvites(response.data);
                setIsLoading(false);
              } else {
                feedback(response.message, 'error');
                setInvites([]);
                setIsLoading(false);
              }
            });
          }
        }}
        aria-labelledby="modal-detail">
        <DialogTitle id="modal-detail-title">
          <Box
            width={'100%'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}>
            <Typography color="primary" variant="h6">
              Convite
            </Typography>
            <IconButton
              aria-label="close"
              onClick={() => setModalInviteState(false)}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent style={{padding: '24px 24px 8px'}}>
          {isLoadingDetails ? (
            <InviteSkeleton />
          ) : (
            <FormControl component="fieldset" fullWidth>
              <InviteDetails
                position={inviteGlobalIndex}
                currentInvite={currentInvite}
                descriptionValidated={descriptionValidated}
                setIsEditing={setIsEditing}
                setCurrentInvite={(e) => {
                  setCurrentInvite(e);
                }}
                handleError={(isError, message) =>
                  setError({isError: isError, message: message})
                }
              />
              <Box
                width={'100%'}
                display={'flex'}
                justifyContent={'flex-start'}>
                {currentInvite.status !== 'EM_ELABORACAO' &&
                  currentInvite.status !== 'LIBERACAO_INICIADA' &&
                  currentInvite.status !== 'AGUARDANDO_LIBERACAO' && (
                    <Button
                      color="primary"
                      startIcon={<Contacts />}
                      onClick={openModal}>
                      <Typography
                        style={{
                          textDecoration: 'underline',
                        }}>
                        Ver pessoas
                      </Typography>
                    </Button>
                  )}
              </Box>
            </FormControl>
          )}
        </DialogContent>
        <DialogActions>
          <Box marginTop={2} display="flex" justifyContent="space-between">
            <Box display="flex" gap={1} margin="0 0 0 auto">
              {currentInvite.status === 'EM_ELABORACAO' && (
                <Button
                  size={'medium'}
                  color="primary"
                  variant="outlined"
                  startIcon={<Delete />}
                  onClick={() => handleDelete(inviteGlobalIndex)}>
                  Excluir
                </Button>
              )}
              {currentInvite.status !== 'AGUARDANDO_LIBERACAO' &&
                currentInvite.status !== 'LIBERACAO_INICIADA' &&
                currentInvite.status !== 'REPROCESSAMENTO_DO_PUBLICO_ALVO' && (
                  <Button
                    size={'medium'}
                    variant={
                      currentInvite.status === 'EM_ELABORACAO'
                        ? 'outlined'
                        : 'contained'
                    }
                    color="primary"
                    onClick={handleSaveInvite}>
                    Salvar
                  </Button>
                )}
              {currentInvite.status === 'EM_ELABORACAO' && (
                <Button
                  size={'medium'}
                  color="primary"
                  variant="contained"
                  onClick={handleSaveBeforeRelease}>
                  Salvar e liberar convite
                </Button>
              )}
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
      <Box display="flex" justifyContent="flex-end" marginBottom={3}>
        <Button
          size="medium"
          color="primary"
          variant="contained"
          onClick={handleNewInvite}
          startIcon={<Add />}
          disabled={isLoading}>
          Novo convite
        </Button>
      </Box>
      <Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell component="th" scope="row">
                  Nome
                </TableCell>
                <TableCell component="th" scope="row">
                  Período
                </TableCell>
                <TableCell component="th" scope="row">
                  Status
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  width={100}
                  align={'right'}>
                  Ação
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <InviteTableSkeleton />
              ) : invites.length ? (
                invites.map((invite, index) => (
                  <TableRow
                    hover
                    key={`invite-quiz-${id}-${index}`}
                    style={{display: invite.id?.length ? 'table-row' : 'none'}}>
                    <TableCell component="th" scope="row">
                      {invite.descricao}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {`${format(
                        new Date(invite.dataHoraInicio),
                        'dd/MM/yyyy',
                      )} à ${format(
                        new Date(invite.dataHoraFim),
                        'dd/MM/yyyy',
                      )}`}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="center"
                        gap={2}>
                        {formataSituacao(
                          invite.status,
                          invite.mensagemDeErro || 'Erro desconhecido.',
                        )}
                      </Box>
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      width={100}
                      align={'right'}>
                      <Box display={'flex'}>
                        <IconButton
                          aria-label="edit"
                          size="small"
                          onClick={() => {
                            handleModalContent(index);
                            setModalInviteState(true);
                            setInviteGlobalIndex(index);
                            setIsLoadingDetails(true);
                            handleCarregarPessoas(invite.id);
                          }}
                          disabled={false}>
                          <Icon fontSize={'small'}>edit</Icon>
                        </IconButton>
                        <Tooltip title={'Copiar link do convite'}>
                          <IconButton
                            size="small"
                            onClick={() => {
                              navigator.clipboard.writeText(invite.linkConvite);
                              feedback('Link copiado!', 'success');
                            }}>
                            <LinkIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={'Acessar histórico do convite.'}>
                          <IconButton
                            size="small"
                            onClick={() => handleLoadInviteLog(index)}>
                            <History />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell>
                    <NotFoundText />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {currentInvite.status !== 'EM_ELABORACAO' &&
        currentInvite.status !== 'LIBERACAO_INICIADA' &&
        currentInvite.status !== 'AGUARDANDO_LIBERACAO' && (
          <Dialog
            fullWidth
            maxWidth="md"
            onClose={() => setOpenModalPessoas(false)}
            open={openModalPessoas}>
            <DialogTitle style={{color: '#408781'}}>Ver pessoas</DialogTitle>
            <Divider />
            <DialogContent>
              <Grid container item xs={12} alignItems="center">
                <Grid item xs={6}>
                  <DialogContentText>
                    <strong>Questionário:</strong> {getNameQuiz()}
                  </DialogContentText>
                  <DialogContentText>
                    <strong>Convite:</strong> {currentInvite.descricao}
                  </DialogContentText>
                </Grid>
                <Grid item xs={6}>
                  <Box width={'100%'} textAlign={'right'}>
                    <Tooltip title={'Baixar tabela de pessoas em .CSV'}>
                      <Button
                        size="medium"
                        variant="contained"
                        color="primary"
                        onClick={handleDownloadPessoas}
                        startIcon={<DownloadIcon />}>
                        Baixar dados
                      </Button>
                    </Tooltip>
                  </Box>
                </Grid>
              </Grid>
              <Box marginTop={1} marginBottom={2}>
                <TableContainer component={Paper}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>Nome</TableCell>
                        <TableCell>Papel</TableCell>
                        <TableCell>Curso</TableCell>
                        <TableCell>E-mail</TableCell>
                        <TableCell>Matrícula</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {pessoas.length ? (
                        pessoas
                          .slice(
                            (page - 1) * rowsPerPage,
                            (page - 1) * rowsPerPage + rowsPerPage,
                          )
                          .map((pessoa) => (
                            <TableRow key={pessoa.id} hover>
                              <TableCell>{pessoa.nome}</TableCell>
                              <TableCell>{pessoa.papel}</TableCell>
                              <TableCell>{pessoa.curso || '-'}</TableCell>
                              <TableCell>{pessoa.email}</TableCell>
                              <TableCell>
                                {pessoa.matricula !== 0
                                  ? pessoa.matricula
                                  : '-'}
                              </TableCell>
                            </TableRow>
                          ))
                      ) : (
                        <TableRow>
                          <TableCell>
                            <NotFoundText />
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                  {pessoas.length > rowsPerPage && (
                    <Box
                      marginTop={1}
                      marginBottom={1}
                      marginLeft={1}
                      marginRight={1}>
                      <Pagination
                        page={page}
                        count={Math.ceil(pessoas.length / rowsPerPage)}
                        onChange={handlePageChange}
                      />
                    </Box>
                  )}
                </TableContainer>
              </Box>
            </DialogContent>
          </Dialog>
        )}
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={() => setOpenModalLiberar(false)}
        open={openModalLiberar}>
        <DialogTitle style={{color: '#408781'}}>Liberar convite</DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText>
            <strong>Questionário:</strong> {getNameQuiz()}
          </DialogContentText>
          <DialogContentText>
            <strong>Convite:</strong> {currentInvite.descricao}
          </DialogContentText>
          <Box marginTop={1} marginBottom={2}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Autocomplete
                  disabled={
                    currentInvite.permitirAcesso ||
                    currentInvite.aceitandoRespostas
                  }
                  multiple
                  filterSelectedOptions
                  options={publicoAlvo}
                  value={currentInvite.publicosAlvo}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  getOptionDisabled={(option: IPublicoAlvo) =>
                    !option.habilitado
                  }
                  getOptionLabel={(option) => option.nome}
                  onChange={(_, value) => {
                    setPublicoAlvo(value);
                  }}
                  renderOption={(option: any) => (
                    <Box component="li" display="flex" alignItems={'center'}>
                      {option?.nome}
                      {!option?.habilitado && (
                        <>
                          <Box display="flex" alignItems={'center'}>
                            <Icon>warning_amber_rounded</Icon>{' '}
                            <span>
                              Público-alvo não possuí pessoas ou está em
                              processamento.
                            </span>
                          </Box>
                        </>
                      )}
                    </Box>
                  )}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip
                        color="primary"
                        label={option.nome}
                        {...getTagProps({index})}
                        disabled={
                          !option.habilitado ||
                          currentInvite.permitirAcesso ||
                          currentInvite.aceitandoRespostas
                        }
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Público alvo *"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled
                  fullWidth
                  variant="outlined"
                  label="Link do convite"
                  value={currentInvite.linkConvite || ''}
                />
              </Grid>
              <Grid item xs={12}>
                <Box marginTop={2} display="flex" gap={2}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleModalCancelar}>
                    Cancelar
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleModalLiberar}>
                    Liberar convite
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog open={openHistory} onClose={handleCloseHistory} maxWidth={'xl'}>
        <DialogTitle id="modal-detail-title">
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}>
            <Box width={'100%'}>
              <Typography color="primary" variant="h6">
                Histórico de processamento do convite
              </Typography>
            </Box>
            <IconButton aria-label="close" onClick={handleCloseHistory}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <InviteLog
            inviteId={currentInvite.id}
            inviteName={currentInvite.descricao}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={() => setOpenChooseBehavior(false)}
        open={openChooseBehavior}>
        <DialogTitle id="modal-detail-title">
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}>
            <Box width={'100%'}>
              <Typography color="primary" variant="h6">
                Deseja reprocessar o convite?
              </Typography>
            </Box>
            <IconButton
              aria-label="close"
              onClick={() => setOpenChooseBehavior(false)}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Box marginTop={1} marginBottom={1}>
            <Typography variant="body1" color={'textPrimary'}>
              Desde a última liberação, novas pessoas podem ter sido adicionadas
              em um dos públicos-alvos selecionados.
            </Typography>
          </Box>
          <Box marginTop={1} marginBottom={1}>
            <Typography variant="body1" color={'textPrimary'}>
              Selecione a primeira opção se você deseja reprocessar o convite.
              Se você deseja apenas salvar as alterações, selecione a segunda
              opção
            </Typography>
          </Box>
          <Box>
            <FormControl>
              <RadioGroup
                defaultValue={1}
                value={restartProcessChoice}
                onChange={handleChangeProcessingChoice}>
                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  label="Sim, quero reprocessar este convite."
                />
                <FormControlLabel
                  value={2}
                  control={<Radio />}
                  label="Não, quero apenas salvar sem adicionar novas pessoas."
                />
              </RadioGroup>
            </FormControl>
          </Box>
          {restartProcessChoice === 1 && (
            <Box marginTop={1} marginBottom={3}>
              <Alert severity="warning">
                <AlertTitle>Atenção</AlertTitle>
                Enquanto o convite estiver em processamento,{' '}
                <strong>
                  todas as pessoas perderão acesso a ele na página de respostas
                </strong>
                . O acesso ao convite será restabelecido ao final do processo.
              </Alert>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            size="medium"
            variant="outlined"
            color="primary"
            onClick={() => setOpenChooseBehavior(false)}>
            Cancelar
          </Button>
          <Button
            size="medium"
            variant="contained"
            color="primary"
            onClick={() => handleUserInviteChoice(restartProcessChoice)}>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openModalMultiplicador}
        onClose={() => setOpenModalMultiplicador(false)}
        fullWidth
        maxWidth="md"
        aria-labelledby="modal-detail">
        <DialogTitle>
          <Box
            width={'100%'}
            display={'flex'}
            alignItems={'center'}
            justifyItems={'space-between'}>
            <Typography color="primary" variant="h6">
              Atenção!
            </Typography>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Typography variant="subtitle1">
            O multiplicador de disciplinas não pode ser aplicado ao
            questionário, pois o público alvo selecionado não possui este
            multiplicador ativo. Remova o multiplicador do questionário ou
            altere o público alvo para que o convite possa ser liberado.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isLoading}
            color="primary"
            variant="contained"
            onClick={() => handleModalMultiplicador()}>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
