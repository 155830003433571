import {memo} from 'react';
import {Box, Theme, Typography, LinearProgress} from '@mui/material';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  progress: {
    width: '100%',
    height: '12px',
    borderRadius: 7,
    '& .MuiLinearProgress-barColorPrimary': {
      borderRadius: 50,
    },
    border: `1px solid ${theme.palette.common.white}`,
  },

  text: {
    whiteSpace: 'nowrap',
  },
}));

interface IProgressProps {
  count: number;
  currentPosition: number;
  text?: string;
}

export const Progress: React.FC<IProgressProps> = memo(
  ({count, currentPosition, text = ''}) => {
    const styles = useStyles();

    return (
      <Box display="flex" alignItems="center" gap={2}>
        <LinearProgress
          className={styles.progress}
          variant="determinate"
          value={count !== 0 ? (currentPosition * 100) / count : -1}
        />
        <Typography className={styles.text}>
          {`${currentPosition}/${count} ${text}`.trim()}
        </Typography>
      </Box>
    );
  },
);
