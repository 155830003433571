import React, {useCallback} from 'react';

import {
  Box,
  Grid,
  List,
  Toolbar,
  ListItem,
  useTheme,
  IconButton,
  Typography,
  ListItemIcon,
  Hidden,
} from '@mui/material';
import {Menu} from '@mui/icons-material';

import {LogoGVdasa} from 'shared/assets';
import {useTitle} from 'shared/hooks';
import {StyledAppBar, useStyles} from './styles';
import {UserAvatar} from '../user-avatar/UserAvatar';

interface IHeaderProps {
  isOpen: boolean;
  buttonActive: boolean;
  handleDrawerOpen(): void;
  setButtonActive(value: boolean): void;
}

export const Header: React.FC<IHeaderProps> = ({
  isOpen,
  buttonActive,
  handleDrawerOpen,
  setButtonActive,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const {title} = useTitle();

  const handleOpenMenu = useCallback(() => {
    handleDrawerOpen();

    setButtonActive(true);
  }, [handleDrawerOpen, setButtonActive]);

  const handleCloseMenu = useCallback(() => {
    handleDrawerOpen();

    setButtonActive(false);
  }, [handleDrawerOpen, setButtonActive]);

  return (
    <StyledAppBar position="fixed">
      <Toolbar style={{paddingLeft: 0}}>
        <Grid container>
          <Grid item xs={11}>
            <List>
              <ListItem className={classes.listItem}>
                <ListItemIcon>
                  <IconButton
                    edge="start"
                    className={classes.menuButton}
                    style={{
                      marginLeft: theme.spacing(0.5),
                      backgroundColor: buttonActive
                        ? theme.palette.divider
                        : 'transparent',
                    }}
                    onClick={isOpen ? handleCloseMenu : handleOpenMenu}>
                    <Menu className={classes.iconMenu} />
                  </IconButton>
                </ListItemIcon>

                <Hidden xsDown>
                  <img
                    src={LogoGVdasa.default}
                    alt="Logo GVdasa"
                    className={classes.imgLogo}
                  />
                </Hidden>

                <Box marginLeft={2}>
                  <Typography variant="h6">{title}</Typography>
                </Box>
              </ListItem>
            </List>
          </Grid>
        </Grid>
        <Grid item xs>
          <Box display={'flex'}>
            <UserAvatar />
          </Box>
        </Grid>
      </Toolbar>
    </StyledAppBar>
  );
};
