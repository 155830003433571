import React, {useMemo} from 'react';
import {
  Box,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TableBody,
  TableHead
} from '@mui/material';
import {IQuestionDetail} from 'shared/services/api/reports/AnswerSummary';

interface GridMultipleAnswerChartProps {
  configs: IQuestionDetail[];
}

export const GridMultipleAnswer: React.FC<GridMultipleAnswerChartProps> = ({
  configs = [],
}) => {
  const linhas = useMemo(() => {
    if (configs[0].linha.length > 0) {
      return configs[0].linha;
    }

    return [];
  }, [configs]);

  const cold = useMemo(() => {
    return 'F73333';
  }, []);

  const hot = useMemo(() => {
    return '00B9AD';
  }, []);

  const min = useMemo(() => {
    const values: any = linhas.map((linha) =>
      linha.colunas.map((col) => col.Valor),
    );
    const merged = [].concat.apply([], values);
    return Math.min(...merged);
  }, [linhas]);

  const max = useMemo(() => {
    const values: any = linhas.map((linha) =>
      linha.colunas.map((col) => col.Valor),
    );
    const merged = [].concat.apply([], values);
    return Math.max(...merged);
  }, [linhas]);

  const getHex = (colorValue: any) => {
    colorValue = colorValue.toString(16);
    return colorValue.length === 1 ? '0' + colorValue : colorValue;
  };

  const getGradient = (stop: any) => {
    const from = cold;
    const to = hot;

    const red = Math.ceil(
      parseInt(from.substring(0, 2), 16) * stop +
        parseInt(to.substring(0, 2), 16) * (1 - stop),
    );
    const green = Math.ceil(
      parseInt(from.substring(2, 4), 16) * stop +
        parseInt(to.substring(2, 4), 16) * (1 - stop),
    );
    const blue = Math.ceil(
      parseInt(from.substring(4, 6), 16) * stop +
        parseInt(to.substring(4, 6), 16) * (1 - stop),
    );

    return getHex(red) + getHex(green) + getHex(blue);
  };

  const getValuePositionBetweenColors = (
    min: number,
    max: number,
    value: number,
  ) => {
    return (value - min) / (max - min);
  };

  return (
    <Box width={'100%'} maxWidth={'800px'} margin={'0 auto'}>
      <TableContainer>
        <Table size="medium">
          <TableHead style={{backgroundColor: '#fff'}}>
            <TableRow>
              <TableCell component={'td'}></TableCell>
              {linhas[0].colunas.map((col, k) => (
                <TableCell
                  component={'th'}
                  scope={'col'}
                  width={90}
                  key={`column-multiple-answer-${col.IdOpcaoRespostaColuna}`}>
                  {col.Item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {linhas.map((row, i) => (
              <TableRow
                key={`row-multiple-answer-${row.idOpcaoRespostaLinha}`}>
                <TableCell component={'th'} scope={'row'} width={90}>
                  {row.item}
                </TableCell>
                {row.colunas.map((col, j) => (
                  <TableCell
                    key={`row-column-multiple-answer-${col.IdOpcaoRespostaColuna}`}
                    width={90}
                    style={{
                      fontWeight: 'bold',
                      fontSize: '18px',
                      color: 'white',
                      backgroundColor: `#${getGradient(
                        getValuePositionBetweenColors(min, max, col.Valor),
                      )}`,
                    }}
                    component={'td'}>
                    {col.Valor}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
