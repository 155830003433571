import React, {useState, useCallback, useEffect} from 'react';
import {
  Box,
  Tab,
  Tabs,
  Grid,
  Table,
  Paper,
  Theme,
  Dialog,
  AppBar,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
  TableContainer,
  Tooltip,
  CircularProgress,
  useTheme,
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import {NotFoundText} from 'shared/components';
import {
  IQuizModalDetail,
  QuizListService,
} from 'shared/services/api/quiz/QuizList';
import {Skeleton} from '@mui/lab';
import {FiberManualRecord} from '@mui/icons-material';
import {feedback} from 'shared/services';
import WarningIcon from '@mui/icons-material/Warning';

interface ITabPanelProps {
  index: any;
  value: any;
}

interface IQuizModalDetailProps {
  rowId: string;
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  dialogTitle: {
    margin: 0,
    padding: theme.spacing(2),
  },

  table: {
    borderRadius: 0,
  },
}));

const TabPanel: React.FC<ITabPanelProps> = ({
  children,
  value,
  index,
  ...other
}) => {
  const theme = useTheme();

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      style={{
        background: theme.palette.common.white,
        borderBottomRightRadius: theme.spacing(1),
        borderBottomLeftRadius: theme.spacing(1),
      }}
      {...other}>
      {value === index && <Box p={0}>{children}</Box>}
    </Box>
  );
};

export const QuizModalDetail: React.FC<IQuizModalDetailProps> = ({
  rowId,
  open,
  onClose,
}) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [quizData, setQuizData] = useState<IQuizModalDetail>(
    {} as IQuizModalDetail,
  );
  const [value, setValue] = useState(0);

  useEffect(() => {
    setIsLoading(true);
    if (rowId) {
      QuizListService.getModalDetailById(rowId).then((result) => {
        if (result.success) {
          setQuizData(result.data);
          setIsLoading(false);
        } else {
          feedback(
            result.message ||
              'Erro ao carregar detalhes, tente novamente mais tarde.',
            'error',
          );
        }
      });
    }
  }, [rowId]);

  const formataSituacao = useCallback(
    (status: string, errorMessage: string) => {
      switch (status) {
        case 'EM_ELABORACAO':
          return (
            <>
              <FiberManualRecord style={{color: '#FFAC33'}} />
              <Typography>Em elaboração</Typography>
            </>
          );
        case 'ACEITANDO_RESPOSTAS':
          return (
            <>
              <FiberManualRecord style={{color: '#33b673'}} />
              <Typography>Aceitando respostas</Typography>
            </>
          );
        case 'AGUARDANDO_LIBERACAO':
          return (
            <>
              <CircularProgress
                size={16}
                style={{
                  color: '#335DAB',
                }}
              />
              <Tooltip title="Convite está processando a liberação. Não será possível editar até que o processo acabe.">
                <Typography>Aguardando liberação</Typography>
              </Tooltip>
            </>
          );
        case 'LIBERACAO_INICIADA':
          return (
            <>
              <CircularProgress
                size={16}
                style={{
                  color: '#33b673',
                }}
              />
              <Tooltip title="Convite está processando a liberação. Não será possível editar até que o processo acabe.">
                <Typography>Liberação iniciada</Typography>
              </Tooltip>
            </>
          );
        case 'BLOQUEADO_PARA_RESPOSTAS':
          return (
            <>
              <FiberManualRecord style={{color: '#f73333'}} />
              <Typography>Bloqueado para respostas</Typography>
            </>
          );
        case 'CONVITE_ENCERRADO':
          return (
            <>
              <FiberManualRecord style={{color: '#E0E0E0'}} />
              <Typography>Convite encerrado</Typography>
            </>
          );
        case 'ERRO_LIBERACAO':
          return (
            <>
              <Tooltip
                title={`Um erro ocorreu durante o processamento. Erro: ${errorMessage}`}>
                <WarningIcon color={'error'} />
              </Tooltip>
              <Typography>Erro na liberação</Typography>
            </>
          );
      }
    },
    [],
  );

  const handleTabChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: number) => {
      setValue(newValue);
    },
    [],
  );

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      onClose={() => {
        onClose();
        setQuizData({} as IQuizModalDetail);
      }}
      aria-labelledby="modal-detail-title">
      <DialogTitle id="modal-detail-title" className={classes.dialogTitle}>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}>
          <Box width={'100%'}>
            <Typography color="primary" variant="h6">
              Detalhes do questionário
            </Typography>
          </Box>
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        {isLoading ? (
          <Grid container spacing={3}>
            <Grid item xs={5}>
              <Skeleton variant="text" width="70%" height={26} />
            </Grid>
            <Grid item xs={7}>
              <Skeleton variant="text" width="70%" height={26} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="text" width="70%" height={26} />
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={5}>
              <Typography
                style={{
                  width: 'calc(100% - 20px)',
                  display: 'inline-block',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}>
                <strong>Nome:</strong> {quizData.nomeQuestionario}
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography>
                <strong>Tipo:</strong> {quizData.nomeTipoQuestionario}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                style={{
                  width: '100%',
                  display: 'inline-block',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}>
                <strong>Descrição:</strong>{' '}
                {quizData.descricao ? (
                  quizData.descricao
                ) : (
                  <Typography component={'em'} color="textSecondary">
                    Sem descrição
                  </Typography>
                )}
              </Typography>
            </Grid>
          </Grid>
        )}
        <Box overflow="hidden" marginY={2} component={Paper}>
          <TableContainer component={Paper}>
            <AppBar position="static" color="transparent">
              <Tabs
                indicatorColor="primary"
                textColor="primary"
                value={value}
                onChange={handleTabChange}>
                <Tab label="CONVITES" />
                <Tab label="ESTABELECIMENTOS" />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Título
                    </TableCell>
                    <TableCell component="th" scope="row">
                      Status
                    </TableCell>
                    <TableCell component="th" scope="row">
                      Período
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading ? (
                    <>
                      <TableRow hover>
                        <TableCell component="th" scope="row" width={'40%'}>
                          <Skeleton
                            variant="rectangular"
                            width="auto"
                            height={25}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row" width={'20%'}>
                          <Skeleton
                            variant="rectangular"
                            width="auto"
                            height={25}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row" width={'20%'}>
                          <Skeleton
                            variant="rectangular"
                            width="auto"
                            height={25}
                          />
                        </TableCell>
                      </TableRow>
                    </>
                  ) : quizData?.convites && quizData?.convites.length ? (
                    quizData?.convites.map((row) => (
                      <TableRow hover key={row.id}>
                        <TableCell component="th" scope="row">
                          {row.descricao}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Box
                            display="flex"
                            justifyContent="flex-start"
                            gap={10}>
                            {formataSituacao(
                              row.status,
                              row.mensagemDeErro || 'Erro desconhecido.',
                            )}
                          </Box>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {`${row.dataHoraInicio} à ${row.dataHoraFim}`}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell>
                        <NotFoundText />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Nome
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {quizData?.estabelecimentos &&
                  quizData?.estabelecimentos.length > 0 ? (
                    quizData?.estabelecimentos.map((row, index) => (
                      <TableRow hover key={index}>
                        <TableCell component="td" scope="row">
                          {row.name}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell>
                        <NotFoundText />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TabPanel>
          </TableContainer>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
