import {memo, useCallback, useMemo} from 'react';
import {Box, Select, TextField, MenuItem} from '@mui/material';
import {IValidation, TTypeOfValidation} from '../Validation';
import InputMask from 'react-input-mask';

interface ISimpleTextValidationProps {
  value: IValidation;
  onChange: (value: IValidation) => void;
}

export const SimpleTextValidation: React.FC<ISimpleTextValidationProps> = memo(
  ({value, onChange}) => {
    const operators = useMemo(() => {
      switch (value.typeOfValidation) {
        case 'texto':
          return [
            {id: 'contem', chave: 'Contém', suggestion: 'Texto', default: ''},
            {
              id: 'nao-contem',
              chave: 'Não contém',
              suggestion: 'Texto',
              default: '',
            },
          ];
        case 'numero':
          return [
            {
              id: 'maior-que',
              chave: 'Maior que',
              suggestion: 'Número',
              default: '',
            },
            {
              id: 'menor-que',
              chave: 'Menor que',
              suggestion: 'Número',
              default: '',
            },
            {
              id: 'maior-ou-igual',
              chave: 'Maior ou igual a',
              suggestion: 'Número',
              default: '',
            },
            {
              id: 'menor-ou-igual',
              chave: 'Menor ou igual a',
              suggestion: 'Número',
              default: '',
            },
            {id: 'igual', chave: 'Igual', suggestion: 'Número', default: ''},
            {
              id: 'diferente',
              chave: 'Diferente',
              suggestion: 'Número',
              default: '',
            },
            {id: 'entre', chave: 'Entre', suggestion: 'Número', default: ''},
            {
              id: 'nao-esta-entre',
              chave: 'Não está entre',
              suggestion: 'Número',
              default: '',
            },
            {id: 'numero', chave: 'Número', suggestion: 'Número', default: ''},
            {
              id: 'numero-inteiro',
              chave: 'Número inteiro',
              suggestion: 'Número',
              default: '',
            },
          ];
        case 'expressao-regular':
          return [
            {
              id: 'cpf',
              chave: 'CPF',
              suggestion: '',
              default: '^d{​?2}​?.d{​?3}​?.d{​?3}​?/d{​?4}​?-d{​?2}​?$',
            },
            {
              id: 'cnpj',
              chave: 'CNPJ',
              suggestion: '',
              default:
                '^(\\d{​?3}​?.\\d{​?3}​?.\\d{​?3}​?-\\d{​?2}​?)|(\\d{​?11}​?)$',
            },
            {
              id: 'e-mail',
              chave: 'E-mail',
              suggestion: '',
              default:
                '^[a-zA-Z][w.-]*[a-zA-Z0-9]@[a-zA-Z0-9][w.-]*[a-zA-Z0-9].[a-zA-Z][a-zA-Z.]*[a-zA-Z]$',
            },
            {
              id: 'telefone',
              chave: 'Telefone',
              suggestion: '',
              default:
                '^([0-9]{​?2}​?)?(([0-9]{​?2}​?))([0-9]{​?3}​?|[0-9]{​?4}​?)-[0-9]{​?4}​?',
            },
            {
              id: 'url-com-http',
              chave: 'URL com http',
              suggestion: '',
              default: '([a-zA-Z]{​?3,}​?)://([w-]+.)+[w-]+(/[w- ./?%&=]*)?',
            },
            {
              id: 'url-sem-http',
              chave: 'URL sem http',
              suggestion: '',
              default: '([w-]+.)+[w-]+(/[w- ./?%&=]*)?',
            },
          ];
        case 'tamanho-caracteres':
          return [
            {
              id: 'maior-que',
              chave: 'Maior que',
              suggestion: 'Valor numérico',
              default: '',
            },
            {
              id: 'menor-que',
              chave: 'Menor que',
              suggestion: 'Valor numérico',
              default: '',
            },
          ];

        default:
          return [];
      }
    }, [value.typeOfValidation]);

    const handleChangeTypeOfValidation = useCallback(
      (typeOfValidation: TTypeOfValidation) => {
        value.typeOfValidation = typeOfValidation;
        value.operator = 'selecione';
        value.suggestionValue = '';
        value.value = '';
        value.valueEnd = '';
        onChange({...value});
      },
      [onChange, value],
    );

    const handleChangeOperator = useCallback(
      (operator: TTypeOfValidation) => {
        value.operator = operator;
        value.suggestionValue = operators.filter(
          (operator) => operator.id === value.operator,
        )[0]?.suggestion;
        value.value = operators.filter(
          (operator) => operator.id === value.operator,
        )[0]?.default as string;

        onChange({...value});
      },
      [onChange, operators, value],
    );

    const handleChangeErrorMessage = useCallback(
      (errorMessage: string) => {
        value.customErrorMessage = errorMessage;
        onChange({...value});
      },
      [onChange, value],
    );

    const handleChangeValue = useCallback(
      (validationValue: string | number) => {
        value.value = validationValue;
        onChange({...value});
      },
      [onChange, value],
    );

    const handleChangeValueEnd = useCallback(
      (validationValueEnd: string | number) => {
        value.valueEnd = validationValueEnd;
        onChange({...value});
      },
      [onChange, value],
    );

    return (
      <Box display="flex">
        <Box mr={2}>
          <Select
            variant="outlined"
            value={value.typeOfValidation}
            onChange={(e) =>
              handleChangeTypeOfValidation(e.target.value as TTypeOfValidation)
            }>
            <MenuItem value={'selecione'}>Selecione</MenuItem>
            <MenuItem value={'texto'}>Texto</MenuItem>
            <MenuItem value={'numero'}>Número</MenuItem>
            <MenuItem value={'expressao-regular'}>Expressão regular</MenuItem>
            <MenuItem value={'tamanho-caracteres'}>
              Tamanho de caracteres
            </MenuItem>
          </Select>
        </Box>
        <Box mr={2}>
          <Select
            variant="outlined"
            value={value.operator}
            onChange={(e) =>
              handleChangeOperator(e.target.value as TTypeOfValidation)
            }>
            <MenuItem value={'selecione'}>Selecione</MenuItem>
            {operators.map((operator) => {
              return (
                <MenuItem key={operator.id} value={operator.id}>
                  {operator.chave}
                </MenuItem>
              );
            })}
          </Select>
        </Box>

        {/* Validação */}

        <Box mr={2}>
          <InputMask
            maskPlaceholder={null}
            mask={'99999999999'}
            placeholder={value.suggestionValue}
            onChange={(e) =>
              handleChangeValue(
                Number(e.target.value) < 0 ? '0' : e.target.value,
              )
            }
            value={value.value}>
            <TextField
              variant="outlined"
              InputProps={{
                inputProps: {min: 0},
              }}
            />
          </InputMask>
        </Box>

        {(value.operator === 'entre' ||
          value.operator === 'nao-esta-entre') && (
          <Box mr={2}>
            <TextField
              type="number"
              placeholder="Número"
              variant="outlined"
              value={value.valueEnd}
              onChange={(e) => handleChangeValueEnd(e.target.value)}
            />
          </Box>
        )}
        <Box minWidth={350}>
          <TextField
            variant="outlined"
            fullWidth
            value={value.customErrorMessage}
            onChange={(e) => handleChangeErrorMessage(e.target.value)}
            placeholder="Mensagem de erro personalizado"
          />
        </Box>
      </Box>
    );
  },
);
