import React, {useMemo} from 'react';
import {Box, Grid, Typography} from '@mui/material';
import {HorizontalBarChart} from 'pages/reports/components/charts';
import UpArrow from '@mui/icons-material/KeyboardArrowUp';
import DownArrow from '@mui/icons-material/KeyboardArrowDown';
import {IQuestionDetail} from 'shared/services/api/reports/AnswerSummary';
import {useReports} from 'pages/reports/hooks/useReports';

interface ITimeProps {
  configs: IQuestionDetail[];
}

export const TimeAnswer: React.FC<ITimeProps> = ({configs}) => {
  const {changeDashboardValueFormat} = useReports();

  const majorOccurrencesLabels = useMemo(() => {
    return configs[0].maioresOcorrencias.map((occ) => (occ.dataHora === "01/01/1900" || occ.dataHora === "") ? "Não respondido" : occ.dataHora);
  }, [configs]);

  const majorOccurrencesDataset = useMemo(() => {
    return configs[0].maioresOcorrencias.map((occ) => occ.quantidade);
  }, [configs]);

  const minorOccurrencesLabels = useMemo(() => {
    return configs[0].menoresOcorrencias.map((occ) => (occ.dataHora === "01/01/1900" || occ.dataHora === "") ? "Não respondido" : occ.dataHora);
  }, [configs]);

  const minorOccurrencesDataset = useMemo(() => {
    return configs[0].menoresOcorrencias.map((occ) => occ.quantidade);
  }, [configs]);

  const barColors = useMemo(() => {
    const countLabels = majorOccurrencesLabels.length;
    
    const colors = [
      '#018781',
      '#00B9AD',
      '#4285F4',
      '#F4405C',
      '#54BDD5',
      '#EB5333',
    ];

    const randomColors = colors.sort(() => 0.5 - Math.random());

    return randomColors.slice(0, countLabels);
  }, [majorOccurrencesLabels]);

  const chartDefaultDatasetConfig = useMemo(() => {
    return {
      label: '',
      categoryPercentage: 0.8,
      barPercentage: 0.8,
      backgroundColor: barColors,
      borderColor: barColors,
      borderWidth: 1,
      hoverBackgroundColor: barColors,
      hoverBorderColor: barColors
    };
  }, [barColors]);

  return (
    <Box width={'100%'}>
      <Grid container spacing={3} justifyContent={'space-evenly'}>
        <Grid item xs={5}>
          <Box width={'100%'} textAlign={'center'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <UpArrow />
            <Typography variant={'h5'}>Maiores ocorrências</Typography>
          </Box>
          <Box>
            <HorizontalBarChart
              labels={majorOccurrencesLabels}
              datasets={[
                {
                  ...chartDefaultDatasetConfig,
                  data: majorOccurrencesDataset,
                },
              ]}
              options={{
                maintainAspectRatio: true,
                responsive: true,
              }}
              width={600}
              height={300}
              showDataLabels={true}
              showAsPercentage={changeDashboardValueFormat}
            />
          </Box>
        </Grid>
        <Grid item xs={5}>
          <Box width={'100%'} textAlign={'center'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <DownArrow />
            <Typography variant={'h5'}>Menores ocorrências</Typography>
          </Box>
          <Box>
            <HorizontalBarChart
              labels={minorOccurrencesLabels}
              datasets={[
                {
                  ...chartDefaultDatasetConfig,
                  data: minorOccurrencesDataset,
                },
              ]}
              options={{
                maintainAspectRatio: true,
                responsive: true,
              }}
              width={600}
              height={300}
              showDataLabels={true}
              showAsPercentage={changeDashboardValueFormat}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
