import React, {memo, useCallback, useEffect, useState} from 'react';
import {
  TextField,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from '@mui/material';
import {Tooltip} from 'shared/components';
import {useQuiz} from '../hooks/useQuiz';
import {quizObservable} from '../observables/Observable';
import {useField} from '@unform/core';
import {
  IQuestionaryTypes,
  QuestionaryTypesService,
} from 'shared/services/api/questionary-types/QuestionaryTypes';
import {feedback} from 'shared/services';

export const About: React.FC = memo(() => {
  const {
    setTitleQuiz,
    setDescriptionQuiz,
    getTitleQuiz,
    getDescriptionQuiz,
    getFinalMessage,
    setFinalMessage,
    getQuestionaryTypeId,
    setQuestionaryTypeId,
    setIsEditingQuiz,
    setErpIntegracao,
  } = useQuiz();

  const [title, setTitle] = useState(getTitleQuiz());
  const [description, setDescription] = useState(getDescriptionQuiz());
  const [finalMsg, setFinalMsg] = useState(getFinalMessage());
  const [idQuestionaryType, setIdQuestionaryType] = useState(
    getQuestionaryTypeId(),
  );
  const [questionaryTypesList, setQuestionaryTypesList] = useState<
    IQuestionaryTypes[]
  >([]);

  const {error: titleError, clearError: titleClearError} = useField(`title`);
  const {error: descriptionError, clearError: descriptionClearError} =
    useField(`description`);
  const {error: quizTypeError, clearError: quizTypeClearError} =
    useField(`idQuestionaryType`);

  useEffect(() => {
    const listener = quizObservable.subscribe(() => {
      setTitle(getTitleQuiz());
      setDescription(getDescriptionQuiz());
    });
    return () => listener.unsubscribe();
  }, [getDescriptionQuiz, getTitleQuiz]);

  const onChangeTitle = useCallback(
    (title: string) => {
      setTitle(title);
      setTitleQuiz(title);
      setIsEditingQuiz(true);
      titleError && titleClearError();
    },
    [setTitleQuiz, titleClearError, titleError, setIsEditingQuiz],
  );

  const onChangeDescription = useCallback(
    (description: string) => {
      setDescription(description);
      setDescriptionQuiz(description);
      setIsEditingQuiz(true);
      descriptionError && descriptionClearError();
    },
    [
      setDescriptionQuiz,
      descriptionError,
      descriptionClearError,
      setIsEditingQuiz,
    ],
  );

  useEffect(() => {
    QuestionaryTypesService.getAll().then((result) => {
      if (result.success) {
        setQuestionaryTypesList(result.data.data);
        setErpIntegracao(result.data.erpIntegracao);
      } else {
        feedback(result.message || 'Erro ao carregar os dados...', 'error');
      }
    });
  }, [setErpIntegracao]);

  useEffect(() => {
    setFinalMessage(finalMsg);
  }, [finalMsg, setFinalMessage]);

  useEffect(() => {
    setQuestionaryTypeId(idQuestionaryType);
    quizTypeError && quizTypeClearError();
  }, [
    idQuestionaryType,
    setFinalMessage,
    setQuestionaryTypeId,
    quizTypeClearError,
    quizTypeError,
  ]);

  const handleOnChangeQuestionaryType = useCallback(
    (event: React.ChangeEvent<{value: any}>) => {
      const id = event.target.value as string;
      setIdQuestionaryType(id);
      setIsEditingQuiz(true);
    },
    [setIsEditingQuiz],
  );

  const onChangeFinalMessage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFinalMsg(event.target.value as string);
      setIsEditingQuiz(true);
    },
    [setIsEditingQuiz],
  );

  return (
    <Box p={3}>
      <Box
        marginTop={5}
        marginBottom={5}
        display="flex"
        justifyContent="space-between"
        alignItems="start"
        gap={5}>
        <Box width={'70%'} maxWidth={700}>
          <Tooltip text="Informe o título do questionário">
            <TextField
              label="Título do questionário"
              placeholder="Título do questionário "
              variant="outlined"
              fullWidth
              required
              value={title}
              error={!!titleError}
              helperText={titleError || title.length + '/510'}
              onChange={(e) => onChangeTitle(e.target.value)}
              inputProps={{
                maxLength: 510,
              }}
            />
          </Tooltip>
        </Box>
        <Box width={'30%'} maxWidth={300}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Tipo de questionário *</InputLabel>
            <Select
              required
              error={!!quizTypeError}
              value={
                idQuestionaryType === undefined
                  ? questionaryTypesList[0]?.id
                  : idQuestionaryType
              }
              onChange={(e: any) => {
                handleOnChangeQuestionaryType(e);
              }}
              label="Tipo de questionário">
              {questionaryTypesList.map((questionaryType) => {
                return (
                  <MenuItem key={questionaryType.id} value={questionaryType.id}>
                    {questionaryType.nome}
                  </MenuItem>
                );
              })}
            </Select>
            <FormHelperText error={!!quizTypeError}>
              {quizTypeError}
            </FormHelperText>
          </FormControl>
        </Box>
      </Box>

      <Box marginBottom={5}>
        <Tooltip text="informe a descrição do questionário">
          <TextField
            required
            rows={5}
            label="Descrição do questionário"
            helperText={descriptionError || description.length + '/1020'}
            error={!!descriptionError}
            inputProps={{
              maxLength: 1020,
            }}
            value={description}
            onChange={(e) => onChangeDescription(e.target.value)}
            variant="outlined"
            fullWidth
            multiline
          />
        </Tooltip>
      </Box>

      <Box marginBottom={5}>
        <TextField
          label="Mensagem final"
          fullWidth
          value={finalMsg}
          onChange={onChangeFinalMessage}
          variant="outlined"
        />
      </Box>
    </Box>
  );
});
