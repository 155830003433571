import React from 'react';
import {
  Box,
  Avatar,
  Icon,
  TableContainer,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import {Skeleton} from '@mui/lab';

export const GridUniqueMultipleSkeleton: React.FC = () => {
  return (
    <Box width={'100%'} maxWidth={'1124px'} margin={'0 auto'} p={3}>
      <Box width={'100%'} marginBottom={3}>
        <Box
          width={'100%'}
          display={'flex'}
          justifyContent={'flex-end'}
          alignItems={'center'}
          gap={20}>
          <Skeleton variant="rectangular" width="195px" height={15} />
          <Skeleton variant="rectangular" width="274px" height={45} />
        </Box>
      </Box>
      <Box>
        <Box
          width={'100%'}
          display={'flex'}
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
          marginBottom={3}>
          <Avatar>
            <Icon fontSize="large" style={{color: 'white'}}>
              person
            </Icon>
          </Avatar>
          <Box marginLeft={3}>
            <Skeleton variant="text" width={'450px'} height={35} />
            <Skeleton variant="text" width={'120px'} height={15} />
            <Skeleton variant="text" width={'100px'} height={15} />
          </Box>
        </Box>
      </Box>
      <Box width={'100%'} marginTop={3} style={{padding: '0 0 0 64px'}}>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow hover>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box width={'100%'} marginTop={3} style={{padding: '0 0 0 64px'}}>
        <Skeleton variant="text" width={'100px'} height={15} />
        <Skeleton variant="text" width={'500px'} height={20} />
        <Skeleton variant="text" width={'650px'} height={20} />
        <Skeleton variant="text" width={'420px'} height={20} />
        <Skeleton variant="text" width={'650px'} height={20} />
      </Box>
      <Box marginTop={5}>
        <Box
          width={'100%'}
          display={'flex'}
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
          marginBottom={3}>
          <Avatar>
            <Icon fontSize="large" style={{color: 'white'}}>
              person
            </Icon>
          </Avatar>
          <Box marginLeft={3}>
            <Skeleton variant="text" width={'450px'} height={35} />
            <Skeleton variant="text" width={'120px'} height={15} />
            <Skeleton variant="text" width={'100px'} height={15} />
          </Box>
        </Box>
      </Box>
      <Box width={'100%'} marginTop={3} style={{padding: '0 0 0 64px'}}>
      <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow hover>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box width={'100%'} marginTop={3} style={{padding: '0 0 0 64px'}}>
        <Skeleton variant="text" width={'100px'} height={15} />
        <Skeleton variant="text" width={'500px'} height={20} />
        <Skeleton variant="text" width={'650px'} height={20} />
        <Skeleton variant="text" width={'420px'} height={20} />
        <Skeleton variant="text" width={'650px'} height={20} />
      </Box>
    </Box>
  );
};
