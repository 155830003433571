import {Api} from 'shared/services/axios-config';
import {IResultOfAction} from 'shared/services/axios-config/interceptors';

export interface IProfilePermission {
  id: number;
  idPermissaoPerfilAplicacao?: number;
  rotaPermissao: string;
  nome: string;
  rota: string;
  metodo: string;
  ativo: boolean;
}

export interface IPermission {
  rotaPermissao: string;
  nome: string;
  rota: string;
  metodo: string;
  ativo: boolean;
  id: number;
}

export interface IFunctionalityPermissions {
  idAplicacao: number;
  nome: string;
  permissoes: IPermission[];
}

export interface IAplication {
  id: number;
  nome: string;
  caminho: string;
  icone: string;
  permissoes: IFunctionalityPermissions[];
}

export interface IFunctionality {
  id: number;
  idPerfilAplicacao?: number;
  nome: string;
  ativo?: boolean;
  icone?: string;
  caminho?: string;
  permissoes: IProfilePermission[];
}

export interface IProfile {
  id: string;
  perfil: string;
  descricao: string;
  funcionalidades: IFunctionality[];
}

const getById = async (
  idPerfil: string,
): Promise<IResultOfAction<IProfile>> => {
  try {
    const {data} = await Api.get(`/permissoes/perfis/${idPerfil}`);

    const profileDetail: IProfile = {
      id: data.Id,
      perfil: data.Nome,
      descricao: data.Descricao,
      funcionalidades: data.Aplicacoes.map((func: any) => ({
        id: func.IdAplicacao,
        idPerfilAplicacao: func.IdPerfilAplicacao,
        nome: func.Nome,
        ativo: func.Ativo,
        permissoes: func.Permissoes.map((perm: any) => ({
          id: perm.IdPermissao,
          idPermissaoPerfilAplicacao: perm.IdPermissaoPerfilAplicacao,
          rotaPermissao: perm.RotaPermissao,
          nome: perm.Nome,
          rota: perm.Rota,
          metodo: perm.Metodo,
          ativo: perm.Ativo,
        })),
      })),
    };

    const response: IResultOfAction<IProfile> = {
      data: profileDetail,
      success: true,
      message: '',
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const create = async (perfil: IProfile): Promise<IResultOfAction<any>> => {
  try {
    const postObject = {
      Nome: perfil.perfil,
      Descricao: perfil.descricao,
      Aplicacoes: perfil.funcionalidades.map((func: any) => ({
        IdAplicacao: func.id,
        Permissoes: func.permissoes
          .filter((perms: any) => perms.ativo && perms.id)
          .map((activePerm: any) => activePerm.id),
      })),
    };

    const {data} = await Api.post(`/permissoes/perfis`, postObject);

    const response: IResultOfAction<any> = {
      data: data.Data,
      success: data.Success,
      message: data.Message,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const upgradeById = async (
  perfil: IProfile,
  idPerfil: string,
): Promise<IResultOfAction<any>> => {
  try {
    const putObject = {
      IdPerfil: idPerfil,
      Nome: perfil.perfil,
      Descricao: perfil.descricao,
      Aplicacoes: perfil.funcionalidades.map((func: any) => ({
        IdAplicacao: func.id,
        Permissoes: func.permissoes
          .filter((perms: any) => perms.ativo && perms.id)
          .map((activePerm: any) => activePerm.id),
      })),
    };

    const {data} = await Api.put(`/permissoes/perfis/${idPerfil}`, putObject);

    const response: IResultOfAction<any> = {
      data: data.Data,
      success: data.Success,
      message: data.Message,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const getAllFunctionalities = async (): Promise<
  IResultOfAction<IAplication[]>
> => {
  try {
    const {data} = await Api.get('/permissoes/aplicacoesPerfil');

    const functionalities: IAplication[] = data.map((funcs: any) => ({
      id: funcs.Id,
      nome: funcs.Nome,
      icone: funcs.Icone,
      permissoes: funcs.Permissoes.map((perms: any) => ({
        idAplicacao: perms.IdAplicacao,
        nome: perms.Nome,
        permissoes: perms.Permissoes.map((permission: any) => ({
          rotaPermissao: permission.RotaPermissao,
          nome: permission.Nome,
          rota: permission.Rota,
          metodo: permission.Metodo,
          ativo: permission.Ativo,
          id: permission.IdPermissao,
        })),
      })),
    }));

    const response: IResultOfAction<IAplication[]> = {
      success: true,
      message: '',
      data: functionalities,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const deleteById = async (idPerfil: string): Promise<IResultOfAction<any>> => {
  try {
    const {data} = await Api.delete(`/permissoes/perfis/${idPerfil}`);

    const response: IResultOfAction<any> = {
      data: data.Data,
      success: data.Success,
      message: data.Message,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

export const ProfilesDetailService = {
  getById,
  create,
  upgradeById,
  deleteById,
  getAllFunctionalities,
};
