import React, {memo, useCallback, useState} from 'react';
import PersonAdd from '@mui/icons-material/PersonAddOutlined';
import {
  Menu,
  MenuItem,
  Checkbox,
  IconButton,
  FormControlLabel,
  Box,
  Tooltip
} from '@mui/material';
import {usePapers} from 'pages/quiz/hooks/usePapers';
import {useSection, useQuiz} from 'pages/quiz/hooks';
interface ISectionMenuProps {
  openMoveSectionModal: () => void;
  sectionOrder: number;
}

export const SectionMenu: React.FC<ISectionMenuProps> = memo(
  ({sectionOrder}) => {
    const {setIsEditingQuiz, hasInviteSent} = useQuiz();

    const {
      getSectionPapers,
      setSectionPapersToggle,
    } = useSection(sectionOrder);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const {papers} = usePapers();
    const [sectionPapers, setSectionPapers] = useState(getSectionPapers());

    const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    }, []);

    const handleClose = useCallback(() => {
      setAnchorEl(null);
    }, []);

    const handleChangeSectionPaper = useCallback((paperId: string) => {
      setSectionPapersToggle(paperId);
      setSectionPapers([...getSectionPapers()]);
      setIsEditingQuiz(true);
    }, [getSectionPapers, setSectionPapersToggle, setIsEditingQuiz]);

    return (
      <Box onClick={(e) => e.stopPropagation()}>
        <Tooltip title="Papéis que podem ver a seção">
          <IconButton
            size={"small"}
            style={{pointerEvents: 'auto'}}
            onClick={handleClick}>
            <PersonAdd />
          </IconButton>
        </Tooltip>

        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}>

          <MenuItem disabled={true} divider={true}>
            Quem vê a seção
          </MenuItem>

          {papers.map((paper, index) => (
            <MenuItem
              disabled={hasInviteSent}
              key={`paperIndex-${index}-${paper.id}`}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    onChange={() => handleChangeSectionPaper(paper.id)}
                    checked={sectionPapers.includes(paper.id)}
                  />
                }
                label={paper.nome}
              />
            </MenuItem>
          ))}
        </Menu>
      </Box>
    );
  },
);
