import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {
  Box,
  Grow,
  Icon,
  Button,
  Dialog,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
  CircularProgress,
} from '@mui/material';
import FeedbackIcon from '@mui/icons-material/Feedback';
import {NIL as NIL_UUID} from 'uuid';
import {NotFoundText} from 'shared/components';
import {FinalDialog} from './FinalDialog';
import {QuestionSlide} from './QuestionSlide';
import {RenderQuestion} from './RenderQuestion';
import {
  IStudentQuestion,
  StudentQuestionListService,
} from 'shared/services/api/student-quiz/QuestionList';
import {useStudentQuestions} from '../hooks';
import {FinishedQuiz} from './finished-quiz/FinishedQuiz';
import {SubTheme} from '../themes/SubTheme';
import {feedback} from 'shared/services';
import {downloadPDF} from 'shared/components/donwload-from-base-64/DownloadFromBase64';

interface IQuestionWrapper {
  questions: IStudentQuestion[];
}

export const QuestionWrapper: React.FC<IQuestionWrapper> = ({questions}) => {
  const navigate = useNavigate();

  const {
    idPessoaConvite,
    nameSection,
    finalMessage,
    nextSectionId,
    isSectionFinished,
    isQuizCompleted,
    numberOfMultipliers,
    multiplierPosition,
    numberOfQuestionsLastMultiplier,
    nextMultiplier,
    previousMultiplier,
    resetMultiplier,
    updateQuestions,
    completeQuiz,
    quizColor,
    multiplierList,
    setMultiplierPosition,
  } = useStudentQuestions();

  const [currentQuestion, setCurrentQuestion] = useState<IStudentQuestion>(
    {} as IStudentQuestion,
  );

  const [questionPosition, setQuestionPosition] = useState(
    questions && !isQuizCompleted
      ? isSectionFinished
        ? questions.length - 1
        : questions.filter((question) => question.respondido).length
      : 0,
  );

  const [isLoading, setIsLoading] = useState(false);
  const [changeSlide, setChangeSlide] = useState(0);
  const [nextQuestionDirection, setNextQuestionDirection] = useState<
    'previous' | 'next'
  >('next');

  const [hasError, setHasError] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [hasQuestionsToAnswer, setHasQuestionsToAnswer] = useState(false);
  const [hasQuestionsToAnswerDialog, setHasQuestionsToAnswerDialog] =
    useState(false);
  const [
    multipliersWithRemainingQuestions,
    setMultipliersWithRemainingQuestions,
  ] = useState<{[key: string]: number[]}>({});
  const [currentRemainingQuestionIndex, setCurrentRemainingQuestionIndex] =
    useState(0);
  const [currentMultiplierWithQuestions, setCurrentMultiplierWithQuestions] =
    useState('');

  useEffect(() => {
    setCurrentQuestion({...questions[questionPosition]});
  }, [questionPosition, questions]);

  const validate = useCallback((question: IStudentQuestion) => {
    if (question.obrigatoria) {
      switch (question.tipoQuestao) {
        case 'simple-text':
        case 'paragraph-text':
        case 'date':
        case 'time':
          if (!question.respostaTexto || question.respostaTexto === '')
            return false;
          return true;
        case 'unique-answer':
        case 'linear-scale':
          if (
            !question.opcoesRespostaLinha.find((option) => option.selecionado)
          )
            return false;
          return true;
        case 'multiple-answer':
          if (
            !question.opcoesRespostaLinha.some((option) => option.selecionado)
          )
            return false;
          return true;
        case 'grid-unique-answer':
        case 'grid-multiple-answer':
          var error = false;
          question.opcoesRespostaLinha.forEach((option) => {
            const hasSelectedOption = option.opcoesRespostaColuna.some(
              (optionColumn) => optionColumn.selecionado,
            );
            if (!hasSelectedOption) {
              error = true;
            }
          });
          if (error) return false;
          return true;
        default:
          return true;
      }
    } else {
      return true;
    }
  }, []);

  const checkRemainingQuestions = useCallback(() => {
    const questionsToAnswer = [];
    multiplierList.forEach((multiItem, i) => {
      multiItem.questoes.forEach((question, j) => {
        if (!question.respondido || !validate(question)) {
          questionsToAnswer.push(j);
        }
      });
    });
    if (questionsToAnswer.length > 0) {
      return true;
    }

    return false;
  }, [multiplierList, validate]);

  const changeQuestionPositionToNext = useCallback(() => {
    if (questions.length - 1 > questionPosition) {
      setNextQuestionDirection('next');

      setChangeSlide((state) => state + 1);

      setTimeout(() => {
        setQuestionPosition((state) => state + 1);
        setIsLoading(false);
      }, 450);
    } else {
      if (numberOfMultipliers - 1 > multiplierPosition) {
        setNextQuestionDirection('next');

        setChangeSlide((state) => state + 1);
        setTimeout(() => {
          setQuestionPosition(0);
          nextMultiplier();

          setIsLoading(false);
        }, 450);
      } else {
        if (checkRemainingQuestions()) {
          setHasQuestionsToAnswer(true);
          setHasQuestionsToAnswerDialog(true);
        } else {
          setIsFinished(true);
        }

        setIsLoading(false);
      }
    }
  }, [
    checkRemainingQuestions,
    multiplierPosition,
    numberOfMultipliers,
    nextMultiplier,
    questionPosition,
    questions.length,
  ]);

  const save = useCallback(() => {
    setHasError(false);
    if (validate(currentQuestion)) {
      StudentQuestionListService.putById(
        currentQuestion.idResposta,
        currentQuestion,
      ).then((response) => {
        if (response.success) {
          const questionsCopy = questions;
          questionsCopy[questionPosition] = currentQuestion;
          questionsCopy[questionPosition].respondido = true;

          updateQuestions([...questionsCopy]);

          changeQuestionPositionToNext();
        } else {
          setHasError(true);
          setIsLoading(false);
        }
      });
    } else {
      setHasError(true);
      setIsLoading(false);
    }
  }, [
    questions,
    currentQuestion,
    questionPosition,
    validate,
    updateQuestions,
    changeQuestionPositionToNext,
  ]);

  const getComprovante = useCallback(() => {
    StudentQuestionListService.getComprovante(idPessoaConvite).then(
      (result) => {
        if (result.success) {
          downloadPDF(result.data, 'comprovante');
        } else {
          feedback(
            result.message || 'Erro ao carregar permissões de usuário',
            'error',
          );
          setIsLoading(false);
        }
      },
    );
  }, [idPessoaConvite]);

  const handleQuestionsToAnswer = useCallback(() => {
    if (
      multipliersWithRemainingQuestions[currentMultiplierWithQuestions].length -
        1 >
      currentRemainingQuestionIndex
    ) {
      setCurrentRemainingQuestionIndex((oldState) => oldState + 1);
      const questionToGo =
        multipliersWithRemainingQuestions[currentMultiplierWithQuestions][
          currentRemainingQuestionIndex + 1
        ];
      setNextQuestionDirection('next');
      setChangeSlide(questionToGo);

      setTimeout(() => {
        setQuestionPosition(questionToGo);
        setIsLoading(false);
      }, 450);
    } else {
      if (
        !Object.keys(multipliersWithRemainingQuestions)[multiplierPosition + 1]
      ) {
        setCurrentRemainingQuestionIndex(0);
        setHasQuestionsToAnswer(false);
        setHasQuestionsToAnswerDialog(false);
        setIsFinished(true);
      } else {
        setCurrentMultiplierWithQuestions(
          Object.keys(multipliersWithRemainingQuestions)[
            multiplierPosition + 1
          ],
        );
        setCurrentRemainingQuestionIndex(
          multipliersWithRemainingQuestions[currentMultiplierWithQuestions][0],
        );
        setNextQuestionDirection('next');
        setTimeout(() => {
          setChangeSlide(
            multipliersWithRemainingQuestions[
              currentMultiplierWithQuestions
            ][0],
          );
          const nextMultiplier = Object.keys(multipliersWithRemainingQuestions)
            .map((multiItem) => multiItem)
            .indexOf(
              Object.keys(multipliersWithRemainingQuestions)[
                multiplierPosition + 1
              ],
            );
          setQuestionPosition(
            multipliersWithRemainingQuestions[
              currentMultiplierWithQuestions
            ][0],
          );
          setMultiplierPosition(nextMultiplier);
          setIsLoading(false);
        }, 450);
        setIsLoading(false);
      }
    }

    StudentQuestionListService.putById(
      currentQuestion.idResposta,
      currentQuestion,
    ).then((response) => {
      if (response.success) {
        const questionsCopy = questions;
        questionsCopy[questionPosition] = currentQuestion;
        questionsCopy[questionPosition].respondido = true;

        updateQuestions([...questionsCopy]);
      } else {
        setHasError(true);
        setIsLoading(false);
      }
    });
  }, [
    currentQuestion,
    currentMultiplierWithQuestions,
    currentRemainingQuestionIndex,
    multiplierPosition,
    multipliersWithRemainingQuestions,
    setMultiplierPosition,
    questionPosition,
    questions,
    updateQuestions,
  ]);

  const setFirstRemainingQuestionsToAnswer = useCallback(() => {
    let multipliers: {[key: string]: number[]} = {};

    multiplierList.forEach((multiItem, i) => {
      multipliers[multiItem.codigo] = [];
      multiItem.questoes.forEach((question, j) => {
        if (!question.respondido || !validate(question)) {
          multipliers[multiItem.codigo].push(j);
        }
      });
    });

    Object.keys(multipliers).forEach((key) => {
      if (multipliers[key].length === 0) {
        delete multipliers[key];
      }
    });

    setMultipliersWithRemainingQuestions(multipliers);

    const firstMultiplier = Object.keys(multipliers)
      .map((multiItem) => multiItem)
      .indexOf(Object.keys(multipliers)[0]);
    setMultiplierPosition(firstMultiplier);

    setCurrentMultiplierWithQuestions(Object.keys(multipliers)[0]);
    setCurrentRemainingQuestionIndex(
      multipliers[Object.keys(multipliers)[0]][0],
    );

    setTimeout(() => {
      setCurrentQuestion({
        ...questions[multipliers[Object.keys(multipliers)[0]][0]],
      });
    }, 450);
  }, [multiplierList, validate, setMultiplierPosition, questions]);

  const handleNext = useCallback(() => {
    setIsLoading(true);
    if (hasQuestionsToAnswer) {
      handleQuestionsToAnswer();
    } else {
      if (isQuizCompleted || !currentQuestion.idQuestao) {
        changeQuestionPositionToNext();
      } else {
        save();
      }
    }
  }, [
    currentQuestion,
    isQuizCompleted,
    changeQuestionPositionToNext,
    save,
    hasQuestionsToAnswer,
    handleQuestionsToAnswer,
  ]);

  const handlePrevious = useCallback(() => {
    setIsLoading(true);
    setNextQuestionDirection('previous');
    setHasError(false);
    if (questionPosition === 0 && multiplierPosition > 0) {
      setChangeSlide((state) => (state > 0 ? state - 1 : 0));
      setTimeout(() => {
        setQuestionPosition(
          numberOfQuestionsLastMultiplier > 0
            ? numberOfQuestionsLastMultiplier - 1
            : 0,
        );
        setIsLoading(false);
      }, 450);
      previousMultiplier();
    } else {
      setChangeSlide((state) => (state > 0 ? state - 1 : 0));
      setTimeout(() => {
        setQuestionPosition((state) => (state > 0 ? state - 1 : 0));
        setIsLoading(false);
      }, 450);
    }
  }, [
    questionPosition,
    multiplierPosition,
    numberOfQuestionsLastMultiplier,
    previousMultiplier,
  ]);

  const handleRemainingQuestions = useCallback(() => {
    setHasQuestionsToAnswerDialog(false);
    setFirstRemainingQuestionsToAnswer();
  }, [setFirstRemainingQuestionsToAnswer]);

  return (
    <SubTheme color={quizColor}>
      {hasError && (
        <Grow in={hasError}>
          <Box display="flex" gap={8} alignItems="center">
            <FeedbackIcon color="error" />
            <p>{currentQuestion.mensagemErro}</p>
          </Box>
        </Grow>
      )}
      {questions.length ? (
        <QuestionSlide
          changeSlide={changeSlide}
          nextQuestionDirection={nextQuestionDirection}>
          <RenderQuestion
            currentQuizColor={quizColor}
            hasError={hasError}
            isQuizCompleted={isQuizCompleted}
            currentQuestion={currentQuestion}
            setCurrentQuestion={setCurrentQuestion}
          />
        </QuestionSlide>
      ) : (
        <NotFoundText />
      )}

      <Box display="flex" gap={2} marginTop={5} marginBottom={2}>
        <Button
          fullWidth
          style={{maxWidth: '150px'}}
          disabled={
            (multiplierPosition === 0 && questionPosition === 0) ||
            isLoading ||
            hasQuestionsToAnswer
          }
          color="primary"
          variant="outlined"
          onClick={handlePrevious}>
          Anterior
        </Button>
        <Button
          fullWidth
          style={{maxWidth: '150px'}}
          color="primary"
          variant="contained"
          disabled={isLoading}
          onClick={handleNext}>
          Próxima
          {isLoading && (
            <CircularProgress
              size={24}
              style={{position: 'absolute', right: 15}}
            />
          )}
        </Button>
      </Box>
      {isQuizCompleted ? (
        <FinalDialog
          open={isFinished}
          icon={
            <Icon fontSize="large" color="inherit">
              sentiment_satisfied_alt
            </Icon>
          }
          title="Obrigado por responder este questionário.">
          <FinishedQuiz color={quizColor} />
          <Box>
            <Typography color="primary" variant="h5">
              {finalMessage}
            </Typography>
          </Box>
          <Button
            style={{minWidth: '250px', marginTop: 10}}
            color="primary"
            variant="contained"
            onClick={() => getComprovante()}>
            Imprimir comprovante de participação
          </Button>

          <Button
            style={{minWidth: '250px', marginTop: 8}}
            color="primary"
            variant="contained"
            onClick={() => navigate(`/pagina-inicial`, {replace: true})}>
            Voltar para o GVquest
          </Button>
        </FinalDialog>
      ) : nextSectionId !== NIL_UUID ? (
        <FinalDialog
          open={isFinished}
          icon={<Icon fontSize="large">thumb_up_off_alt</Icon>}
          title="Parabéns!"
          description={`Você finalizou a seção ${nameSection}`}>
          <FinishedQuiz color={quizColor} size={'90%'} />
          <Button
            style={{minWidth: '250px', marginTop: 10}}
            color="primary"
            variant="contained"
            onClick={() => {
              navigate(
                `/convite/${idPessoaConvite}/secoes/${nextSectionId}/questoes`,
                {replace: true},
              );
              setIsFinished(false);
              setQuestionPosition(0);
              resetMultiplier();
            }}>
            Próxima seção
          </Button>
          <Button
            style={{minWidth: '250px', marginTop: 8}}
            color="primary"
            variant="outlined"
            onClick={() => {
              setIsFinished(false);
            }}>
            Voltar para questionário
          </Button>
          <Button
            style={{minWidth: '250px', marginTop: 8}}
            color="primary"
            variant="outlined"
            onClick={() => navigate(`/convite/${idPessoaConvite}`)}>
            Listar seções
          </Button>
        </FinalDialog>
      ) : (
        <Dialog
          open={isFinished}
          fullWidth
          maxWidth="sm"
          aria-labelledby="modal-detail-title">
          <DialogTitle id="modal-detail-title">
            <Box gap={2} display="flex" alignItems="center" marginTop={1}>
              <Icon color="primary">done_outline</Icon>
              <Typography color="primary" variant="h5">
                Parabéns! Você respondeu a última questão.
              </Typography>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box marginY={2} marginX={1}>
              <Typography variant="subtitle1">
                Clique em "Concluir" para enviar suas respostas.
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                setIsFinished(false);
              }}>
              Voltar ao questionário
            </Button>
            <Button color="primary" variant="contained" onClick={completeQuiz}>
              Concluir
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Dialog open={hasQuestionsToAnswerDialog} fullWidth maxWidth="sm">
        <DialogTitle>
          <Box gap={2} display="flex" alignItems="center" marginTop={1}>
            <Icon color="primary" fontSize="large">
              rule_outline
            </Icon>
            <Typography color="primary" variant="h5">
              Você possuí questões não respondidas!
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box marginY={2} marginX={1}>
            <Typography variant="subtitle1">
              Clique em "Continuar" para responder as questões restantes.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={handleRemainingQuestions}>
            Continuar
          </Button>
        </DialogActions>
      </Dialog>
    </SubTheme>
  );
};
