import React from 'react';
import {Circle} from '@mui/icons-material';
import {Box, Typography, CircularProgress} from '@mui/material';
import {orange, green, red, grey} from '@mui/material/colors';
import {IStatusConvite} from 'shared/services/api/central-convites/CentralConvitesService';

interface IProps {
  status: IStatusConvite;
}

export const StatusConvite: React.FC<IProps> = ({status}) => {
  switch (status) {
    case 'EM_ELABORACAO':
      return (
        <Box
          display={'flex'}
          justifyContent={'flex-start'}
          alignItems={'center'}>
          <Circle
            sx={{color: orange[500], fontSize: 'medium', marginRight: 1}}
          />
          <Typography variant="body2">Convite em elaboração</Typography>
        </Box>
      );
    case 'ACEITANDO_RESPOSTAS':
      return (
        <Box
          display={'flex'}
          justifyContent={'flex-start'}
          alignItems={'center'}>
          <Circle
            sx={{color: green[500], fontSize: 'medium', marginRight: 1}}
          />
          <Typography variant="body2">Aceitando respostas</Typography>
        </Box>
      );
    case 'BLOQUEADO_PARA_RESPOSTAS':
      return (
        <Box
          display={'flex'}
          justifyContent={'flex-start'}
          alignItems={'center'}>
          <Circle sx={{color: red[500], fontSize: 'medium', marginRight: 1}} />
          <Typography variant="body2">Bloqueado para respostas</Typography>
        </Box>
      );
    case 'CONVITE_ENCERRADO':
      return (
        <Box
          display={'flex'}
          justifyContent={'flex-start'}
          alignItems={'center'}>
          <Circle sx={{color: grey[300], fontSize: 'medium', marginRight: 1}} />
          <Typography variant="body2">Convite encerrado</Typography>
        </Box>
      );
    case 'AGUARDANDO_LIBERACAO':
      return (
        <Box
          display={'flex'}
          justifyContent={'flex-start'}
          alignItems={'center'}>
          <CircularProgress size={16} sx={{marginRight: 1}} />
          <Typography variant="body2">Aguardando liberação</Typography>
        </Box>
      );
    case 'LIBERACAO_INICIADA':
      return (
        <Box
          display={'flex'}
          justifyContent={'flex-start'}
          alignItems={'center'}>
          <CircularProgress size={16} sx={{marginRight: 1}} />

          <Typography variant="body2">Liberação iniciada</Typography>
        </Box>
      );
    case 'ERRO_LIBERACAO':
      return (
        <Box
          display={'flex'}
          justifyContent={'flex-start'}
          alignItems={'center'}>
          <Circle sx={{color: red[500], fontSize: 'medium', marginRight: 1}} />
          <Typography variant="body2">Erro na liberação</Typography>
        </Box>
      );
    case 'REPROCESSAMENTO_DO_PUBLICO_ALVO':
      return (
        <Box
          display={'flex'}
          justifyContent={'flex-start'}
          alignItems={'center'}>
          <CircularProgress size={16} sx={{marginRight: 1}} />

          <Typography variant="body2">Reprocessamento público alvo</Typography>
        </Box>
      );
    case 'PUBLICO_ALVO_SEM_DESTINATARIOS':
      return (
        <Box
          display={'flex'}
          justifyContent={'flex-start'}
          alignItems={'center'}>
          <Circle sx={{color: grey[300], fontSize: 'medium', marginRight: 1}} />
          <Typography variant="body2">
            Público alvo sem destinatários
          </Typography>
        </Box>
      );
    default:
      return (
        <Box
          display={'flex'}
          justifyContent={'flex-start'}
          alignItems={'center'}>
          <Circle sx={{color: grey[300], fontSize: 'medium', marginRight: 1}} />
          <Typography variant="body2">-</Typography>
        </Box>
      );
  }
};
