import React, {memo} from 'react';
import {
  Box,
  Typography,
  OutlinedInput,
  Grid,
  Checkbox,
} from '@mui/material';

import {IOption} from 'shared/services/api/quiz/Quiz';
interface IMultipleAnswersPreviewProps {
  value: IOption[];
  hasComment?: boolean;
}

export const MultipleAnswerPreview: React.FC<IMultipleAnswersPreviewProps> =
  memo(({value, hasComment}) => {
    return (
      <>
        <Box alignItems="center" justifyContent={'start'}>
          {value.map((option) => (
            <Box
              key={`unique-answer-option-preview-${option.order}`}
              alignItems="center"
              alignContent="center"
              justifyContent={'start'}
              margin="auto"
              display="flex">
              <Checkbox checked={false} disabled={true} />

              <Box
                p={2}
                fontWeight="fontWeightBold"
                display="flex"
                justifyContent="start">
                <Typography>
                  {option.description || 'Opção ' + option.order}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
        {hasComment && (
          <Grid item xs={4}>
            <Box marginTop={10} justifyContent="center" display="flex">
              <OutlinedInput fullWidth placeholder="Comentar" disabled={true} />
            </Box>
          </Grid>
        )}
      </>
    );
  });
