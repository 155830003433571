import React, {memo, useCallback, useEffect, useState} from 'react';

import {
  Button,
  Typography,
  Box,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
} from '@mui/material';
import {DragIndicatorOutlined} from '@mui/icons-material';

import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';

import {useQuiz} from '../../hooks';
import {sectionsObservable} from 'pages/quiz/observables/Observable';

interface IMoveSectionProps {
  open: boolean;
  closeModal: () => void;
}

export const MoveSectionModal: React.FC<IMoveSectionProps> = memo(
  ({open, closeModal}) => {
    const theme = useTheme();
    const {getSectionsNameOrder, setSectionsNewOrder, setIsEditingQuiz} =
      useQuiz();

    const [sections, setSections] = useState(getSectionsNameOrder());

    useEffect(() => {
      const listener = sectionsObservable.subscribe(() => {
        setSections([...getSectionsNameOrder()]);
      });
      return () => listener.unsubscribe();
    }, [getSectionsNameOrder]);

    const handleOnDragEnd = useCallback(
      (result: DropResult) => {
        if (!result.destination) return;

        const [reorderedItem] = sections.splice(result.source.index, 1);
        sections.splice(result.destination.index, 0, reorderedItem);
        sections.forEach((item, index) => {
          item.order = index + 1;
        });

        setSections([...sections]);
        setIsEditingQuiz(true);
      },
      [sections, setIsEditingQuiz],
    );

    const handleSave = useCallback(() => {
      setSectionsNewOrder(sections);
      closeModal();
    }, [closeModal, sections, setSectionsNewOrder]);

    const handleClose = useCallback(() => {
      closeModal();
    }, [closeModal]);

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={'paper'}
        fullWidth={true}
        maxWidth={'md'}>
        <DialogTitle>Reordenar seções</DialogTitle>
        <Grid container justifyContent={'flex-start'}>
          <DialogContent dividers>
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <div>
                <Droppable droppableId={'droppable-1'}>
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {sections.map((section, index) => (
                        <Draggable
                          key={section.id}
                          draggableId={'draggable-' + section.id}
                          index={index}>
                          {(provided, {isDragging}) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                ...provided.draggableProps.style,
                                background: theme.palette.background.paper,
                                boxShadow: isDragging
                                  ? theme.shadows[4]
                                  : undefined,
                                paddingRight: isDragging
                                  ? theme.spacing(3)
                                  : theme.spacing(0),
                                paddingTop: isDragging
                                  ? theme.spacing(0)
                                  : theme.spacing(2),
                              }}>
                              <Grid item xs={12}>
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  padding={1}>
                                  <DragIndicatorOutlined fontSize={'small'} />

                                  <Typography variant={'body1'}>
                                    {`Seção ${section.order} - ${section.name}`}
                                  </Typography>
                                </Box>
                              </Grid>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            </DragDropContext>
          </DialogContent>
        </Grid>
        <DialogActions>
          <Button onClick={handleClose} variant="contained">
            Cancelar
          </Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    );
  },
);
