import React, {useState, useCallback, useEffect} from 'react';
import {
  Box,
  Grid,
  InputAdornment,
  FormControl,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Paper,
  Radio,
  TextField,
  CircularProgress,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {Pagination} from '@mui/lab';
import {Environment} from 'shared/Env';
import {NotFoundText} from 'shared/components';
import {IParticipant} from 'shared/services/api/reports/IndividualAnswers';
import {useDebounce} from 'shared/hooks';
import {ParticipantsListSkeleton} from './ParticipantsListSkeleton';

export interface IParticipantsListSelectProps {
  participants: IParticipant[];
  onChangeParticipant: (part: IParticipant) => void;
  selectedParticipant: string;
}

export const ParticipantsListSelect: React.FC<IParticipantsListSelectProps> = ({
  participants,
  onChangeParticipant,
  selectedParticipant,
}) => {
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(Environment.LIMITE_DE_LINHAS);
  const [isLoading, setIsLoading] = useState(false);
  const {debounce} = useDebounce();
  const [currentParticipants, setCurrentParticipants] = useState<
    IParticipant[]
  >([]);
  const [searchParticipants, setSearchParticipants] = useState<IParticipant[]>(
    [],
  );

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setCurrentParticipants(participants);
      setSearchParticipants(participants);
      setIsLoading(false);
    }, 1000);
  }, [participants]);

  const handlePageChange = useCallback((event: any | null, page: number) => {
    setPage(page);
  }, []);

  const removeAccentuation = useCallback((value: string) => {
    const resultFilter = value
      .toLocaleLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

    return resultFilter;
  }, []);

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsLoading(true);
      setSearch(e.target.value);

      debounce(() => {
        const valor = removeAccentuation(e.target.value);

        const resultFilter = searchParticipants.filter(
          (item) =>
            removeAccentuation(item.nome).indexOf(valor) > -1 ||
            removeAccentuation(item.descricaoCurso).indexOf(valor) > -1 ||
            removeAccentuation(item.papel).indexOf(valor) > -1,
        );

        if (e.target.value === '') {
          setCurrentParticipants(searchParticipants);
        } else {
          setCurrentParticipants(resultFilter);
        }
        setPage(1);
        setIsLoading(false);
      });
    },
    [debounce, searchParticipants, removeAccentuation],
  );

  return (
    <>
      <Box
        width={'100%'}
        marginBottom={'20px'}
        key={'search-participants-block'}>
        <Grid container item spacing={0} xs={12} alignItems={'center'}>
          <Grid item xs={6}>
            <FormControl variant="outlined" fullWidth>
              <Paper>
                <TextField
                  style={{padding: 0, margin: 0}}
                  fullWidth
                  variant="outlined"
                  placeholder="Pesquise por nome, curso ou papel"
                  value={search}
                  onChange={handleSearch}
                  InputProps={{
                    style: {
                      height: 40,
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        {isLoading ? (
                          <CircularProgress size={30} />
                        ) : (
                          <SearchIcon color="disabled" />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Paper>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Box width={'100%'} key={'participants-table-block'}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell component="th" scope="row" width={'150px'}>
                  Selecionar
                </TableCell>
                <TableCell component="th" scope="row">
                  Nome
                </TableCell>
                <TableCell component="th" scope="row">
                  Matrícula
                </TableCell>
                <TableCell component="th" scope="row">
                  Curso
                </TableCell>
                <TableCell component="th" scope="row">
                  Papel
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <ParticipantsListSkeleton />
              ) : currentParticipants.length ? (
                currentParticipants
                  .slice(
                    (page - 1) * rowsPerPage,
                    (page - 1) * rowsPerPage + rowsPerPage,
                  )
                  .map((part, i) => (
                    <TableRow hover key={`participant-row-${part.id}`}>
                      <TableCell component="th" scope="row">
                        <Radio
                          checked={selectedParticipant === part.id}
                          onChange={(e) => {
                            onChangeParticipant(part);
                          }}
                          value={part.id}
                          name="rb-select-partcipants"
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {part.nome}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {part.matricula === '0' ? '-' : part.matricula}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {part.descricaoCurso || '-'}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {part.papel || '-'}
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell>
                    <NotFoundText />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {currentParticipants.length > rowsPerPage && (
            <Box
              marginTop={1}
              marginBottom={1}
              marginLeft={1}
              marginRight={1}
              display={'flex'}
              justifyContent={'end'}>
              <Pagination
                page={page}
                count={Math.ceil(currentParticipants.length / rowsPerPage)}
                onChange={handlePageChange}
              />
            </Box>
          )}
        </TableContainer>
      </Box>
    </>
  );
};
