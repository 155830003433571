import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {
  Box,
  Icon,
  Grid,
  Paper,
  Button,
  Container,
  Typography,
} from '@mui/material';
import {Skeleton} from '@mui/lab';
import {NotFoundText, ReadMore} from 'shared/components';
import {Progress} from 'pages/student-quiz/components/Progress';
import {useStyles} from 'pages/student-quiz/styles';
import {IQuizSections} from 'shared/services/api/student-quiz/SectionList';
import {LogoGVdasa} from 'shared/assets/index';
import {useQuiz} from 'pages/quiz/hooks/useQuiz';

export const PreviewSectionList: React.FC = () => {
  const {
    quizSectionPreview,
    setQuestionsToPreview,
    setCurrentSectionPreviewIndex,
  } = useQuiz();
  const navigate = useNavigate();
  const styles = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [sectionList, setSectionList] = useState<IQuizSections>();

  useEffect(() => {
    if (Object.keys(quizSectionPreview).length > 0) {
      setSectionList(quizSectionPreview);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } else {
      navigate('/questionarios');
    }
  }, [quizSectionPreview, navigate]);

  return (
    <Box className={styles.root}>
      <Box
        width={'100%'}
        padding={'10px'}
        style={{backgroundColor: '#414141', color: 'white'}}>
        <Box display={'flex'} alignItems={'center'} gap={10}>
          <Icon>warning_amber_outlined</Icon>
          <Typography component={'b'}>
            Questionário em modo de visualização prévia. Algumas informações
            estão indisponíveis.
          </Typography>
        </Box>
      </Box>
      {isLoading ? (
        <Skeleton variant="rectangular" width="100%" height={144} />
      ) : sectionList?.banner ? (
        <Box
          className={styles.banner}
          style={{backgroundImage: `url(${sectionList?.banner})`}}
        />
      ) : (
        <Box className={styles.banner} display="flex" alignItems="center">
          <Container>
            <img src={LogoGVdasa.default} alt="Logo GVdasa" />
          </Container>
        </Box>
      )}
      <Box marginTop={7} marginBottom={13}>
        <Container>
          {isLoading ? (
            <Grid container spacing={3} justifyContent="flex-start">
              <Grid item xs={12}>
                <Skeleton width="80%" height={35} />
                <Box marginBottom={2} />
                <Skeleton width="60%" height={35} />
                <Box marginBottom={2} />
                <Skeleton width="30%" height={30} />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                <Skeleton width="50%" height={40} />
                <Skeleton width="50%" height={40} />
                <Box marginBottom={2} />
                <Skeleton width="80%" height={40} />
                <Box marginBottom={1} />
                <Skeleton width="80%" height={50} />
              </Grid>
            </Grid>
          ) : (
            <Box marginTop={2}>
              <Grid container spacing={3} justifyContent="flex-start">
                <Grid item xs={12}>
                  {sectionList ? (
                    <>
                      <ReadMore
                        cutAt={80}
                        text={sectionList.tituloQuestionario}
                        color="primary"
                        variant="h5"
                        style={{wordBreak: 'break-all', fontWeight: 700}}
                      />
                      <Box marginBottom={2} />
                      <ReadMore
                        cutAt={150}
                        text={sectionList.descricaoConvite}
                        color="primary"
                        variant="h6"
                        style={{wordBreak: 'break-all'}}
                        gutterBottom
                      />
                      <Box marginBottom={2} />
                      <Typography variant="h6" style={{fontWeight: 400}}>
                        {`${sectionList.numeroTotalQuestoesQuestionario} perguntas totais`}
                      </Typography>
                    </>
                  ) : (
                    <NotFoundText />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Box
                    width="100%"
                    marginTop={1}
                    display="flex"
                    flexDirection="column"
                    gap={32}>
                    {sectionList?.secoes.map((secao, i) => {
                      return (
                        <Box
                          key={secao.id}
                          display="flex"
                          flexDirection="column">
                          <Box>
                            <ReadMore
                              cutAt={30}
                              text={secao.nomeSecao}
                              variant="h6"
                              style={{wordBreak: 'break-all', fontWeight: 700}}
                            />
                            <ReadMore
                              cutAt={50}
                              text={secao.descricao}
                              variant="h6"
                              color="textSecondary"
                              style={{wordBreak: 'break-all', fontWeight: 400}}
                            />
                          </Box>
                          <Grid
                            container
                            spacing={3}
                            justifyContent="flex-start">
                            <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                              <Box margin={'12px 0px 16px 0'}>
                                <Progress
                                  count={secao.numeroTotalQuestoes}
                                  currentPosition={
                                    secao.numeroQuestoesRespondidas
                                  }
                                />
                              </Box>
                              <Paper elevation={1}>
                                <Button
                                  fullWidth
                                  color="primary"
                                  variant="outlined"
                                  onClick={() => {
                                    setQuestionsToPreview(secao.questoes);
                                    setCurrentSectionPreviewIndex(i);
                                    setTimeout(() => {
                                      navigate(
                                        '/questionario-preview/questoes',
                                      );
                                    }, 100);
                                  }}>
                                  {secao.numeroQuestoesRespondidas === 0
                                    ? 'Começar'
                                    : 'Continuar'}
                                </Button>
                              </Paper>
                            </Grid>
                          </Grid>
                        </Box>
                      );
                    })}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box marginTop={4}>
                    <Button
                      variant="text"
                      color="primary"
                      startIcon={<Icon>arrow_back</Icon>}
                      onClick={() => navigate(-1)}>
                      Voltar
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
        </Container>
      </Box>
    </Box>
  );
};
