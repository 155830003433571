import React from 'react';
import {Box, Theme} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {ReactComponent as SpiningGears} from './svg/gears.svg';
import {SvgPerson} from '../person/SvgPerson';

interface IGearsProps {
  color: string;
}

const useStyles = makeStyles<Theme, IGearsProps>({
  '@keyframes rotacao': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(359deg)',
    },
  },
  '@keyframes rotacaoCont': {
    from: {
      transform: 'rotate(359deg)',
    },
    to: {
      transform: 'rotate(0deg)',
    },
  },
  gearsWrapper: {
    '& svg': {
      '&.machine': {
        width: '300px',
        fill: (props: IGearsProps) => `#${props.color}`,
      },
      '& .small-shadow, & .medium-shadow, & .large-shadow': {
        fill: 'rgba(0, 0, 0, 0.05)',
      },
      '& .small, & .small-shadow': {
        animation: '$rotacaoCont 2.5s infinite linear',
        transformOrigin: '100.136px 225.345px',
      },
      '& .medium, & .medium-shadow': {
        animation: '$rotacao 3.75s infinite linear',
        transformOrigin: '254.675px 379.447px',
      },
      '& .large, & .large-shadow': {
        animation: '$rotacaoCont 5s infinite linear',
        transformOrigin: '461.37px 173.694px',
      },
    },
  },
});

export const Gears: React.FC<IGearsProps> = ({color}) => {
  const classes = useStyles({color});

  return (
    <>
      <Box
        width={'100%'}
        display="flex"
        justifyContent={'center'}
        className={classes.gearsWrapper}>
        <Box position={'relative'}>
          <SpiningGears />
          <Box position={'absolute'} top={0} left={0}>
            <SvgPerson color={color} />
          </Box>
        </Box>
      </Box>
    </>
  );
};
