import React, {createContext, useState, useEffect, useCallback} from 'react';
import {useParams} from 'react-router-dom';
import {feedback} from 'shared/services';
import {
  AnswerSummaryService,
  IAnswerSummary,
  IAnswerSummaryData,
  IAnswerSummarySection,
  IAnswerSummarySectionFilters,
  IAudienceComposition,
  IBurnDown,
  ISectionList,
} from 'shared/services/api/reports/AnswerSummary';
import {useTitle} from 'shared/hooks';
import {
  IIndividualAnswers,
  IndividualAnswersService,
  IParticipant,
  ISection,
} from 'shared/services/api/reports/IndividualAnswers';

interface IReportsContextData {
  answerSummary: IAnswerSummary;
  answerSummarySection: IAnswerSummarySection[];
  isLoading: boolean;
  isLoadingSection: boolean;
  setIsLoadingAnswerSummary: (isLoadingAnswerSummary: boolean) => void;
  burnDownChart: IBurnDown[];
  audiencieComposition: IAudienceComposition[];
  filters: IAnswerSummaryData['filtrosBurndownComposicaoPublico']['filtros'];
  answersSummaryDifferenceDays: number;
  answerSummarySectionFilters: IAnswerSummarySectionFilters;
  setAnswerSummarySectionFilters: (
    filters: IAnswerSummarySectionFilters,
  ) => void;
  individualAnswersCurrentParticipant: IParticipant;
  setIndividualAnswersCurrentParticipant: (part: IParticipant) => void;
  individualAnswersParticipants: IParticipant[];
  setIndividualAnswersParticipants: (parts: IParticipant[]) => void;
  individualAnswers: IIndividualAnswers;
  individualAnswersSections: ISection[];
  hasIndividualAnswersParticipants: boolean;
  selectedParticipant: string;
  setSelectedParticipant: (participantId: string) => void;
  idConvite: string;
  changeDashboardValueFormat: boolean;
  setChangeDashboardValueFormat: (value: boolean) => void;
  sectionList: ISectionList[];
}

export const ReportsContext = createContext<IReportsContextData>(
  {} as IReportsContextData,
);

export const ReportsProvider: React.FC = ({children}) => {
  const [answerSummary, setAnswerSummary] = useState<IAnswerSummary>({
    ativo: true,
    convitesEnviados: 0,
    dataHoraFim: String(new Date()),
    numeroConviteSemRespostas: 0,
    percentualGeral: 0,
    percentualParticipacao: 0,
    questionariosRecebidos: 0,
    respostasAnonimas: 0,
    tituloConvite: '',
  });
  const [answerSummarySections, setAnswerSummarySections] = useState<
    IAnswerSummarySection[]
  >([]);
  const [burnDownChart, setBurnDownChart] = useState<IBurnDown[]>([]);
  const [compPublico, setCompPublico] = useState<IAudienceComposition[]>([]);
  const [diffDays, setDiffDays] = useState(0);
  const [filtros, setFiltros] = useState<
    IAnswerSummaryData['filtrosBurndownComposicaoPublico']['filtros']
  >([]);
  const {idConvite = '0'} = useParams<'idConvite'>();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingSection, setIsLoadingSection] = useState(true);
  const {setTitle} = useTitle();
  const [answerSummarySectionFilters, setAnswerSummarySectionFilters] =
    useState<IAnswerSummarySectionFilters>({
      tipoQuestao: '',
      papel: '',
      secao: '',
      coordenador: '',
      turma: '',
      curso: '',
      disciplina: '',
    });

  const [
    individualAnswersCurrentParticipant,
    setIndividualAnswersCurrentParticipant,
  ] = useState<IParticipant>({} as IParticipant);
  const [individualAnswersParticipants, setIndividualAnswersParticipants] =
    useState<IParticipant[]>([]);
  const [individualAnswers, setIndividualAnswers] =
    useState<IIndividualAnswers>({} as IIndividualAnswers);
  const [individualAnswersSections, setIndividualAnswersSections] = useState<
    ISection[]
  >([]);
  const [
    hasIndividualAnswersParticipants,
    setIndividualAnswersHasParticipants,
  ] = useState(true);
  const [selectedParticipant, setSelectedParticipant] = useState('0');
  const [changeDashboardValueFormat, setChangeDashboardValueFormat] =
    useState(true);
  const [sectionList, setSectionList] = useState<ISectionList[]>([]);

  const orderSections = (
    s1: IAnswerSummarySection | ISection | ISectionList,
    s2: IAnswerSummarySection | ISection | ISectionList,
  ) => {
    const order1 = s1.ordem,
      order2 = s2.ordem;

    if (order1 < order2) return -1;
    if (order1 > order2) return 1;

    return 0;
  };

  const loadSummaryAndSections = useCallback(() => {
    const tempFilters: IAnswerSummarySectionFilters = {
      ...answerSummarySectionFilters,
    };
    feedback('Carregando mais seções...', 'loading');

    AnswerSummaryService.getAnswerSummaryById(idConvite, tempFilters).then(
      (result) => {
        if (result.success) {
          setAnswerSummary(result.data);
          const todayDate = new Date().valueOf();
          const dataHoraFim = new Date(
            result.data.dataHoraFim as string,
          ).valueOf();
          const day = 24 * 60 * 60 * 1000;
          setDiffDays(Math.round((dataHoraFim - todayDate) / day));
          setIsLoading(false);
        } else {
          feedback(
            result.message || 'Erro ao carregar resumo de respostas...',
            'error',
          );
          setIsLoading(false);
        }
      },
    );

    AnswerSummaryService.getAllAnswerSummarySectionsById(idConvite).then(
      (result) => {
        if (result.success) {
          setSectionList([...result.data].sort(orderSections) || []);
          if (result.data.length) {
            feedback('Carregando relatórios...', 'loading');
            const tempFilters: IAnswerSummarySectionFilters = {
              papel: '',
              secao: [...result.data].sort(orderSections)[0].id || '',
              tipoQuestao: '',
              coordenador: '',
              curso: '',
              disciplina: '',
              turma: '',
            };
            AnswerSummaryService.getAnswerSummarySectionsById(
              idConvite,
              tempFilters,
            ).then((result) => {
              if (result.success) {
                setAnswerSummarySections(result.data.dadosSecoes || []);
                setBurnDownChart(
                  result.data.filtrosBurndownComposicaoPublico.burnDown,
                );
                setCompPublico(
                  result.data.filtrosBurndownComposicaoPublico
                    .composicaoPublico,
                );
                setFiltros(
                  result.data.filtrosBurndownComposicaoPublico.filtros,
                );
                setIsLoadingSection(false);
                feedback('Relatório carregado com sucesso!', 'success');
              } else {
                feedback(
                  result.message || 'Erro ao carregar seções...',
                  'error',
                );
                setIsLoadingSection(false);
              }
            });
          } else {
            feedback('Relatório carregado com sucesso.', 'success');
          }
        } else {
          feedback(result.message || 'Erro ao carregar seções...', 'error');
          setIsLoadingSection(false);
        }
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idConvite]);

  useEffect(() => {
    setTitle('Relatórios');
    setIsLoading(true);

    if (idConvite !== '0') {
      IndividualAnswersService.getParticipantsById(idConvite).then((result) => {
        if (result.success) {
          setIndividualAnswersParticipants(result.data);
          setIndividualAnswersCurrentParticipant(result.data[0]);
          setIndividualAnswersHasParticipants(
            result.data.length > 0 ? true : false,
          );

          if (result.data.length > 0) {
            setSelectedParticipant(result.data[0].id || '0');
          }

          loadSummaryAndSections();
        } else {
          feedback(
            result.message || 'Erro ao carregar participantes.',
            'error',
          );
        }
      });
    }
  }, [idConvite, loadSummaryAndSections, setTitle]);

  useEffect(() => {
    if (selectedParticipant !== '0') {
      IndividualAnswersService.getById(selectedParticipant).then((result) => {
        if (result.success) {
          setIndividualAnswers(result.data);
          setIndividualAnswersSections(result.data.secoes.sort(orderSections));
        } else {
          feedback(
            result.message || 'Erro ao carregar respostas individuais',
            'error',
          );
        }
      });
    }
  }, [selectedParticipant]);

  return (
    <ReportsContext.Provider
      value={{
        idConvite: idConvite,
        answerSummary,
        answerSummarySection: answerSummarySections,
        isLoading,
        isLoadingSection,
        setIsLoadingAnswerSummary: setIsLoading,
        burnDownChart: burnDownChart,
        audiencieComposition: compPublico,
        filters: filtros,
        answersSummaryDifferenceDays: diffDays,
        answerSummarySectionFilters,
        setAnswerSummarySectionFilters,
        individualAnswersCurrentParticipant,
        individualAnswersParticipants,
        individualAnswers,
        individualAnswersSections,
        hasIndividualAnswersParticipants,
        selectedParticipant,
        setSelectedParticipant,
        setIndividualAnswersCurrentParticipant,
        setIndividualAnswersParticipants,
        changeDashboardValueFormat,
        setChangeDashboardValueFormat,
        sectionList,
      }}>
      {children}
    </ReportsContext.Provider>
  );
};
