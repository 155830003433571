import {Api} from 'shared/services';
import {IResultOfAction} from 'shared/services/axios-config/interceptors';
import {IStudentQuestion} from './QuestionList';

interface IStudentSection {
  id: string;
  ordem: number;
  nomeSecao: string;
  descricao: string;
  numeroTotalQuestoes: number;
  numeroQuestoesRespondidas: number;
  questoes?: IStudentQuestion[];
}

export interface IQuizSections {
  idPessoaConvite: string;
  tituloQuestionario: string;
  descricaoConvite: string;
  descricaoQuestionario?: string;
  permiteAnonimo: boolean;
  ehUsuarioAnonimo: boolean;
  visualizouModalRespostasAnonimas: boolean;
  numeroTotalQuestoesQuestionario: number;
  secoes: IStudentSection[];
  banner?: string;
  logo?: string;
  corQuestionario?: string;
  idQuestionario?: string;
}

const getById = async (
  idPessoaConvite: string,
): Promise<IResultOfAction<IQuizSections>> => {
  try {
    const {data} = await Api.get(
      `/ConvitesRecebidos/${idPessoaConvite}/obterSecoes`,
    );

    const object: IQuizSections = {
      idPessoaConvite: data.IdPessoaConvite,
      tituloQuestionario: data.TituloQuestionario,
      descricaoConvite: data.DescricaoConvite,
      descricaoQuestionario: data.DescricaoQuestionario,
      permiteAnonimo: data.PermiteAnonimo,
      ehUsuarioAnonimo: data.EhUsuarioAnonimo,
      visualizouModalRespostasAnonimas: data.VisualizouModalRespostasAnonimas,
      numeroTotalQuestoesQuestionario: data.NumeroTotalQuestoesQuestionario,
      idQuestionario: data.IdQuestionario,
      secoes: data.Secoes.map((item: any) => ({
        id: item.Id,
        ordem: item.Ordem,
        nomeSecao: item.NomeSecao,
        descricao: item.Descricao,
        numeroTotalQuestoes: item.NumeroTotalQuestoes,
        numeroQuestoesRespondidas: item.NumeroQuestoesRespondidas,
      })),
      banner: data.Banner ? `data:image/png;base64,${data.Banner}` : undefined,
      logo: data.Logo ? `data:image/png;base64,${data.Logo}` : undefined,
      corQuestionario: data.CorQuestionario,
    };

    const response: IResultOfAction<IQuizSections> = {
      success: true,
      data: object,
      message: '',
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const putById = async (
  idPessoaConvite: string,
  responderAnonimo: boolean,
): Promise<IResultOfAction<any>> => {
  const object: any = {
    Id: idPessoaConvite,
    ResponderAnonimo: responderAnonimo,
  };

  try {
    const {data} = await Api.put(
      `/ConvitesRecebidos/respostasAnonimas/${idPessoaConvite}`,
      object,
    );

    const response: IResultOfAction<any> = {
      success: data.Success,
      data: data.Data,
      message: data.Message,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

export const StudentSectionListService = {
  getById,
  putById,
};
