import {Theme} from '@mui/material';
import {makeStyles} from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  actionTabButtonsParent: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '16px',
    padding: '18px 0',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },

    '& button': {
      maxWidth: '240px',
    },
  },
  doNotShowOnPrint: {
    overflowX: 'hidden',
    '@media print': {
      display: 'none',
    },
  },
}));
