import {Api} from 'shared/services/axios-config';
import {IResultOfAction} from 'shared/services/axios-config/interceptors';

export interface IPaper {
  id: string;
  nome: string;
  selected?: boolean;
}

const getPapers = async (): Promise<IResultOfAction<IPaper[]>> => {
  try {
    const {data} = await Api.get('/papeis');

    const papeis = data.map((data: any) => ({
      id: data.IdGrupoPapel,
      nome: data.Nome,
    }));

    const response: IResultOfAction<IPaper[]> = {
      success: true,
      message: '',
      data: papeis,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

export const PaperService = {
  getPapers,
};
