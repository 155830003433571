import React, {
  createContext,
  useRef,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {useNavigate, useParams} from 'react-router';
import {v4 as uuid} from 'uuid';
import {
  IQuestion,
  IQuizDetail,
  ISection,
  QuizService,
} from 'shared/services/api/quiz/Quiz';
import {feedback, confirm} from 'shared/services';
import {
  sectionsEmitChange,
  questionEmitChange,
  quizEmitChange,
} from '../observables/Observable';
import {PapersContextProvider} from 'pages/quiz/contexts/PapersContext';
import {VForm, useVFormRef, IVFormErros} from 'shared/forms';
import {formValidationSchema} from '../validationSchemas/QuizValidationSchema';
import {IQuizSections} from 'shared/services/api/student-quiz/SectionList';
import {IStudentQuestion} from 'shared/services/api/student-quiz/QuestionList';

interface IQuizContextData {
  getQuiz: () => IQuizDetail;
  getQuestionsBySection: (sectionOrder: number) => IQuestion[];
  addQuestion: () => void;
  saveQuiz: () => void;
  saveQuizWithInvite: () => void;
  addSection: () => void;
  getSections: () => ISection[];
  setNameQuiz: (name: string) => void;
  setTitleQuiz: (title: string) => void;
  setDescriptionQuiz: (description: string) => void;
  getNameQuiz: () => string;
  getTitleQuiz: () => string;
  getDescriptionQuiz: () => string;
  getSectionsNameOrder: () => {id: string; order: number; name: string}[];
  setSectionsNewOrder: (sectionList: {id: string; order: number}[]) => void;
  setBanner: (banner: string) => void;
  getBanner: () => string;
  setLogo: (logo: string) => void;
  getLogo: () => string;
  setQuizColor: (color: string) => void;
  getQuizColor: () => string;
  getEstablishments: () => number[];
  setEstablishments: (estabs: number[]) => void;
  getFinalMessage: () => string;
  setFinalMessage: (msg: string) => void;
  getQuestionaryTypeId: () => string;
  setQuestionaryTypeId: (id: string) => void;
  isLoading: boolean;
  hasInviteSent: boolean;
  quizSectionPreview: IQuizSections;
  previewQuiz: () => void;
  questionsToPreview: IStudentQuestion[] | undefined;
  setQuestionsToPreview: (questions: IStudentQuestion[] | undefined) => void;
  currentSectionPreviewIndex: number;
  setCurrentSectionPreviewIndex: (index: number) => void;
  setIsEditingQuiz: (isEditing: boolean) => void;
  isEditingQuiz: boolean;
  setIsSavingQuiz: (isSavingQuiz: boolean) => void;
  isSavingQuiz: boolean;
  enableQuiz: () => void;
  getQuizActive: () => boolean;
  setErpIntegracao: (erpIntegracao: string) => void;
  erpIntegracao: string;
}

export const QuizContext = createContext<IQuizContextData>(
  {} as IQuizContextData,
);

export const QuizContextProvider: React.FC = ({children}) => {
  const vFormRef = useVFormRef();
  const [isLoading, setIsLoading] = useState(false);
  const {id = '0'} = useParams<'id'>();
  const navigate = useNavigate();
  const [sectionListPreview, setSectionListPreview] = useState<IQuizSections>(
    {} as IQuizSections,
  );
  const [questionsToPreview, setQuestionsToPreview] = useState<
    IStudentQuestion[] | undefined
  >([]);
  const [currentSectionPreviewIndex, setCurrentSectionPreviewIndex] =
    useState(0);
  const [isEditingQuiz, setIsEditingQuiz] = useState(false);
  const [isSavingQuiz, setIsSavingQuiz] = useState(false);
  const [erpIntegracao, setErpIntegracao] = useState('');

  const quiz = useRef<IQuizDetail>({
    id: id === '0' ? '' : id,
    idQuestionaryType: '',
    nameQuestionaryType: '',
    hasInviteSent: false,
    name: '',
    title: '',
    description: '',
    finalMessage: '',
    bannerBase64: '',
    bannerExtension: '',
    logoBase64: '',
    logoExtension: '',
    quizColor: '',
    establishment: [],
    sections: [],
    active: true,
  });

  const getLastSectionOrder = useCallback(() => {
    return quiz.current.sections[quiz.current.sections.length - 1]?.order || 0;
  }, []);

  const getLastQuestionOrder = useCallback((sectionOrder: number) => {
    const section = quiz.current.sections[sectionOrder - 1];

    return section?.questions[section.questions.length - 1]?.order || 0;
  }, []);

  useEffect(() => {
    if (id !== '0') {
      setIsLoading(true);
      QuizService.getById(id).then((result) => {
        if (result.success) {
          quiz.current = result.data;

          QuizService.getBannerById(id).then((result) => {
            if (result.success) {
              quiz.current.bannerBase64 = result.data.bannerBase64;
              quiz.current.bannerExtension = result.data.bannerExtension;
            }
          });

          QuizService.getLogoById(id).then((result) => {
            if (result.success) {
              quiz.current.logoBase64 = result.data.logoBase64;
              quiz.current.logoExtension = result.data.logoExtension;
            }
          });

          setIsEditingQuiz(false);
          setIsLoading(false);
        } else {
          feedback(result.message || 'Erro ao carregar os dados...', 'error');
          setIsLoading(false);
        }
      });
    }
    setIsEditingQuiz(false);
  }, [id, setIsEditingQuiz]);

  const enableQuiz = useCallback(() => {
    setIsLoading(true);
    QuizService.changeQuizStatus(id, true)
      .then((response) => {
        if (response.success) {
          quiz.current.active = true;

          feedback(
            response.message || 'O questionário foi arquivado.',
            'success',
          );
        } else {
          feedback(
            response.message || 'Erro ao arquivar questionário.',
            'error',
          );
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  const getQuiz = useCallback(() => {
    return quiz.current;
  }, []);

  const getQuizActive = useCallback(() => {
    return quiz.current.active;
  }, []);

  const getQuizColor = useCallback(() => {
    return quiz.current.quizColor;
  }, []);

  const setQuizColor = useCallback((color: string) => {
    quiz.current.quizColor = color;
    setIsEditingQuiz(true);
  }, []);

  const setNameQuiz = useCallback((name: string) => {
    quiz.current.name = name;
    quizEmitChange();
  }, []);

  const getNameQuiz = useCallback(() => {
    return quiz.current.name;
  }, []);

  const setTitleQuiz = useCallback((title: string) => {
    quiz.current.title = title;
    quizEmitChange();
  }, []);

  const getTitleQuiz = useCallback(() => {
    return quiz.current.title;
  }, []);

  const setDescriptionQuiz = useCallback((description: string) => {
    quiz.current.description = description;
    quizEmitChange();
  }, []);

  const getDescriptionQuiz = useCallback(() => {
    return quiz.current.description;
  }, []);

  const getQuestionsBySection = useCallback((order: number) => {
    return quiz.current.sections[order].questions.sort((a, b) =>
      a.order > b.order ? 1 : -1,
    );
  }, []);

  const setBanner = useCallback((banner: string) => {
    quiz.current.bannerBase64 = banner.substring(22);
    quiz.current.bannerExtension = banner.substring(11, 14);
    setIsEditingQuiz(true);
  }, []);

  const getBanner = useCallback(() => {
    if (quiz.current.bannerExtension && quiz.current.bannerBase64) {
      return `data:image/${quiz.current.bannerExtension};base64,${quiz.current.bannerBase64}`;
    }

    return '';
  }, []);

  const setLogo = useCallback((logo: string) => {
    quiz.current.logoBase64 = logo.substring(22);
    quiz.current.logoExtension = logo.substring(11, 14);
    setIsEditingQuiz(true);
  }, []);

  const getLogo = useCallback(() => {
    if (quiz.current.logoExtension && quiz.current.logoBase64) {
      return `data:image/${quiz.current.logoExtension};base64,${quiz.current.logoBase64}`;
    }

    return '';
  }, []);

  const getEstablishments = useCallback(() => {
    return quiz.current.establishment;
  }, []);

  const setEstablishments = useCallback((estabs: number[]) => {
    quiz.current.establishment = estabs;
    quizEmitChange();
  }, []);

  const getFinalMessage = useCallback(() => {
    return quiz.current.finalMessage;
  }, []);

  const setFinalMessage = useCallback((msg: string) => {
    quiz.current.finalMessage = msg;
    quizEmitChange();
  }, []);

  const getQuestionaryTypeId = useCallback(() => {
    return quiz.current.idQuestionaryType;
  }, []);

  const setQuestionaryTypeId = useCallback((id: string) => {
    quiz.current.idQuestionaryType = id;
  }, []);

  const addSection = useCallback(() => {
    const newId = `newSection__${uuid()}`;
    quiz.current.sections.push({
      id: newId,
      idQuiz: id,
      order: getLastSectionOrder() + 1,
      name: '',
      description: '',
      multiplyCourse: false,
      multiplyDiscipline: false,
      isSelected: true,
      paperGroups: [],
      questions: [],
      evaluationIntent: '1',
    });

    sectionsEmitChange();
    setIsEditingQuiz(true);

    setTimeout(() => {
      const sectionToNavigate = document.getElementById(newId);
      if (sectionToNavigate) {
        sectionToNavigate.scrollIntoView();
      }
    }, 50);
  }, [getLastSectionOrder, id]);

  const addQuestion = useCallback(() => {
    if (quiz.current.sections.length === 0) {
      addSection();
    }
    const selectedSection = quiz.current.sections.filter(
      (section) => section.isSelected === true,
    )[0];

    if (selectedSection) {
      quiz.current.sections[selectedSection.order - 1].questions.push({
        id: `newQuestion__${uuid()}`,
        isRequired: false,
        order: getLastQuestionOrder(selectedSection.order) + 1,
        name: '',
        description: '',
        type: 'simple-text',
        hasValidation: false,
        hasComment: false,
        hasShuffleAnswer: false,
        hasWeight: false,
        isSelected: true,
        validation: {
          typeOfValidation: 'selecione',
          operator: 'selecione',
          customErrorMessage: '',
          value: '',
        },
        columnOptions: [],
        rowOptions: [],
      });
      quiz.current.sections[selectedSection.order - 1].isSelected = true;
    } else {
      quiz.current.sections[quiz.current.sections.length - 1].isSelected = true;

      const selectedSection = quiz.current.sections.filter(
        (section) => section.isSelected === true,
      )[0];

      quiz.current.sections[selectedSection.order - 1].questions.push({
        id: `newQuestion__${uuid()}`,
        isRequired: false,
        order: getLastQuestionOrder(selectedSection.order) + 1,
        name: '',
        description: '',
        type: 'simple-text',
        hasValidation: false,
        hasComment: false,
        hasShuffleAnswer: false,
        hasWeight: false,
        isSelected: false,
        validation: {
          typeOfValidation: 'selecione',
          operator: 'selecione',
          customErrorMessage: '',
          value: '',
        },
        columnOptions: [],
        rowOptions: [],
      });

      quiz.current.sections[quiz.current.sections.length - 1].isSelected = true;
    }

    sectionsEmitChange();
    questionEmitChange();
    setIsEditingQuiz(true);
  }, [addSection, getLastQuestionOrder]);

  const saveQuiz = useCallback(() => {
    setIsSavingQuiz(true);
    feedback('Salvando questionário...', 'loading');

    quiz.current.sections.forEach((section) => {
      if (section.id.includes('newSection__')) section.id = '';
      section.questions.forEach((question) => {
        if (question.id.includes('newQuestion__')) question.id = '';
        question.columnOptions.forEach((columnOptions) => {
          if (columnOptions.id.includes('newOption__')) columnOptions.id = '';
        });
        question.rowOptions?.forEach((rowOptions) => {
          if (rowOptions.id.includes('newOption__')) rowOptions.id = '';
        });
      });
    });

    vFormRef.current?.setErrors({});

    formValidationSchema
      .validate(quiz.current, {abortEarly: false})
      .then((validatedData) => {
        if (quiz.current.id === '') {
          QuizService.create(validatedData as IQuizDetail).then((response) => {
            if (response) {
              setIsSavingQuiz(false);
              feedback(
                response.message,
                response.success ? 'success' : 'error',
              );
              if (response.success) {
                navigate(`/questionarios/detalhe/${response.data.Id}`);
              }
            }
          });
        } else {
          QuizService.updateById(validatedData as IQuizDetail).then(
            (response) => {
              if (response) {
                setIsSavingQuiz(false);
                feedback(
                  response.message,
                  response.success ? 'success' : 'error',
                );
                if (response.success) {
                  navigate(`/questionarios/detalhe/${response.data.Id}`);
                }
              }
            },
          );
        }
      })
      .catch((error) => {
        const validationErrors: IVFormErros = {};

        error.inner.forEach((error: any) => {
          if (!error.path) return;

          validationErrors[error.path] = error.message;
        });
        vFormRef.current?.setErrors(validationErrors);

        feedback(error.errors[0], 'error');
        setIsSavingQuiz(false);
        setIsEditingQuiz(true);
      });
    setIsEditingQuiz(false);
  }, [navigate, vFormRef]);

  const saveQuizWithInvite = useCallback(() => {
    setIsSavingQuiz(true);
    feedback('Atualizando questionário...', 'loading');

    quiz.current.sections.forEach((section) => {
      if (section.id.includes('newSection__')) section.id = '';
      section.questions.forEach((question) => {
        if (question.id.includes('newQuestion__')) question.id = '';
        question.columnOptions.forEach((columnOptions) => {
          if (columnOptions.id.includes('newOption__')) columnOptions.id = '';
        });
        question.rowOptions?.forEach((rowOptions) => {
          if (rowOptions.id.includes('newOption__')) rowOptions.id = '';
        });
      });
    });

    vFormRef.current?.setErrors({});

    formValidationSchema
      .validate(quiz.current, {abortEarly: false})
      .then((validatedData) => {
        QuizService.updateWithIvniteById(validatedData as IQuizDetail).then(
          (response) => {
            if (response.success) {
              setIsSavingQuiz(false);
              feedback(
                response.message || 'Questionário atualizado com sucesso.',
                'success',
              );
              navigate(`/questionarios/detalhe/${response.data.Id}`);
            } else {
              feedback(
                response.message || 'Erro ao atualizar o questionário.',
                'error',
              );
            }
          },
        );
      })
      .catch((error) => {
        const validationErrors: IVFormErros = {};

        error.inner.forEach((error: any) => {
          if (!error.path) return;

          validationErrors[error.path] = error.message;
        });
        vFormRef.current?.setErrors(validationErrors);

        feedback(error.errors[0], 'error');
        setIsSavingQuiz(false);
      });
    setIsEditingQuiz(false);
  }, [navigate, vFormRef]);

  const getSections = useCallback(() => {
    return quiz.current.sections.sort((a, b) => (a.order > b.order ? 1 : -1));
  }, []);

  const getSectionsNameOrder = useCallback(
    () =>
      quiz.current.sections.map((section) => ({
        id: section.id,
        order: section.order,
        name: section.name,
      })),

    [],
  );

  const setSectionsNewOrder = useCallback(
    (sectionList: {id: string; order: number}[]) => {
      quiz.current.sections.forEach((section: ISection) => {
        section.order =
          sectionList.find(
            (sectionListItem) => sectionListItem.id === section.id,
          )?.order || section.order;
      });

      quiz.current.sections = quiz.current.sections.sort((a, b) =>
        a.order > b.order ? 1 : -1,
      );

      sectionsEmitChange();
    },
    [],
  );

  const previewQuiz = useCallback(() => {
    quiz.current.sections.forEach((section) => {
      if (section.id.includes('newSection__')) section.id = '';
      section.questions.forEach((question) => {
        if (question.id.includes('newQuestion__')) question.id = '';
        question.columnOptions.forEach((columnOptions) => {
          if (columnOptions.id.includes('newOption__')) columnOptions.id = '';
        });
        question.rowOptions?.forEach((rowOptions) => {
          if (rowOptions.id.includes('newOption__')) rowOptions.id = '';
        });
      });
    });

    if (quiz.current.id === '') {
      confirm(
        'Para a visualização prévia ser exibida, é necessário salvar o questionário. Deseja salvar este questionário?',
        'confirmation',
        saveQuiz,
      );
    } else {
      vFormRef.current?.setErrors({});

      formValidationSchema
        .validate(quiz.current, {abortEarly: false})
        .then((validatedData) => {
          const previewSectionObject: IQuizSections = {
            ehUsuarioAnonimo: true,
            permiteAnonimo: true,
            banner: undefined,
            idPessoaConvite: '',
            visualizouModalRespostasAnonimas: true,
            descricaoConvite: quiz.current.description,
            tituloQuestionario: quiz.current.title,
            numeroTotalQuestoesQuestionario: quiz.current.sections
              .map((sec) => sec.questions.length)
              .reduce((partialSum, item) => partialSum + item, 0),
            secoes: quiz.current.sections.map((quizCurrentSec) => ({
              descricao: quizCurrentSec.description || '',
              id: quizCurrentSec.id || '',
              nomeSecao: quizCurrentSec.name,
              ordem: quizCurrentSec.order,
              numeroQuestoesRespondidas: 0,
              numeroTotalQuestoes: quizCurrentSec.questions.length,
              questoes: quizCurrentSec.questions.map(
                (currentQuestion) =>
                  ({
                    idQuestao: currentQuestion.id || '',
                    idResposta: '',
                    enunciado: currentQuestion.name,
                    descricao: currentQuestion.description,
                    permiteComentario: true,
                    respondido: false,
                    tipoQuestao: currentQuestion.type,
                    validacao: '',
                    operador: '',
                    ordem: currentQuestion.order,
                    obrigatoria: currentQuestion.isRequired,
                    campoValidacao: '',
                    campoAuxValidacao: '',
                    mensagemErro: '',
                    embaralhaRespostas: false,
                    iniciaNoZero: false,
                    validarRespostas: '',
                    usarPeso: false,
                    respostaTexto: '',
                    comentario: 'Aqui o comentário',
                    opcoesRespostaLinha: currentQuestion.rowOptions?.map(
                      (row) => ({
                        id: row.id || '',
                        descricao: row.description || '',
                        tipoIcone: row.iconOrColor || '',
                        iconeCor: row.iconOrColor || '',
                        selecionado: false,
                        opcoesRespostaColuna: currentQuestion.columnOptions.map(
                          (col) => ({
                            id: col.id || '',
                            descricao: col.description || '',
                            tipoIcone: col.iconOrColor || '',
                            iconeCor: col.iconOrColor || '',
                            selecionado: false,
                          }),
                        ),
                      }),
                    ),
                  } as IStudentQuestion),
              ),
            })),
          };

          setSectionListPreview(previewSectionObject);

          setTimeout(() => {
            navigate('/questionario-preview');
          }, 100);
        })
        .catch((error) => {
          const validationErrors: IVFormErros = {};

          error.inner.forEach((error: any) => {
            if (!error.path) return;

            validationErrors[error.path] = error.message;
          });
          vFormRef.current?.setErrors(validationErrors);

          feedback(error.errors[0], 'error');
        });
    }
  }, [vFormRef, navigate, saveQuiz]);

  return (
    <VForm ref={vFormRef} onSubmit={() => undefined}>
      <QuizContext.Provider
        value={{
          getQuiz,
          getQuestionsBySection,
          addQuestion,
          saveQuiz,
          saveQuizWithInvite,
          addSection,
          getSections,
          setNameQuiz,
          setTitleQuiz,
          setDescriptionQuiz,
          getNameQuiz,
          getTitleQuiz,
          getDescriptionQuiz,
          getSectionsNameOrder,
          setSectionsNewOrder,
          setBanner,
          getBanner,
          setLogo,
          getLogo,
          setQuizColor,
          getQuizColor,
          getEstablishments,
          setEstablishments,
          getFinalMessage,
          setFinalMessage,
          getQuestionaryTypeId,
          setQuestionaryTypeId,
          isLoading,
          hasInviteSent: quiz.current.hasInviteSent,
          quizSectionPreview: sectionListPreview,
          previewQuiz,
          questionsToPreview,
          setQuestionsToPreview,
          currentSectionPreviewIndex,
          setCurrentSectionPreviewIndex,
          isEditingQuiz: isEditingQuiz,
          setIsEditingQuiz: setIsEditingQuiz,
          isSavingQuiz,
          setIsSavingQuiz,
          enableQuiz,
          getQuizActive,
          erpIntegracao,
          setErpIntegracao,
        }}>
        <PapersContextProvider>{children}</PapersContextProvider>
      </QuizContext.Provider>
    </VForm>
  );
};
