import React, {useCallback, useEffect, useState} from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from '@mui/material';

import {
    ConfirmationDialogService,
    ConfirmationDialogTypes,
} from '../../services';

interface IDialogComponentState {
    /** "warning" | "delete" | "confirmation" */
    type: ConfirmationDialogTypes;
    onConfirm?: Function;
    onCancel?: Function;
    isOpen: boolean;
    message: string;
}

export const ConfirmationDialogComponent: React.FC = () => {
    const [confirmation, setConfirmation] = useState<IDialogComponentState>({
        type: undefined,
        isOpen: false,
        message: '',
    });

    const showConfirmation = useCallback(
        (
            message: string,
            type: ConfirmationDialogTypes,
            onConfirm?: Function,
            onCancel?: Function,
        ) => {
            setConfirmation({message, type, isOpen: true, onConfirm, onCancel});
        },
        [],
    );

    useEffect(() => {
        const subscription = ConfirmationDialogService.subscribe((msg) =>
            showConfirmation(
                msg.message,
                msg.type,
                msg.onConfirm,
                msg.onCancel,
            ),
        );
        return () => {
            subscription.unsubscribe();
            setConfirmation({message: '', type: undefined, isOpen: false});
        };
    }, [showConfirmation]);

    const handleCloseConfirmation = useCallback(() => {
        setConfirmation({
            ...confirmation,
            isOpen: false,
        });

        if (confirmation.onCancel) {
            confirmation.onCancel();
        }
    }, [setConfirmation, confirmation]);

    const handleConfirmation = useCallback(() => {
        setConfirmation({
            ...confirmation,
            isOpen: false,
        });

        confirmation.onConfirm && confirmation.onConfirm();
    }, [confirmation]);

    return (
        <Dialog open={confirmation.isOpen} onClose={handleCloseConfirmation}>
            <DialogTitle>
                {confirmation.type === 'confirmation'
                    ? 'Tem certeza?'
                    : confirmation.type === 'delete'
                    ? 'Excluir'
                    : 'Aviso'}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>{confirmation.message}</DialogContentText>
            </DialogContent>
            <DialogActions style={{minWidth: 300}}>
                <Button onClick={handleCloseConfirmation} color="primary">
                    Cancelar
                </Button>
                <Button onClick={handleConfirmation} color="primary" autoFocus>
                    Confirmar
                </Button>
            </DialogActions>
        </Dialog>
    );
};
