import React, {useState, useMemo, useCallback, useEffect} from 'react';
import {
  Box,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputAdornment,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  Paper,
  Typography,
  Icon,
  TextField,
} from '@mui/material';
import {useDebounce} from 'shared/hooks';
import {Search} from '@mui/icons-material';
import {PersonInfo} from '../PersonInfo';
import {
  IGroupedByPropertyType,
  IQuestionDetail,
  IQuestionODataType,
} from 'shared/services/api/reports/AnswerSummary';

interface IGridUniqueMultipleQuestionContent {
  content: IGroupedByPropertyType;
  configs: IQuestionDetail[];
}

export const GridUniqueMultipleAnswer: React.FC<
  IGridUniqueMultipleQuestionContent
> = ({content, configs}) => {
  const [tableContent, setTableContent] =
    useState<IGroupedByPropertyType>(content);
  const searchContent = useMemo<IGroupedByPropertyType>(() => {
    return content;
  }, [content]);
  const [searchText, setSearchText] = useState('');
  const [showComments, setShowComments] = useState(true);
  const {debounce} = useDebounce();
  const [commentsByClass, setCommentsByClass] = useState<IQuestionODataType[]>(
    [],
  );
  const [groupedGridChoice, setGroupedGridChoice] = useState<any>();

  const linhas = useMemo(() => {
    if (configs[0].linha.length > 0) {
      return configs[0].linha;
    }

    return [];
  }, [configs]);

  const removeAccentuation = useCallback((value: string) => {
    const resultFilter = value
      .toLocaleLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

    return resultFilter;
  }, []);

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setSearchText(e.target.value);

      debounce(() => {
        const valor = removeAccentuation(e.target.value);

        const resultedKey = Object.keys(searchContent).filter(
          (key) =>
            searchContent[key][0].nomePessoa &&
            removeAccentuation(searchContent[key][0].nomePessoa).indexOf(
              valor,
            ) > -1,
        );

        const contentToPush: any = {};
        if (e.target.value === '') {
          resultedKey.forEach((key) => {
            contentToPush[key] = searchContent[key];
          });
          setTableContent({...searchContent, ...contentToPush});
        } else {
          resultedKey.forEach((key) => {
            contentToPush[key] = searchContent[key];
          });
          setTableContent({...contentToPush});
        }
      });
    },
    [debounce, removeAccentuation, searchContent],
  );

  const isEmpty = (obj: any) => {
    return Object.keys(obj).length === 0;
  };

  useEffect(() => {
    var groupedClassAndCode: any = {};
    Object.keys(content).forEach((contentKey) => {
      var result = content[contentKey].reduce(function (
        prev: any,
        current: any,
      ) {
        (prev[current.idTurma || current.nomeDisciplina] = prev[current.idTurma || current.nomeDisciplina] || []).push(current);
        return prev;
      },
      {});
      groupedClassAndCode[contentKey] = result;
    });
    setGroupedGridChoice(groupedClassAndCode);

    var arrayOfGroupedByCodeAndClass: any = [];
    Object.keys(content).forEach((contentKey, k) => {
      var helper: IGroupedByPropertyType = {};
      var result = content[contentKey].reduce(function (r: any, o: any) {
        var key = o.codigoUsuario + '-' + o.idTurma || o.nomeDisciplina;

        if (!helper[key]) {
          helper[key] = Object.assign({}, o);
          r.push(helper[key]);
        }

        return r;
      }, []);
      arrayOfGroupedByCodeAndClass.push(...result);
    });
    setCommentsByClass(arrayOfGroupedByCodeAndClass);
  }, [content]);

  return (
    <Box width={'100%'}>
      <Box width={'100%'} maxWidth={'1123px'} margin={'0 auto'} p={3}>
        <Box width={'100%'} marginBottom={3}>
          <Box
            width={'100%'}
            display={'flex'}
            justifyContent={'flex-end'}
            alignItems={'center'}
            gap={20}>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={false}
                  color="primary"
                  checked={showComments}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setShowComments(e.target.checked);
                  }}
                />
              }
              label={<strong>Mostrar comentários</strong>}
            />
            <FormControl variant="outlined">
              <Paper>
                <TextField
                  style={{padding: 0, margin: 0}}
                  fullWidth
                  variant="outlined"
                  placeholder="Pesquisar participantes"
                  value={searchText}
                  onChange={handleSearch}
                  InputProps={{
                    style: {
                      height: 40,
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search color="disabled" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Paper>
            </FormControl>
          </Box>
        </Box>
        <Box>
          {Object.keys(tableContent).map((key, g) => (
            <Box width={'100%'} marginBottom={5}>
              <Box
                width={'100%'}
                key={`grids-unique-multiple-person-answer-${
                  tableContent[key][0].id
                }-${Math.random() * g}`}>
                <PersonInfo
                  curso={tableContent[key][0].curso}
                  grupoPapel={tableContent[key][0].papel}
                  identifier={tableContent[key][0].id}
                  matricula={tableContent[key][0].matricula}
                  nomePessoa={tableContent[key][0].nomePessoa}
                />
                <Box width={'100%'} p={'0 0 0 64px'} marginTop={1}>
                  <Box marginBottom={2} />
                  {tableContent[key][0].multiplicarDisciplina &&
                  !isEmpty(content) ? (
                    commentsByClass
                      .filter((f) => {
                        return (
                          f.codigoUsuario === tableContent[key][0].codigoUsuario
                        );
                      })
                      .map((filteredComments) => (
                        <>
                          <Box marginBottom={3} />
                          {filteredComments.nomeTurma && (
                            <Typography variant={'h5'} color={'textSecondary'}>
                              Turma: {filteredComments.nomeTurma}
                            </Typography>
                          )}
                          <Typography variant={'h6'} color={'textSecondary'}>
                            Disciplina: {filteredComments.nomeDisciplina}
                          </Typography>
                          <Typography color={'textSecondary'}>
                            Resposta:
                          </Typography>
                          <TableContainer component={Paper}>
                            <Table>
                              <TableRow hover>
                                <TableCell component={'td'}></TableCell>
                                {linhas[0].colunas.map((col, k) => (
                                  <TableCell
                                    style={{
                                      border:
                                        '1px solid rgba(224, 224, 224, 1)',
                                    }}
                                    component={'th'}
                                    scope={'col'}
                                    width={90}
                                    key={`column-unique-multiple-answer-${
                                      col.IdOpcaoRespostaColuna
                                    }-${Math.random() * k}`}>
                                    {col.Item}
                                  </TableCell>
                                ))}
                              </TableRow>
                              {linhas.map((row, i) => (
                                <>
                                  <TableRow
                                    hover
                                    key={`row-unique-multiple-answer-${
                                      row.idOpcaoRespostaLinha
                                    }-${Math.random() * i}`}>
                                    <TableCell
                                      style={{
                                        border:
                                          '1px solid rgba(224, 224, 224, 1)',
                                      }}
                                      component={'th'}
                                      scope={'row'}
                                      width={90}>
                                      {row.item}
                                    </TableCell>
                                    {row.colunas.map((col, j) => (
                                      <TableCell
                                        style={{
                                          border:
                                            '1px solid rgba(224, 224, 224, 1)',
                                        }}
                                        key={`row-column-unique-multiple-answer-${
                                          col.IdOpcaoRespostaColuna
                                        }-${Math.random() * j}`}
                                        width={90}
                                        component={'td'}>
                                        {groupedGridChoice[key][
                                          String(filteredComments.idTurma || filteredComments.nomeDisciplina)
                                        ].some(
                                          (value: any) =>
                                            value.respostaLinha === row.item &&
                                            value.respostaColuna === col.Item,
                                        ) ? (
                                          <Box>
                                            <Icon fontSize="small">check</Icon>
                                          </Box>
                                        ) : (
                                          ''
                                        )}
                                      </TableCell>
                                    ))}
                                  </TableRow>
                                </>
                              ))}
                            </Table>
                          </TableContainer>
                          {filteredComments.comentario !== '' && showComments && (
                            <>
                              <Typography color={'textSecondary'}>
                                Comentário:
                              </Typography>
                              <Typography component={'p'}>
                                {filteredComments.comentario}
                              </Typography>
                            </>
                          )}
                        </>
                      ))
                  ) : (
                    <Box>
                      <Typography color={'textSecondary'}>Resposta:</Typography>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableRow hover>
                            <TableCell component={'td'}></TableCell>
                            {linhas[0].colunas.map((col, k) => (
                              <TableCell
                                style={{
                                  border: '1px solid rgba(224, 224, 224, 1)',
                                }}
                                component={'th'}
                                scope={'col'}
                                width={90}
                                key={`column-unique-multiple-answer-${
                                  col.IdOpcaoRespostaColuna
                                }-${Math.random() * k}`}>
                                {col.Item}
                              </TableCell>
                            ))}
                          </TableRow>
                          {linhas.map((row, i) => (
                            <>
                              <TableRow
                                hover
                                key={`row-unique-multiple-answer-${
                                  row.idOpcaoRespostaLinha
                                }-${Math.random() * i}`}>
                                <TableCell
                                  style={{
                                    border: '1px solid rgba(224, 224, 224, 1)',
                                  }}
                                  component={'th'}
                                  scope={'row'}
                                  width={90}>
                                  {row.item}
                                </TableCell>
                                {row.colunas.map((col, j) => (
                                  <TableCell
                                    style={{
                                      border:
                                        '1px solid rgba(224, 224, 224, 1)',
                                    }}
                                    key={`row-column-unique-multiple-answer-${
                                      col.IdOpcaoRespostaColuna
                                    }-${Math.random() * j}`}
                                    width={90}
                                    component={'td'}>
                                    {tableContent[key].some(
                                      (value) =>
                                        value.respostaLinha === row.item &&
                                        value.respostaColuna === col.Item,
                                    ) ? (
                                      <Box>
                                        <Icon fontSize="small">check</Icon>
                                      </Box>
                                    ) : (
                                      ''
                                    )}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </>
                          ))}
                        </Table>
                      </TableContainer>
                      {showComments &&
                        tableContent[key][0].comentario !== '' &&
                        !isEmpty(content) && (
                          <>
                            <Box marginBottom={3} />
                            <Typography color={'textSecondary'}>
                              Comentário:
                            </Typography>
                            <Typography component={'p'}>
                              {tableContent[key][0].comentario}
                            </Typography>
                          </>
                        )}
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
