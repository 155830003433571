import React, {useEffect, useMemo, useState, useCallback} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {
  Box,
  Icon,
  Grid,
  Paper,
  Button,
  Dialog,
  Divider,
  Container,
  IconButton,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
  Avatar,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import {Skeleton} from '@mui/lab';
import {NIL as NIL_UUID} from 'uuid';
import {feedback} from 'shared/services';
import {ReadMore, CentralWarning} from 'shared/components';
import {Progress} from 'pages/student-quiz/components/Progress';
import {useStyles} from 'pages/student-quiz/styles';
import {
  StudentSectionListService,
  IQuizSections,
} from 'shared/services/api/student-quiz/SectionList';
import {LogoGVdasa} from 'shared/assets/index';
import {SubTheme} from './themes/SubTheme';
import {SectionSplashScreen} from './components/section-splash-screen/SectionSplashScreen';
import {QuizService} from 'shared/services/api/quiz/Quiz';
import {useStudentQuiz} from './hooks';

export const StudentSectionList: React.FC = () => {
  const {bannerColorLogoAvailable} = useStudentQuiz();
  const {id = NIL_UUID} = useParams<'id'>();
  const navigate = useNavigate();
  const styles = useStyles();
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [sectionList, setSectionList] = useState<IQuizSections>();
  const [isUserAnonymous, setIsUserAnonymous] = useState(false);
  const [isModalAnswered, setIsModalAnswered] = useState(false);
  const [subThemeColor, setSubThemeColor] = useState('');
  const [storedBanner, setStoredBanner] = useState('');
  const [fallbackBanner, setFallbackBanner] = useState('');
  const [logo, setLogo] = useState('');

  const isMobile = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    if (bannerColorLogoAvailable[id]) {
      setStoredBanner(bannerColorLogoAvailable[id].bannerBase64 || '');
      setSubThemeColor(bannerColorLogoAvailable[id].quizColor || '018781');
    }
  }, [id, bannerColorLogoAvailable]);

  useEffect(() => {
    if (id) {
      StudentSectionListService.getById(id).then((data) => {
        if (data.success) {
          setSectionList(data.data);
          setIsUserAnonymous(data.data.ehUsuarioAnonimo);
          setIsModalAnswered(data.data.visualizouModalRespostasAnonimas);
          setSubThemeColor(data.data.corQuestionario || '018781');
          setIsLoading(false);

          QuizService.getBannerById(data.data.idQuestionario as string).then(
            (result) => {
              if (result.success) {
                setFallbackBanner(
                  `data:image/${result.data.bannerExtension};base64,${result.data.bannerBase64}`,
                );
              } else {
                setFallbackBanner('');
              }
            },
          );

          QuizService.getLogoById(data.data.idQuestionario as string).then(
            (result) => {
              if (result.success) {
                setLogo(
                  `data:image/${result.data.logoExtension};base64,${result.data.logoBase64}`,
                );
              } else {
                setLogo('');
              }
            },
          );
        } else {
          feedback(data.message, 'error');
          setIsLoading(false);
        }
      });
    }
  }, [id, bannerColorLogoAvailable]);

  const allowAnonymous = useMemo(() => {
    if (sectionList) {
      return sectionList.permiteAnonimo;
    }
    return false;
  }, [sectionList]);

  const isAllAnswered = useMemo(() => {
    if (sectionList) {
      return sectionList.secoes.every(
        (secao) =>
          secao.numeroQuestoesRespondidas === secao.numeroTotalQuestoes,
      );
    }
    return false;
  }, [sectionList]);

  const save = useCallback(
    (isUserAnonymous: boolean) => {
      StudentSectionListService.putById(id, isUserAnonymous).then(
        (response) => {
          if (response.success) {
            setIsUserAnonymous(isUserAnonymous);
            setIsModalAnswered(true);
          } else {
            feedback(response.message, 'error');
          }
        },
      );
    },
    [id],
  );

  return (
    <SubTheme color={subThemeColor}>
      <Box className={styles.root}>
        {isLoading ? (
          <Skeleton variant="rectangular" width="100%" height={144} />
        ) : storedBanner || fallbackBanner ? (
          <Box position="relative">
            {logo && (
              <Box position="absolute" bottom={10} left={10}>
                <Avatar
                  alt="Logo"
                  src={logo}
                  style={{
                    backgroundColor: theme.palette.background.paper,
                    border: `1px solid ${theme.palette.background.paper}`,
                    width: theme.spacing(12.5),
                    height: theme.spacing(12.5),
                  }}>
                  G
                </Avatar>
              </Box>
            )}
            <Box
              className={styles.banner}
              style={{
                backgroundColor: `#${subThemeColor}`,
                backgroundImage: `url(${storedBanner || fallbackBanner})`,
              }}
            />
          </Box>
        ) : (
          <Box
            className={styles.banner}
            style={{backgroundColor: `#${subThemeColor}`}}
            display="flex"
            alignItems="center"
            position="relative">
            {logo ? (
              <Box position="absolute" bottom={10} left={10}>
                <Avatar
                  alt="Logo"
                  src={logo}
                  style={{
                    border: `1px solid ${theme.palette.background.paper}`,
                    width: theme.spacing(12.5),
                    height: theme.spacing(12.5),
                  }}>
                  G
                </Avatar>
              </Box>
            ) : (
              <Container>
                <img src={LogoGVdasa.default} alt="Logo GVdasa" />
              </Container>
            )}
          </Box>
        )}
        <Box marginTop={7} marginBottom={3} flex={'1 0 auto'}>
          <Container>
            {isLoading ? (
              <>
                {bannerColorLogoAvailable[id] ? (
                  <SectionSplashScreen
                    color={
                      bannerColorLogoAvailable[id].quizColor || subThemeColor
                    }
                  />
                ) : (
                  <SectionSplashScreen
                    color={localStorage.getItem('corQuestionario') || ''}
                  />
                )}
              </>
            ) : (
              <Box marginTop={2}>
                <Grid container spacing={1} justifyContent="flex-start">
                  <Grid item xs={12}>
                    {sectionList ? (
                      <Box width="100%" display="flex" flexDirection="column">
                        <ReadMore
                          cutAt={isMobile ? 20 : 60}
                          text={sectionList.tituloQuestionario}
                          color="primary"
                          variant="h5"
                          style={{fontWeight: 900, wordBreak: 'break-all'}}
                        />
                        <ReadMore
                          cutAt={150}
                          text={sectionList.descricaoQuestionario || ''}
                          color="primary"
                          variant="h6"
                          style={{wordBreak: 'break-all', fontWeight: 500}}
                          gutterBottom
                        />
                        <Box marginBottom={2} />

                        <ReadMore
                          cutAt={150}
                          text={sectionList.descricaoConvite}
                          color="primary"
                          variant="h6"
                          style={{wordBreak: 'break-all', fontWeight: 400}}
                          gutterBottom
                        />

                        <Box marginBottom={2} />
                        <Typography variant="h6" style={{fontWeight: 400}}>
                          {`${sectionList.numeroTotalQuestoesQuestionario} perguntas totais`}
                        </Typography>
                      </Box>
                    ) : (
                      <>
                        <CentralWarning
                          variant={'warning'}
                          children={'Nenhuma seção disponível para responder.'}
                        />
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      width="100%"
                      marginTop={1}
                      display="flex"
                      flexDirection="column"
                      gap={6}>
                      {sectionList?.secoes.map((secao) => {
                        return (
                          <Box
                            key={secao.id}
                            display="flex"
                            flexDirection="column">
                            <Box>
                              <ReadMore
                                cutAt={90}
                                text={secao.nomeSecao}
                                variant="h6"
                                style={{
                                  wordBreak: 'break-all',
                                  fontWeight: 700,
                                }}
                              />
                              <ReadMore
                                cutAt={30}
                                text={secao.descricao}
                                variant="h6"
                                color="textSecondary"
                                style={{
                                  wordBreak: 'break-all',
                                  fontWeight: 400,
                                }}
                              />
                              <Box marginBottom={2} />
                            </Box>
                            <Grid
                              container
                              spacing={3}
                              justifyContent="flex-start">
                              <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                                <Box margin={'12px 0px 16px 0'}>
                                  <Progress
                                    count={secao.numeroTotalQuestoes}
                                    currentPosition={
                                      secao.numeroQuestoesRespondidas
                                    }
                                  />
                                </Box>
                                <Paper elevation={3}>
                                  <Button
                                    fullWidth
                                    color="primary"
                                    variant="outlined"
                                    onClick={() =>
                                      navigate(
                                        `/convite/${sectionList.idPessoaConvite}/secoes/${secao.id}/questoes`,
                                      )
                                    }>
                                    {secao.numeroQuestoesRespondidas === 0
                                      ? 'Começar'
                                      : 'Continuar'}
                                  </Button>
                                </Paper>
                              </Grid>
                            </Grid>
                          </Box>
                        );
                      })}
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box marginTop={4}>
                      <Button
                        variant="text"
                        color="primary"
                        startIcon={<Icon>arrow_back</Icon>}
                        onClick={() => navigate('/pagina-inicial')}>
                        Voltar
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Container>
        </Box>
        {allowAnonymous && (
          <>
            <Box
              className={styles.footer}
              style={{backgroundColor: `#${subThemeColor}`}}>
              <Container>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  className={styles.footerInner}>
                  <Box
                    display="flex"
                    alignItems="center"
                    gap={1}
                    className={styles.identificationStatus}>
                    <IconButton color="secondary" size="small">
                      <Icon className={styles.colorWhite}>
                        {isUserAnonymous ? 'visibility_off' : 'visibility'}
                      </Icon>
                    </IconButton>
                    <Typography className={styles.colorWhite}>
                      {isUserAnonymous
                        ? 'Você está respondendo anonimamente.'
                        : 'Você está identificado.'}
                    </Typography>
                  </Box>
                  {!isAllAnswered && (
                    <Button
                      variant="outlined"
                      className={styles.buttonAnonymous}
                      onClick={() => {
                        save(!isUserAnonymous);
                      }}>
                      {isUserAnonymous ? 'Identificar-se' : 'Tornar-me anônimo'}
                    </Button>
                  )}
                </Box>
              </Container>
            </Box>
            <Dialog
              open={!isLoading && !isModalAnswered}
              fullWidth
              maxWidth="md"
              aria-labelledby="modal-detail-title">
              <DialogTitle
                id="modal-detail-title"
                className={styles.dialogTitle}
                display={'flex'}
                justifyContent={'space-between'}>
                <Typography color="primary" variant="h6">
                  Você deseja se identificar?
                </Typography>
                <IconButton
                  aria-label="close"
                  className={styles.closeButton}
                  onClick={() => {
                    setIsModalAnswered(true);
                  }}>
                  <Icon>close</Icon>
                </IconButton>
              </DialogTitle>
              <Divider />
              <DialogContent>
                <Typography variant="subtitle1">
                  Ao concordar, você autoriza a instituição de ensino a ler suas
                  respostas individualmente. Caso permaneça como anônimo, suas
                  respostas serão analisadas em conjunto, não sendo possível sua
                  identificação.
                </Typography>
              </DialogContent>
              <DialogActions className={styles.dialogActions}>
                <Button
                  className={styles.dialogActionsButtons}
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    save(true);
                  }}>
                  Não, quero permanecer anônimo
                </Button>
                <Button
                  className={styles.dialogActionsButtons}
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    save(false);
                  }}>
                  Sim, quero me identificar
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </Box>
    </SubTheme>
  );
};
