import {Subject} from 'rxjs';

const quizSubject = new Subject();
export const quizEmitChange = () => quizSubject.next();
export const quizObservable = quizSubject.asObservable();

const sectionsSubject = new Subject();
export const sectionsEmitChange = () => sectionsSubject.next();
export const sectionsObservable = sectionsSubject.asObservable();

const questionSubject = new Subject();
export const questionEmitChange = () => questionSubject.next();
export const questionObservable = questionSubject.asObservable();
