import {
  Drawer,
  Icon,
  List,
  ListItem,
  ListItemButton,
  ListItemButtonProps,
  TextField,
} from '@mui/material';
import {Theme, styled} from '@mui/material/styles';
import {makeStyles} from '@mui/styles';

export const drawerWidth = 280;

interface IStyledDrawerProps {
  marginTop?: number | string;
  backgroundColor?: string;
  isOpen: boolean;
}

export const StyledDrawer = styled(Drawer)<IStyledDrawerProps>((props) => ({
  width: props.isOpen ? props.theme.spacing(35) : props.theme.spacing(9),
  backgroundColor: props.theme.palette.primary.main,
  marginTop: 0,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  position: 'relative',
  zIndex: '1000',
  transition: props.theme.transitions.create('width', {
    easing: props.theme.transitions.easing.sharp,
    duration: props.isOpen
      ? props.theme.transitions.duration.leavingScreen
      : props.theme.transitions.duration.enteringScreen,
  }),
  '@media print': {
    display: 'none',
  },
  [props.theme.breakpoints.down('sm')]: props.isOpen
    ? {
        width: '0',
        padding: '0',
        border: '0',
      }
    : {
        position: 'absolute',
      },

  overflowX: 'hidden',
  overflowY: 'hidden',
}));

export const StyledList = styled(List)`
  position: 'relative';
  min-height: 600;
  max-height: 1000;
  background-color: ${(props) => props.theme.palette.primary.main};
`;

export const StyledListItemSearch = styled(ListItem)<{isOpen: boolean}>`
  height: ${(props) => props.theme.spacing(11.25)};
  color: '#FFF';
  position: 'sticky';
  background-color: ${(props) => props.theme.palette.primary.main};
  z-index: ${(props) => props.theme.zIndex.drawer + 2};
  box-shadow: ${(props) => props.theme.shadows[3]};
  top: ${(props) => props.theme.spacing(7)};

  transition: ${(props) =>
    props.theme.transitions.create('width', {
      easing: props.theme.transitions.easing.sharp,
      duration: props.isOpen
        ? props.theme.transitions.duration.leavingScreen
        : props.theme.transitions.duration.enteringScreen,
    })};
  ${(props) =>
    props.isOpen
      ? {
          color: '#FFF',
          width: props.theme.spacing(35),
          position: 'sticky',
        }
      : {
          color: '#FFF',
          overflowX: 'hidden',
          width: props.theme.spacing(9),
          [props.theme.breakpoints.down('sm')]: {
            width: '0',
            padding: '0',
          },
        }}
  ${(props) => [props.theme.breakpoints.down('sm')]} {
    color: '#FFF';
    width: 0;
  }
`;

export const StyledTextField = styled(TextField)((props) => ({
  color: '#fff',
  backgroundColor: props.theme.palette.secondary.main,
  borderRadius: 8,

  '& .MuiOutlinedInput-input': {
    color: '#FFF',
  },

  '& .MuiInputLabel-root': {
    color: '#FFF',
  },
}));

export const StyledIconInputSearch = styled(Icon)(() => ({
  color: '#FFF',
  marginLeft: -6.5,
}));

export const MuiIcon = styled(Icon)(({theme}) => ({
  color: theme.palette.secondary.contrastText,
  marginLeft: theme.spacing(0.9),
}));

export const ListItemFeedback = styled(ListItemButton)<
  ListItemButtonProps & {'drawer-open': boolean}
>(({theme, 'drawer-open': drawerOpen}) => ({
  height: theme.spacing(7),
  color: theme.palette.secondary.contrastText,
  position: 'fixed',
  backgroundColor: theme.palette.primary.main,
  bottom: theme.spacing(7),
  boxShadow: theme.shadows[2],
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
  opacity: 1,
  width: drawerOpen ? drawerWidth : theme.spacing(9),
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: drawerOpen
      ? theme.transitions.duration.enteringScreen
      : theme.transitions.duration.leavingScreen,
  }),
  overflowX: drawerOpen ? undefined : 'hidden',

  [theme.breakpoints.down('sm')]: {
    width: 0,
    padding: 0,

    ...(drawerOpen && {
      width: drawerWidth,
      padding: undefined,
    }),
  },
}));

export const ListItemLogout = styled(ListItemButton)<
  ListItemButtonProps & {'drawer-open': boolean}
>(({theme, 'drawer-open': drawerOpen}) => ({
  height: theme.spacing(7),
  color: theme.palette.secondary.contrastText,
  position: 'fixed',
  backgroundColor: theme.palette.primary.main,
  bottom: 0,
  zIndex: theme.zIndex.drawer + 1,
  boxShadow: theme.shadows[6],
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
  opacity: 1,
  width: drawerOpen ? drawerWidth : theme.spacing(9),
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: drawerOpen
      ? theme.transitions.duration.enteringScreen
      : theme.transitions.duration.leavingScreen,
  }),
  overflowX: drawerOpen ? undefined : 'hidden',

  [theme.breakpoints.down('sm')]: {
    width: 0,
    padding: 0,

    ...(drawerOpen && {
      width: drawerWidth,
      padding: undefined,
    }),
  },
}));

export const useStyles = makeStyles((theme: Theme) => ({
  drawerOpen: {
    position: 'relative',
    top: 0,
    marginTop: theme.spacing(11.25),
    height: '100%',
    overflow: 'auto',
    backgroundColor: theme.palette.primary.main,
    width: theme.spacing(35),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  drawerClose: {
    position: 'relative',
    top: 0,
    marginTop: theme.spacing(11.25),
    height: '100%',
    overflow: 'hidden',
    backgroundColor: theme.palette.primary.main,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(9),
    [theme.breakpoints.down('sm')]: {
      width: '0',
      padding: '0',
      border: 0,
    },
  },
  listItem: {
    height: theme.spacing(7),
    color: '#FFF',
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  icons: {
    color: '#FFF',
    marginLeft: theme.spacing(0.9),
  },
  iconSearch: {
    marginTop: 3.5,
  },
  iconInputSearch: {
    color: '#FFF',
    marginLeft: -6.5,
  },

  inputSearch: {
    color: '#FFF',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 8,
  },
  input: {
    '&::placeholder': {
      color: '#FFF',
      opacity: 1,
    },
  },
  inputBoxSearch: {
    height: theme.spacing(11.25),
    color: '#FFF !important',
    position: 'fixed',
    backgroundColor: theme.palette.primary.main,
    zIndex: theme.zIndex.drawer + 2,
    boxShadow: theme.shadows[3],
    top: theme.spacing(8),
  },
  inputBoxSearchOpen: {
    color: '#FFF',
    width: theme.spacing(35),
    zIndex: theme.zIndex.drawer + 2,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  inputBoxSearchClose: {
    color: '#FFF',
    zIndex: theme.zIndex.drawer + 2,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(9),
    [theme.breakpoints.down('sm')]: {
      width: '0',
      padding: '0',
    },
  },
}));
