import React, {useEffect, useState, useCallback, useRef} from 'react';
import {
  Container,
  FormControl,
  Icon,
  Button,
  MenuItem,
  Paper,
  Tooltip,
  IconButton,
  TextField,
  Box,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
} from '@mui/material';
import {useTitle} from 'shared/hooks';
import {AddCircleRounded} from '@mui/icons-material';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import {
  IEstablishmentsProfiles,
  IPermissionsProfiles,
} from 'shared/services/api/permissions/PermissionsDetail';
import {useParams} from 'react-router-dom';
import {confirm} from 'shared/services';
import {
  EstablishmentService,
  IEstablishmentTree,
} from 'shared/services/api/establishment/Establishment';
import {usePermissions} from '../hooks/usePermissions';
import {QuestDetailSkeleton} from './QuestDetailSkeleton';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import {TreeView, TreeItem} from '@mui/x-tree-view';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const PermissionsDetailQuest: React.FC = () => {
  const {permissionsProfiles, establishmentProfiles, setEstablishmentProfiles} =
    usePermissions();
  const {setTitle} = useTitle();
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [establishmentsTree, setEstablishmentsTree] = useState<
    IEstablishmentTree[] | null
  >(null);
  const [permissionsProfilesList, setPermissionsProfilesList] = useState<
    IPermissionsProfiles[]
  >([]);
  const {id = '0'} = useParams<'id'>();
  const [userEstablishmentsProfiles, setUserEstablishmentsProfiles] = useState<
    IEstablishmentsProfiles[]
  >([]);

  const [isTreeLoading, setIsTreeLoading] = useState(false);

  const [selectedEstablishments, setSelectedEstablishments] = useState<
    IEstablishmentsProfiles[]
  >([]);
  const currentProfileId = useRef<string>('');

  const handleOpenModalClick = (profileId: string) => {
    setOpen(true);
    currentProfileId.current = profileId;
  };
  const handleCloseModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    setTitle('Gerenciar permissões de usuário');
  }, [setTitle]);

  useEffect(() => {
    setSelectedEstablishments(userEstablishmentsProfiles);
  }, [userEstablishmentsProfiles]);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
      setPermissionsProfilesList(permissionsProfiles);
      setUserEstablishmentsProfiles(establishmentProfiles);
    }, 500);
  }, [isLoading, permissionsProfiles, id, establishmentProfiles]);

  const handleProfileChange = useCallback(
    (event: React.ChangeEvent<{value: any}>, index: number) => {
      let currentUserEstablishmentProfile: IEstablishmentsProfiles[] =
        userEstablishmentsProfiles;
      currentUserEstablishmentProfile[index].profileId = event.target.value;
      setUserEstablishmentsProfiles([...currentUserEstablishmentProfile]);
      setEstablishmentProfiles([...currentUserEstablishmentProfile]);
    },
    [userEstablishmentsProfiles, setEstablishmentProfiles],
  );

  const handleSaveSelectedEstablishments = () => {
    setEstablishmentProfiles(selectedEstablishments);
    handleCloseModal();
  };

  const handleTreeCheckboxChange = (id: number) => {
    const cloneArray = JSON.parse(
      JSON.stringify(selectedEstablishments),
    ) as IEstablishmentsProfiles[];

    const selectedProfile = cloneArray.filter(
      (selected) => selected.profileId === currentProfileId.current,
    )[0];

    const establishmentArray = selectedProfile?.establishmentId;
    if (selectedProfile?.establishmentId.includes(id)) {
      setSelectedEstablishments([
        ...selectedEstablishments.filter(
          (estab) => estab.profileId !== currentProfileId.current,
        ),
        {
          ...selectedProfile,
          establishmentId: establishmentArray.filter((value) => value !== id),
        },
      ]);
    } else {
      setSelectedEstablishments([
        ...selectedEstablishments.filter(
          (estab) => estab.profileId !== currentProfileId.current,
        ),
        {
          ...selectedProfile,
          establishmentId: [...selectedProfile.establishmentId, id],
        },
      ]);
    }
  };

  const handleDeletePermission = useCallback(
    (indexEstabsProfiles: number) => {
      let currentUserEstablishmentProfile: IEstablishmentsProfiles[] =
        userEstablishmentsProfiles;
      currentUserEstablishmentProfile.splice(indexEstabsProfiles, 1);
      setUserEstablishmentsProfiles([...currentUserEstablishmentProfile]);
      setEstablishmentProfiles([...currentUserEstablishmentProfile]);
    },
    [userEstablishmentsProfiles, setEstablishmentProfiles],
  );

  useEffect(() => {
    const fetchEstablishmentsTree = async () => {
      try {
        setIsTreeLoading(true);
        const treeData = await EstablishmentService.getAllTreeFormat();

        setEstablishmentsTree(treeData.data as unknown as IEstablishmentTree[]);
        setIsTreeLoading(false);
      } catch (error) {
        setIsTreeLoading(false);
      }
    };

    fetchEstablishmentsTree();
  }, []);

  const handleNewPermission = useCallback(() => {
    const newEstablishmentsProfiles: IEstablishmentsProfiles = {
      profileId: '0',
      establishmentId: [],
      active: true,
    };

    let currentUserEstablishmentProfile: IEstablishmentsProfiles[] =
      userEstablishmentsProfiles;
    currentUserEstablishmentProfile.push(newEstablishmentsProfiles);

    setUserEstablishmentsProfiles([...currentUserEstablishmentProfile]);
    setEstablishmentProfiles([...currentUserEstablishmentProfile]);
  }, [userEstablishmentsProfiles, setEstablishmentProfiles]);

  const renderTreeView = useCallback(
    (data: IEstablishmentTree[] | null) => {
      if (isTreeLoading) {
        return <p>Carregando...</p>;
      }

      if (!data || !Array.isArray(data) || data.length === 0) {
        return <p>Nenhum estabelecimento encontrado.</p>;
      }

      return (
        <TreeView
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}>
          {data.map((estab) => renderTreeViewOptions(estab))}
        </TreeView>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedEstablishments],
  );

  const renderTreeViewOptions = (estab: IEstablishmentTree) => {
    if (!estab) return null;
    return (
      <TreeItem
        key={estab.IdEstabelecimento}
        nodeId={estab.CodigoEstruturado.toString()}
        label={
          <>
            <Checkbox
              checked={selectedEstablishments
                .filter(
                  (selectedEstab) =>
                    selectedEstab.profileId === currentProfileId.current,
                )[0]
                ?.establishmentId?.includes(estab?.IdEstabelecimento)}
              onChange={() => handleTreeCheckboxChange(estab.IdEstabelecimento)}
              onClick={(event) => event.stopPropagation()}
            />

            {estab.Sigla}
          </>
        }>
        {estab.EstabelecimentosFilhos &&
          estab.EstabelecimentosFilhos.length > 0 &&
          estab.EstabelecimentosFilhos.map((estabFilho) =>
            renderTreeViewOptions(estabFilho),
          )}
      </TreeItem>
    );
  };
  return (
    <>
      <Container>
        {isLoading ? (
          <QuestDetailSkeleton />
        ) : (
          <>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Perfil
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      width={100}
                      align="center">
                      Ações
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userEstablishmentsProfiles.length > 0 ? (
                    userEstablishmentsProfiles.map((ep, i) => (
                      <TableRow
                        key={`row-profile-establishment-${ep.profileId}-${i}`}>
                        <TableCell component="th" scope="row" width={500}>
                          <FormControl
                            required
                            variant="outlined"
                            fullWidth
                            style={{maxWidth: '500px'}}>
                            <TextField
                              placeholder="Selecione"
                              label={'Perfis'}
                              id={'profile-select'}
                              key={`profile-field-${ep.profileId}`}
                              variant="outlined"
                              select
                              fullWidth
                              name={'Perfis'}
                              value={
                                userEstablishmentsProfiles[i].profileId || '0'
                              }
                              onChange={(event) => {
                                handleProfileChange(event, i);
                              }}
                              required>
                              <MenuItem key={'0'} value={'0'}>
                                <em>Selecione</em>
                              </MenuItem>
                              {permissionsProfilesList.map((profile, j) => {
                                return (
                                  <MenuItem
                                    key={profile.id}
                                    value={profile.id}
                                    disabled={
                                      userEstablishmentsProfiles.filter(
                                        (usEstabProf) =>
                                          usEstabProf.profileId === profile.id,
                                      ).length > 0
                                    }>
                                    {profile.name}
                                  </MenuItem>
                                );
                              })}
                            </TextField>
                          </FormControl>
                        </TableCell>

                        <TableCell scope="row" align="right">
                          <Box display={'flex'} justifyContent={'flex-end'}>
                            <Button
                              variant="contained"
                              startIcon={<SwapHorizIcon />}
                              color="primary"
                              size="small"
                              onClick={() => {
                                handleOpenModalClick(ep.profileId);
                              }}>
                              Vincular Estabelecimentos
                            </Button>

                            <Tooltip title={'Remover permissão'}>
                              <IconButton
                                aria-label="delete"
                                size="small"
                                onClick={() => {
                                  confirm(
                                    'Tem certeza que deseja excluir essa permissão?',
                                    'delete',
                                    () => {
                                      handleDeletePermission(i);
                                    },
                                    undefined,
                                  );
                                }}
                                disabled={false}>
                                <Icon fontSize={'small'}>delete</Icon>
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell component="th" scope="row" colSpan={3}>
                        <Typography color={'primary'}>
                          Nenhuma permissão criada para este usuário...
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <Box width={'100%'} padding={1} textAlign="right">
                <Button
                  size={'large'}
                  variant="text"
                  color="primary"
                  startIcon={<AddCircleRounded />}
                  onClick={handleNewPermission}>
                  Adicionar permissão
                </Button>
              </Box>
            </TableContainer>
          </>
        )}
      </Container>

      <Dialog
        open={open}
        onClose={handleCloseModal}
        maxWidth="md"
        aria-labelledby="modal-title">
        <DialogTitle id="modal-title">Vincular Estabelecimentos</DialogTitle>
        <DialogContent>
          <Box width={'600px'} paddingBottom={3}>
            {renderTreeView(establishmentsTree)}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            size={'medium'}
            variant="contained"
            color="primary"
            onClick={handleSaveSelectedEstablishments}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
