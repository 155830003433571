import React, {useMemo} from 'react';
import {Box, Typography} from '@mui/material';
import Quote from '@mui/icons-material/FormatQuoteRounded';
import {IQuestionDetail} from 'shared/services/api/reports/AnswerSummary';

interface TextProps {
  totalRespostas: number;
  configs: IQuestionDetail[];
}

export const ParagraphText: React.FC<TextProps> = ({
  configs,
  totalRespostas,
}) => {
  const answers = useMemo(() => {
    return configs[0].respostasTexto;
  }, [configs]);

  return (
    <Box width={'100%'}>
      {answers.length > 0 ? (
        answers.map((ans, i) => (
          <Box
            key={i}
            width={'100%'}
            display={'flex'}
            alignItems={'baseline'}
            textAlign={'left'}
            marginBottom={2}>
            <Quote fontSize='small' color='disabled' />
            <Typography style={{color: '#414141'}}>{ans}</Typography>
          </Box>
        ))
      ) : (
        <Box width={'100%'} textAlign={'left'}>
          <Typography>
            Nenhuma resposta para ser exibida...
          </Typography>
        </Box>
      )}
    </Box>
  );
};
