import React from 'react';
import {Box, Typography, Avatar, Icon} from '@mui/material';

interface IPersonInfoProps {
  nomePessoa?: string;
  grupoPapel?: string;
  curso?: string;
  matricula?: number;
  identifier: string;
}

export const PersonInfo: React.FC<IPersonInfoProps> = (props) => {
  const {nomePessoa, grupoPapel, curso, matricula, identifier} = props;

  return (
    <>
      <Box
        width={'100%'}
        display={'flex'}
        alignItems={'flex-start'}
        justifyContent={'flex-start'}
        key={identifier}>
        <Avatar>
          <Icon fontSize="large" style={{color: 'white'}}>
            person
          </Icon>
        </Avatar>
        <Box marginLeft={3}>
          <Typography variant={'h6'}>{(nomePessoa === "" || nomePessoa === null) ? 'Usuário Anônimo' : nomePessoa}</Typography>
          {grupoPapel && curso && (
            <Typography color={'textSecondary'}>
              {grupoPapel} - {curso}
            </Typography>
          )}
          {(matricula !== null && matricula !== 0) && (
            <Typography color={'textSecondary'}>
              Matrícula: {matricula}
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
};
