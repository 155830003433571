import React, {memo, useCallback, useMemo} from 'react';
import {
  Box,
  Radio,
  RadioGroup,
  TextField,
  FormControlLabel,
  Grid,
} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {makeStyles} from '@mui/styles';
import clsx from 'clsx';

import {IOptions} from 'shared/services/api/student-quiz/QuestionList';

const useStyles = makeStyles((theme: Theme) => ({
  radio: {
    margin: 0,
    borderRadius: theme.spacing(0.5),
  },
  radioChecked: {
    backgroundColor: theme.palette.grey[200],
  },
}));

interface IUniqueAnswerProps {
  options: IOptions[];
  comment: string;
  hasComment?: boolean;
  isCompleted: boolean;
  setComment: (comment: string) => void;
  onChange: (options: IOptions[]) => void;
}

export const UniqueAnswerPreview: React.FC<IUniqueAnswerProps> = memo(
  ({hasComment, options, isCompleted, comment, setComment, onChange}) => {
    const styles = useStyles();

    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const currentSelectedId = (event.target as HTMLInputElement).value;

        const lastItemSelected: number = options.findIndex(
          (option) => option.selecionado,
        );

        if (lastItemSelected !== -1)
          options[lastItemSelected].selecionado = false;

        const currentItemSelected: number = options.findIndex(
          (option) => option.id === currentSelectedId,
        );

        options[currentItemSelected].selecionado = true;

        onChange(options);
      },
      [onChange, options],
    );

    const handleComment = (e: React.ChangeEvent<HTMLInputElement>) => {
      setComment(e.target.value);
    };

    const selectedId = useMemo(() => {
      return options.find((option) => option.selecionado)?.id || '';
    }, [options]);

    return (
      <Grid item xs={12} sm={12} md={8} lg={8}>
        <RadioGroup
          style={{gap: 8, marginLeft: 10}}
          value={selectedId}
          onChange={handleChange}>
          {options.map((option, i) => {
            return (
              <FormControlLabel
                key={option.id}
                disabled={isCompleted}
                label={option.descricao}
                value={option.id}
                control={<Radio id={`unique-option-${i}`} color="primary" />}
                className={clsx(styles.radio, {
                  [styles.radioChecked]: selectedId === option.id,
                })}
              />
            );
          })}
        </RadioGroup>
        {((hasComment && !isCompleted) || (comment !== '' && isCompleted)) && (
          <Box marginTop={3} justifyContent="center" display="flex">
            <TextField
              fullWidth
              multiline
              rows={2}
              disabled={isCompleted}
              color="primary"
              label="Comentário"
              variant="outlined"
              placeholder="Adicionar um comentário"
              value={comment}
              onChange={handleComment}
            />
          </Box>
        )}
      </Grid>
    );
  },
);
