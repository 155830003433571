import React, {useEffect, useState, memo} from 'react';
import {
  Box,
  FormControl,
  Grid,
  ListItemIcon,
  OutlinedInput,
  Select,
  MenuItem,
  Typography,
} from '@mui/material';
import {DragHandle, Lens} from '@mui/icons-material';
import {IOption, TSatisfaction} from 'shared/services/api/quiz/Quiz';
import {useQuiz} from 'pages/quiz/hooks';

interface ILinearScaleOptionColumnProps {
  isEdit: boolean;
  option: IOption;
  endsWith: number;
  onChangeDescription: (index: number, text: string) => void;
  onChangeValue: (
    index: number,
    value: TSatisfaction,
    isInitialFix?: boolean,
  ) => void;
}

export const OptionColumn: React.FC<ILinearScaleOptionColumnProps> = memo(
  ({
    isEdit,
    option,
    onChangeValue,
    onChangeDescription,
  }: ILinearScaleOptionColumnProps) => {
    const {hasInviteSent} = useQuiz();

    const [satisfacion, setSatisfaction] = useState<TSatisfaction>(
      option.satisfaction || 'satisfeito',
    );
    const [isInitialFix, setIsInitialFix] = useState(true);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      if (isEdit) {
        setIsLoading(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (!isLoading) {
        onChangeValue(option.order, satisfacion, isInitialFix);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [satisfacion]);

    useEffect(() => {
      setSatisfaction(option.satisfaction || 'satisfeito');
    }, [option.satisfaction]);

    return option.order === 0 ? (
      <Grid
        container
        spacing={3}
        alignItems={'center'}
        justifyContent={'flex-start'}>
        <Grid item xs={1}></Grid>
        <Grid item xs={1}>
          <Box fontWeight="fontWeightBold">
            <Typography>{option.order}</Typography>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <FormControl variant="outlined" fullWidth>
            <OutlinedInput
              value={option.description}
              onChange={(value) =>
                onChangeDescription(option.order, value.target.value)
              }
              placeholder={`Opção ${option.order}`}
            />
          </FormControl>
        </Grid>
      </Grid>
    ) : (
      <Grid
        container
        spacing={3}
        alignItems={'center'}
        justifyContent={'flex-start'}>
        <Grid item xs={1}>
          <DragHandle />
        </Grid>
        <Grid item xs={1}>
          <Box fontWeight="fontWeightBold">
            <Typography>{option.order}</Typography>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <FormControl variant="outlined" fullWidth>
            <OutlinedInput
              value={option.description}
              placeholder={`Opção ${option.order}`}
              onChange={(value) =>
                onChangeDescription(option.order, value.target.value)
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            gap={2}>
            <Select
              displayEmpty
              variant="outlined"
              autoWidth
              disabled={hasInviteSent}
              value={satisfacion}
              style={{maxBlockSize: 55}}
              onChange={(event) => {
                if (isInitialFix) {
                  setIsInitialFix(false);
                }
                setSatisfaction(event.target.value as TSatisfaction);
              }}>
              <MenuItem value={'satisfeito'}>
                <ListItemIcon>
                  <Lens style={{color: 'green', marginRight: 5}} />
                  <Typography>Satisfeito</Typography>
                </ListItemIcon>
              </MenuItem>
              <MenuItem value={'insatisfeito'}>
                <ListItemIcon>
                  <Lens style={{color: 'red', marginRight: 5}} />
                  <Typography>Insatisfeito</Typography>
                </ListItemIcon>
              </MenuItem>
            </Select>
          </Box>
        </Grid>
      </Grid>
    );
  },
);
