import {TableRow, TableCell} from '@mui/material';
import {Skeleton} from '@mui/lab';

export const InviteTableSkeleton: React.FC = () => {
  return (
    <>
      <TableRow hover>
        <TableCell component="th" scope="row">
          <Skeleton variant="rectangular" width="auto" height={25} />
        </TableCell>
        <TableCell component="th" scope="row">
          <Skeleton variant="rectangular" width="auto" height={25} />
        </TableCell>
        <TableCell component="th" scope="row">
          <Skeleton variant="rectangular" width="auto" height={25} />
        </TableCell>
        <TableCell component="th" scope="row">
          <Skeleton variant="rectangular" width="auto" height={25} />
        </TableCell>
      </TableRow>
      <TableRow hover>
        <TableCell component="th" scope="row">
          <Skeleton variant="rectangular" width="auto" height={25} />
        </TableCell>
        <TableCell component="th" scope="row">
          <Skeleton variant="rectangular" width="auto" height={25} />
        </TableCell>
        <TableCell component="th" scope="row">
          <Skeleton variant="rectangular" width="auto" height={25} />
        </TableCell>
        <TableCell component="th" scope="row">
          <Skeleton variant="rectangular" width="auto" height={25} />
        </TableCell>
      </TableRow>
    </>
  );
};
