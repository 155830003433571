import React from 'react';

import {Box, Dialog, Grid, Paper, Typography} from '@mui/material';

import {UploadImage} from './upload-image/UploadImage';

interface IProps {
  imageId: string;
  openModal: boolean;
  onClose(): void;
  image: string;
  setImage(value: string): void;
  width: number;
  height: number;
  maxWidth?: 'md' | 'lg' | undefined;
}

export const ModalImage: React.FC<IProps> = ({
  imageId,
  openModal,
  onClose,
  image,
  setImage,
  width,
  height,
  maxWidth = 'md',
}) => {
  return (
    <Dialog open={openModal} onClose={onClose} maxWidth={maxWidth} fullWidth>
      <Grid container>
        <Grid item xs={12}>
          <Box component={Paper} p={3}>
            <Typography variant="h5" color="primary">
              <strong>Personalizar imagem</strong>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <UploadImage
            onClose={onClose}
            imagePreview={image}
            setImage={setImage}
            id={imageId}
            width={width}
            height={height}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};
