import React, {memo, useCallback, useEffect, useState, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {
  Box,
  Chip,
  Grid,
  Paper,
  Table,
  TableRow,
  Container,
  TableBody,
  TableCell,
  TableHead,
  IconButton,
  FormControl,
  InputAdornment,
  TableContainer,
  Typography,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  TextField,
  Theme,
  CircularProgress,
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {Pagination, Skeleton, ToggleButton, ToggleButtonGroup} from '@mui/lab';
import {
  Search,
  BarChartOutlined,
  FiberManualRecord,
  Close,
} from '@mui/icons-material';
import {useDebounce, useTitle} from 'shared/hooks';
import {NotFoundText} from 'shared/components';
import {feedback} from 'shared/services';
import {
  IInviteReport,
  ReportsListingService,
} from 'shared/services/api/reports/ReportsListing';
import {Environment} from 'shared/Env';
import {format} from 'date-fns';
import {QuizListSkeleton} from 'pages/quiz/QuizListSkeleton';

const useStyles = makeStyles((theme: Theme) => ({
  toggleButton: {
    '&.MuiToggleButton-root.Mui-selected': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));

export const InviteReportsListing: React.FC = memo(() => {
  const {debounce} = useDebounce();
  const {setTitle} = useTitle();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [reportData, setReportData] = useState<IInviteReport[]>([]);

  const [toggleButtonValue, setToggleButtonValue] = useState(1);

  const cssClasses = useStyles();
  const [reportDataToSearch, setReportDataToSearch] = useState<IInviteReport[]>(
    [],
  );
  const [currentInvitations, setCurrentInvitations] = useState<
    IInviteReport['convites'] | undefined
  >([]);

  useEffect(() => {
    setTitle('Relatórios');
  }, [setTitle]);

  useEffect(() => {
    setIsLoading(true);
    ReportsListingService.getAll().then((result) => {
      if (result.success) {
        setReportData(result.data);
        setReportDataToSearch(result.data);
        setIsLoading(false);
      } else {
        feedback(result.message || 'Erro ao carregar os dados...', 'error');
        setIsLoading(false);
      }
    });
  }, []);

  const getCurrentQuiz = useCallback(
    (reportId: string) => {
      const currentReport = reportData.find((report) => {
        return report.id === reportId;
      });

      return currentReport;
    },
    [reportData],
  );

  const allReportData = useMemo(() => {
    return reportData;
  }, [reportData]);

  const activeReports = useMemo(() => {
    return reportData.filter((report) => report.ativo);
  }, [reportData]);

  const inactiveReports = useMemo(() => {
    return reportData.filter((report) => !report.ativo);
  }, [reportData]);

  const getInvites = useCallback(
    (quizId: string) => {
      setIsLoading(true);
      const clickedQuiz = getCurrentQuiz(quizId);
      setCurrentInvitations(clickedQuiz?.convites);
      setIsLoading(false);
    },
    [getCurrentQuiz],
  );

  const handleToggleButtonChange = (
    event: React.MouseEvent<HTMLElement>,
    value: number,
  ) => {
    setToggleButtonValue(value);
    setPage(1);
  };

  const accessModal = useCallback(
    (id: string) => {
      getInvites(id);
      setModalOpen(true);
    },
    [getInvites],
  );

  const removeAccentuation = useCallback((value: string) => {
    const resultFilter = value
      .toLocaleLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

    return resultFilter;
  }, []);

  const handlePageChange = useCallback(
    (event: React.ChangeEvent<unknown>, value: number) => {
      setPage(value);
    },
    [],
  );

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsLoading(true);
      setSearch(e.target.value);

      debounce(() => {
        const valor = removeAccentuation(e.target.value);

        const resultFilter = reportDataToSearch?.filter(
          (item) =>
            removeAccentuation(item.descricao).indexOf(valor) > -1 ||
            removeAccentuation(item.nomeQuestionario).indexOf(valor) > -1 ||
            removeAccentuation(item.tipoQuestionario).indexOf(valor) > -1,
        );

        if (e.target.value === '') {
          setReportData(reportDataToSearch);
        } else {
          setReportData(resultFilter);
        }
        setPage(1);
        setIsLoading(false);
      });
    },
    [debounce, reportDataToSearch, removeAccentuation],
  );

  const formataSituacao = useCallback((status: boolean) => {
    switch (status) {
      case true:
        return (
          <>
            <FiberManualRecord style={{color: '#33b673'}} />
            <Typography>Ativo</Typography>
          </>
        );
      case false:
        return (
          <>
            <FiberManualRecord style={{color: '#E0E0E0'}} />
            <Typography>Inativo</Typography>
          </>
        );
    }
  }, []);

  return (
    <Container>
      <Box marginBottom={2}>
        <Typography variant={'body1'} color={'primary'}>
          <strong>
            Clique em um questionário para visualizar os convites e seus
            relatórios
          </strong>
        </Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid container item xs={12} spacing={0}>
          <Grid item xs={7}>
            <Box display={'flex'} alignItems={'center'} gap={1}>
              <FormControl variant="outlined" fullWidth>
                <Paper>
                  <TextField
                    style={{padding: 0, margin: 0}}
                    fullWidth
                    variant="outlined"
                    placeholder="Pesquise por nome, descrição ou tipo"
                    value={search}
                    onChange={handleSearch}
                    InputProps={{
                      style: {
                        height: 40,
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          {isLoading ? (
                            <CircularProgress size={30} />
                          ) : (
                            <Search color="disabled" />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Paper>
              </FormControl>

              <Tooltip title={'Filtrar questionários ativos e inativos.'}>
                <ToggleButtonGroup
                  size="small"
                  value={toggleButtonValue}
                  exclusive
                  onChange={handleToggleButtonChange}>
                  <ToggleButton
                    disabled={allReportData.length > 0 ? false : true}
                    color="primary"
                    value={0}
                    className={cssClasses.toggleButton}>
                    Todos
                  </ToggleButton>
                  <ToggleButton
                    disabled={activeReports.length > 0 ? false : true}
                    color="primary"
                    value={1}
                    className={cssClasses.toggleButton}>
                    Ativos
                  </ToggleButton>
                  <ToggleButton
                    disabled={inactiveReports.length > 0 ? false : true}
                    color="primary"
                    value={2}
                    className={cssClasses.toggleButton}>
                    Inativos
                  </ToggleButton>
                </ToggleButtonGroup>
              </Tooltip>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} marginBottom={1}>
          <TableContainer component={Paper} elevation={3}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Nome
                  </TableCell>
                  <TableCell component="th" scope="row">
                    Tipo
                  </TableCell>
                  <TableCell component="th" scope="row">
                    Descrição
                  </TableCell>
                  <TableCell component="th" scope="row">
                    Convites
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <QuizListSkeleton />
                ) : reportData &&
                  reportData.filter((report) =>
                    toggleButtonValue === 1
                      ? report.ativo
                      : toggleButtonValue === 2
                      ? !report.ativo
                      : report,
                  ).length ? (
                  reportData
                    ?.filter((report) =>
                      toggleButtonValue === 1
                        ? report.ativo
                        : toggleButtonValue === 2
                        ? !report.ativo
                        : report,
                    )
                    .slice(
                      (page - 1) * Environment.LIMITE_DE_LINHAS,
                      (page - 1) * Environment.LIMITE_DE_LINHAS +
                        Environment.LIMITE_DE_LINHAS,
                    )
                    .map((row) => (
                      <TableRow hover key={row.id} style={{cursor: 'pointer'}}>
                        <TableCell
                          style={{wordBreak: 'break-word'}}
                          onClick={() => accessModal(row.id)}
                          component="td"
                          scope="row"
                          width={'30%'}>
                          {row.nomeQuestionario.length > 60 ? (
                            <Tooltip title={row.nomeQuestionario}>
                              <>{`${row.nomeQuestionario.slice(0, 60)}...`}</>
                            </Tooltip>
                          ) : (
                            <>{row.nomeQuestionario}</>
                          )}
                        </TableCell>
                        <TableCell
                          onClick={() => accessModal(row.id)}
                          component="td"
                          scope="row"
                          width={'15%'}>
                          {row.tipoQuestionario}
                        </TableCell>
                        <TableCell
                          style={{wordBreak: 'break-word'}}
                          onClick={() => accessModal(row.id)}
                          component="td"
                          scope="row"
                          width={'16%'}>
                          {row.descricao.length > 60 ? (
                            <Tooltip title={row.descricao}>
                              <>{`${row.descricao.slice(0, 60)}...`}</>
                            </Tooltip>
                          ) : (
                            <>{row.descricao}</>
                          )}
                        </TableCell>
                        <TableCell
                          onClick={() => accessModal(row.id)}
                          component="td"
                          scope="row"
                          width={'14%'}>
                          {row.convites.length === 0 ? (
                            <p>Nenhum convite</p>
                          ) : (
                            ''
                          )}
                          {row.convites.length > 0 && (
                            <Box marginTop={0.5} marginBottom={0.5}>
                              <Chip
                                size="small"
                                color={'primary'}
                                style={{color: 'white'}}
                                label={row.convites.length + ' convites'}
                              />
                            </Box>
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell>
                      <NotFoundText />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {reportData && (
              <Box
                marginTop={1}
                marginBottom={1}
                display={'flex'}
                justifyContent={'end'}>
                <Pagination
                  page={page}
                  count={Math.ceil(
                    reportData.filter((report) =>
                      toggleButtonValue === 1
                        ? report.ativo
                        : toggleButtonValue === 2
                        ? !report.ativo
                        : report,
                    ).length / Environment.LIMITE_DE_LINHAS,
                  )}
                  onChange={handlePageChange}
                />
              </Box>
            )}
          </TableContainer>
        </Grid>
      </Grid>
      <Dialog
        fullWidth
        maxWidth="md"
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-detail">
        <DialogTitle id="modal-detail-title">
          <Box
            width={'100%'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}>
            <Typography color="primary" variant="h6">
              Visualizar convites e relatórios
            </Typography>
            <IconButton aria-label="close" onClick={() => setModalOpen(false)}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent style={{padding: '24px 24px'}}>
          <TableContainer component={Paper} elevation={3}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Título
                  </TableCell>
                  <TableCell component="th" scope="row">
                    Status
                  </TableCell>
                  <TableCell component="th" scope="row">
                    Período
                  </TableCell>
                  <TableCell component="th" scope="row">
                    Relatórios
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <>
                    <TableRow hover>
                      <TableCell component="th" scope="row" width={'40%'}>
                        <Skeleton
                          variant="rectangular"
                          width="auto"
                          height={25}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row" width={'20%'}>
                        <Skeleton
                          variant="rectangular"
                          width="auto"
                          height={25}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row" width={'20%'}>
                        <Skeleton
                          variant="rectangular"
                          width="auto"
                          height={25}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row" width={'20%'}>
                        <Skeleton
                          variant="rectangular"
                          width="auto"
                          height={25}
                        />
                      </TableCell>
                    </TableRow>
                  </>
                ) : currentInvitations && currentInvitations.length > 0 ? (
                  currentInvitations.map((invite, i) => (
                    <TableRow
                      hover
                      key={invite.id}
                      style={{cursor: 'pointer'}}
                      onClick={() =>
                        navigate(`/relatorios/detalhe/${invite.id}`)
                      }>
                      <TableCell component="th" scope="row">
                        {invite.descricao}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Box
                          display="flex"
                          justifyContent="flex-start"
                          gap={10}>
                          {formataSituacao(invite.ativo)}
                        </Box>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {`${format(
                          new Date(invite.dataHoraInicio),
                          'dd/MM/yyyy',
                        )} à ${format(
                          new Date(invite.dataHoraFim),
                          'dd/MM/yyyy',
                        )}`}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        width={45}
                        style={{
                          textAlign: 'center',
                          cursor: 'pointer',
                        }}>
                        <Tooltip title={'Acessar relatórios'}>
                          <BarChartOutlined
                            onClick={() =>
                              navigate(`/relatorios/detalhe/${invite.id}`)
                            }
                          />
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell>
                      <NotFoundText />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </Container>
  );
});
