import React, {memo} from 'react';
import {
  Grid,
  OutlinedInput,
  FormControl,
  IconButton,
  Radio,
} from '@mui/material';
import {DragHandle, Add, Remove} from '@mui/icons-material';
import {IOption} from 'shared/services/api/quiz/Quiz';
import {useQuiz} from 'pages/quiz/hooks';

interface IOptionsProps {
  option: IOption;
  quantityOfOptions: number;
  handleAddOption: (index: number) => void;
  handleRemoveOption: (index: number) => void;
  handleDescriptionChange: (index: number, text: string) => void;
}

export const Options: React.FC<IOptionsProps> = memo(
  ({
    option,
    quantityOfOptions,
    handleAddOption,
    handleRemoveOption,
    handleDescriptionChange,
  }: IOptionsProps) => {
    const {hasInviteSent} = useQuiz();

    return (
      <Grid container spacing={3} alignItems="center" alignContent="center">
        <Grid item xs={1}>
          <DragHandle />
        </Grid>
        <Grid item xs={1}>
          <Radio checked={false} disabled={true} />
        </Grid>

        <Grid item xs={7}>
          <FormControl variant="outlined" fullWidth>
            <OutlinedInput
              id={'input-' + option.order}
              value={option.description}
              placeholder={`Opção ${option.order}`}
              onChange={(value) =>
                handleDescriptionChange(option.order, value.target.value)
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <IconButton
            disabled={hasInviteSent}
            color="primary"
            onClick={() => handleAddOption(option.order)}
            edge="end">
            <Add />
          </IconButton>
          <IconButton
            color="default"
            onClick={() => handleRemoveOption(option.order)}
            edge="end"
            disabled={quantityOfOptions === 1 || hasInviteSent}>
            <Remove />
          </IconButton>
        </Grid>
      </Grid>
    );
  },
);
