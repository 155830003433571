import React, {useState} from 'react';
import {Checkbox, FormControlLabel, Grid, Tooltip, Box} from '@mui/material';

interface MultiselectCheckboxProps {
  options: {
    label: string;
    method: string;
    route: string;
    checked: boolean;
    permissionId: number;
    disabled: boolean;
  }[];
  onChange: (
    data: {permissionId: number; label: string; checked?: boolean}[], editedIndex: number
  ) => void;
}

export const MultiselectCheckbox: React.FC<MultiselectCheckboxProps> = ({
  options,
  onChange
}) => {
  const [data, setData] = useState(options);
  const [checkAll, setCheckAll] = useState(false);

  const toggle = (index: number) => {
    var newData = [...data];
    newData[index].checked = !data[index].checked;
    setData(newData);
    onChange(newData, index);
  };

  const handleSelectAll = () => {
    setCheckAll(!checkAll);
    var allSelected = [...data];

    allSelected.forEach((checkbox, i) => {
      checkbox.checked = true;
      setData(allSelected);
      onChange(allSelected, i);
    });

    if(checkAll) {
      allSelected.forEach((checkbox, i) => {
        checkbox.checked = false;
        setData(allSelected);
        onChange(allSelected, i);
      });
    }
  }

  return (
    <Box padding={'0 10px'}>
      <FormControlLabel
        control={
          <Checkbox
            color="secondary"
            checked={checkAll}
            onClick={handleSelectAll}
            disabled={false}
          />
        }
        label={"Selecionar tudo"}
      />
      <Grid container>
        {data.map((item, index) => (
          <Grid key={`checkbox-filter-${index}-${item.permissionId}`} item xs={4}>
            <Tooltip title={`${item.method} ${item.route}`} placement='bottom' arrow>
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    checked={item.checked}
                    onClick={() => toggle(index)}
                    disabled={item.disabled}
                  />
                }
                label={item.label}
              />
            </Tooltip>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
