import React, {useState, useCallback, useMemo, useEffect} from 'react';
import {
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputAdornment,
  TextField,
  Paper
} from '@mui/material';
import {
  IGroupedByPropertyType,
  IQuestionODataType,
} from 'shared/services/api/reports/AnswerSummary';
import {PersonInfo} from '../PersonInfo';
import {Search} from '@mui/icons-material';
import {useDebounce} from 'shared/hooks';

interface IDateTimeTextQuestionContent {
  content: IQuestionODataType[];
}

export const DateTimeTextAnswer: React.FC<IDateTimeTextQuestionContent> = ({
  content,
}) => {
  const [showComments, setShowComments] = useState(true);
  const [search, setSearch] = useState('');
  const {debounce} = useDebounce();
  const [contentObject, setContentObject] =
    useState<IQuestionODataType[]>(content);
  const hasToGroupData = useMemo(() => {
    return content[0]?.multiplicarDisciplina;
  }, [content]);
  const [groupedData, setGroupedData] = useState<IGroupedByPropertyType>(
    {} as IGroupedByPropertyType,
  );
  const [groupedDataToSearch, setGroupedDataToSearch] = useState<IGroupedByPropertyType>(
    {} as IGroupedByPropertyType,
  );
  const [commentsByClass, setCommentsByClass] = useState<IQuestionODataType[]>(
    [],
  );

  const contentToSearch = useMemo<IQuestionODataType[]>(() => {
    return content;
  }, [content]);

  const removeAccentuation = useCallback((value: string) => {
    const resultFilter = value
      .toLocaleLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

    return resultFilter;
  }, []);

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value);

      debounce(() => {
        const valor = removeAccentuation(e.target.value);
        const resultFilter = contentToSearch.filter(
          (item) => removeAccentuation(item.nomePessoa).indexOf(valor) > -1
        );

        setContentObject(resultFilter);

        if (e.target.value === '') {
          setContentObject(contentToSearch);
        }
      });
    },
    [debounce, contentToSearch, removeAccentuation],
  );

  const handleSearchWithKey = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value);

      debounce(() => {
        const valor = removeAccentuation(e.target.value);

        const resultedKey = Object.keys(groupedDataToSearch).filter(
          (key) =>
          groupedDataToSearch[key][0].nomePessoa &&
            removeAccentuation(groupedDataToSearch[key][0].nomePessoa).indexOf(
              valor,
            ) > -1,
        );

        const contentToPush: any = {};
        if (e.target.value === '') {
          resultedKey.forEach((key) => {
            contentToPush[key] = groupedDataToSearch[key];
          });
          setGroupedData({...groupedDataToSearch, ...contentToPush});
        } else {
          resultedKey.forEach((key) => {
            contentToPush[key] = groupedDataToSearch[key];
          });
          setGroupedData({...contentToPush});
        }
      });
    },
    [debounce, removeAccentuation, groupedDataToSearch],
  );

  useEffect(() => {
    if (hasToGroupData) {
      const groupedByCode = content.reduce(function (prev: any, current: any) {
        (prev[current.codigoUsuario] = prev[current.codigoUsuario] || []).push(
          current,
        );
        return prev;
      }, {});

      var arrayOfGroupedByCodeAndClass: any = [];
      Object.keys(groupedByCode).forEach((contentKey, k) => {
        var helper: IGroupedByPropertyType = {};
        var result = groupedByCode[contentKey].reduce(function (
          r: any,
          o: any,
        ) {
          var key = `${o.codigoUsuario}-${o.idTurma || ''}-${o.nomeDisciplina}`;

          if (!helper[key]) {
            helper[key] = Object.assign({}, o);
            r.push(helper[key]);
          }

          return r;
        },
        []);
        arrayOfGroupedByCodeAndClass.push(...result);
      });

      setCommentsByClass(arrayOfGroupedByCodeAndClass);
      setGroupedData(groupedByCode);
      setGroupedDataToSearch(groupedByCode);
    }
  }, [content, hasToGroupData]);

  const isEmpty = (obj: any) => {
    return Object.keys(obj).length === 0;
  };

  return (
    <Box width={'100%'}>
      <Box width={'100%'} maxWidth={'1123px'} margin={'0 auto'} p={3}>
        <Box width={'100%'} marginBottom={1}>
          <Box
            width={'100%'}
            display={'flex'}
            justifyContent={'flex-end'}
            alignItems={'center'}
            gap={20}>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={false}
                  color="primary"
                  checked={showComments}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setShowComments(e.target.checked);
                  }}
                />
              }
              label={<strong>Mostrar comentários</strong>}
            />
            <FormControl variant="outlined">
              <Paper>
                <TextField
                  style={{padding: 0, margin: 0}}
                  fullWidth
                  variant="outlined"
                  placeholder="Pesquisar participantes"
                  value={search}
                  onChange={hasToGroupData ? handleSearchWithKey : handleSearch}
                  InputProps={{
                    style: {
                      height: 40,
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search color="disabled" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Paper>
            </FormControl>
          </Box>
        </Box>
        {hasToGroupData
          ? !isEmpty(groupedData) && Object.keys(groupedData).map((key, k) => (
              <Box width={'100%'} marginBottom={5}>
                <Box
                  width={'100%'}
                  key={`lin-scale-multiple-unique-person-answer-comment-${
                    groupedData[key][0].id
                  }-${Math.floor(Math.random() * k)}`}>
                  <PersonInfo
                    curso={groupedData[key][0].curso}
                    grupoPapel={groupedData[key][0].papel}
                    identifier={groupedData[key][0].id}
                    matricula={groupedData[key][0].matricula}
                    nomePessoa={groupedData[key][0].nomePessoa}
                  />
                  <Box width={'100%'} p={'0 0 0 64px'}>
                    <Box marginBottom={2} />
                    {groupedData[key][0].multiplicarDisciplina &&
                    !isEmpty(content) ? (
                      commentsByClass
                        .filter((f) => {
                          return (
                            f.codigoUsuario ===
                            groupedData[key][0].codigoUsuario
                          );
                        })
                        .map((filteredComments) => (
                          <>
                            <Box marginBottom={3} />
                            {filteredComments.nomeTurma && (
                              <Typography variant={'h5'} color={'textSecondary'}>
                                Turma: {filteredComments.nomeTurma}
                              </Typography>
                            )}
                            <Typography variant={'h6'} color={'textSecondary'}>
                              Disciplina: {filteredComments.nomeDisciplina}
                            </Typography>
                            <Typography color={'textSecondary'}>
                              Resposta:
                            </Typography>
                            <Typography component={'p'}>
                              {filteredComments.respostaLinha === "" ? "Não respondido" : filteredComments.respostaLinha}
                            </Typography>
                            <Box marginBottom={2} />
                            {showComments &&
                              filteredComments.comentario !== '' && (
                                <>
                                  <Typography color={'textSecondary'}>
                                    Comentário:
                                  </Typography>
                                  <Typography component={'p'}>
                                    {filteredComments.comentario}
                                  </Typography>
                                </>
                              )}
                          </>
                        ))
                    ) : showComments &&
                      groupedData[key][0].comentario !== '' &&
                      !isEmpty(content) ? (
                      <>
                        <Box marginBottom={3} />
                        <Typography color={'textSecondary'}>
                          Comentário:
                        </Typography>
                        <Typography component={'p'}>
                          {groupedData[key][0].comentario}
                        </Typography>
                      </>
                    ) : (
                      <Box>
                        <Typography color={'primary'} variant={'h4'}>
                          Nenhum comenário para ser mostrado.
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            ))
          : contentObject.map((info, k) => (
              <Box
                width={'100%'}
                marginBottom={5}
                key={`person-answer-comment-${info.idQuestao}-${k}`}>
                <PersonInfo
                  curso={info.curso}
                  grupoPapel={info.papel}
                  identifier={info.id}
                  matricula={info.matricula}
                  nomePessoa={info.nomePessoa}
                />
                <Box marginTop={1}>
                  <Box p={'0 0 0 64px'}>
                    <Typography color={'textSecondary'}>Resposta:</Typography>
                    <Typography component={'p'}>
                      {info.respostaLinha}
                    </Typography>
                    {showComments && info.comentario !== '' && (
                      <>
                        <Box marginBottom={3} />
                        <Typography color={'textSecondary'}>
                          Comentário:
                        </Typography>
                        <Typography component={'p'}>
                          {info.comentario}
                        </Typography>
                      </>
                    )}
                  </Box>
                </Box>
              </Box>
            ))}
      </Box>
    </Box>
  );
};
