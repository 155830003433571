import React, {useEffect, useState, useMemo, useCallback} from 'react';
import {
  Box,
  Grid,
  Paper,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  Typography,
  Tooltip,
  IconButton,
} from '@mui/material';
import {Pagination, Skeleton} from '@mui/lab';
import {Environment} from 'shared/Env';
import {feedback} from 'shared/services';
import {
  IInviteProcessLog,
  InvitationService,
} from 'shared/services/api/invitation/Invitation';
import {format} from 'date-fns';
import RestartProcessIcon from '@mui/icons-material/Refresh';

interface IInviteLogProps {
  inviteId: string;
  inviteName: string;
}

export const InviteLog: React.FC<IInviteLogProps> = ({
  inviteId,
  inviteName,
}) => {
  const [inviteLog, setInviteLog] = useState<IInviteProcessLog[]>([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const rowsPerPage = useMemo(() => {
    return Environment.LIMITE_DE_LINHAS;
  }, []);

  useEffect(() => {
    setIsLoading(true);
    InvitationService.getInviteProcessLog(inviteId)
      .then((result) => {
        if (result.success) {
          setInviteLog(result.data);
        } else {
          feedback(
            result.message ||
              'Erro ao carregar histórico de processamento do convite.',
            'error',
          );
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [inviteId]);

  const handlePageChange = useCallback(
    (event: React.ChangeEvent<unknown>, value: number) => {
      setPage(value);
    },
    [],
  );

  const handleStartInviteProcessAgain = useCallback((pInviteId: string) => {
    InvitationService.releaseInvite(pInviteId).then((resp) => {
      if (!resp.success) {
        feedback(resp.message || 'Erro ao liberar convite.', 'error');
      }
    });
  }, []);

  return (
    <Box width={'1100px'} margin={'24px auto'}>
      <Box width={'100%'} marginBottom={2} display={'flex'} gap={1}>
        <Typography>
          <strong>Nome:</strong>
        </Typography>
        <Typography variant="body1" color="primary">
          {inviteName || ''}
        </Typography>
      </Box>
      <Grid container>
        <Grid container item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Etapa de processamento
                  </TableCell>
                  <TableCell component="th" scope="row">
                    Status do processamento
                  </TableCell>
                  <TableCell component="th" scope="row">
                    Data e Hora
                  </TableCell>
                  <TableCell component="th" scope="row">
                    Mensagem de erro
                  </TableCell>
                  <TableCell component="th" scope="row" width={65}>
                    Ações
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <>
                    <TableRow hover>
                      <TableCell component="th" scope="row">
                        <Skeleton
                          variant="rectangular"
                          width="auto"
                          height={25}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton
                          variant="rectangular"
                          width="auto"
                          height={25}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton
                          variant="rectangular"
                          width="auto"
                          height={25}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton
                          variant="rectangular"
                          width="auto"
                          height={25}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton
                          variant="rectangular"
                          width="auto"
                          height={25}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell component="th" scope="row">
                        <Skeleton
                          variant="rectangular"
                          width="auto"
                          height={25}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton
                          variant="rectangular"
                          width="auto"
                          height={25}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton
                          variant="rectangular"
                          width="auto"
                          height={25}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton
                          variant="rectangular"
                          width="auto"
                          height={25}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton
                          variant="rectangular"
                          width="auto"
                          height={25}
                        />
                      </TableCell>
                    </TableRow>
                  </>
                ) : (
                  <>
                    {inviteLog.length > 0 ? (
                      <>
                        {inviteLog
                          .slice(
                            (page - 1) * rowsPerPage,
                            (page - 1) * rowsPerPage + rowsPerPage,
                          )
                          .map((invLog) => (
                            <TableRow
                              hover
                              key={`status-processamento-${invLog.id}`}>
                              <TableCell component="th" scope="row">
                                {invLog.etapaProcessamento}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {invLog.status}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {`${format(
                                  new Date(invLog.dataHora),
                                  'dd/MM/yyyy HH:mm',
                                )}`}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {invLog.mensagemErro || '-'}
                              </TableCell>
                              <TableCell component="th" scope="row" width={65}>
                                {invLog.mensagemErro ? (
                                  <Tooltip title="Reiniciar processamento do convite.">
                                    <IconButton
                                      size="small"
                                      onClick={() => {
                                        handleStartInviteProcessAgain(inviteId);
                                      }}>
                                      <RestartProcessIcon />
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  '-'
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                      </>
                    ) : (
                      <TableRow>
                        <TableCell component="th" scope="row" colSpan={4}>
                          <Typography variant={'body1'} color="primary">
                            Não existe nenhum histórico para este convite.
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                )}
              </TableBody>
            </Table>
            {inviteLog.length > rowsPerPage && (
              <Box
                marginTop={1}
                marginBottom={1}
                marginLeft={1}
                marginRight={1}>
                <Pagination
                  page={page}
                  count={Math.ceil(inviteLog.length / rowsPerPage)}
                  onChange={handlePageChange}
                />
              </Box>
            )}
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
};
