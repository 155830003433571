import React, {memo, useCallback, useEffect, useState} from 'react';
import {useTitle} from 'shared/hooks';
import {
  Container,
  TextField,
  Box,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  IconButton,
  List,
  ListItemText,
  ListItem,
  CircularProgress,
  useTheme,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {SectionsWrapper} from './components/section/SectionsWrapper';
import {Tabs} from './components/Tabs';
import {Tooltip} from 'shared/components';
import {DetailActions} from './components/DetailActions';
import {useQuiz} from './hooks/useQuiz';
import {quizObservable} from './observables/Observable';
import {MoveSectionModal} from './components/section/MoveSectionModal';
import {QuizDetailSkeleton} from './QuizDetailSkeleton';
import {useField} from '@unform/core';
import {useParams, useNavigate} from 'react-router';
import {CloseRounded, DeleteForeverRounded} from '@mui/icons-material';
import {SubTheme} from 'pages/student-quiz/themes/SubTheme';
import {QuizService} from 'shared/services/api/quiz/Quiz';
import {feedback} from 'shared/services';
import {Alert, AlertTitle} from '@mui/lab';

export const QuizDetail: React.FC = memo(() => {
  const {setTitle} = useTitle();
  const {
    setNameQuiz,
    getNameQuiz,
    isLoading,
    isEditingQuiz,
    setIsEditingQuiz,
    hasInviteSent,
    enableQuiz,
    getQuizActive,
  } = useQuiz();
  const {id = '0'} = useParams<'id'>();
  const [isOpenModalReorder, setIsOpenModalReorder] = useState(false);
  const [isOnQuestTab, setIsOnQuestTab] = useState(id === '0' ? false : true);
  const [name, setName] = useState(getNameQuiz());
  const {error: nameError, clearError: nameClearError} = useField(`name`);
  const [openDeletePermaDialog, setOpenDeletePermaDialog] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [confirmationName, setConfirmationName] = useState('');
  const [disableDeleteButton, setDisableDeleteButton] = useState(true);
  const [showCircularProgress, setShowCircularProgress] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    if (id === '0') {
      setTitle('Novo questionário');
      setIsOnQuestTab(false);
    } else {
      setTitle('Editar questionário');
      setIsOnQuestTab(true);
    }
  }, [setTitle, id]);

  useEffect(() => {
    if (id === '0') {
      setTitle(isEditingQuiz ? 'Novo questionário •' : 'Novo questionário');
    } else if (!hasInviteSent) {
      setTitle(isEditingQuiz ? 'Editar questionário •' : 'Editar questionário');
    }
  }, [setTitle, id, isEditingQuiz, hasInviteSent]);

  useEffect(() => {
    const listener = quizObservable.subscribe(() => {
      setName(getNameQuiz());
    });
    return () => listener.unsubscribe();
  }, [getNameQuiz]);

  const onChangeName = useCallback(
    (name: string) => {
      setName(name);
      setNameQuiz(name);
      setIsEditingQuiz(true);
      nameError && nameClearError();
    },
    [nameClearError, nameError, setNameQuiz, setIsEditingQuiz],
  );

  const handleOpenModalReorder = useCallback((value: boolean) => {
    setIsOpenModalReorder(value);
  }, []);

  const changeTab = useCallback((isQuestTab: boolean) => {
    setIsOnQuestTab(isQuestTab);
  }, []);

  const handleDeletePermanentlyBehavior = useCallback(() => {
    setOpenConfirmDelete(true);
    setOpenDeletePermaDialog(false);
  }, []);

  const handleEnableDeleteButton = useCallback(() => {
    setDisableDeleteButton(true);
    if (confirmationName !== '' && confirmationName === name) {
      setShowCircularProgress(true);
      setTimeout(() => {
        setDisableDeleteButton(false);
        setShowCircularProgress(false);
      }, 1000);
    }
  }, [confirmationName, name]);

  const handleDeletePermanent = useCallback(() => {
    feedback('Excluindo questionário...', 'loading');
    QuizService.deletePermanentById(id, confirmationName)
      .then((response) => {
        if (response.success) {
          feedback(
            response.message || 'Você excluiu o questionário permanentemente.',
            'success',
          );
        } else {
          feedback(
            response.message ||
              'Erro ao excluir o questionário permanentemente.',
            'error',
          );
        }
      })
      .finally(() => {
        navigate('/questionarios');
      });
  }, [id, confirmationName, navigate]);

  return (
    <Container>
      {!isLoading ? (
        <>
          <Grid container spacing={2} marginTop={1}>
            {!getQuizActive() && (
              <Grid item={true} xs={11} sm={11} lg={11} md={11}>
                <Box marginTop={1} marginBottom={3}>
                  <Alert
                    severity="warning"
                    action={
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() => enableQuiz()}>
                        Clique aqui para reativar
                      </Button>
                    }>
                    <AlertTitle>Questionário inativo</AlertTitle>
                    Para utilizar o questionário, será necessário reativação do
                    mesmo.
                  </Alert>
                </Box>
              </Grid>
            )}
            <Grid item={true} xs={11} sm={11}>
              <Tooltip text="Informe o nome do questionário">
                <TextField
                  required
                  fullWidth
                  value={name}
                  label="Nome do questionário"
                  variant="outlined"
                  error={!!nameError}
                  helperText={nameError || name.length + '/510'}
                  onChange={(e) => onChangeName(e.target.value)}
                  inputProps={{
                    maxLength: 510,
                  }}
                />
              </Tooltip>
            </Grid>

            <Grid item xs={1} sm={1}>
              <Tooltip
                text="Insira aqui o nome interno do questionário. Esse dado não será exibido para o público alvo."
                position="top">
                <InfoOutlinedIcon fontSize="small" />
              </Tooltip>
            </Grid>

            <Grid
              item
              xs={12}
              sm={11}
              style={{
                borderRadius: '10px',
                boxShadow: '3px',
              }}>
              <Tabs questCurrentTab={changeTab} />
              {isOnQuestTab && <SectionsWrapper />}
            </Grid>

            <Grid item={true} xs={4} sm={1}>
              <Box
                display="flex"
                position="fixed"
                justifyContent="center"
                alignItems="center"
                flexDirection={'column'}
                gap={5}>
                <DetailActions
                  isOnQuestTab={isOnQuestTab}
                  openModalReorder={handleOpenModalReorder}
                />
              </Box>
            </Grid>
          </Grid>
          {isOpenModalReorder && (
            <MoveSectionModal
              open={isOpenModalReorder}
              closeModal={() => handleOpenModalReorder(false)}
            />
          )}
        </>
      ) : (
        <QuizDetailSkeleton />
      )}
      <Dialog
        maxWidth={'md'}
        open={openDeletePermaDialog}
        onClose={() => setOpenDeletePermaDialog(false)}>
        <DialogTitle>
          <Box
            width={'100%'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}>
            <Typography color="error" variant="h6">
              Você tem certeza?
            </Typography>
            <IconButton
              aria-label="close"
              onClick={() => setOpenDeletePermaDialog(false)}>
              <CloseRounded />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box width={'600px'}>
            <strong>Nesta operação você está excluindo:</strong>
            <List disablePadding>
              <ListItem>
                <ListItemText primary={'- Questionário'} />
              </ListItem>
              <ListItem>
                <ListItemText primary={'- Seções'} />
              </ListItem>
              <ListItem>
                <ListItemText primary={'- Questões'} />
              </ListItem>
              <ListItem>
                <ListItemText primary={'- Opções de respostas'} />
              </ListItem>
              <ListItem>
                <ListItemText primary={'- Convites'} />
              </ListItem>
              <ListItem>
                <ListItemText primary={'- Respostas dos participantes'} />
              </ListItem>
            </List>
          </Box>
        </DialogContent>
        <DialogActions>
          <SubTheme color={'F50000'}>
            <Box
              width={'100%'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'flex-end'}
              gap={2}>
              <Button
                color={'primary'}
                size={'large'}
                variant="outlined"
                onClick={() => handleDeletePermanentlyBehavior()}>
                Estou ciente e quero excluir tudo
              </Button>
              <Button
                color={'primary'}
                size={'large'}
                variant="contained"
                onClick={() => setOpenDeletePermaDialog(false)}>
                Desistir
              </Button>
            </Box>
          </SubTheme>
        </DialogActions>
      </Dialog>
      <Dialog
        maxWidth={'md'}
        open={openConfirmDelete}
        onClose={() => setOpenConfirmDelete(false)}>
        <SubTheme color={'F50000'}>
          <DialogTitle>
            <Box
              width={'100%'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}>
              <Typography color="error" variant="h6">
                Você tem certeza?
              </Typography>
              <IconButton
                aria-label="close"
                onClick={() => setOpenConfirmDelete(false)}>
                <CloseRounded />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box width={'600px'} marginBottom={3}>
              <Box width={'100%'} marginBottom={3}>
                <strong>
                  Para finalizar digite o nome do questionário abaixo:
                </strong>
              </Box>
              <TextField
                required
                fullWidth
                value={confirmationName}
                label="Nome do questionário"
                variant="outlined"
                onChange={(e) => setConfirmationName(e.target.value)}
                onBlur={() => handleEnableDeleteButton()}
                onKeyDown={() => setDisableDeleteButton(true)}
                inputProps={{
                  maxLength: 510,
                }}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Box
              width={'100%'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'flex-end'}
              gap={2}>
              <Button
                disabled={disableDeleteButton}
                color={'primary'}
                size={'large'}
                variant="outlined"
                startIcon={
                  showCircularProgress && <CircularProgress size={20} />
                }
                onClick={() => handleDeletePermanent()}>
                Excluir permanentemente
              </Button>
              <Button
                color={'primary'}
                size={'large'}
                variant="contained"
                onClick={() => setOpenConfirmDelete(false)}>
                Cancelar
              </Button>
            </Box>
          </DialogActions>
        </SubTheme>
      </Dialog>
      {!isLoading && (
        <Grid container>
          <Grid item xs={12} sm={11}>
            <Box
              width={'100%'}
              marginTop={5}
              textAlign="right"
              style={{color: theme.palette.grey[400]}}>
              <Button
                size={'medium'}
                variant="text"
                color="inherit"
                startIcon={<DeleteForeverRounded />}
                onClick={() => setOpenDeletePermaDialog(true)}>
                Excluir permanentemente
              </Button>
            </Box>
          </Grid>
        </Grid>
      )}
    </Container>
  );
});
