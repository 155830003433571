import React, {memo, useCallback, useEffect, useState, useMemo} from 'react';
import {useNavigate} from 'react-router';
import {
  Box,
  Chip,
  Grid,
  Paper,
  Table,
  Button,
  Tooltip,
  TableRow,
  Container,
  TableBody,
  TableCell,
  TableHead,
  IconButton,
  FormControl,
  InputAdornment,
  TableContainer,
  TextField,
  CircularProgress,
  Theme,
  Icon,
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {Pagination, ToggleButton, ToggleButtonGroup} from '@mui/lab';
import {Search, Add, FileCopy} from '@mui/icons-material';
import {useDebounce, useTitle} from 'shared/hooks';
import {ErrorPage, NotFoundText} from 'shared/components';
import {QuizModalDetail} from 'pages/quiz/components/quiz-modal-detail/QuizModalDetail';
import {QuizListSkeleton} from 'pages/quiz/QuizListSkeleton';
import {feedback, confirm} from 'shared/services';
import {IQuiz, QuizListService} from 'shared/services/api/quiz/QuizList';
import {IQuizDetail, QuizService} from 'shared/services/api/quiz/Quiz';
import {Environment} from 'shared/Env';

const useStyles = makeStyles((theme: Theme) => ({
  toggleButton: {
    '&.MuiToggleButton-root.Mui-selected': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));

export const QuizList: React.FC = memo(() => {
  const {debounce} = useDebounce();
  const {setTitle} = useTitle();
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [quizData, setQuizData] = useState<IQuiz[]>([]);
  const [quizDataToSearch, setQuizDataToSearch] = useState<IQuiz[]>([]);
  const [modalRowId, setModalRowId] = useState('');
  const [page, setPage] = useState(1);
  const [hasCriticalError, setHasCriticalError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [toggleButtonValue, setToggleButtonValue] = useState(1);
  const cssClasses = useStyles();

  useEffect(() => {
    setTitle('Questionários');
  }, [setTitle]);

  useEffect(() => {
    setIsLoading(true);

    QuizListService.getAll()
      .then((result) => {
        if (result.success) {
          setQuizData(result.data);
          setQuizDataToSearch(result.data);
        } else {
          if (result.message) {
            if (result.message.includes('ServiceUnavailable')) {
              setHasCriticalError(true);
              setErrorMessage(
                result.message.substring(result.message.indexOf('|') + 1),
              );
            }
            feedback(result.message || 'Erro ao carregar os dados...', 'error');
          }
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const allQuizData = useMemo(() => {
    return quizData;
  }, [quizData]);

  const activeQuizes = useMemo(() => {
    return quizData.filter((quiz) => quiz.ativo);
  }, [quizData]);

  const inactiveQuizes = useMemo(() => {
    return quizData.filter((quiz) => !quiz.ativo);
  }, [quizData]);

  const accessModal = useCallback((id: string) => {
    setModalRowId(id);
    setModalOpen(true);
  }, []);

  const handleChangeQuizStatus = useCallback((quizId: string) => {
    const changeStatus = () => {
      setIsLoading(true);

      QuizService.changeQuizStatus(quizId, false)
        .then((response) => {
          if (response.success) {
            setQuizData((oldQuizData) =>
              oldQuizData?.map((quiz) => {
                if (quiz.id === quizId) {
                  return {...quiz, ativo: false};
                }

                return quiz;
              }),
            );

            feedback(
              response.message || 'O questionário foi arquivado.',
              'success',
            );
          } else {
            feedback(
              response.message || 'Erro ao arquivar questionário.',
              'error',
            );
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    confirm('Deseja ocultar o questionário?', 'confirmation', changeStatus);
  }, []);

  const removeAccentuation = useCallback((value: string) => {
    const resultFilter = value
      .toLocaleLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

    return resultFilter;
  }, []);

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsLoading(true);
      setSearch(e.target.value);

      debounce(() => {
        const valor = removeAccentuation(e.target.value);

        const resultFilter = quizDataToSearch?.filter(
          (item) =>
            removeAccentuation(item.description).indexOf(valor) > -1 ||
            removeAccentuation(item.name).indexOf(valor) > -1 ||
            removeAccentuation(item.nameQuestionaryType).indexOf(valor) > -1 ||
            item.establishment.find(
              (estab) => removeAccentuation(estab.name).indexOf(valor) > -1,
            ),
        );

        if (e.target.value === '') {
          setQuizData(quizDataToSearch);
        } else {
          setQuizData(resultFilter);
        }
        setPage(1);
        setIsLoading(false);
      });
    },
    [debounce, quizDataToSearch, removeAccentuation],
  );

  const handlePageChange = useCallback(
    (event: React.ChangeEvent<unknown>, value: number) => {
      setPage(value);
    },
    [],
  );

  const duplicateQuiz = useCallback(
    (copiedQuiz: IQuizDetail) => {
      if (copiedQuiz) {
        QuizService.create(copiedQuiz).then((response) => {
          feedback(response.message, response.success ? 'success' : 'error');
          if (response.success) {
            navigate(`/questionarios/detalhe/${response.data.Id}`);
          }
        });
      } else {
        feedback(
          'Ocorreu um erro ao realizar a cópia do questionário.',
          'error',
        );
      }
    },
    [navigate],
  );

  const handleDuplicate = useCallback(
    (id: string) => {
      const handleConfirmDuplicate = (id: string) => {
        feedback('Duplicando questionário...', 'loading');

        QuizService.getById(id).then((result) => {
          if (result.success) {
            let copiedQuiz: IQuizDetail = {
              ...result.data,
              id: '',
              title: `Cópia de ${result.data.title.toLowerCase()}`,
              name: `Cópia de ${result.data.name.toLowerCase()}`,
              establishment: result.data.establishment,
              active: true,
            };

            QuizService.getBannerById(id).then((result) => {
              if (result.success) {
                copiedQuiz.bannerBase64 = result.data.bannerBase64;
                copiedQuiz.bannerExtension = result.data.bannerExtension;
              }

              QuizService.getLogoById(id).then((result) => {
                if (result.success) {
                  copiedQuiz.logoBase64 = result.data.logoBase64;
                  copiedQuiz.logoExtension = result.data.logoExtension;
                }

                duplicateQuiz(copiedQuiz);
              });
            });
          }
        });
      };

      confirm(
        'Tem certeza que deseja duplicar este registro?',
        'confirmation',
        () => handleConfirmDuplicate(id),
      );
    },
    [duplicateQuiz],
  );

  const handleToggleButtonChange = (
    event: React.MouseEvent<HTMLElement>,
    value: number,
  ) => {
    setToggleButtonValue(value);
    setPage(1);
  };

  if (hasCriticalError) {
    return (
      <Container>
        <ErrorPage message={errorMessage} />
      </Container>
    );
  }

  return (
    <Container>
      <QuizModalDetail
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        rowId={modalRowId}
      />
      <Grid container spacing={3}>
        <Grid container item xs={12} spacing={0}>
          <Grid item xs={6}>
            <Box display={'flex'} alignItems={'center'} gap={1}>
              <FormControl variant="outlined" fullWidth>
                <Paper>
                  <TextField
                    style={{padding: 0, margin: 0}}
                    fullWidth
                    variant="outlined"
                    placeholder="Pesquise nome, descrição, tipo ou estabelecimento"
                    value={search}
                    onChange={handleSearch}
                    InputProps={{
                      style: {
                        height: 40,
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          {isLoading ? (
                            <CircularProgress size={30} />
                          ) : (
                            <Search color="disabled" />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Paper>
              </FormControl>
              <Tooltip title={'Filtrar questionários ativos e inativos.'}>
                <ToggleButtonGroup
                  size="small"
                  value={toggleButtonValue}
                  exclusive
                  onChange={handleToggleButtonChange}>
                  <ToggleButton
                    disabled={allQuizData.length > 0 ? false : true}
                    color="primary"
                    value={0}
                    className={cssClasses.toggleButton}>
                    Todos
                  </ToggleButton>
                  <ToggleButton
                    disabled={activeQuizes.length > 0 ? false : true}
                    color="primary"
                    value={1}
                    className={cssClasses.toggleButton}>
                    Ativos
                  </ToggleButton>
                  <ToggleButton
                    disabled={inactiveQuizes.length > 0 ? false : true}
                    color="primary"
                    value={2}
                    className={cssClasses.toggleButton}>
                    Inativos
                  </ToggleButton>
                </ToggleButtonGroup>
              </Tooltip>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            container
            justifyContent={'flex-end'}
            alignItems={'center'}>
            <Grid item xs={3}>
              <Tooltip title={'Adicionar um novo questionário.'}>
                <Button
                  size="medium"
                  fullWidth
                  variant="contained"
                  color="primary"
                  startIcon={<Add />}
                  onClick={() => {
                    navigate('/questionarios/detalhe/0');
                  }}>
                  Novo
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} marginBottom={1}>
          <TableContainer component={Paper} elevation={3}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Nome
                  </TableCell>
                  <TableCell component="th" scope="row">
                    Tipo
                  </TableCell>
                  <TableCell component="th" scope="row">
                    Estabelecimentos
                  </TableCell>
                  <TableCell component="th" scope="row">
                    Convites
                  </TableCell>
                  <TableCell component="th" scope="row">
                    Status
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    width={100}
                    align={'right'}>
                    Ações
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <QuizListSkeleton />
                ) : quizData &&
                  quizData.filter((quiz) =>
                    toggleButtonValue === 1
                      ? quiz.ativo
                      : toggleButtonValue === 2
                      ? !quiz.ativo
                      : quiz,
                  ).length ? (
                  quizData
                    ?.filter((quiz) =>
                      toggleButtonValue === 1
                        ? quiz.ativo
                        : toggleButtonValue === 2
                        ? !quiz.ativo
                        : quiz,
                    )
                    .slice(
                      (page - 1) * Environment.LIMITE_DE_LINHAS,
                      (page - 1) * Environment.LIMITE_DE_LINHAS +
                        Environment.LIMITE_DE_LINHAS,
                    )
                    .map((row) => (
                      <TableRow hover key={row.id} style={{cursor: 'pointer'}}>
                        <TableCell
                          onClick={() => accessModal(row.id)}
                          component="td"
                          scope="row"
                          width={'30%'}>
                          {row.name.length > 60 ? (
                            <Tooltip title={row.name}>
                              <>{`${row.name.slice(0, 60)}...`}</>
                            </Tooltip>
                          ) : (
                            <>{row.name}</>
                          )}
                        </TableCell>
                        <TableCell
                          onClick={() => accessModal(row.id)}
                          component="td"
                          scope="row"
                          width={'15%'}>
                          {row.nameQuestionaryType}
                        </TableCell>
                        <TableCell
                          onClick={() => accessModal(row.id)}
                          component="td"
                          scope="row"
                          width={'15%'}>
                          {row.establishment[0]?.name}
                          {row.establishment.length - 1 > 0 ? (
                            <Tooltip
                              title={row.establishment
                                .slice(1, row.establishment.length)
                                .map((estab) => estab.name)
                                .join(', ')}>
                              <Chip
                                style={{marginLeft: '5px'}}
                                size="small"
                                color={'primary'}
                                label={`+ ${row.establishment.length - 1}`}
                              />
                            </Tooltip>
                          ) : (
                            ''
                          )}
                        </TableCell>
                        <TableCell
                          onClick={() => accessModal(row.id)}
                          component="td"
                          scope="row"
                          width={'14%'}>
                          {row.activeInvitations === 0 &&
                          row.inactiveInvitations === 0 ? (
                            <p>Nenhum convite</p>
                          ) : (
                            ''
                          )}
                          {row.activeInvitations > 0 && (
                            <Box marginTop={0.5} marginBottom={0.5}>
                              <Chip
                                size="small"
                                color={'primary'}
                                label={row.activeInvitations + ' ativos'}
                              />
                            </Box>
                          )}
                          {row.inactiveInvitations > 0 && (
                            <Box marginTop={0.5} marginBottom={0.5}>
                              <Chip
                                size="small"
                                color={'default'}
                                label={row.inactiveInvitations + ' inativos'}
                              />
                            </Box>
                          )}
                        </TableCell>
                        <TableCell
                          onClick={() => accessModal(row.id)}
                          component="td"
                          scope="row"
                          width={'14%'}>
                          <Box marginTop={0.5} marginBottom={0.5}>
                            <Chip
                              size="small"
                              color={row.ativo ? 'primary' : 'default'}
                              label={row.ativo ? 'Ativo' : 'Inativo'}
                            />
                          </Box>
                        </TableCell>
                        <TableCell
                          component="td"
                          scope="row"
                          width={'11%'}
                          align={'right'}>
                          <Tooltip
                            title="Duplicar questionário"
                            placement="top">
                            <IconButton
                              aria-label="edit"
                              size="small"
                              onClick={() => handleDuplicate(row.id)}>
                              <FileCopy fontSize={'small'} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={'Editar'}>
                            <IconButton
                              aria-label="edit"
                              size="small"
                              onClick={() =>
                                navigate(`/questionarios/detalhe/${row.id}`)
                              }
                              disabled={false}>
                              <Icon fontSize={'small'}>edit</Icon>
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={'Ocultar questionário'}>
                            <IconButton
                              aria-label="edit"
                              size="small"
                              onClick={() => handleChangeQuizStatus(row.id)}
                              disabled={false}>
                              <Icon fontSize={'small'}>delete_rounded</Icon>
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell component="td" scope="row" colSpan={6}>
                      <NotFoundText />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {quizData && (
              <Box
                marginTop={1}
                marginBottom={1}
                display={'flex'}
                justifyContent={'end'}>
                <Pagination
                  page={page}
                  count={Math.ceil(
                    quizData.filter((quiz) =>
                      toggleButtonValue === 1
                        ? quiz.ativo
                        : toggleButtonValue === 2
                        ? !quiz.ativo
                        : quiz,
                    ).length / Environment.LIMITE_DE_LINHAS,
                  )}
                  onChange={handlePageChange}
                />
              </Box>
            )}
          </TableContainer>
        </Grid>
      </Grid>
    </Container>
  );
});
