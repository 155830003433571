import React from 'react';
import {Box, useTheme} from '@mui/material';
import {Answers} from './Answers';
import {IAnswerSummarySection} from 'shared/services/api/reports/AnswerSummary';
import {ReadMore} from 'shared/components';

export const AnswersSection: React.FC<IAnswerSummarySection> = ({
  id,
  ordem,
  nomeSecao,
  questoes,
  multiplicador,
}) => {
  const theme = useTheme();

  return (
    <Box id={id} width={'100%'}>
      <Box
        width="100%"
        paddingY={1}
        paddingX={3}
        bgcolor={theme.palette.secondary.main}>
        <ReadMore
          cutAt={150}
          text={`${ordem}. ${nomeSecao}`}
          variant={'h6'}
          style={{color: '#fff'}}
        />
      </Box>
      <Box width={'100%'}>
        {questoes.map((questao) => (
          <Answers
            comentarios={questao.comentarios}
            respostasPendentes={questao.respostasPendentes}
            respostasRecebidas={questao.respostasRecebidas}
            key={questao.id}
            enunciado={questao.enunciado}
            detalhes={questao.detalhes}
            descricao={questao.descricao}
            id={questao.id}
            tipoQuestao={questao.tipoQuestao}
          />
        ))}
      </Box>
    </Box>
  );
};
