import {Theme} from '@mui/material';
import {makeStyles} from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) =>({
    chartWrapper: {
      width: '100%',
      position: 'relative',
      flexGrow: 2,
    },
  }),
);
