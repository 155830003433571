import React from 'react';
import {Box, Typography, useTheme} from '@mui/material';
import {ISection} from 'shared/services/api/reports/IndividualAnswers';
import {RenderQuestion} from 'pages/student-quiz/components/RenderQuestion';
import {ReadMore} from 'shared/components';

export interface IIndividualAnswersSectionProps {
  sectionList: ISection[];
}

export const IndividualAnswersSection: React.FC<
  IIndividualAnswersSectionProps
> = ({sectionList}) => {
  const theme = useTheme();

  return (
    <Box width={'100%'}>
      {sectionList.map((section, index) => (
        <Box
          width={'100%'}
          key={`section-box-${section.idSecao}`}
          marginBottom={5}>
          <Box
            color="#fff"
            width="100%"
            marginBottom={2}
            paddingY={1}
            paddingX={3}
            bgcolor={theme.palette.secondary.main}>
            <ReadMore
              cutAt={95}
              text={`${section.ordem}. ${section.nomeSecao}`}
              variant={'h6'}
              style={{color: '#fff'}}
            />
          </Box>
          {section.multiplicadores.map((multiplicador) => (
            <Box key={`multiplier-${index}-${multiplicador.codigo}`}>
              {multiplicador.nome && (
                <Box
                  marginBottom={2}
                  width="100%"
                  paddingY={1}
                  paddingX={3}
                  bgcolor={theme.palette.secondary.light}>
                  <Typography style={{fontSize: '15px', color: '#fff'}}>
                    {multiplicador.nome}{' '}
                    {multiplicador.professor
                      ? ` - ${multiplicador.professor}`
                      : ''}
                  </Typography>
                </Box>
              )}
              <Box
                padding={'0 20px'}
                width={'100%'}
                key={`questions-box-${section.idSecao}`}>
                <Box
                  paddingBottom={4}
                  width="100%"
                  display="flex"
                  gap={6}
                  flexDirection="column">
                  {multiplicador.questoes.map((question) => (
                    <RenderQuestion
                      key={question.idResposta}
                      setCurrentQuestion={() => {}}
                      currentQuestion={question}
                    />
                  ))}
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
};
