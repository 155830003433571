import React from 'react';
import {
  Box,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import {Skeleton} from '@mui/lab';

export const RemainingParticipantsSkeleton: React.FC = () => {
  return (
    <Box width={'100%'}>
      <Box>
        <Skeleton variant="text" width={345} />
        <Box marginBottom={2} />
      </Box>
      <Box marginBottom={3}>
        <Skeleton variant="rectangular" width={700} height={350} />
      </Box>
      <Box>
        <Skeleton variant="rectangular" width={500} height={56} />
        <Box marginBottom={1} />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell component="th" scope="row">
                  Nome
                </TableCell>
                <TableCell component="th" scope="row">
                  Matrícula
                </TableCell>
                <TableCell component="th" scope="row">
                  Curso
                </TableCell>
                <TableCell component="th" scope="row">
                  Papel
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow hover>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={25} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={25} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={25} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={25} />
                </TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={25} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={25} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={25} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={25} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};
