import {Api} from 'shared/services/axios-config';
import {IResultOfAction} from 'shared/services/axios-config/interceptors';

export interface IInviteProcessLog {
  id: string;
  idConvite: string;
  etapaProcessamento: string;
  mensagemErro: string;
  dataHora: string;
  status: string;
}

export interface IPublicoAlvo {
  id: string;
  nome: string;
  habilitado: boolean;
  publicoSemDisciplinas: boolean;
}

export interface IPessoa {
  id: number;
  nome: string;
  papel: string;
  curso: string;
  email: string;
  matricula: number;
}

export interface IDownloadPessoas {
  fileContent: string;
}

export interface IInvitation {
  id: string;
  descricao: string;
  dataHoraInicio: string;
  dataHoraFim: string;
  aceitandoRespostas: boolean;
  linkConvite: string;
  permitirAcesso: boolean;
  status:
    | 'EM_ELABORACAO'
    | 'AGUARDANDO_LIBERACAO'
    | 'ACEITANDO_RESPOSTAS'
    | 'BLOQUEADO_PARA_RESPOSTAS'
    | 'LIBERACAO_INICIADA'
    | 'CONVITE_ENCERRADO'
    | 'REPROCESSAMENTO_DO_PUBLICO_ALVO'
    | 'PUBLICO_ALVO_SEM_DESTINATARIOS'
    | 'ERRO_LIBERACAO'
    | 'REPROCESSAMENTO_DO_PUBLICO_ALVO_CONCLUIDO';
  resposta: number;
  mensagemDeErro: string;
  dataHoraBloqueio: string;
  publicosAlvo: IPublicoAlvo[];
}

const getAll = async (
  idQuestionario: string,
  triggerBackground: boolean,
): Promise<IResultOfAction<IInvitation[]>> => {
  try {
    const {data} = await Api.get(
      `/convites/questionario/${idQuestionario}?triggerBackground=${triggerBackground}`,
    );

    const invitationList: IInvitation[] = data.map((data: any) => ({
      id: data.Id,
      descricao: data.Descricao,
      dataHoraInicio: data.DataHoraInicio,
      dataHoraFim: data.DataHoraFim,
      aceitandoRespostas: data.AceitandoRespostas,
      linkConvite: data.LinkConvite,
      permitirAcesso: data.PermitirAcesso,
      status: data.Status,
      resposta: data.Resposta,
      dataHoraBloqueio: data.DataHoraBloqueio,
      publicosAlvo: data.PublicosAlvo.map((pa: any) => ({
        id: pa.Id,
        nome: pa.Nome,
      })),
    }));

    const response: IResultOfAction<IInvitation[]> = {
      data: invitationList,
      success: true,
      message: '',
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const getPublicoAlvo = async (): Promise<IResultOfAction<IPublicoAlvo[]>> => {
  try {
    const {data} = await Api.get('/publicosAlvo');

    const publicoAlvoList: IPublicoAlvo[] = data.map((data: any) => ({
      id: data.Id,
      nome: data.Nome,
      habilitado: data.PublicoEmStatusConcluido && data.PublicoPossuiPessoas,
      publicoSemDisciplinas: data.publicoSemDisciplinas,
    }));

    const response: IResultOfAction<IPublicoAlvo[]> = {
      data: publicoAlvoList,
      success: true,
      message: '',
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const getPessoas = async (
  idConvite: string,
): Promise<IResultOfAction<IPessoa[]>> => {
  try {
    const {data} = await Api.get(`/convites/pessoas/${idConvite}`);

    const pessoaList: IPessoa[] = data.map((data: any) => ({
      id: data.Id,
      nome: data.Nome,
      papel: data.Papel,
      curso: data.Curso,
      matricula: data.Matricula,
    }));

    const response: IResultOfAction<IPessoa[]> = {
      data: pessoaList,
      success: true,
      message: '',
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const getDownloadPessoasCSV = async (
  idConvite: string,
): Promise<IResultOfAction<IDownloadPessoas>> => {
  try {
    const {data} = await Api.get(`/convites/pessoas/download/${idConvite}`, {
      responseType: 'blob',
    });

    const downloadLink: IDownloadPessoas = {
      fileContent: data,
    };

    const response: IResultOfAction<IDownloadPessoas> = {
      data: downloadLink,
      success: true,
      message: '',
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const getInviteProcessLog = async (
  idConvite: string,
): Promise<IResultOfAction<IInviteProcessLog[]>> => {
  try {
    const {data} = await Api.get(`/convites/historico/${idConvite}`);

    const invitationList: IInviteProcessLog[] = data.map((invLog: any) => ({
      id: invLog.Id,
      idConvite: invLog.IdConvite,
      etapaProcessamento: invLog.EtapaDeProcessamento,
      mensagemErro: invLog.MensagemDeErro,
      dataHora: invLog.DataHora,
      status: invLog.Status,
    }));

    const response: IResultOfAction<IInviteProcessLog[]> = {
      data: invitationList,
      success: true,
      message: '',
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const releaseInvite = async (
  idInvite: string,
): Promise<IResultOfAction<any>> => {
  try {
    const {data} = await Api.post(`/liberarConvites/${idInvite}`);

    const response: IResultOfAction<any> = {
      data: data.Data,
      success: data.Success,
      message: data.Message,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const create = async (
  invite: IInvitation,
  idQuestionario: string,
): Promise<IResultOfAction<any>> => {
  try {
    const postOject: any = {
      Descricao: invite.descricao,
      IdQuestionario: idQuestionario,
      DataHoraInicio: invite.dataHoraInicio,
      DataHoraFim: invite.dataHoraFim,
      AceitandoRespostas: invite.aceitandoRespostas,
      PermitirAcesso: invite.permitirAcesso,
      Resposta: invite.resposta,
      DataHoraBloqueio: invite.permitirAcesso
        ? invite.dataHoraFim
        : invite.dataHoraBloqueio,
      PublicosAlvo: invite.publicosAlvo.map((pa: any) => pa.id),
    };

    const {data} = await Api.post('/convites', postOject);

    const response: IResultOfAction<any> = {
      data: data.Data,
      success: data.Success,
      message: data.Message,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const updateById = async (
  invite: IInvitation,
  idQuestionario: string,
): Promise<IResultOfAction<any>> => {
  try {
    const putObject: any = {
      Descricao: invite.descricao,
      IdQuestionario: idQuestionario,
      DataHoraInicio: invite.dataHoraInicio,
      DataHoraFim: invite.dataHoraFim,
      AceitandoRespostas: invite.aceitandoRespostas,
      PermitirAcesso: invite.permitirAcesso,
      Resposta: invite.resposta,
      DataHoraBloqueio: invite.permitirAcesso
        ? invite.dataHoraFim
        : invite.dataHoraBloqueio,
      PublicosAlvo: invite.publicosAlvo.map((pa: any) => pa.id),
      Id: invite.id,
    };

    const {data} = await Api.put(`/convites/${invite.id}`, putObject);

    const response: IResultOfAction<any> = {
      data: data.Data,
      success: data.Success,
      message: data.Message,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const extendInviteDate = async (
  invite: IInvitation,
): Promise<IResultOfAction<any>> => {
  try {
    const putObject: any = {
      DataHoraBloqueio: invite.dataHoraBloqueio,
      DataHoraFim: invite.dataHoraFim,
      Id: invite.id,
    };

    const {data} = await Api.put(`/convites/${invite.id}/reabrir`, putObject);

    const response: IResultOfAction<any> = {
      data: data.Data,
      success: data.Success,
      message: data.Message,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const deleteById = async (idInvite: string): Promise<IResultOfAction<any>> => {
  try {
    const {data} = await Api.delete(`/convites/${idInvite}`);

    const response: IResultOfAction<any> = {
      data: data.Data,
      success: data.Success,
      message: data.Message,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const updateInviteAudienceById = async (
  idInvite: string,
  publicosAlvo: string[],
): Promise<IResultOfAction<any>> => {
  try {
    const patchData: any = {
      Id: idInvite,
      PublicosAlvo: publicosAlvo,
    };

    const {data} = await Api.patch(
      `/convites/${idInvite}/editarPublicosAlvo`,
      patchData,
    );

    const response: IResultOfAction<any> = {
      data: data.Data,
      success: data.Success,
      message: data.Message,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

export const InvitationService = {
  getAll,
  getPessoas,
  getDownloadPessoasCSV,
  getPublicoAlvo,
  create,
  updateById,
  releaseInvite,
  deleteById,
  getInviteProcessLog,
  extendInviteDate,
  updateInviteAudienceById,
};
