import React, {memo, useEffect, useState} from 'react';
import {
  Box,
  Grid,
  OutlinedInput,
  Select,
  MenuItem,
  IconButton,
  ListItemIcon,
} from '@mui/material';
import {
  SentimentVeryDissatisfied,
  SentimentDissatisfied,
  SentimentSatisfied,
  SentimentSatisfiedAlt,
  SentimentVerySatisfied,
  DragHandle,
  Lens,
  Add,
  Remove,
  Block,
} from '@mui/icons-material';
import {IOption} from 'shared/services/api/quiz/Quiz';
import {useQuiz} from 'pages/quiz/hooks';

interface IOptionColumnProps {
  option: IOption;
  quantityOfColumns: number;
  selectedValue: string;
  handleAddColumn: (index: number) => void;
  handleRemoveColumn: (index: number) => void;
  handleUpdateDescription: (index: number, text: string) => void;
  onChangeSelect: (index: number, value: string) => void;
}

const optionsColor = [
  '#D7201A',
  '#F95951',
  '#F82C33',
  '#FB7D00',
  '#F38942',
  '#FFCB2E',
  '#FBC115',
  '#98CB38',
  '#46BC4A',
  '#2F9C33',
];

const optionsEmoji = [
  <SentimentVeryDissatisfied />,
  <SentimentDissatisfied />,
  <SentimentSatisfied />,
  <SentimentSatisfiedAlt />,
  <SentimentVerySatisfied />,
];

export const OptionColumn: React.FC<IOptionColumnProps> = memo(
  ({
    option,
    quantityOfColumns,
    selectedValue,
    handleAddColumn,
    handleRemoveColumn,
    handleUpdateDescription,
    onChangeSelect,
  }) => {
    const {hasInviteSent} = useQuiz();
    const [useEmoji, setUseEmoji] = useState(false);
    const [isSelectOpen, setIsSelectOpen] = useState(false);

    useEffect(() => {
      if (quantityOfColumns <= 5) {
        setUseEmoji(true);
      } else if (quantityOfColumns >= 6) {
        setUseEmoji(false);
      }
    }, [quantityOfColumns]);

    return (
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="flex-start">
        <Grid item={true} xs={1}>
          <DragHandle fontSize="small" />
        </Grid>
        <Grid item={true} xs={6}>
          <OutlinedInput
            placeholder={`Opção ${option.order}`}
            value={option.description}
            fullWidth
            onChange={(event) =>
              handleUpdateDescription(option.order, event.target.value)
            }
          />
        </Grid>
        <Grid item={true} xs={2}>
          <Select
            variant="outlined"
            autoWidth
            value={selectedValue}
            style={{maxBlockSize: 55}}
            onChange={(event) =>
              onChangeSelect(option.order, event.target.value as string)
            }
            defaultValue=""
            onOpen={() => setIsSelectOpen(true)}
            onClose={() => setIsSelectOpen(false)}>
            {useEmoji
              ? optionsEmoji.map((component, index) => {
                  return (
                    <MenuItem key={index} value={String(index)}>
                      {component}
                    </MenuItem>
                  );
                })
              : optionsColor.map((color, index) => {
                  return (
                    <MenuItem key={index} value={color}>
                      <Lens style={{color: color}} />
                    </MenuItem>
                  );
                })}
            <MenuItem value={''}>
              <ListItemIcon>
                {isSelectOpen && <Block style={{color: 'red'}} />}
              </ListItemIcon>
            </MenuItem>
          </Select>
        </Grid>
        <Grid item={true} xs={2}>
          <Box display="flex">
            <IconButton
              disabled={hasInviteSent}
              onClick={() => handleAddColumn(option.order)}>
              <Add />
            </IconButton>
            <IconButton
              onClick={() => handleRemoveColumn(option.order)}
              disabled={quantityOfColumns === 1 || hasInviteSent}>
              <Remove />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    );
  },
);
