import React, {memo} from 'react';

import {IOption} from 'shared/services/api/quiz/Quiz';
import {DisplayMode} from '../../DisplayMode';
import {GridUniqueAnswerConfig} from './GridUniqueAnswerConfig';
import {GridUniqueAnswerPreview} from './GridUniqueAnswerPreview';
interface IGridUniqueAnswerProps {
  displayMode: DisplayMode;
  columnOptions: IOption[];
  rowOptions: IOption[];
  onChangeRows: (value: IOption[]) => void;
  onChangeColumns: (value: IOption[]) => void;
}

export const GridUniqueAnswer: React.FC<IGridUniqueAnswerProps> = memo(
  ({displayMode, columnOptions, rowOptions, onChangeRows, onChangeColumns}) => {
    return (
      <>
        {displayMode === DisplayMode.config && (
          <GridUniqueAnswerConfig
            rowOptions={rowOptions}
            onChangeRows={onChangeRows}
            columnOptions={columnOptions}
            onChangeColumns={onChangeColumns}
          />
        )}
        {displayMode === DisplayMode.preview && (
          <GridUniqueAnswerPreview
            rowOptions={rowOptions}
            columnOptions={columnOptions}
          />
        )}
      </>
    );
  },
);
