import {Box, Container, Grid} from '@mui/material';
import {Skeleton} from '@mui/lab';
import React, {memo} from 'react';

export const QuizDetailSkeleton: React.FC = memo(() => {
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={11} sm={11}>
          <Skeleton variant="rectangular" width="auto" height={55} />
        </Grid>

        <Grid item xs={12} sm={11}>
          <Skeleton variant="rectangular" width="auto" height={50} />
          <Box
            marginTop={5}
            display="flex"
            justifyContent="center"
            alignItems="center"></Box>
          <Skeleton variant="rectangular" width="auto" height={50} />
          <Box
            marginTop={3}
            display="flex"
            justifyContent="center"
            alignItems="center"></Box>
          <Skeleton variant="rectangular" width="auto" height={150} />
          <Box
            marginTop={5}
            display="flex"
            justifyContent="center"
            alignItems="center"></Box>
          <Skeleton variant="rectangular" width="auto" height={500} />
        </Grid>
        <Grid item xs={4} sm={1}>
          <Box
            display="flex"
            position="fixed"
            justifyContent="center"
            alignItems="center">
            <Skeleton variant="rectangular" width={60} height={170} />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
});
