import React, {useCallback} from 'react';

import {Switch as MuiSwitch} from '@mui/material';

interface ISwitchProps {
    checked: boolean;
    onChange: (checked: boolean) => void;
    disabled?: boolean;
}

export const Switch: React.FC<ISwitchProps> = ({
    checked,
    onChange,
    disabled = false,
}) => {
    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            onChange(event.target.checked as boolean);
        },
        [onChange],
    );

    return (
        <MuiSwitch
            checked={checked}
            onChange={handleChange}
            disabled={disabled}
            inputProps={{'aria-label': 'secondary checkbox'}}
        />
    );
};
