import React, {useCallback, useMemo, memo} from 'react';

import {Box, Radio, Typography, OutlinedInput, Grid} from '@mui/material';
import {
  SentimentVeryDissatisfied,
  SentimentDissatisfied,
  SentimentSatisfied,
  SentimentSatisfiedAlt,
  SentimentVerySatisfied,
} from '@mui/icons-material';

import {IOption} from 'shared/services/api/quiz/Quiz';
interface ILinearScalePreviewProps {
  value: IOption[];
  hasComment?: boolean;
}

export const LinearScalePreview: React.FC<ILinearScalePreviewProps> = memo(
  ({value, hasComment}: ILinearScalePreviewProps) => {
    const useEmoji = useMemo(() => {
      return value[value.length - 1].order <= 5 ? true : false;
    }, [value]);

    const getEmojiIcon = useCallback((Emoji: string) => {
      const Icon = Emoji.split(',')[0];
      const Color = Emoji.split(',')[1];
      switch (Icon) {
        case 'SentimentVeryDissatisfied':
          return (
            <SentimentVeryDissatisfied
              htmlColor={Color}
              color={Color ? undefined : 'primary'}
              fontSize={'large'}
            />
          );

        case 'SentimentDissatisfied':
          return (
            <SentimentDissatisfied
              htmlColor={Color}
              color={Color ? undefined : 'primary'}
              fontSize={'large'}
            />
          );

        case 'SentimentSatisfied':
          return (
            <SentimentSatisfied
              htmlColor={Color}
              color={Color ? undefined : 'primary'}
              fontSize={'large'}
            />
          );

        case 'SentimentSatisfiedAlt':
          return (
            <SentimentSatisfiedAlt
              htmlColor={Color}
              color={Color ? undefined : 'primary'}
              fontSize={'large'}
            />
          );

        case 'SentimentVerySatisfied':
          return (
            <SentimentVerySatisfied
              htmlColor={Color}
              color={Color ? undefined : 'primary'}
              fontSize={'large'}
            />
          );

        default:
          return <SentimentVeryDissatisfied fontSize={'large'} />;
      }
    }, []);
    return (
      <>
        <Box
          style={{overflowX: 'scroll', whiteSpace: 'nowrap'}}
          display="flex"
          alignItems="center"
          justifyContent={'start'}>
          {value.map((option) =>
            useEmoji ? (
              //emoji
              <Box
                key={option.order + 'previewEmoji'}
                alignItems="center"
                alignContent="center"
                justifyContent={'center'}>
                <Box
                  p={2}
                  fontWeight="fontWeightBold"
                  display="flex"
                  justifyContent="center">
                  {getEmojiIcon(option.iconOrColor ?? '')}
                </Box>
                <Box p={2} fontWeight="fontWeightBold">
                  <Typography>
                    {option.description || 'Opção ' + option.order}
                  </Typography>
                </Box>

                <Box display="flex" justifyContent="center">
                  <Radio checked={false} disabled={true} />
                </Box>
              </Box>
            ) : (
              //color
              <Box
                key={option.order + 'previewColor'}
                alignItems="center"
                alignContent="center"
                justifyContent="center">
                <Box
                  p={2}
                  fontWeight="fontWeightBold"
                  style={{
                    borderRadius: '10px',
                    padding: '10px',
                    marginRight: '10px',
                    background: option.iconOrColor,
                    color: !option.iconOrColor ? 'black' : 'white',
                  }}>
                  <Typography>
                    {option.description || 'Opção ' + option.order}
                  </Typography>
                </Box>

                <Box
                  display="flex"
                  justifyContent="center"
                  style={{
                    padding: '10px',
                    marginRight: '10px',
                  }}>
                  <Radio checked={false} disabled={true} />
                </Box>
              </Box>
            ),
          )}
        </Box>
        {hasComment && (
          <Grid item xs={4}>
            <Box marginTop={10} justifyContent="center" display="flex">
              <OutlinedInput fullWidth placeholder="Comentar" disabled={true} />
            </Box>
          </Grid>
        )}
      </>
    );
  },
);
