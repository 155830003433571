import React from 'react';
import {Box, Container} from '@mui/material';
import {Skeleton} from '@mui/lab';

export const AnsweredQuizSkeleton: React.FC = () => {
  return (
    <>
      <Box overflow="hidden" borderRadius={4}>
        <Skeleton variant="rectangular" width="100%" height={200} />
        <Container marginTop={6} component={Box}>
          <Skeleton variant="text" width="80%" height={40} />
          <Skeleton variant="text" width="60%" height={25} />
        </Container>
        <Container marginTop={4} component={Box}>
          <Skeleton variant="text" width="80%" height={32} />
          <Skeleton variant="text" width="60%" height={25} />
        </Container>
      </Box>
    </>
  );
};
