import {Theme} from '@mui/material/styles';
import {makeStyles} from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  answeredQuizContainer: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0',
      paddingRight: '0',
    },
  },
  footerInner: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      gap: '20px',
    },
  },
  identificationStatus: {
    [theme.breakpoints.down('sm')]: {
      display: 'block !important',
      textAlign: 'center',
    },
  },
  dialogActions: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: '15px',
    },
  },
  dialogActionsButtons: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: 0,
      fontSize: '13px',
    },
  },
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1201,
    width: '100%',
    minHeight: '100vh',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
  },

  banner: {
    width: '100%',
    height: theme.spacing(18),
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundColor: theme.palette.primary.main,
  },

  card: {
    borderTop: 0,
    borderRight: 0,
    borderBottom: 0,
    borderLeft: 10,
    borderRadius: 10,
    borderStyle: 'solid',
    padding: theme.spacing(2),
    borderColor: theme.palette.primary.main,
  },

  container: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    flex: '1 0 auto',
  },

  footer: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(2, 0),
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#fff',
    },
    zIndex: 1201,
    flexShrink: 0,
  },

  colorWhite: {
    color: '#fff',
  },

  gridProgress: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  gridAnsweredQuestions: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },

  gridNameSection: {
    height: '100%',
    gap: 16,
    alignItems: 'center',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },

  closeButton: {
    position: 'absolute',
    color: theme.palette.grey[500],
  },

  dialogTitle: {
    margin: 0,
    padding: theme.spacing(2),
  },

  table: {
    borderRadius: 0,
  },

  buttonAnonymous: {
    color: '#fff !important',
    borderColor: '#fff !important',
    whiteSpace: 'nowrap',
  },
}));
