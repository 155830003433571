import {Api} from 'shared/services';
import {IResultOfAction} from 'shared/services/axios-config/interceptors';

export interface IEstablishmentsProfiles {
  profileId: string;
  establishmentId: number[];
  active: boolean;
}

export interface IPermissionsProfiles {
  id: string;
  name: string;
  userQuantity: number;
}

export interface IPublicoAlvo {
  Habilitado: boolean;
  Estabelecimentos: number[];
  EstabelecimentosEstruturado?: string[];
}

export interface IPermissions {
  Id?: string;
  userCode: number;
  userCodeAuth: number;
  userName: string;
  userLogin: string;
  establishmentProfiles: IEstablishmentsProfiles[];
  publicoAlvo: IPublicoAlvo;
}

const getById = async (
  userId: string,
): Promise<IResultOfAction<IPermissions>> => {
  try {
    const {data} = await Api.get(`/permissoes/usuarios/${userId}`);

    const userPermissions: IPermissions = {
      Id: data.Id,
      userName: data.Nome,
      establishmentProfiles: data.PerfisEstabelecimentos.map((pe: any) => ({
        profileId: pe.IdPerfil || '',
        establishmentId: pe.Estabelecimentos,
        active: pe.Ativo,
      })),
      userCode: data.CodigoUsuario,
      userLogin: data.Login,
      publicoAlvo: data.PublicoAlvo,
      userCodeAuth: data.CodigoUsuarioAuth,
    };

    const response: IResultOfAction<IPermissions> = {
      success: true,
      message: '',
      data: userPermissions,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const getAll = async (): Promise<IResultOfAction<IPermissionsProfiles[]>> => {
  try {
    const {data} = await Api.get('/permissoes/perfis');

    const userPermissionsProfiles: IPermissionsProfiles[] = data.map(
      (user: any) => ({
        id: user.Id,
        name: user.Nome,
        userQuantity: user.QuantidadeUsuarios,
      }),
    );

    const response: IResultOfAction<IPermissionsProfiles[]> = {
      success: true,
      message: '',
      data: userPermissionsProfiles,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const create = async (
  userPermissions: IPermissions,
): Promise<IResultOfAction<any>> => {
  try {
    const postObject = {
      CodigoUsuario: userPermissions.userCode,
      CodigoUsuarioAuth: userPermissions.userCodeAuth,
      Login: userPermissions.userLogin,
      PerfisEstabelecimentos: userPermissions.establishmentProfiles.map(
        (ep) => ({
          IdPerfil: ep.profileId,
          Estabelecimentos: ep.establishmentId,
          Ativo: ep.active,
        }),
      ),
      PublicoAlvo: {
        Estabelecimentos: userPermissions.publicoAlvo.Estabelecimentos,
        Habilitado: userPermissions.publicoAlvo.Habilitado,
      } as IPublicoAlvo,
    };

    const {data} = await Api.post('/permissoes/usuario', postObject);

    const response: IResultOfAction<any> = {
      data: data.Data,
      success: data.Success,
      message: data.Message,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const upgradeById = async (
  userCode: string,
  userPermissions: IPermissions,
): Promise<IResultOfAction<any>> => {
  try {
    const putObject = {
      CodigoUsuario: userPermissions.userCode,
      CodigoUsuarioAuth: userPermissions.userCodeAuth,
      Login: userPermissions.userLogin,
      PerfisEstabelecimentos: userPermissions.establishmentProfiles.map(
        (ep) => ({
          IdPerfil: ep.profileId,
          Estabelecimentos: ep.establishmentId,
          Ativo: ep.active,
        }),
      ),
      PublicoAlvo: userPermissions.publicoAlvo,
    };

    const {data} = await Api.put(`/permissoes/usuario/${userCode}`, putObject);

    const response: IResultOfAction<any> = {
      data: data.Data,
      success: data.Success,
      message: data.Message,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

export const PermissionsDetailService = {
  getAll,
  getById,
  create,
  upgradeById,
};
