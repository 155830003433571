import React from 'react';
import HorizontalBar from 'react-chartjs-2';
import {useStyles} from './styles';
import {IChartProps} from './ChartType';

export const HorizontalBarChart: React.FC<IChartProps> = ({
  labels,
  datasets,
  options,
  width,
  height,
  showDataLabels,
  showAsPercentage,
  displayLegend = false,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.chartWrapper}>
      <HorizontalBar
        data={{labels, datasets}}
        width={width}
        height={height}
        options={{
          ...options,
          layout: {
            padding: {
              left: 1,
              right: 50,
              top: 20,
              bottom: 20,
            },
          },
          scales: {
            x: {
              grid: {
                display: false,
              },
            },
            y: {
              ticks: {
                autoSkip: false,
                font: {
                  size: 15,
                },
              },
              grid: {
                display: false,
              },
            },
          },
          indexAxis: 'y',
          elements: {
            bar: {
              borderWidth: 2,
            },
          },
          plugins: {
            legend: {
              display: displayLegend,
            },
            datalabels: {
              padding: {
                left: 0,
              },
              display: showDataLabels,
              color: '#414141',
              align: 'end',
              anchor: 'end',
              labels: {
                title: {
                  font: {
                    weight: 'normal',
                  },
                },
                value: {
                  color: 'green',
                },
              },
              formatter: function (value: any, context: any) {
                if (showAsPercentage) {
                  const percentual =
                    (Number(value) * 100) /
                    context.chart.data.datasets[
                      context.datasetIndex
                    ].data.reduce(
                      (a: Number, b: Number) => Number(a) + Number(b),
                      0,
                    );

                  return `${isNaN(percentual) ? 0 : percentual.toFixed(2)}%`;
                }
                return value;
              },
            },
          },
        }}
        type="bar"
      />
    </div>
  );
};
