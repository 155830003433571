import React, {memo, useCallback} from 'react';
import {
  Box,
  Checkbox,
  TextField,
  RadioGroup,
  FormControlLabel,
  Grid,
} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {makeStyles} from '@mui/styles';
import clsx from 'clsx';

import {IOptions} from 'shared/services/api/student-quiz/QuestionList';

const useStyles = makeStyles((theme: Theme) => ({
  checkbox: {
    margin: 0,
    borderRadius: theme.spacing(0.5),
  },
  checkboxChecked: {
    backgroundColor: theme.palette.grey[200],
  },
}));

interface IUniqueAnswerProps {
  options: IOptions[];
  comment: string;
  hasComment?: boolean;
  isCompleted: boolean;
  setComment: (comment: string) => void;
  onChange: (options: IOptions[]) => void;
}

export const MultipleAnswerPreview: React.FC<IUniqueAnswerProps> = memo(
  ({hasComment, options, isCompleted, comment, setComment, onChange}) => {
    const styles = useStyles();

    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
        const currentItemSelected = options.findIndex(
          (option) => option.id === id,
        );

        const optionsCopy = options;
        optionsCopy[currentItemSelected].selecionado = event.target.checked;

        onChange(optionsCopy);
      },
      [onChange, options],
    );

    const handleComment = (e: React.ChangeEvent<HTMLInputElement>) => {
      setComment(e.target.value);
    };

    return (
      <Grid item xs={12} sm={12} md={8} lg={8}>
        <RadioGroup style={{gap: 8, marginLeft: 10}} onChange={handleChange}>
          {options.map((option) => {
            return (
              <FormControlLabel
                key={option.id}
                disabled={isCompleted}
                className={clsx(styles.checkbox, {
                  [styles.checkboxChecked]: option.selecionado,
                })}
                control={
                  <Checkbox
                    color="primary"
                    checked={option.selecionado}
                    onChange={(e) => handleChange(e, option.id)}
                    name={option.descricao}
                  />
                }
                label={option.descricao}
              />
            );
          })}
        </RadioGroup>
        {((hasComment && !isCompleted) || (comment !== '' && isCompleted)) && (
          <Box marginTop={3} justifyContent="center" display="flex">
            <TextField
              fullWidth
              multiline
              rows={2}
              disabled={isCompleted}
              color="primary"
              label="Comentário"
              variant="outlined"
              placeholder="Adicionar um comentário"
              value={comment}
              onChange={handleComment}
            />
          </Box>
        )}
      </Grid>
    );
  },
);
