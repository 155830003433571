import React, {memo, useRef, useState, useCallback, useEffect} from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
  IconButton,
  Icon,
  Popover,
} from '@mui/material';
import {AddCircle, Image} from '@mui/icons-material';
import {ColorImage, BannerImage, LogoImage} from './components';
import {ChromePicker} from 'react-color';
import {ModalImage} from './components/ModalImage';
import {useQuiz} from 'pages/quiz/hooks';
import {quizObservable} from 'pages/quiz/observables/Observable';
import {feedback} from 'shared/services';
import {PersonalizationSkeleton} from './components/PersonalizationSekeleton';

export const Personalization: React.FC = memo(() => {
  const {
    isSavingQuiz,
    getBanner,
    setBanner,
    getLogo,
    setLogo,
    getQuizColor,
    setQuizColor,
  } = useQuiz();
  const buttonRef = useRef(null);
  const [colorPickerAnchorElement, setColorPickerAnchorElement] =
    useState(null);
  const popoverOpen = Boolean(colorPickerAnchorElement);
  const [colorPicker, setColorPicker] = useState(
    getQuizColor() !== '' ? `#${getQuizColor()}` : '#018781',
  );
  const [openModalBanner, setOpenModalBanner] = useState(false);
  const [openModalLogo, setOpenModalLogo] = useState(false);
  const [bannerImg, setBannerImg] = useState(getBanner());
  const [logoImg, setLogoImg] = useState(getLogo());
  const [isLoadingPersonalization, setIsLoadingPersonaliztion] = useState(true);

  const handleClickColorPicker = () => {
    setColorPickerAnchorElement(buttonRef.current);
  };

  const handleCloseColorPicker = () => {
    setColorPickerAnchorElement(null);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoadingPersonaliztion(false);
    }, 450);
  }, []);

  useEffect(() => {
    const listener = quizObservable.subscribe(() => {
      setBannerImg(getBanner());
      setLogoImg(getLogo());
      setColorPicker(getQuizColor() !== '' ? `#${getQuizColor()}` : '#018781');
    });
    return () => listener.unsubscribe();
  }, [getBanner, getLogo, getQuizColor]);

  const handleSetNewBanner = useCallback(
    (image: string) => {
      setBannerImg(image);
      setBanner(image);
    },
    [setBanner],
  );

  const handleSetLogo = useCallback(
    (image: string) => {
      setLogoImg(image);
      setLogo(image);
    },
    [setLogo],
  );

  const handleQuizColorChange = useCallback(
    (color: string) => {
      setColorPicker(color);
      if (color === '#ffffff') {
        feedback('A cor branca não é indicada nesse contexto!', 'warning');
      } else {
        setQuizColor(color.replace('#', ''));
      }
    },
    [setQuizColor],
  );

  return (
    <Box p={3}>
      {isLoadingPersonalization ? (
        <PersonalizationSkeleton />
      ) : (
        <>
          <Box width={'100%'}>
            <Box width={'100%'} marginBottom={5}>
              <Typography variant="h6">Logo no cabeçalho</Typography>
              <Typography>
                Essa foto aparecerá na parte superior esquerda do questionário.
              </Typography>
              <Box marginY={3} />
              <Grid container>
                <Grid item xs={12} sm={4}>
                  <LogoImage logo={logoImg} />
                </Grid>
                <Grid item xs={5}>
                  <Typography>
                    Recomendamos usar uma imagem de até 4 MB com pelo menos 200
                    x 200 pixels. Use um arquivo no formato JPG, PNG ou GIF (sem
                    animações).
                  </Typography>
                  <Box marginY={2} />
                  <Button
                    style={{maxWidth: '220px'}}
                    startIcon={<Image />}
                    fullWidth
                    color="primary"
                    variant="outlined"
                    disabled={isLoadingPersonalization || isSavingQuiz}
                    onClick={
                      logoImg === ''
                        ? () => setOpenModalLogo(true)
                        : () => handleSetLogo('')
                    }>
                    {logoImg === '' ? 'Escolher logo' : 'Remover logo'}
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Box width={'100%'} marginBottom={5}>
              <Typography variant="h6">Banner no cabeçalho</Typography>
              <Typography>
                Essa imagem aparecerá na parte superior do formulário.
              </Typography>
              <Box marginY={3} />
              <Grid container>
                <Grid item xs={12} sm={4}>
                  <BannerImage banner={bannerImg} />
                </Grid>
                <Grid item xs={5}>
                  <Typography>
                    Para garantir os melhores resultados em todos os
                    dispositivos, escolha uma imagem de até 6 MB. (Resolução
                    recomendada 900 x 150 pixels)
                  </Typography>
                  <Box marginY={2} />
                  <Button
                    style={{maxWidth: '220px'}}
                    startIcon={<Image />}
                    fullWidth
                    color="primary"
                    variant="outlined"
                    disabled={isLoadingPersonalization || isSavingQuiz}
                    onClick={
                      bannerImg === ''
                        ? () => setOpenModalBanner(true)
                        : () => handleSetNewBanner('')
                    }>
                    {bannerImg === '' ? 'Escolher banner' : 'Remover banner'}
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Box width={'100%'} marginBottom={5}>
              <Typography variant="h6">Cor do tema do questionário</Typography>
              <Typography>
                Escolha a cor que será utilizada na hora de responder o
                questionário.
              </Typography>
              <Box marginY={3} />
              <Grid container component={Box} position="relative">
                <Grid item xs={12} sm={4}>
                  <ColorImage color={colorPicker} />
                </Grid>
                <Grid item xs={5}>
                  <Typography>
                    Utilize o código hexadecimal para incluir uma cor de sua
                    preferência não disponível.{' '}
                    <strong>Cores muito claras não são indicadas.</strong>
                  </Typography>
                  <Box marginY={2}>
                    <IconButton
                      style={{color: '#018781'}}
                      disabled={isLoadingPersonalization || isSavingQuiz}
                      onClick={() => handleQuizColorChange('#018781')}>
                      <Icon>
                        {colorPicker === '#018781'
                          ? 'check_circle_filled'
                          : 'circle_filled'}
                      </Icon>
                    </IconButton>
                    <IconButton
                      style={{color: '#00B9AD'}}
                      disabled={isLoadingPersonalization || isSavingQuiz}
                      onClick={() => handleQuizColorChange('#00B9AD')}>
                      <Icon>
                        {colorPicker === '#00B9AD'
                          ? 'check_circle_filled'
                          : 'circle_filled'}
                      </Icon>
                    </IconButton>
                    <IconButton
                      style={{color: '#4285F4'}}
                      disabled={isLoadingPersonalization || isSavingQuiz}
                      onClick={() => handleQuizColorChange('#4285F4')}>
                      <Icon>
                        {colorPicker === '#4285F4'
                          ? 'check_circle_filled'
                          : 'circle_filled'}
                      </Icon>
                    </IconButton>
                    <IconButton
                      style={{color: '#F4405C'}}
                      disabled={isLoadingPersonalization || isSavingQuiz}
                      onClick={() => handleQuizColorChange('#F4405C')}>
                      <Icon>
                        {colorPicker === '#F4405C'
                          ? 'check_circle_filled'
                          : 'circle_filled'}
                      </Icon>
                    </IconButton>
                    <IconButton
                      style={{color: '#54BDD5'}}
                      disabled={isLoadingPersonalization || isSavingQuiz}
                      onClick={() => handleQuizColorChange('#54BDD5')}>
                      <Icon>
                        {colorPicker === '#54BDD5'
                          ? 'check_circle_filled'
                          : 'circle_filled'}
                      </Icon>
                    </IconButton>
                    <IconButton
                      style={{color: '#EB5333'}}
                      disabled={isLoadingPersonalization || isSavingQuiz}
                      onClick={() => handleQuizColorChange('#EB5333')}>
                      <Icon>
                        {colorPicker === '#EB5333'
                          ? 'check_circle_filled'
                          : 'circle_filled'}
                      </Icon>
                    </IconButton>
                    <IconButton
                      ref={buttonRef}
                      onClick={handleClickColorPicker}
                      disabled={isLoadingPersonalization || isSavingQuiz}>
                      <AddCircle />
                    </IconButton>
                    <Popover
                      open={popoverOpen}
                      anchorEl={colorPickerAnchorElement}
                      onClose={handleCloseColorPicker}
                      anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}>
                      <ChromePicker
                        disableAlpha
                        color={colorPicker}
                        onChange={(color) => handleQuizColorChange(color.hex)}
                      />
                    </Popover>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <ModalImage
            imageId="logo"
            openModal={openModalLogo}
            onClose={() => setOpenModalLogo(false)}
            image={logoImg}
            setImage={handleSetLogo}
            width={200}
            height={200}
          />
          <ModalImage
            imageId="banner"
            openModal={openModalBanner}
            onClose={() => setOpenModalBanner(false)}
            image={bannerImg}
            setImage={handleSetNewBanner}
            maxWidth="lg"
            width={900}
            height={150}
          />
        </>
      )}
    </Box>
  );
});
