import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  DialogActions,
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import React, {useEffect, useState} from 'react';
import {downloadPDF} from 'shared/components/donwload-from-base-64/DownloadFromBase64';
import {feedback} from 'shared/services';
import {
  AnswerSummaryService,
  ICourseTeacher,
  ICourses,
  ICoursesFilter,
  IPostCourses,
} from 'shared/services/api/reports/AnswerSummary';

interface IProp {
  idConvite: string;
}

const useStyles = makeStyles(() => {
  return {
    noPaddingTop: {
      paddingTop: 0,
    },
    noPaddingBottom: {
      paddingBottom: 0,
    },
  };
});

export const ModalCourseSummary: React.FC<IProp> = ({idConvite}) => {
  const {noPaddingTop, noPaddingBottom} = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [courseFilter, setCourseFilter] = useState<ICoursesFilter>({
    Cursos: [],
  });
  const [isCheckboxAll, setIsCheckboxAll] = useState(true);
  const [courseCheckboxHandler, setCourseCheckboxHandler] = useState<
    ICourses[]
  >([{Nome: '', Professores: []}]);

  const handleByCoursesFilter = async () => {
    try {
      const response = await AnswerSummaryService.getCoursesFilter(idConvite);
      setCourseFilter({...courseFilter, Cursos: response.Cursos});
    } catch (error) {
      feedback('erro', 'error');
    }
  };

  useEffect(() => {
    handleByCoursesFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (openModal) {
      setCourseCheckboxHandler(courseFilter.Cursos);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  useEffect(() => {
    isAllCheckVerify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseCheckboxHandler]);

  const handleCourseCheckboxClick = (course: ICourses) => {
    const isChecked = isCourseCheckedVerify(course.Nome);
    const newArray = JSON.parse(
      JSON.stringify(courseCheckboxHandler),
    ) as ICourses[];

    if (isChecked) {
      setCourseCheckboxHandler(
        newArray.filter((curso) => curso.Nome !== course.Nome),
      );
    } else {
      newArray.push({
        Nome: course.Nome,
        Professores: course.Professores,
      });
      setCourseCheckboxHandler(newArray);
    }
  };

  const handleTeacherCheckboxClick = (
    course: ICourses,
    teacher: ICourseTeacher,
  ) => {
    const isChecked = isTeacherCheckerVerify(course.Nome, teacher.Nome);
    const newArray = JSON.parse(
      JSON.stringify(courseCheckboxHandler),
    ) as ICourses[];

    if (!newArray.some((curso) => curso.Nome === course.Nome)) {
      newArray.push({Nome: course.Nome, Professores: [teacher]} as ICourses);
      setCourseCheckboxHandler(newArray);
    } else {
      setCourseCheckboxHandler(
        newArray.map((curso) => {
          if (curso.Nome === course.Nome) {
            isChecked
              ? (curso.Professores = curso.Professores.filter(
                  (prof) => prof.Nome !== teacher.Nome,
                ))
              : curso.Professores.push({
                  Nome: teacher.Nome,
                  Id: teacher.Id,
                });
          }
          return curso;
        }),
      );
    }
  };

  const isTeacherCheckerVerify = (courseName: string, profName: string) => {
    if (isCourseCheckedVerify(courseName)) {
      return courseCheckboxHandler
        .filter((curso) => curso.Nome === courseName)[0]
        .Professores.some((teacher) => teacher.Nome === profName);
    }
    return false;
  };

  const isCourseCheckedVerify = (courseName: string) => {
    return courseCheckboxHandler.some((curso) => curso.Nome === courseName);
  };

  const isAllCheckVerify = () => {
    const isAllTeachersChecked =
      courseFilter.Cursos.map((course) =>
        course.Professores.map((prof) => prof.Id),
      ).flat().length ===
      courseCheckboxHandler
        .map((course) => course.Professores.map((prof) => prof.Id))
        .flat().length;

    const isAllCoursesChecked =
      courseFilter.Cursos.length === courseCheckboxHandler.length;

    setIsCheckboxAll(isAllTeachersChecked && isAllCoursesChecked);
  };

  const allCheckClickHandler = () => {
    isCheckboxAll
      ? setCourseCheckboxHandler([])
      : setCourseCheckboxHandler(courseFilter.Cursos);
  };

  const handlePostCourseFilter = async (courses: IPostCourses) => {
    feedback('Carregando', 'loading');
    try {
      for (const course of courseCheckboxHandler) {
        const coursePost = {
          Cursos: {
            Nome: course.Nome,
            Professores: course.Professores.map((item) => item.Id),
          },
          IdConvite: courses.IdConvite,
        } as IPostCourses;
        const response = await AnswerSummaryService.postCoursesFilter(
          coursePost,
        );
        feedback('PDF abaixado com sucesso', 'success');
        downloadPDF(
          response,
          `relatorio-filtro-${course.Nome.toLocaleLowerCase().replaceAll(
            '.',
            '',
          )}`,
        );
      }
    } catch (error) {
      feedback('Não foi possivel imprimir', 'error');
    }
  };

  const handleCloseModal = () => {
    const namesCourses = courseCheckboxHandler
      .map((course) => course.Nome.toString())
      .filter((name) => name !== '')
      .join();

    const teachersCourses = courseCheckboxHandler
      // eslint-disable-next-line array-callback-return
      .map((course) => {
        if (namesCourses.includes(course.Nome)) {
          return course.Professores.map((prof) => prof.Id);
        }
      })
      .flat();

    const postCourses = {
      IdConvite: idConvite,
      Cursos: {
        Nome: namesCourses,
        Professores: teachersCourses,
      },
    } as IPostCourses;

    handlePostCourseFilter(postCourses);
    setOpenModal(false);
  };

  return (
    <>
      {courseFilter.Cursos.length > 0 ? (
        <>
          <Button
            onClick={() => {
              setOpenModal(true);
            }}
            variant="outlined"
            startIcon={<PictureAsPdfIcon />}
            color="primary"
            size="medium">
            Por curso
          </Button>
          <Dialog
            maxWidth="md"
            open={openModal}
            onClose={() => {
              setOpenModal(false);
            }}>
            <DialogTitle className={noPaddingBottom}>
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}>
                <Box width={'100%'}>
                  <Typography color="primary" variant="h6">
                    Imprimir Resultados Por Curso
                  </Typography>
                </Box>

                <IconButton
                  aria-label="close"
                  onClick={() => {
                    setOpenModal(false);
                  }}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </DialogTitle>
            <DialogTitle>
              <Typography>
                <b>Selecione os cursos e professores desejados</b>
              </Typography>
            </DialogTitle>

            <DialogContent className={noPaddingTop}>
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'start'}>
                <Checkbox
                  checked={isCheckboxAll}
                  color="primary"
                  onClick={() => allCheckClickHandler()}
                />
                <Typography variant="subtitle1">Selecionar todos</Typography>
              </Box>
              <Box width={'800px'} paddingBottom={3}>
                <Box
                  width={'100%'}
                  display="flex"
                  alignItems={'start'}
                  flexDirection={'column'}>
                  {courseFilter.Cursos.map((curso) => {
                    return (
                      <Box
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'start'}>
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'start'}>
                          <Checkbox
                            checked={isCourseCheckedVerify(curso.Nome)}
                            color="primary"
                            onClick={() => handleCourseCheckboxClick(curso)}
                          />
                          <Typography variant="subtitle1">
                            {curso.Nome}
                          </Typography>
                        </Box>
                        <Box>
                          {curso.Professores.map((prof) => {
                            return (
                              <Box
                                width={'100%'}
                                marginLeft={'15%'}
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'start'}>
                                <Checkbox
                                  checked={isTeacherCheckerVerify(
                                    curso.Nome,
                                    prof.Nome,
                                  )}
                                  color="primary"
                                  size="small"
                                  onClick={() =>
                                    handleTeacherCheckboxClick(curso, prof)
                                  }
                                />
                                <Typography variant="subtitle2">
                                  {prof.Nome}
                                </Typography>
                              </Box>
                            );
                          })}
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </DialogContent>
            <DialogActions>
              <Box
                width={'100%'}
                display={'flex'}
                justifyContent={'end'}
                alignItems={'center'}>
                <Button
                  onClick={handleCloseModal}
                  variant="contained"
                  color="primary"
                  size="medium">
                  Imprimir
                </Button>
              </Box>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
