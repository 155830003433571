import React from 'react';

import {Icon, IconButton} from '@mui/material';

import {Tooltip} from '../tooltip/Tooltip';

interface IActionButtonsProps {
    rowId: string | number;
    editTooltipText?: string;
    deleteTooltipText?: string;
    canEdit?: boolean;
    canDelete?: boolean;
    hasTooltip?: boolean;
    onClickEdit: () => void;
    onClickDelete: () => void;
}

export const ActionButtons: React.FC<IActionButtonsProps> = (
    props: IActionButtonsProps,
) => {
    const deleteMessage = props.deleteTooltipText
        ? props.deleteTooltipText
        : 'Não é permitido excluir o registro';
    const editMessage = props.editTooltipText
        ? props.editTooltipText
        : 'Não é permitido editar o registro';

    return (
        <>
            {props.hasTooltip ? (
                <>
                    <Tooltip text={props.canEdit ? 'Editar' : editMessage}>
                        <IconButton
                            aria-label="edit"
                            size="small"
                            onClick={props.onClickEdit}
                            disabled={!props.canEdit}>
                            <Icon fontSize={'small'}>edit</Icon>
                        </IconButton>
                    </Tooltip>
                    <Tooltip text={props.canDelete ? 'Excluir' : deleteMessage}>
                        <IconButton
                            aria-label="delete"
                            size="small"
                            onClick={props.onClickDelete}
                            disabled={!props.canDelete}>
                            <Icon fontSize={'small'}>delete</Icon>
                        </IconButton>
                    </Tooltip>
                </>
            ) : (
                <>
                    <IconButton
                        aria-label="edit"
                        size="small"
                        onClick={props.onClickEdit}
                        disabled={!props.canEdit}>
                        <Icon fontSize={'small'}>edit</Icon>
                    </IconButton>
                    <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={props.onClickDelete}
                        disabled={!props.canDelete}>
                        <Icon fontSize={'small'}>delete</Icon>
                    </IconButton>
                </>
            )}
        </>
    );
};
