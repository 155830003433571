import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {FormControl, Grid, Container} from '@mui/material';
import {useTitle} from 'shared/hooks';
import {
  QuestionaryTypesService,
  IQuestionaryTypes,
} from 'shared/services/api/questionary-types/QuestionaryTypes';
import {feedback, valide} from 'shared/services';
import {IVFormErros, useVFormRef, VForm, VTextField} from 'shared/forms';
import {ActionTab} from 'shared/components/action-tab/ActionTab';

const formValidationSchema = valide.object().shape<IQuestionaryTypes>({
  id: valide.string(),
  nome: valide.string().required().default(''),
  descricao: valide.string(),
});

const defaultValue: IQuestionaryTypes = {
  id: '',
  nome: '',
  descricao: '',
};

export const QuestionaryTypesDetail: React.FC = () => {
  const {id = '0'} = useParams<'id'>();
  const navigate = useNavigate();
  const {setTitle} = useTitle();
  const formRef = useVFormRef();
  const [formState, setFormState] = useState<IQuestionaryTypes>(defaultValue);

  useEffect(() => {
    if (id === 'new') {
      setFormState({nome: '', descricao: ''} as IQuestionaryTypes);
      setTitle('Novo tipo de questionário');
      formRef.current?.setData(defaultValue);
    } else {
      QuestionaryTypesService.getById(id).then((result) => {
        if (result.success) {
          setFormState(result.data);
          formRef.current?.setData(result.data);
          setTitle(`Tipos de questionários: ${result.data.nome}`);
        } else {
          setTitle('Novo tipo de questionário');
          feedback(
            result.message || 'Erro ao carregar o tipo de questionário.',
            'error',
          );
        }
      });
    }
  }, [id, setTitle, formRef]);

  const handleSubmit = useCallback(
    (formData: IQuestionaryTypes) => {
      feedback('Salvando...', 'loading');
      formRef.current?.setErrors({});

      formValidationSchema
        .validate({...formState, ...formData}, {abortEarly: false})
        .then((ValidatedData) => {
          if (!ValidatedData) {
            feedback('Campos inválidos...', 'error');
            return;
          }

          if (id === 'new') {
            QuestionaryTypesService.create(ValidatedData).then((response) => {
              if (response.success) {
                feedback('Tipo de questionário criado com sucesso!', 'success');
                navigate(`/tipos-questionarios/detalhe/${response.data.Id}`);
              } else {
                feedback(
                  response.message || 'Erro ao criar tipo de questionário...',
                  'error',
                );
              }
            });
          } else {
            QuestionaryTypesService.updateById(id, ValidatedData).then(
              (response) => {
                if (response.success) {
                  feedback(
                    'Tipo de questionário atualizado com sucesso!',
                    'success',
                  );
                } else {
                  feedback(
                    response.message ||
                      'Erro ao atualizar tipo de questionário...',
                    'error',
                  );
                }
              },
            );
          }
        })
        .catch((err: valide.ValidationError) => {
          feedback('Campos inválidos', 'error');
          const ValidationErrors: IVFormErros = {};

          err.inner.forEach((error) => {
            ValidationErrors[error.path] = error.message;
          });

          formRef.current?.setErrors(ValidationErrors);
        });
    },
    [formRef, formState, navigate, id],
  );

  return (
    <Container>
      <VForm ref={formRef} onSubmit={handleSubmit}>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={12} marginTop={1}>
            <FormControl margin="none" fullWidth>
              <VTextField
                required
                id="QuestionaryType"
                name="QuestionaryType"
                variant="outlined"
                label="Tipo de questionário"
                value={formState.nome}
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    nome: e.target.value,
                  })
                }
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl margin="none" fullWidth>
              <VTextField
                id="description"
                name="description"
                variant="outlined"
                label="Descrição"
                value={formState.descricao}
                multiline={true}
                rows={5}
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    descricao: e.target.value,
                  })
                }
              />
            </FormControl>
          </Grid>
        </Grid>
      </VForm>

      <ActionTab
        onSave={() => {
          handleSubmit(formState);
        }}
        onCancel={() => {
          navigate('/tipos-questionarios');
        }}
        onCancelLabel={id === 'new' ? 'Cancelar' : 'Voltar'}
      />
    </Container>
  );
};
