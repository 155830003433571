import {Environment} from 'shared/Env';
import {Api} from 'shared/services/axios-config';
import {IResultOfAction} from 'shared/services/axios-config/interceptors';

export interface IUser {
  name: string;
  avatar: string;
}

export interface IRoutes {
  name: string;
  path: string;
  icon: string;
  newTab: boolean;
}

export interface IUserConfig {
  user: IUser;
  routes: IRoutes[];
}

export interface IChangePasswordLink {
  link: string;
}

const getUserConfig = async (): Promise<IResultOfAction<IUserConfig>> => {
  try {
    const {data} = await Api.get('/permissoes/menus');

    const userConfig: IUserConfig = {
      user: {
        name: data.Usuario.Nome,
        avatar: data.Usuario.Avatar,
      },
      routes: data.Menu.map((route: any) => ({
        name: route.Nome,
        path: route.Path,
        icon: route.Icone,
        newTab: route.NovaAba,
      })),
    };

    const response: IResultOfAction<IUserConfig> = {
      data: userConfig,
      message: '',
      success: true,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const signout = async (): Promise<boolean> => {
  try {
    if (Environment.URL_ORIGIN_DEBUG.length > 0) {
      window.location.href = `${Environment.API_GATEWAY}/SignOut?callback_url=${window.location.href}&origin_debug=${Environment.URL_ORIGIN_DEBUG}`;
    } else {
      window.location.href = `${Environment.API_GATEWAY}/SignOut?callback_url=${window.location.href}`;
    }
    return true;
  } catch (error) {
    return false;
  }
};

const getChangePasswordLink = async (): Promise<
  IResultOfAction<IChangePasswordLink>
> => {
  try {
    const {data} = await Api.get(`/alterarSenha`);

    const link: IChangePasswordLink = {
      link: data.Data.Link,
    };

    const response: IResultOfAction<IChangePasswordLink> = {
      data: link,
      success: true,
      message: '',
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

export const UserService = {
  signout,
  getUserConfig,
  getChangePasswordLink,
};
