import {Box, Skeleton} from '@mui/material';

export const Loading = () => {
  return (
    <Box>
      <Box
        display={'flex'}
        justifyContent={'left'}
        alignItems={'center'}
        width={'100%'}
        marginBottom={2}>
        <Box marginRight={2}>
          <Skeleton variant="rounded" width={400} height={40} />
        </Box>
        <Box>
          <Skeleton variant="rounded" width={160} height={40} />
        </Box>
      </Box>
      <Box>
        <Skeleton variant="rounded" width={800} height={320} />
      </Box>
    </Box>
  );
};
