import React, {memo} from 'react';

import {Box, TextField} from '@mui/material';

export const DateConfig: React.FC = memo(() => {
  return (
    <Box>
      <Box marginBottom={5}>
        <TextField
          variant="outlined"
          type="date"
          disabled
          placeholder="DD/MM/AAAA"
        />
      </Box>
    </Box>
  );
});
