import React, {memo} from 'react';

import {Box, TextField} from '@mui/material';

export const TimeConfig: React.FC = memo(() => {
  return (
    <Box>
      <Box marginBottom={5}>
        <TextField
          variant="outlined"
          type="time"
          disabled
          defaultValue="00:00"
        />
      </Box>
    </Box>
  );
});
