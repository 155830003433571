import React, {useCallback, useState, useMemo, useEffect} from 'react';
import {
  Container,
  Grid,
  Typography,
  Box,
  Card,
  CardContent,
  TextField,
  useTheme,
  Icon,
  Link,
  Dialog,
  DialogContent,
  Divider,
  DialogTitle,
  IconButton,
  Button,
  Paper,
  Tooltip,
  Drawer,
  FormControlLabel,
  Switch,
  Checkbox,
} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {makeStyles} from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import CopyIcon from '@mui/icons-material/FileCopy';
import ExportIcon from '@mui/icons-material/GetAppRounded';
import FilterIcon from '@mui/icons-material/FilterListRounded';
import {LineChart, HorizontalBarChart} from '../charts';
import Autocomplete from '@mui/lab/Autocomplete';
import {AnswersSection} from './AnswersSection';
import {feedback} from 'shared/services';
import {format} from 'date-fns';
import {AnswerSummarySkeleton} from './AnswerSummarySkeleton';
import {ActionTab, CentralWarning, ReadMore} from 'shared/components';
import {Skeleton} from '@mui/lab';
import {useReports} from 'pages/reports/hooks/useReports';
import {useLocation, useNavigate} from 'react-router-dom';
import {RemainingParticipants} from './RemainingParticipants';
import {
  AnswerSummaryService,
  IAnswerSummary,
  IAnswerSummarySection,
  IAnswerSummarySectionFilters,
  ISectionList,
} from 'shared/services/api/reports/AnswerSummary';
import {Environment} from 'shared/Env';
import GetAppIcon from '@mui/icons-material/GetApp';
import StorageIcon from '@mui/icons-material/Storage';
import {ModalTeacherSummary} from './ModalTeacherSummary';
import DescriptionIcon from '@mui/icons-material/Description';
import {ModalCourseSummary} from './ModalCourseSummary';
import useInfiniteScroll from 'pages/reports/hooks/useInfiniteScroll';
import {ISection} from 'shared/services/api/reports/IndividualAnswers';
import {ModalSubjectSummary} from './ModalSubjectSummary';

const useStyles = makeStyles((theme: Theme) => ({
  tabActionButton: {
    width: 120,
  },
  chartCard: {
    width: 'calc(50% - 16px)',
    minHeight: 416,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 16,
  },
  chartCardContent: {
    width: '100%',
    padding: 0,
  },
  reportCard: {
    width: '100%',
    minHeight: 145,
    boxShadow:
      '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    display: 'flex',
    alignItems: 'start',
    flexDirection: 'column',
    padding: '20px',
    gap: '0',
    justifyContent: 'center',
    flex: '1 1 0px',

    '& > div > span': {
      fontSize: '3.5rem !important',
    },
  },
  doNotShowOnPrint: {
    '@media print': {
      display: 'none',
    },
  },
  breakPageAfter: {
    '@media print': {
      display: 'block',
      clear: 'both',
      breakAfter: 'page',
      pageBreakAfter: 'always',
      float: 'none',
    },
  },
  breakPageBefore: {
    '@media print': {
      display: 'block',
      clear: 'both',
      breakBefore: 'page',
      pageBreakBefore: 'always',
    },
  },
  paperBackGround: {
    backgroundColor: `#f2fbfb !important`,
  },
  dialogTextColor: {
    color: `#757575 !important `,
  },
  dialogTextSize: {
    fontSize: '1.4rem',
  },
}));

export const AnswerSummary: React.FC = () => {
  const {
    answerSummary,
    answerSummarySection,
    isLoading,
    burnDownChart,
    audiencieComposition,
    filters,
    answersSummaryDifferenceDays,
    answerSummarySectionFilters,
    setAnswerSummarySectionFilters,
    idConvite,
    isLoadingSection,
    changeDashboardValueFormat,
    setChangeDashboardValueFormat,
    sectionList,
  } = useReports();
  const {state} = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const [isLoadingFromFilters, setIsLoadingFromFilters] = useState(false);
  const navigate = useNavigate();
  const [openRemainingParticipants, setOpenRemainingParticipants] =
    useState(false);
  const [sections, setSections] = useState<IAnswerSummarySection[]>(
    answerSummarySection || [],
  );
  const [sectionCards, setSectionCards] =
    useState<IAnswerSummary>(answerSummary);
  const [openOdataInformation, setOpenOdataInformation] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [sectionCurrentIndex, setSectionCurrentIndex] = useState(1);

  const [respostaTextuais, setRespostasTextuais] = useState({
    checked: true,
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [diffDays, setDiffDays] = useState(0);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRespostasTextuais({
      ...respostaTextuais,
      [event.target.name]: event.target.checked,
    });
  };

  const backendOdataUrl = useMemo(() => {
    return `${Environment.URL_BACKEND}/odata/RespostasEncerradas?$select=MultiplicarDisciplina,Id,NomeDisciplina,NomePessoa,IdQuestao,Enunciado,IdPessoaConvite,Comentario,TipoQuestao,RespostaLinha,RespostaColuna,IdLinhaSelecionado,IdColunaSelecionado,CodigoUsuario,Papel,Matricula,DescricaoCurso&$filter=Id eq ${idConvite}`;
  }, [idConvite]);

  useEffect(() => {
    setSections(answerSummarySection);
    setSectionCards(answerSummary);
  }, [answerSummarySection, answerSummary]);

  const handleClose = () => {
    setOpenRemainingParticipants(false);
  };

  const camelCaseText = (str: string) => {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word: string, index: number) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, '');
  };

  const normalizeText = useCallback((value: string) => {
    const resultFilter = value
      .toLocaleLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

    return camelCaseText(resultFilter);
  }, []);

  const orderSections = (
    s1: IAnswerSummarySection | ISection | ISectionList,
    s2: IAnswerSummarySection | ISection | ISectionList,
  ) => {
    const order1 = s1.ordem,
      order2 = s2.ordem;

    if (order1 < order2) return -1;
    if (order1 > order2) return 1;

    return 0;
  };

  const allSectionIds = useMemo(() => {
    return sectionList.sort(orderSections).map((sec) => sec.id);
  }, [sectionList]);

  const handleChangeSectionFilters = useCallback(
    (keyFiltro: string, valueFiltro: string) => {
      const filtros = {
        ...answerSummarySectionFilters,
        [normalizeText(keyFiltro)]: valueFiltro || '',
      };
      setAnswerSummarySectionFilters(filtros);
    },
    [
      answerSummarySectionFilters,
      normalizeText,
      setAnswerSummarySectionFilters,
    ],
  );

  const handleLoadMoreSections = useCallback(() => {
    if (sectionCurrentIndex === allSectionIds.length) {
      setIsFetching(false);
      return;
    }

    const tempFilters: IAnswerSummarySectionFilters = {
      ...answerSummarySectionFilters,
      secao: allSectionIds[sectionCurrentIndex],
    };
    feedback('Carregando mais seções...', 'loading');

    AnswerSummaryService.getAnswerSummarySectionsById(
      idConvite,
      tempFilters,
    ).then((result) => {
      if (result.success) {
        setSections((sections) => {
          const existingIds = new Set(sections.map((sec) => sec.id));
          const mergedSections = [
            ...sections,
            ...result.data.dadosSecoes.filter(
              (newSection) => !existingIds.has(newSection.id),
            ),
          ];
          return mergedSections;
        });
        setSectionCurrentIndex((prevIndex) => prevIndex + 1);
        feedback('Seções carregadas!', 'success');
        setIsFetching(false);
      } else {
        feedback(result.message || 'Erro ao carregar seções...', 'error');
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    idConvite,
    allSectionIds,
    sectionCurrentIndex,
    answerSummarySectionFilters,
  ]);

  const [isFetching, setIsFetching] = useInfiniteScroll(handleLoadMoreSections);

  const handleGetFilteredSections = useCallback(() => {
    setShowFilters(false);
    setIsLoadingFromFilters(true);
    feedback('Carregando seções...', 'loading');

    AnswerSummaryService.getAnswerSummaryById(
      idConvite,
      answerSummarySectionFilters,
    ).then((result) => {
      if (result.success) {
        setSectionCards(result.data);
        const todayDate = new Date().valueOf();
        const dataHoraFim = new Date(
          result.data.dataHoraFim as string,
        ).valueOf();
        const day = 24 * 60 * 60 * 1000;
        setDiffDays(Math.round((dataHoraFim - todayDate) / day));
      } else {
        feedback(
          result.message || 'Erro ao carregar resumo de respostas...',
          'error',
        );
        feedback(result.message || 'Erro ao carregar seções...', 'error');
      }
    });

    AnswerSummaryService.getAnswerSummarySectionsById(
      idConvite,
      answerSummarySectionFilters,
    ).then((result) => {
      if (result.success) {
        setSections([...result.data.dadosSecoes].sort(orderSections));
        if (result.data.dadosSecoes.length) {
          setIsFetching(false);
          setSectionCurrentIndex(allSectionIds.length);
        }
        setIsLoadingFromFilters(false);
        feedback('Filtros aplicados!', 'success');
      } else {
        feedback(result.message || 'Erro ao carregar seções...', 'error');
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    answerSummarySectionFilters,
    answerSummary,
    idConvite,
    setAnswerSummarySectionFilters,
    allSectionIds,
    sectionCurrentIndex,
  ]);

  const handleClearFilters = useCallback(() => {
    const tempFilters: IAnswerSummarySectionFilters = {
      papel: '',
      secao: '',
      tipoQuestao: '',
      coordenador: '',
      curso: '',
      disciplina: '',
      turma: '',
    };
    setAnswerSummarySectionFilters(tempFilters);
    setShowFilters(false);
    setIsLoadingFromFilters(true);
    feedback('Carregando seções...', 'loading');

    AnswerSummaryService.getAnswerSummaryById(idConvite, tempFilters).then(
      (result) => {
        if (result.success) {
          setSectionCards(result.data);
          const todayDate = new Date().valueOf();
          const dataHoraFim = new Date(
            result.data.dataHoraFim as string,
          ).valueOf();
          const day = 24 * 60 * 60 * 1000;
          setDiffDays(Math.round((dataHoraFim - todayDate) / day));
        } else {
          feedback(
            result.message || 'Erro ao carregar resumo de respostas...',
            'error',
          );
          feedback(result.message || 'Erro ao carregar seções...', 'error');
        }
      },
    );

    AnswerSummaryService.getAnswerSummarySectionsById(
      idConvite,
      tempFilters,
    ).then((result) => {
      if (result.success) {
        setSections([...result.data.dadosSecoes].sort(orderSections));
        if (result.data.dadosSecoes.length) {
          const idToContinueFrom =
            result.data.dadosSecoes[
              result.data.dadosSecoes.length - 1 > 0
                ? result.data.dadosSecoes.length - 1
                : 0
            ].id;
          const continueFromIndex = allSectionIds.indexOf(idToContinueFrom);
          setSectionCurrentIndex(
            continueFromIndex > -1 ? continueFromIndex + 1 : 0,
          );
          setIsFetching(false);
        }
        setIsLoadingFromFilters(false);
        feedback('Filtros aplicados!', 'success');
      } else {
        feedback(result.message || 'Erro ao carregar seções...', 'error');
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allSectionIds,
    setAnswerSummarySectionFilters,
    handleGetFilteredSections,
    idConvite,
    answerSummary,
  ]);

  const handleCloseOdata = useCallback(() => {
    setOpenOdataInformation(false);
  }, []);

  const copyToClipBoard = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  const handleContentDownload = useCallback(
    (data: string, filename: string, type: string) => {
      const file = new Blob([data], {type: type});
      const fileLink = document.createElement('a');
      const url = URL.createObjectURL(file);

      fileLink.href = url;
      fileLink.download = filename;
      document.body.appendChild(fileLink);
      fileLink.click();

      setTimeout(function () {
        document.body.removeChild(fileLink);
        window.URL.revokeObjectURL(url);
      }, 100);
    },
    [],
  );

  const handleDownloadPessoas = useCallback(() => {
    feedback('Carregando dados...', 'loading');
    AnswerSummaryService.downloadReport(
      idConvite,
      respostaTextuais.checked,
    ).then((result) => {
      if (result.success) {
        feedback('Dados prontos para download.', 'success');
        const fileName = `dados_relatorio_${format(
          new Date(),
          'dd_MM_yyyy HH_mm_ss',
        )}`;

        handleContentDownload(result.data.fileContent, fileName, 'text/csv');
      } else {
        feedback(
          result.message || 'Erro ao carregar dados para download.',
          'error',
        );
      }
    });
  }, [idConvite, handleContentDownload, respostaTextuais.checked]);

  const handleDownloadByCourse = useCallback(() => {
    feedback('Carregando dados...', 'loading');
    AnswerSummaryService.downloadGroupedByCourse(idConvite).then((result) => {
      if (result.success) {
        feedback('Dados prontos para download.', 'success');
        const fileName = `dados_relatorio_${format(
          new Date(),
          'dd_MM_yyyy HH_mm_ss',
        )}`;

        handleContentDownload(result.data.fileContent, fileName, 'text/csv');
      } else {
        feedback(
          result.message || 'Erro ao carregar dados para download.',
          'error',
        );
      }
    });
  }, [idConvite, handleContentDownload]);

  const handleDownloadBySubject = useCallback(() => {
    feedback('Carregando dados...', 'loading');
    AnswerSummaryService.downloadGroupedBySubject(idConvite).then((result) => {
      if (result.success) {
        feedback('Dados prontos para download.', 'success');
        const fileName = `dados_relatorio_${format(
          new Date(),
          'dd_MM_yyyy HH_mm_ss',
        )}`;

        handleContentDownload(result.data.fileContent, fileName, 'text/csv');
      } else {
        feedback(
          result.message || 'Erro ao carregar dados para download.',
          'error',
        );
      }
    });
  }, [idConvite, handleContentDownload]);

  const handleDownloadByTextAnswer = useCallback(() => {
    feedback('Carregando dados...', 'loading');
    AnswerSummaryService.downloadGroupedTextAnswer(idConvite).then((result) => {
      if (result.success) {
        feedback('Dados prontos para download.', 'success');
        const fileName = `dados_relatorio_${format(
          new Date(),
          'dd_MM_yyyy HH_mm_ss',
        )}`;

        handleContentDownload(result.data.fileContent, fileName, 'text/csv');
      } else {
        feedback(
          result.message || 'Erro ao carregar dados para download.',
          'error',
        );
      }
    });
  }, [idConvite, handleContentDownload]);

  const handleChangeDashboardValues = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setChangeDashboardValueFormat(event.target.checked);
  };

  if (
    answerSummary.percentualParticipacao <= 0 &&
    (!isLoading || !isLoadingSection)
  ) {
    return (
      <Container>
        <Box
          width={'100%'}
          minHeight={50}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'flex-start'}>
          <ReadMore
            cutAt={150}
            text={answerSummary?.tituloConvite}
            variant={'h6'}
          />
        </Box>
        <Box width={'100%'} marginBottom={5} marginTop={5}>
          <CentralWarning
            variant={'warning'}
            children={
              'Por favor, aguarde até que o questionário seja respondido para acessar os dados do relatório.'
            }
          />
        </Box>
        <ActionTab
          onCancel={() => {
            navigate('/relatorios', {state: state, replace: true});
          }}
          onCancelLabel="Voltar"
        />
      </Container>
    );
  }

  return (
    <Container>
      {isLoading ? (
        <AnswerSummarySkeleton />
      ) : (
        <Box>
          <Box
            width={'100%'}
            height={'100%'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            marginBottom={2}>
            <Grid item xs={6}>
              <Box
                width={'100%'}
                height={'100%'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'flex-end'}>
                <ReadMore
                  cutAt={150}
                  text={answerSummary.tituloConvite}
                  variant={'h6'}
                />
              </Box>
            </Grid>
            <Grid className={classes.doNotShowOnPrint} item xs={6}>
              <Box
                width={'100%'}
                justifyContent={'flex-end'}
                alignItems={'center'}
                display={'flex'}
                gap={1}>
                <ModalCourseSummary idConvite={idConvite} />

                <Box>
                  <ModalSubjectSummary idConvite={idConvite} />
                </Box>
                <Box>
                  <ModalTeacherSummary idConvite={idConvite} />
                </Box>
                <Button
                  onClick={() => setOpenOdataInformation(true)}
                  startIcon={<GetAppIcon />}
                  size="medium"
                  variant="outlined"
                  color="primary">
                  Exportar Dados
                </Button>
              </Box>
            </Grid>
          </Box>
          <Box width={'100%'} className={classes.breakPageAfter}>
            <Grid container spacing={4}>
              <Grid item xs={3}>
                <Box
                  className={classes.reportCard}
                  position={'relative'}
                  zIndex={3}>
                  <Box
                    position={'absolute'}
                    top={'31px'}
                    zIndex={2}
                    right={'23px'}>
                    <Icon color="disabled">forward_to_inbox_outlined</Icon>
                  </Box>
                  <Typography variant={'h4'}>
                    {sectionCards.convitesEnviados}
                  </Typography>
                  <Typography component={'p'}>
                    {sectionCards.convitesEnviados > 1
                      ? 'Convites '
                      : 'Convite '}
                    {sectionCards.convitesEnviados > 1 ? 'enviados' : 'enviado'}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box
                  className={classes.reportCard}
                  position={'relative'}
                  zIndex={3}>
                  <Box
                    position={'absolute'}
                    top={'31px'}
                    zIndex={2}
                    right={'23px'}>
                    <Icon color="disabled">check_outlined</Icon>
                  </Box>
                  <Typography variant={'h4'}>
                    {sectionCards.questionariosRecebidos}
                  </Typography>
                  <Typography component={'p'}>
                    {sectionCards.questionariosRecebidos > 1
                      ? 'Questionários '
                      : 'Questionário '}
                    {sectionCards.questionariosRecebidos > 1
                      ? 'recebidos'
                      : 'recebido'}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box
                  className={classes.reportCard}
                  position={'relative'}
                  zIndex={3}>
                  <Box
                    position={'absolute'}
                    top={'31px'}
                    zIndex={2}
                    right={'23px'}>
                    <Icon color="disabled">data_saver_off_outlined</Icon>
                  </Box>
                  <Typography
                    variant={
                      'h4'
                    }>{`${sectionCards.percentualParticipacao.toFixed(
                    2,
                  )}%`}</Typography>
                  <Typography component={'p'}>de participação</Typography>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box
                  className={classes.reportCard}
                  position={'relative'}
                  zIndex={3}>
                  <Box
                    position={'absolute'}
                    top={'31px'}
                    zIndex={2}
                    right={'23px'}>
                    <Icon color="disabled">visibility_off_outlined</Icon>
                  </Box>
                  <Typography variant={'h4'}>
                    {sectionCards.respostasAnonimas}
                  </Typography>
                  <Typography component={'p'}>respostas anônimas</Typography>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box
                  className={classes.reportCard}
                  position={'relative'}
                  zIndex={3}
                  style={{
                    backgroundColor:
                      answersSummaryDifferenceDays > 0
                        ? theme.palette.primary.light
                        : theme.palette.error.dark,
                  }}>
                  <Box
                    position={'absolute'}
                    top={'31px'}
                    zIndex={2}
                    right={'23px'}>
                    <Icon color="disabled">
                      {answersSummaryDifferenceDays > 0
                        ? 'check_circle_outlined'
                        : 'cancel_outlined'}
                    </Icon>
                  </Box>
                  <Typography component={'p'}>Status</Typography>
                  <Typography variant={'h4'}>
                    {answersSummaryDifferenceDays > 0 ? 'Ativo' : 'Encerrado'}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box
                  className={classes.reportCard}
                  position={'relative'}
                  zIndex={3}
                  style={{
                    backgroundColor:
                      answersSummaryDifferenceDays > 0
                        ? theme.palette.primary.light
                        : theme.palette.error.dark,
                  }}>
                  <Box
                    position={'absolute'}
                    top={'31px'}
                    zIndex={2}
                    right={'23px'}>
                    <Icon color="disabled">event_outlined</Icon>
                  </Box>
                  <Typography component={'p'}>
                    {answersSummaryDifferenceDays > 0
                      ? 'Encerra em'
                      : 'Encerrado em '}
                  </Typography>
                  <Typography variant={'h4'}>
                    {answersSummaryDifferenceDays > 0
                      ? `${Math.abs(answersSummaryDifferenceDays)} dias`
                      : format(
                          new Date(
                            sectionCards.dataHoraFim as string,
                          ).valueOf(),
                          'dd/MM/yyyy',
                        )}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box
                  className={classes.reportCard}
                  position={'relative'}
                  zIndex={3}>
                  <Box
                    position={'absolute'}
                    top={'31px'}
                    zIndex={2}
                    right={'23px'}>
                    <Icon color="disabled">comments_disabled_outlined</Icon>
                  </Box>
                  <Typography variant={'h4'}>
                    {sectionCards.numeroConviteSemRespostas}
                  </Typography>
                  <Link
                    onClick={() => {}}
                    style={{color: 'white'}}
                    underline="none">
                    pessoas ainda não responderam
                  </Link>
                </Box>
              </Grid>
              {sectionCards.percentualGeral !== 0 ? (
                <Grid item xs={3}>
                  <Box
                    className={classes.reportCard}
                    position={'relative'}
                    zIndex={3}>
                    <Box
                      position={'absolute'}
                      top={'31px'}
                      zIndex={2}
                      right={'23px'}>
                      <Icon color="disabled">sentiment_satisfied_alt</Icon>
                    </Box>
                    <Typography variant={'h4'}>
                      {sectionCards.percentualGeral.toFixed(2)}
                    </Typography>
                    <Link
                      onClick={() => {}}
                      style={{color: 'white'}}
                      underline="none">
                      de satisfação
                    </Link>
                  </Box>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </Box>
          {isLoadingSection ? (
            <Box
              width={'100%'}
              marginTop={4}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              gap={32}>
              <Skeleton variant="rectangular" width={'50%'} height={416} />
              <Skeleton variant="rectangular" width={'50%'} height={416} />
            </Box>
          ) : (
            <>
              <Box
                width={'100%'}
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
                marginTop={4}>
                <Card className={classes.chartCard}>
                  {sectionCards.percentualParticipacao > 0 ? (
                    <>
                      <Box width={'100%'} textAlign={'left'}>
                        <Typography variant={'h6'}>
                          Ritmo de participação
                        </Typography>
                      </Box>
                      <CardContent className={classes.chartCardContent}>
                        <LineChart
                          labels={burnDownChart.map((chartData) =>
                            format(
                              new Date(chartData.data.split('T')[0]),
                              'dd/MM/yyyy',
                            ),
                          )}
                          datasets={[
                            {
                              label: 'Planejado',
                              showLine: true,
                              fill: 10,
                              backgroundColor: theme.palette.primary.main,
                              borderColor: theme.palette.primary.main,
                              borderWidth: 1,
                              hoverBackgroundColor: theme.palette.primary.main,
                              hoverBorderColor: theme.palette.primary.main,
                              data: burnDownChart.map(
                                (points) => points.valorEsperado,
                              ),
                            },
                            {
                              label: 'Executado',
                              showLine: true,
                              fill: 10,
                              backgroundColor: '#335DAB',
                              borderColor: '#335DAB',
                              borderWidth: 1,
                              hoverBackgroundColor: '#335DAB',
                              hoverBorderColor: '#335DAB',
                              data: burnDownChart.map(
                                (points) =>
                                  points.valorEsperado - points.valorExecutado,
                              ),
                            },
                          ]}
                          options={{
                            maintainAspectRatio: false,
                            responsive: true,
                          }}
                          width={470}
                          height={300}
                          showDataLabels={false}
                        />
                      </CardContent>
                    </>
                  ) : (
                    <Box width={'100%'} textAlign={'left'}>
                      <Typography variant={'h6'}>
                        Ritmo de participação
                      </Typography>
                      <Typography color="secondary">
                        Nenhuma participação neste questionário ainda...
                      </Typography>
                    </Box>
                  )}
                </Card>
                <Card className={classes.chartCard}>
                  {audiencieComposition.length > 0 ? (
                    <>
                      <Box width={'100%'} textAlign={'left'}>
                        <Typography variant={'h6'}>Público-alvo</Typography>
                        <Typography>
                          Número de pessoas que já responderam por papéis
                        </Typography>
                      </Box>
                      <CardContent className={classes.chartCardContent}>
                        <HorizontalBarChart
                          labels={audiencieComposition.map(
                            (publi) => publi.papel,
                          )}
                          datasets={[
                            {
                              label: 'Respondido',
                              barPercentage: 0.55,
                              backgroundColor: [
                                '#018781',
                                '#00B9AD',
                                '#4285F4',
                                '#F4405C',
                                '#54BDD5',
                                '#EB5333',
                              ],
                              borderColor: [
                                '#018781',
                                '#00B9AD',
                                '#4285F4',
                                '#F4405C',
                                '#54BDD5',
                                '#EB5333',
                              ],
                              borderWidth: 1,
                              hoverBackgroundColor: [
                                '#018781',
                                '#00B9AD',
                                '#4285F4',
                                '#F4405C',
                                '#54BDD5',
                                '#EB5333',
                              ],
                              hoverBorderColor: [
                                '#018781',
                                '#00B9AD',
                                '#4285F4',
                                '#F4405C',
                                '#54BDD5',
                                '#EB5333',
                              ],
                              data: audiencieComposition.map(
                                (publi) => publi.valor,
                              ),
                            },
                          ]}
                          options={{
                            maintainAspectRatio: false,
                            responsive: true,
                          }}
                          width={450}
                          height={195}
                          showDataLabels={true}
                        />
                      </CardContent>
                    </>
                  ) : (
                    <>
                      <Box width={'100%'} textAlign={'left'}>
                        <Typography variant={'h6'}>Público-alvo</Typography>
                        <Typography>
                          Número de pessoas que já responderam por papéis:
                        </Typography>
                        <Typography color="secondary">
                          Nenhum público para o questionário...
                        </Typography>
                      </Box>
                    </>
                  )}
                </Card>
              </Box>
            </>
          )}
          <Box
            width={'100%'}
            marginTop={10}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            flexWrap={'wrap'}>
            {isLoadingSection ? (
              <Box
                width={'100%'}
                marginBottom={3}
                display="flex"
                justifyContent="flex-end"
                alignItems="center">
                <Skeleton variant="rectangular" width={153} height={36} />
              </Box>
            ) : (
              <>
                <Drawer
                  anchor="right"
                  open={showFilters}
                  onClose={() => setShowFilters(false)}>
                  <Box width={'100%'} padding={2}>
                    <Typography variant="body1" gutterBottom>
                      <strong>Selecione as opções para filtrar</strong>
                    </Typography>
                    {answerSummary.percentualParticipacao > 0 &&
                    filters.length > 0 ? (
                      filters.map((filtro, i) => (
                        <Box
                          key={`filter-questions-box-${i}`}
                          width={300}
                          marginBottom={2}>
                          <Autocomplete
                            fullWidth
                            key={`filter-questions-${i}`}
                            onChange={(_, value) => {
                              handleChangeSectionFilters(
                                filtro.nome,
                                value?.id || '',
                              );
                            }}
                            value={{
                              id:
                                answerSummarySectionFilters[
                                  normalizeText(
                                    filtro.nome,
                                  ) as keyof IAnswerSummarySectionFilters
                                ] || '',
                              nome:
                                filtro.itens.filter(
                                  (item) =>
                                    item.id ===
                                    answerSummarySectionFilters[
                                      normalizeText(
                                        filtro.nome,
                                      ) as keyof IAnswerSummarySectionFilters
                                    ],
                                )[0]?.nome || '',
                            }}
                            noOptionsText={'Sem opções'}
                            options={filtro.itens || []}
                            getOptionLabel={(option) =>
                              option.nome || 'Selecione'
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                id={`filter-option-${i}-${normalizeText(
                                  filtro.nome,
                                )}`}
                                value={
                                  answerSummarySectionFilters[
                                    normalizeText(
                                      filtro.nome,
                                    ) as keyof IAnswerSummarySectionFilters
                                  ]
                                }
                                label={`Filtrar ${filtro.nome}`}
                                variant="outlined"
                                placeholder="Selecione"
                              />
                            )}
                          />
                        </Box>
                      ))
                    ) : (
                      <Box marginBottom={2}>
                        <Typography variant="caption" color="textSecondary">
                          Filtros indisponíveis.
                        </Typography>
                      </Box>
                    )}
                    <Box
                      width={'100%'}
                      marginBottom={2}
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'space-between'}>
                      <Button
                        disabled={filters.length <= 0}
                        onClick={() => handleClearFilters()}
                        size="medium"
                        variant="outlined"
                        color="primary">
                        Limpar
                      </Button>
                      <Button
                        disabled={filters.length <= 0}
                        onClick={() => handleGetFilteredSections()}
                        size="medium"
                        variant="contained"
                        color="primary">
                        Aplicar
                      </Button>
                    </Box>
                  </Box>
                </Drawer>
                <Box
                  className={classes.doNotShowOnPrint}
                  width={'100%'}
                  marginBottom={2}
                  display={'flex'}
                  justifyContent={'space-between'}
                  alignItems={'center'}>
                  <FormControlLabel
                    control={
                      <Switch
                        size="medium"
                        checked={changeDashboardValueFormat}
                        onChange={handleChangeDashboardValues}
                        name="percent-switch"
                        color="primary"
                      />
                    }
                    label="Visualizar valores em porcentagem"
                  />
                </Box>
              </>
            )}
          </Box>
        </Box>
      )}
      {isLoading || isLoadingFromFilters || isLoadingSection ? (
        <>
          <Skeleton variant="rectangular" width={'100%'} height={800} />
        </>
      ) : (
        <Box width={'100%'} className={classes.breakPageBefore}>
          {sections.length > 0 ? (
            <>
              {sections.map((sec, i) => (
                <AnswersSection
                  key={`section-${sec.id}`}
                  id={sec.id}
                  ordem={sec.ordem}
                  nomeSecao={sec.nomeSecao}
                  questoes={sec.questoes}
                  multiplicador={sec.multiplicador}
                />
              ))}
              {isFetching && sectionCurrentIndex < allSectionIds.length && (
                <Box marginTop={2}>
                  <Skeleton variant="rectangular" width={'100%'} height={800} />
                </Box>
              )}
            </>
          ) : (
            <Box width={'100%'} marginBottom={5}>
              <CentralWarning
                variant={'info'}
                children={'Nenhuma seção encontrada...'}
              />
            </Box>
          )}
        </Box>
      )}
      <ActionTab
        onCancel={() => {
          navigate('/relatorios', {state: state, replace: true});
        }}
        onCancelLabel="Voltar">
        <Button
          disabled={isLoadingSection}
          onClick={() => setShowFilters(true)}
          startIcon={<FilterIcon />}
          size="medium"
          variant="contained"
          color="primary">
          Filtrar
        </Button>
      </ActionTab>
      <Dialog fullScreen open={openRemainingParticipants} onClose={handleClose}>
        <DialogTitle>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}>
            <Box width={'100%'}>
              <Typography color="primary" variant="h6">
                Pessoas que ainda não responderam
              </Typography>
            </Box>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <RemainingParticipants />
        </DialogContent>
      </Dialog>

      <Dialog
        maxWidth="md"
        open={openOdataInformation}
        onClose={handleCloseOdata}>
        <DialogTitle>
          <Grid container>
            <Grid item xs={12}>
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}>
                <Box marginRight={1} marginTop={0.5}>
                  <StorageIcon color="primary" />
                </Box>
                <Box width={'100%'}>
                  <Typography color="primary" variant="h6">
                    Credenciais para integração com OData
                  </Typography>
                </Box>
                <IconButton aria-label="close" onClick={handleCloseOdata}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <Box marginBottom={1.5}>
            <Typography className={classes.dialogTextColor}>
              <i>
                O ODATA permite a consulta a todos os dados da avaliação,
                permitimdo selecionar somente as colunas desejadas além a
                aplicação de filtros. para conhecer mais sobre o protocolo,
                acesse a documentação oficial em
                https://www.odata.org/documentation/
              </i>
            </Typography>
          </Box>
        </DialogContent>
        <Box padding={4} marginTop={-1} marginBottom={-1.5}>
          <Box className={classes.paperBackGround}>
            <DialogContent>
              <Box width={'800px'} paddingY={3}>
                <Grid container alignItems="center">
                  <Grid container alignItems="center" item xs={12}>
                    <Grid item xs={12}>
                      <Box
                        width={'100%'}
                        display="flex"
                        marginBottom={4}
                        gap={10}>
                        <Paper style={{width: '100%'}}>
                          <TextField
                            style={{padding: 0, margin: 0}}
                            fullWidth
                            placeholder="URL"
                            label={'URL'}
                            variant="outlined"
                            defaultValue={backendOdataUrl}
                            InputProps={{
                              style: {
                                height: 40,
                              },
                              readOnly: true,
                            }}
                          />
                        </Paper>
                        <Tooltip title={'Copiar URL completa do OData'}>
                          <IconButton
                            onClick={() => copyToClipBoard(backendOdataUrl)}>
                            <CopyIcon color="primary" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container alignItems="center" item xs={12}>
                    <Grid item xs={12}>
                      <Box width={'100%'} display="flex" gap={10}>
                        <Paper style={{width: '100%'}}>
                          <TextField
                            style={{padding: 0, margin: 0}}
                            fullWidth
                            placeholder="Token"
                            label={'Token'}
                            variant="outlined"
                            defaultValue={
                              answerSummary.tokenRelatorio ||
                              'Token Indisponível'
                            }
                            InputProps={{
                              style: {
                                height: 40,
                              },
                              readOnly: true,
                            }}
                          />
                        </Paper>
                        <Tooltip title={'Copiar Token'}>
                          <IconButton
                            onClick={() =>
                              copyToClipBoard(
                                answerSummary.tokenRelatorio || '',
                              )
                            }>
                            <CopyIcon color="primary" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </DialogContent>
          </Box>
        </Box>

        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <Box
                marginTop={-2}
                paddingY={2}
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}>
                <Box display={'flex'} alignItems={'center'}>
                  <Box marginRight={1}>
                    <DescriptionIcon color="primary" />
                  </Box>
                  <Box>
                    <Typography
                      className={classes.dialogTextSize}
                      color="primary">
                      <>Exportar tudo</>
                    </Typography>
                  </Box>
                  <Box marginLeft={2}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          style={{marginRight: -2}}
                          checked={respostaTextuais.checked}
                          onChange={handleChange}
                          name="checked"
                        />
                      }
                      style={{marginRight: -4}}
                      className={classes.dialogTextColor}
                      label="Exportar respostas textuais"
                    />
                  </Box>
                </Box>
                <Box>
                  <Button
                    onClick={() => handleDownloadPessoas()}
                    startIcon={<ExportIcon />}
                    size="medium"
                    variant="outlined"
                    color="primary">
                    Baixar
                  </Button>
                </Box>
              </Box>

              <Box marginLeft={1}>
                <Typography className={classes.dialogTextColor}>
                  Esta opção permite exportar todos os dados detalhados da
                  avaliação em um arquivo Excel
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <Box
                marginTop={2}
                paddingY={2}
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}>
                <Box display={'flex'} alignItems={'center'}>
                  <Box marginRight={1}>
                    <DescriptionIcon color="primary" />
                  </Box>
                  <Box>
                    <Typography
                      className={classes.dialogTextSize}
                      color="primary">
                      <>Exportar respostas agrupadas por curso</>
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Button
                    onClick={() => handleDownloadByCourse()}
                    startIcon={<ExportIcon />}
                    size="medium"
                    variant="outlined"
                    color="primary">
                    Baixar
                  </Button>
                </Box>
              </Box>
              <Box marginTop={-2.5} marginLeft={1}>
                <Typography className={classes.dialogTextColor}>
                  Baixe apenas o total de resposta para cada curso
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <Box
                marginTop={2}
                paddingY={2}
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}>
                <Box display={'flex'} alignItems={'center'}>
                  <Box marginRight={1}>
                    <DescriptionIcon color="primary" />
                  </Box>
                  <Box>
                    <Typography
                      className={classes.dialogTextSize}
                      color="primary">
                      <>Exportar respostas agrupadas por disciplina</>
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Button
                    onClick={() => handleDownloadBySubject()}
                    startIcon={<ExportIcon />}
                    size="medium"
                    variant="outlined"
                    color="primary">
                    Baixar
                  </Button>
                </Box>
              </Box>
              <Box marginTop={-2.5} marginLeft={1}>
                <Typography className={classes.dialogTextColor}>
                  Baixe apenas o total de resposta para cada
                  disciplina/professor
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <Box
                marginTop={2}
                paddingY={2}
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}>
                <Box display={'flex'} alignItems={'center'}>
                  <Box marginRight={1}>
                    <DescriptionIcon color="primary" />
                  </Box>
                  <Box>
                    <Typography
                      className={classes.dialogTextSize}
                      color="primary">
                      <>Exportar respostas textuais</>
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Button
                    onClick={() => handleDownloadByTextAnswer()}
                    startIcon={<ExportIcon />}
                    size="medium"
                    variant="outlined"
                    color="primary">
                    Baixar
                  </Button>
                </Box>
              </Box>
              <Box marginTop={-2.5} marginLeft={1} marginBottom={2}>
                <Typography className={classes.dialogTextColor}>
                  Baixe apenas as respostas de texto livre
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Container>
  );
};
