import {Api} from 'shared/services/axios-config';
import {IResultOfAction} from 'shared/services/axios-config/interceptors';

export interface IEstablishment {
  id: number;
  name: string;
  initials: string;
}
export interface IEstablishmentTree {
  IdEstabelecimento: number;
  NomeEstabelecimento: string;
  Sigla: string;
  CodigoEstruturado: string;
  CodigoPai: string;
  EstabelecimentosFilhos: IEstablishmentTree[];
}

const getAll = async (): Promise<IResultOfAction<IEstablishment[]>> => {
  try {
    const {data} = await Api.get('/estabelecimentos');

    const estabs = data.map((data: any) => ({
      id: data.IdEstabelecimento,
      name: data.NomeEstabelecimento,
      initials: data.Sigla,
    }));

    const response: IResultOfAction<IEstablishment[]> = {
      success: true,
      message: '',
      data: estabs,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const getAllTreeFormat = async (): Promise<
  IResultOfAction<IEstablishmentTree[]>
> => {
  try {
    const {data} = await Api.get('/estabelecimentos/listaemarvore?filtro=true');

    const estabsTree = data;

    const response: IResultOfAction<IEstablishmentTree[]> = {
      success: true,
      message: '',
      data: estabsTree,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

export const EstablishmentService = {
  getAll,
  getAllTreeFormat,
};
