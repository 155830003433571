import React from 'react';
import {Box} from '@mui/material';

import {
  SimpleText,
  ParagraphText,
  LinearScaleUniqueMultipleAnswer,
  GridUniqueAnswer,
  GridMultipleAnswer,
  DateAnswer,
  TimeAnswer,
} from './answerTypes/index';
import {IAnswerSummaryQuestion} from 'shared/services/api/reports/AnswerSummary';
import {SatisfactionPercentageAnswer} from './satisfactionPercentage/SatisfactionPercentageAnswer';

export interface IAnswerTypeRenderer {
  questionType: string;
  question: IAnswerSummaryQuestion;
}

export const AnswersTypesRenderer: React.FC<IAnswerTypeRenderer> = ({
  questionType,
  question,
}) => {
  switch (questionType) {
    case 'simple-text':
      return (
        <SimpleText
          totalRespostas={question.respostasRecebidas}
          configs={question.detalhes}
        />
      );

    case 'paragraph-text':
      return (
        <ParagraphText
          totalRespostas={question.respostasRecebidas}
          configs={question.detalhes}
        />
      );

    case 'linear-scale':
      return <LinearScaleUniqueMultipleAnswer configs={question.detalhes} />;

    case 'unique-answer':
      return <LinearScaleUniqueMultipleAnswer configs={question.detalhes} />;

    case 'multiple-answer':
      return <LinearScaleUniqueMultipleAnswer configs={question.detalhes} />;

    case 'satisfaction-percentage':
      return <SatisfactionPercentageAnswer configs={question.detalhes} />;

    case 'grid-multiple-answer':
      return <GridMultipleAnswer configs={question.detalhes} />;

    case 'grid-unique-answer':
      return <GridUniqueAnswer configs={question.detalhes} />;

    case 'date':
      return <DateAnswer configs={question.detalhes} />;

    case 'time':
      return <TimeAnswer configs={question.detalhes} />;

    default:
      return <Box></Box>;
  }
};
