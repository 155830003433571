import React, {useEffect, useState, useCallback, useMemo} from 'react';
import {
  Container,
  Box,
  Grid,
  FormControl,
  InputAdornment,
  Button,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TextField,
  CircularProgress,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import {useTitle, useDebounce} from 'shared/hooks';
import {feedback, confirm} from 'shared/services';
import {Pagination, Skeleton} from '@mui/lab';
import {Environment} from 'shared/Env';
import {ActionButtons, NotFoundText} from 'shared/components';
import {useNavigate, useParams} from 'react-router-dom';
import {
  PermissionsDetailService,
  IPermissionsProfiles,
} from 'shared/services/api/permissions/PermissionsDetail';
import {ProfilesDetailService} from 'shared/services/api/permissions/ProfilesDetails';

export const ProfilesListing: React.FC = () => {
  const {setTitle} = useTitle();
  const [search, setSearch] = useState('');
  const {debounce} = useDebounce();
  const [isLoading, setIsLoading] = useState(false);
  const [profilesList, setProfilesList] = useState<IPermissionsProfiles[]>([]);
  const [searchProfilesList, setSearchProfilesList] = useState<
    IPermissionsProfiles[]
  >([]);
  const navigate = useNavigate();
  const {page = '1'} = useParams<'page'>();

  const rowsPerPage = useMemo(() => {
    return Environment.LIMITE_DE_LINHAS;
  }, []);

  useEffect(() => {
    setTitle('Perfis');
  }, [setTitle]);

  useEffect(() => {
    setIsLoading(true);
    PermissionsDetailService.getAll().then((result) => {
      if (result.success) {
        setProfilesList(result.data);
        setSearchProfilesList(result.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        feedback(result.message || 'Erro ao carregar perfis.', 'error');
      }
    });
  }, []);

  const removeAccentuation = useCallback((value: string) => {
    const resultFilter = value
      .toLocaleLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

    return resultFilter;
  }, []);

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsLoading(true);
      setSearch(e.target.value);

      debounce(() => {
        const valor = removeAccentuation(e.target.value);

        const result = searchProfilesList.filter(
          (item) => removeAccentuation(item.name).indexOf(valor) > -1,
        );

        if (e.target.value === '') {
          setProfilesList(result);
        }

        setProfilesList(result);

        navigate(`/perfis/1`);
        setIsLoading(false);
      });
    },
    [debounce, removeAccentuation, searchProfilesList, navigate],
  );

  const handlePageChange = useCallback(
    (event: React.ChangeEvent<unknown>, value: number) => {
      navigate(`/perfis/${value}`);
    },
    [navigate],
  );

  const handleDelete = useCallback(
    (profileId: string) => {
      const handleConfirmDeleteProfile = () => {
        feedback('Excluindo perfil...', 'loading');
        ProfilesDetailService.deleteById(profileId).then((result) => {
          if (result.success) {
            const currentProfileState = profilesList.filter(
              (profile) => profile.id !== profileId,
            );
            setProfilesList([...currentProfileState]);
            feedback(
              result.message || 'Perfil excluído com sucesso!',
              'success',
            );
          } else {
            feedback(
              result.message || 'Não foi possível excluir o perfil.',
              'error',
            );
          }
        });
      };

      confirm(
        'Tem certeza que deseja excluir o Perfil?',
        'delete',
        handleConfirmDeleteProfile,
      );
    },
    [profilesList],
  );

  return (
    <Container>
      <Box width={'100%'}>
        <Grid container item xs={12} spacing={0}>
          <Grid container item xs={6}>
            <FormControl variant="outlined" fullWidth>
              <Paper>
                <TextField
                  style={{padding: 0, margin: 0}}
                  fullWidth
                  variant="outlined"
                  placeholder="Pesquise pelo nome do perfil"
                  value={search}
                  onChange={handleSearch}
                  InputProps={{
                    style: {
                      height: 40,
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        {isLoading ? (
                          <CircularProgress size={30} />
                        ) : (
                          <SearchIcon color="disabled" />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Paper>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={6}
            container
            justifyContent={'flex-end'}
            alignItems={'center'}>
            <Grid item xs={3}>
              <Button
                size="medium"
                fullWidth
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => {
                  navigate('/perfis/detalhe/0');
                }}>
                Novo
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Box width={'100%'} marginTop={3} marginBottom={1}>
          <TableContainer component={Paper} elevation={3}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Perfil
                  </TableCell>
                  <TableCell component="th" scope="row">
                    Usuários com perfil
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    width={100}
                    align="right">
                    Ações
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <>
                    <TableRow hover>
                      <TableCell component="th" scope="row">
                        <Skeleton
                          variant="rectangular"
                          width="auto"
                          height={25}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton
                          variant="rectangular"
                          width="auto"
                          height={25}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton
                          variant="rectangular"
                          width="auto"
                          height={25}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell component="th" scope="row">
                        <Skeleton
                          variant="rectangular"
                          width="auto"
                          height={25}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton
                          variant="rectangular"
                          width="auto"
                          height={25}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton
                          variant="rectangular"
                          width="auto"
                          height={25}
                        />
                      </TableCell>
                    </TableRow>
                  </>
                ) : profilesList.length ? (
                  profilesList
                    .slice(
                      (Number(page) - 1) * rowsPerPage,
                      (Number(page) - 1) * rowsPerPage + rowsPerPage,
                    )
                    .map((row) => (
                      <TableRow hover key={row.id}>
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.userQuantity}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          width={100}
                          align="right">
                          <ActionButtons
                            rowId={row.id}
                            canEdit={true}
                            canDelete={true}
                            onClickEdit={() =>
                              navigate(`/perfis/detalhe/${row.id}`)
                            }
                            onClickDelete={() => handleDelete(row.id)}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell>
                      <NotFoundText />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {profilesList.length > rowsPerPage && (
              <Box
                marginTop={1}
                marginBottom={1}
                marginLeft={1}
                marginRight={1}
                display={'flex'}
                justifyContent={'end'}>
                <Pagination
                  page={Number(page)}
                  count={Math.ceil(profilesList.length / rowsPerPage)}
                  onChange={handlePageChange}
                />
              </Box>
            )}
          </TableContainer>
        </Box>
      </Box>
    </Container>
  );
};
