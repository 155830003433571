import {TextField} from '@mui/material';
import {TimePicker} from '@mui/x-date-pickers';

export const useISOTimeFormatter = () => {
  const settingTime = (time: any) => {
    const timeIntoDate = new Date(time);
    const timezoneOffset = timeIntoDate.getTimezoneOffset() * 60 * 1000;
    const timezoneOffsetFormatted = timeIntoDate.setTime(
      timeIntoDate.getTime() - timezoneOffset,
    );
    return timezoneOffsetFormatted;
  };

  const getISOTimeToString = (time: any) => {
    const timezoneOffsetFormatted = settingTime(time);
    return new Date(timezoneOffsetFormatted).toISOString();
  };

  const getISOTimeToStringWithoutGMT = (time: any) => {
    const ISOTimeToString = getISOTimeToString(time);
    const GMTTimeString = ISOTimeToString.length - 5;
    return ISOTimeToString.substring(0, GMTTimeString);
  };

  const getISOTimeWithoutGMT = (time: any) => {
    const timeISOToStringWithoutGMT = getISOTimeToStringWithoutGMT(time);
    return new Date(timeISOToStringWithoutGMT);
  };

  return {
    getISOTimeToString,
    getISOTimeToStringWithoutGMT,
    getISOTimeWithoutGMT,
  };
};

interface IKeyboardTimepickerProps {
  ampm?: boolean;
  label?: string;
  inputError?: boolean;
  value: string;

  handleChange: (value: string, e: any) => void;
}

export const KeyboardTimepicker: React.FC<IKeyboardTimepickerProps> = ({
  ampm = false,
  label = '',
  inputError = false,
  value,
  handleChange,
}) => {
  const {getISOTimeWithoutGMT} = useISOTimeFormatter();

  const handleTimeChange = (e: Date) => {
    handleChange(value, e);
  };

  return (
    <TimePicker
      ampm={ampm}
      label={label}
      value={getISOTimeWithoutGMT(value)}
      onChange={(e: any) => handleTimeChange(e)}
      renderInput={(params: any) => (
        <TextField
          {...params}
          error={inputError}
          inputProps={{...params.inputProps, readOnly: true}}
        />
      )}
    />
  );
};
