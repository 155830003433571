import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  createContext,
} from 'react';
import {useParams} from 'react-router-dom';
import {NIL as NIL_UUID} from 'uuid';

import {feedback} from 'shared/services';

import {
  StudentQuestionListService,
  IStudentMultiplier,
  IStudentQuestion,
} from 'shared/services/api/student-quiz/QuestionList';
import {StudentQuizListService} from 'shared/services/api/student-quiz/QuizList';
import { SubTheme } from '../themes/SubTheme';

interface IStudentQuestionsContextData {
  isLoading: boolean;
  multiplierPosition: number;
  idPessoaConvite: string;
  nameSection: string;
  finalMessage: string;
  nextSectionId: string;
  multiplierList: IStudentMultiplier[];
  isSectionFinished: boolean;
  isQuizCompleted: boolean;
  numberOfQuestions: number;
  numberOfAnsweredQuestions: number;
  numberOfMultipliers: number;
  numberOfQuestionsLastMultiplier: number;
  nextMultiplier: () => void;
  previousMultiplier: () => void;
  resetMultiplier: () => void;
  completeQuiz: () => void;
  updateQuestions: (questions: IStudentQuestion[]) => void;
  quizColor: string;
  setMultiplierPosition: (multiplierPosition: number) => void;
  setMultiplierList: (multiplierList: IStudentMultiplier[]) => void;
}

export const StudentQuestionsContext =
  createContext<IStudentQuestionsContextData>(
    {} as IStudentQuestionsContextData,
  );

export const StudentQuestionsProvider: React.FC = ({children}) => {
  const {idPessoaConvite = NIL_UUID, idSecao = NIL_UUID} =
    useParams<'idPessoaConvite' | 'idSecao'>();

  const [isLoading, setIsLoading] = useState(true);

  const [multiplierPosition, setMultiplierPosition] = useState(0);

  const [nameSection, setNameSection] = useState('');
  const [nextSectionId, setNextSectionId] = useState('');
  const [multiplierList, setMultiplierList] = useState<IStudentMultiplier[]>(
    [],
  );
  const [isQuizCompleted, setIsQuizCompleted] = useState(false);
  const [finalMessage, setFinalMessage] = useState('');
  const [quizColor, setQuizColor] = useState('');

  useEffect(() => {
    setIsLoading(true);
    setQuizColor(localStorage.getItem('corQuestionario') || '');

    StudentQuestionListService.getById(idPessoaConvite, idSecao).then(
      (response) => {
        const {success, data, message} = response;

        if (success) {
          setNameSection(data.nomeSecao);
          setFinalMessage(data.mensagemFinalQuestionario || '');
          setNextSectionId(data.idProximaSecao);
          setMultiplierList(data.multiplicadores);

          const isCompleted = ['RESPONDIDO', 'EXPIRADO'].includes(
            data.statusPessoaConvite,
          );
          setIsQuizCompleted(isCompleted);

          const isSectionFinished = data.multiplicadores.every(
            (multiplier) => multiplier.todasRespondidas,
          );

          setMultiplierPosition(
            isCompleted
              ? 0
              : isSectionFinished
              ? data.multiplicadores.length - 1
              : data.multiplicadores.filter(
                  (multiplier) => multiplier.todasRespondidas,
                ).length,
          );

          setIsLoading(false);
        } else {
          setIsLoading(false);
          feedback(message, 'error');
        }
      },
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idSecao]);

  const numberOfQuestions = useMemo(() => {
    if (multiplierList) {
      return multiplierList.flatMap((multiplier) => multiplier.questoes).length;
    }
    return 0;
  }, [multiplierList]);

  const numberOfAnsweredQuestions = useMemo(() => {
    if (multiplierList) {
      return multiplierList
        .flatMap((multiplier) => multiplier.questoes)
        .filter((questao) => questao.respondido).length;
    }
    return 0;
  }, [multiplierList]);

  const numberOfMultipliers = useMemo(() => {
    return multiplierList.length;
  }, [multiplierList.length]);

  const numberOfQuestionsLastMultiplier = useMemo(() => {
    if (multiplierList.length && multiplierPosition > 0) {
      return multiplierList[multiplierPosition - 1].questoes.length;
    }
    return 0;
  }, [multiplierList, multiplierPosition]);

  const isSectionFinished = useMemo(() => {
    return multiplierList.every((multiplier) => multiplier.todasRespondidas);
  }, [multiplierList]);

  const nextMultiplier = useCallback(() => {
    setMultiplierPosition((old) => old + 1);
  }, []);

  const previousMultiplier = useCallback(() => {
    setMultiplierPosition((old) => old - 1);
  }, []);

  const resetMultiplier = useCallback(() => {
    setMultiplierPosition(0);
  }, []);

  const updateQuestions = useCallback(
    (questions: IStudentQuestion[]) => {
      const multipliersCopy = multiplierList;
      multipliersCopy[multiplierPosition].questoes = questions;

      setMultiplierList([...multipliersCopy]);
    },
    [multiplierPosition, multiplierList],
  );

  const completeQuiz = useCallback(() => {
    if (idPessoaConvite) {
      StudentQuizListService.putById(idPessoaConvite).then((response) => {
        if (response.success) {
          setIsQuizCompleted(true);
        } else {
          feedback(response.message, 'error');
        }
      });
    } else {
      feedback('Erro ao salvar...', 'error');
    }
  }, [idPessoaConvite]);

  return (
    <StudentQuestionsContext.Provider
      value={{
        isLoading,
        multiplierPosition,
        idPessoaConvite,
        multiplierList,
        nameSection,
        finalMessage,
        nextSectionId,
        isSectionFinished,
        isQuizCompleted,
        numberOfQuestions,
        numberOfAnsweredQuestions,
        numberOfMultipliers,
        numberOfQuestionsLastMultiplier,
        nextMultiplier,
        previousMultiplier,
        resetMultiplier,
        updateQuestions,
        completeQuiz,
        quizColor,
        setMultiplierPosition,
        setMultiplierList
      }}>
      <SubTheme color={quizColor}>
        {children}
      </SubTheme>
    </StudentQuestionsContext.Provider>
  );
};
