import React, {memo} from 'react';

import {DisplayMode} from '../../DisplayMode';
import {ParagraphTextConfig} from './ParagraphTextConfig';
import {ParagraphTextPreview} from './ParagraphTextPreview';

export interface IParagraphTextProps {
  displayMode: DisplayMode;
}

export const ParagraphText: React.FC<IParagraphTextProps> = memo(
  ({displayMode}) => {
    return (
      <>
        {displayMode === DisplayMode.config && <ParagraphTextConfig />}
        {displayMode === DisplayMode.preview && <ParagraphTextPreview />}
      </>
    );
  },
);
