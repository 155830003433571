import React, {useEffect, useState, useCallback, memo, useMemo} from 'react';
import {
  Box,
  FormControl,
  Grid,
  ListItemIcon,
  OutlinedInput,
  Select,
  MenuItem,
  Typography,
} from '@mui/material';
import {
  SentimentVeryDissatisfied,
  SentimentDissatisfied,
  SentimentSatisfied,
  SentimentSatisfiedAlt,
  SentimentVerySatisfied,
  DragHandle,
  Lens,
  Block,
} from '@mui/icons-material';
import {IOption} from 'shared/services/api/quiz/Quiz';

interface ILinearScaleOptionColumnProps {
  isEdit: boolean;
  option: IOption;
  endsWith: number;
  onChangeDescription: (index: number, text: string) => void;
  onChangeValue: (index: number, value: string, isInitialFix?: boolean) => void;
}

export const OptionColumn: React.FC<ILinearScaleOptionColumnProps> = memo(
  ({
    isEdit,
    option,
    endsWith,
    onChangeValue,
    onChangeDescription,
  }: ILinearScaleOptionColumnProps) => {
    const DEFAULT_EMPTY = '';
    const [useEmoji, setUseEmoji] = useState(false);
    const [isInitialFix, setIsInitialFix] = useState(true);
    const [emoji, setEmoji] = useState(DEFAULT_EMPTY);
    const [color, setColor] = useState(DEFAULT_EMPTY);
    const [isLoading, setIsLoading] = useState(true);
    const [isSelectOpen, setIsSelectOpen] = useState(false);

    const optionsColor = useMemo(() => {
      return [
        {color: '#D7201A'},
        {color: '#F95951'},
        {color: '#F82C33'},
        {color: '#FB7D00'},
        {color: '#F38942'},
        {color: '#FFCB2E'},
        {color: '#FBC115'},
        {color: '#98CB38'},
        {color: '#46BC4A'},
        {color: '#2F9C33'},
      ];
    }, []);

    const optionsEmoji = useMemo(() => {
      return [
        {emoji: 'SentimentVeryDissatisfied'},
        {emoji: 'SentimentDissatisfied'},
        {emoji: 'SentimentSatisfied'},
        {emoji: 'SentimentSatisfiedAlt'},
        {emoji: 'SentimentVerySatisfied'},
      ];
    }, []);

    useEffect(() => {
      if (endsWith <= 5) {
        setUseEmoji(true);
      } else if (endsWith >= 6) {
        setUseEmoji(false);
      }
    }, [endsWith]);

    const iconOrColorFormatter = () => {
      return `${emoji}${
        emoji?.length > 0 && color?.length > 0 ? ',' : DEFAULT_EMPTY
      }${color}`;
    };

    const mockedHandlerEmojiUndefined = (value: string[] | '') => {
      if (value.includes('undefined')) {
        setColor(value[0] === undefined ? DEFAULT_EMPTY : value[0]);
        setEmoji(DEFAULT_EMPTY);
      }
    };

    const mockerHandlerTwoColors = (value: string | undefined) => {
      const isError = value?.split('#').length
        ? value?.split('#').length - 1 > 1
        : false;
      if (isError) {
        setColor(value?.split(',')[1] ? value?.split(',')[1] : '');
      }
    };

    const iconOrColorCorrection = () => {
      const splitedIconOrColor =
        option?.iconOrColor?.split(',') || DEFAULT_EMPTY;

      mockedHandlerEmojiUndefined(splitedIconOrColor);
      mockerHandlerTwoColors(option?.iconOrColor);

      if (endsWith < 6) {
        if (
          splitedIconOrColor.length === 1 &&
          splitedIconOrColor[0]?.includes('#')
        ) {
          setEmoji(DEFAULT_EMPTY);
          setColor(
            splitedIconOrColor[0] === undefined
              ? DEFAULT_EMPTY
              : splitedIconOrColor[0],
          );
        } else {
          setEmoji(
            splitedIconOrColor[0] === undefined
              ? DEFAULT_EMPTY
              : splitedIconOrColor[0],
          );
          setColor(
            splitedIconOrColor[1] === undefined
              ? DEFAULT_EMPTY
              : splitedIconOrColor[1],
          );
        }
      } else {
        setColor(
          splitedIconOrColor[0] === undefined
            ? DEFAULT_EMPTY
            : splitedIconOrColor[0],
        );
      }
    };

    useEffect(() => {
      if (isEdit) {
        iconOrColorCorrection();
        setIsLoading(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (!isLoading) {
        onChangeValue(option.order, iconOrColorFormatter(), isInitialFix);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emoji, color]);

    useEffect(() => {
      if (isEdit && !useEmoji && !isLoading) {
        setEmoji(DEFAULT_EMPTY);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [useEmoji]);

    const getEmojiIcon = useCallback((IconName: string) => {
      switch (IconName) {
        case 'SentimentVeryDissatisfied':
          return <SentimentVeryDissatisfied />;

        case 'SentimentDissatisfied':
          return <SentimentDissatisfied />;

        case 'SentimentSatisfied':
          return <SentimentSatisfied />;

        case 'SentimentSatisfiedAlt':
          return <SentimentSatisfiedAlt />;

        case 'SentimentVerySatisfied':
          return <SentimentVerySatisfied />;

        default:
          return <div />;
      }
    }, []);

    return option.order === 0 ? (
      <Grid
        container
        spacing={3}
        alignItems={'center'}
        justifyContent={'flex-start'}>
        <Grid item xs={1}></Grid>
        <Grid item xs={1}>
          <Box fontWeight="fontWeightBold">
            <Typography>{option.order}</Typography>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <FormControl variant="outlined" fullWidth>
            <OutlinedInput
              value={option.description}
              onChange={(value) =>
                onChangeDescription(option.order, value.target.value)
              }
              placeholder={`Opção ${option.order}`}
            />
          </FormControl>
        </Grid>
      </Grid>
    ) : (
      <Grid
        container
        spacing={3}
        alignItems={'center'}
        justifyContent={'flex-start'}>
        <Grid item xs={1}>
          <DragHandle />
        </Grid>
        <Grid item xs={1}>
          <Box fontWeight="fontWeightBold">
            <Typography>{option.order}</Typography>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <FormControl variant="outlined" fullWidth>
            <OutlinedInput
              value={option.description}
              placeholder={`Opção ${option.order}`}
              onChange={(value) =>
                onChangeDescription(option.order, value.target.value)
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            gap={2}>
            {useEmoji && (
              <Select
                displayEmpty
                id="select-input"
                variant="outlined"
                autoWidth
                value={emoji}
                style={{maxBlockSize: 55}}
                onChange={(event) => {
                  if (isInitialFix) {
                    setIsInitialFix(false);
                  }
                  setEmoji(event.target.value as string);
                }}
                onOpen={() => setIsSelectOpen(true)}
                onClose={() => setIsSelectOpen(false)}>
                {useEmoji
                  ? optionsEmoji.map(({emoji}, index) => (
                      <MenuItem key={index + 'emoji'} value={emoji}>
                        <ListItemIcon>{getEmojiIcon(emoji)}</ListItemIcon>
                      </MenuItem>
                    ))
                  : optionsColor.map(({color}, index) => (
                      <MenuItem key={index + 'color'} value={color}>
                        <ListItemIcon>
                          <Lens style={{color: color}} />
                        </ListItemIcon>
                      </MenuItem>
                    ))}

                <MenuItem value={''}>
                  <ListItemIcon>
                    {isSelectOpen && <Block style={{color: 'red'}} />}
                  </ListItemIcon>
                </MenuItem>
              </Select>
            )}
            <Select
              displayEmpty
              variant="outlined"
              autoWidth
              value={color}
              style={{maxBlockSize: 55}}
              onChange={(event) => {
                if (isInitialFix) {
                  setIsInitialFix(false);
                }
                setColor(event.target.value as string);
              }}
              onOpen={() => setIsSelectOpen(true)}
              onClose={() => setIsSelectOpen(false)}>
              {optionsColor.map(({color}, index) => (
                <MenuItem key={index + 'color'} value={color}>
                  <ListItemIcon>
                    <Lens style={{color: color}} />
                  </ListItemIcon>
                </MenuItem>
              ))}
              <MenuItem value={''}>
                <ListItemIcon>
                  {isSelectOpen && <Block style={{color: 'red'}} />}
                </ListItemIcon>
              </MenuItem>
            </Select>
          </Box>
        </Grid>
      </Grid>
    );
  },
);
