import React, {useCallback, useMemo, memo} from 'react';
import {v4 as uuid} from 'uuid';
import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from '@mui/material';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import {IOption} from 'shared/services/api/quiz/Quiz';
import {OptionColumn} from './OptionColumn';
import {useQuiz} from 'pages/quiz/hooks';

interface ILinearScaleConfigProps {
  value: IOption[];
  onChange: (value: IOption[], isInitialFix?: boolean) => void;
}

export const LinearScaleConfig: React.FC<ILinearScaleConfigProps> = memo(
  ({value, onChange}) => {
    const {hasInviteSent} = useQuiz();
    const theme = useTheme();
    const options = useMemo(() => {
      if (value.length === 0) {
        value.push(
          {
            id: `newOption__${uuid()}`,
            description: '',
            order: 1,
          },
          {
            id: `newOption__${uuid()}`,
            description: '',
            order: 2,
          },
          {
            id: `newOption__${uuid()}`,
            description: '',
            order: 3,
          },
          {
            id: `newOption__${uuid()}`,
            description: '',
            order: 4,
          },
          {
            id: `newOption__${uuid()}`,
            description: '',
            order: 5,
          },
          {
            id: `newOption__${uuid()}`,
            description: '',
            order: 6,
          },
        );
      }
      return value.sort((a, b) => a.order - b.order);
    }, [value]);

    const startWith = useMemo(() => {
      return options[0].order === 1 ? 1 : 0;
    }, [options]);

    const endWith = useMemo(() => {
      return options[options.length - 1].order || 6;
    }, [options]);

    const handleChangeStartsWith = useCallback(
      (start: 0 | 1) => {
        if (start === 0) {
          options.unshift({
            id: `newOption__${uuid()}`,
            description: 'Não sei opinar',
            order: 0,
          });
        } else {
          options.shift();
        }
        onChange([...options]);
      },
      [onChange, options],
    );

    const handleChangeEndWith = useCallback(
      (end: number) => {
        const result = end - options.length + (startWith === 0 ? 1 : 0);

        if (result === 0) return;

        if (result < 0) {
          Array.from(Array(result * -1).keys()).forEach(() => {
            options.pop();
          });
        } else {
          Array.from(Array(result).keys()).forEach(() => {
            options.push({
              id: `newOption__${uuid()}`,
              description: '',
              order: options.length + startWith,
            });
          });
        }

        onChange([...options]);
      },
      [onChange, options, startWith],
    );

    const handleDescriptionChange = useCallback(
      (index: number, text: string) => {
        options[index - startWith].description = text;
        onChange([...options]);
      },
      [onChange, options, startWith],
    );

    const handleValueChange = useCallback(
      (index: number, value: string, isInitialFix?: boolean) => {
        options[index - startWith].iconOrColor = value;
        onChange([...options], isInitialFix);
      },
      [onChange, options, startWith],
    );

    const handleOnDragEnd = useCallback(
      (result: DropResult) => {
        if (!result.destination) return;

        const [reorderedItem] = options.splice(result.source.index, 1);
        options.splice(result.destination.index, 0, reorderedItem);
        var count = startWith;
        options.forEach((item) => {
          item.order = count;
          count++;
        });

        onChange([...options]);
      },
      [onChange, options, startWith],
    );

    return (
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={1}>
            <FormControl variant="outlined" fullWidth>
              <Select
                disabled={hasInviteSent}
                value={startWith}
                onChange={(e) =>
                  handleChangeStartsWith(Number(e.target.value) as 0 | 1)
                }>
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={1}>1</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={1}>
            <FormControl variant="outlined" fullWidth>
              <Select
                value={endWith}
                onChange={(e) => handleChangeEndWith(Number(e.target.value))}>
                <MenuItem disabled={hasInviteSent && 2 <= endWith} value={2}>
                  2
                </MenuItem>
                <MenuItem disabled={hasInviteSent && 3 <= endWith} value={3}>
                  3
                </MenuItem>
                <MenuItem disabled={hasInviteSent && 4 <= endWith} value={4}>
                  4
                </MenuItem>
                <MenuItem disabled={hasInviteSent && 5 <= endWith} value={5}>
                  5
                </MenuItem>
                <MenuItem disabled={hasInviteSent && 6 <= endWith} value={6}>
                  6
                </MenuItem>
                <MenuItem disabled={hasInviteSent && 7 <= endWith} value={7}>
                  7
                </MenuItem>
                <MenuItem disabled={hasInviteSent && 8 <= endWith} value={8}>
                  8
                </MenuItem>
                <MenuItem disabled={hasInviteSent && 9 <= endWith} value={9}>
                  9
                </MenuItem>
                <MenuItem disabled={hasInviteSent && 10 <= endWith} value={10}>
                  10
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Colunas</Typography>
          </Grid>
          <Grid item xs={8}>
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="droppable-1">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {options.map((option, index) =>
                      option.order !== 0 ? (
                        <Draggable
                          key={`LinearScale-option-${option.order}`}
                          draggableId={'draggable-' + option.order}
                          index={index}>
                          {(provided, {isDragging}) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                ...provided.draggableProps.style,
                                background: theme.palette.background.paper,
                                boxShadow: isDragging
                                  ? theme.shadows[4]
                                  : undefined,
                                paddingRight: isDragging
                                  ? theme.spacing(3)
                                  : theme.spacing(0),
                                paddingTop: isDragging
                                  ? theme.spacing(1.5)
                                  : theme.spacing(2),
                              }}>
                              <OptionColumn
                                isEdit={option.iconOrColor !== ' '}
                                option={option}
                                endsWith={endWith}
                                onChangeDescription={handleDescriptionChange}
                                onChangeValue={handleValueChange}
                              />
                            </div>
                          )}
                        </Draggable>
                      ) : (
                        <Box key={'LinearScale-option-0'} alignItems={'center'}>
                          <OptionColumn
                            isEdit={option.iconOrColor !== ' '}
                            option={option}
                            endsWith={endWith}
                            onChangeDescription={handleDescriptionChange}
                            onChangeValue={handleValueChange}
                          />
                        </Box>
                      ),
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Grid>
        </Grid>
      </Box>
    );
  },
);
