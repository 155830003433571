import React, {useState, useRef} from 'react';
import {
  Avatar,
  Box,
  Tooltip,
  Typography,
  useTheme,
  Popover,
  Icon,
} from '@mui/material';

import {useUser} from 'shared/hooks';
import {AvatarPopover} from './AvatarPopover';

export const UserAvatar: React.FC = () => {
  const theme = useTheme();
  const {user} = useUser();
  const avatarRef = useRef(null);
  const [anchorElement, setAnchorElement] = useState(null);

  const popoverOpen = Boolean(anchorElement);

  const handleClickAvatar = () => {
    setAnchorElement(avatarRef.current);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  return (

    <Box display="flex" alignItems={'center'} height={theme.spacing(10)} justifyContent={'flex-end'}>
      <div style={{display: 'flex', alignItems: 'center', height: '100%'}} ref={avatarRef} onClick={handleClickAvatar}>
        <Tooltip title={user.name || ''} arrow>
          <Avatar
            style={{
              background: 'white',
              cursor: 'pointer',
            }}>
            {user.name ? (
              <Typography color="secondary" variant={'h6'}>
                {user.name[0].toUpperCase() || ''}
              </Typography>
            ) : (
              <Icon color={'secondary'}>person</Icon>
            )}
          </Avatar>
        </Tooltip>
      </div>
      <Popover
        open={popoverOpen}
        anchorEl={anchorElement}
        onClose={handleClose}
        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <AvatarPopover />
      </Popover>
    </Box>
  );
};
