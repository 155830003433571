import React, {memo, useCallback, useMemo} from 'react';
import {Box, FormControl, InputLabel, Select, MenuItem} from '@mui/material';
import {
  LinearScale,
  ViewHeadline,
  Apps,
  ViewCompact,
  CheckBox,
  RadioButtonChecked,
  CalendarToday,
  AccessAlarm,
  List,
  ShortText,
} from '@mui/icons-material';
import {TTypeOfQuestion} from 'shared/types/QuestionTypes';
import {useQuiz} from 'pages/quiz/hooks';

interface ISelectQuestionTypeProps {
  selectedQuestionTypeId: TTypeOfQuestion;
  onChangeSelectQuestionType: (selectedQuestionType: TTypeOfQuestion) => void;
  resetQuestion: () => void;
}

export const SelectQuestionType: React.FC<ISelectQuestionTypeProps> = memo(
  ({onChangeSelectQuestionType, selectedQuestionTypeId, resetQuestion}) => {
    const {hasInviteSent} = useQuiz();

    const questionsType = useMemo(() => {
      return [
        {
          id: 'simple-text',
          description: 'Texto simples',
          icon: <ShortText fontSize="small" />,
        },
        {
          id: 'paragraph-text',
          description: 'Texto parágrafo',
          icon: <ViewHeadline fontSize="small" />,
          renderDivider: true,
        },
        {
          id: 'linear-scale',
          description: 'Escala linear',
          icon: <LinearScale fontSize="small" />,
        },
        {
          id: 'unique-answer',
          description: 'Resposta única',
          icon: <RadioButtonChecked fontSize="small" />,
        },
        {
          id: 'multiple-answer',
          description: 'Respostas múltiplas',
          icon: <CheckBox fontSize="small" />,
          renderDivider: true,
        },
        {
          id: 'grid-unique-answer',
          description: 'Grade resposta única',
          icon: <ViewCompact fontSize="small" />,
        },
        {
          id: 'grid-multiple-answer',
          description: 'Grade respostas múltiplas',
          icon: <Apps fontSize="small" />,
          renderDivider: true,
        },
        {
          id: 'date',
          description: 'Data',
          icon: <CalendarToday fontSize="small" />,
        },
        {
          id: 'time',
          description: 'Hora',
          icon: <AccessAlarm fontSize="small" />,
        },
        {
          id: 'satisfaction-percentage',
          description: 'Percentual de Satisfação',
          icon: <List fontSize="small" />,
        },
      ];
    }, []);

    const handleChangeSelectState = useCallback(
      (event: React.ChangeEvent<{value: any}>) => {
        const questionTypeId = event.target.value as TTypeOfQuestion;

        onChangeSelectQuestionType(questionTypeId);
        resetQuestion();
      },
      [onChangeSelectQuestionType, resetQuestion],
    );

    return (
      <Box>
        <FormControl required variant="outlined" fullWidth>
          <InputLabel htmlFor="Tipo de questão">Tipo de questão</InputLabel>
          <Select
            disabled={hasInviteSent}
            name={'Tipo de questão'}
            label={'Tipo de questão'}
            value={selectedQuestionTypeId}
            onChange={(e: any) => handleChangeSelectState(e)}
            required>
            {questionsType.map((questionType: any) => {
              return (
                <MenuItem
                  divider={questionType.renderDivider}
                  key={questionType.id}
                  value={questionType.id}>
                  <Box
                    marginRight={2}
                    display="flex"
                    justifyContent="start"
                    alignItems="center">
                    {questionType.icon}
                    <Box marginLeft={2}>{questionType.description}</Box>
                  </Box>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
    );
  },
);
