import React from 'react';

import {Tooltip as MuiTooltip, Fade, Typography} from '@mui/material';

interface ITooltipProps {
    text: string;
    position?: any;
}

export const Tooltip: React.FC<ITooltipProps> = ({
    children,
    text,
    position = 'bottom',
}) => {
    return (
        <MuiTooltip
            title={<Typography variant="body2">{text}</Typography>}
            arrow
            disableTouchListener
            placement={position}
            TransitionComponent={Fade}>
            {
                // É usado span por causa da compatibilidade com safari;
            }
            <span>{children}</span>
        </MuiTooltip>
    );
};
