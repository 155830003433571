import React, {useState, useEffect} from 'react';
import {Box, Theme, LinearProgress, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {SubTheme} from 'pages/student-quiz/themes/SubTheme';

interface IProgressProps {
  color: string;
}

const useStyles = makeStyles<Theme, IProgressProps>((theme: Theme) => ({
  loadingBar: {
    marginTop: theme.spacing(6),
    width: theme.spacing(35),
    color: (props: IProgressProps) => `#${props.color}`,
  },
}));

export const Progress: React.FC<IProgressProps> = ({color}) => {
  const classes = useStyles({color});

  const [progress, setProgress] = useState(30);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) return prevProgress;
        return prevProgress + 1;
      });
    }, 10);

    return () => clearInterval(timer);
  }, []);

  return (
    <SubTheme color={color}>
      <Box
        width={'100%'}
        textAlign={'center'}
        display="flex"
        flexDirection={'column'}
        alignItems="center"
        justifyContent={'center'}>
        <LinearProgress
          color="primary"
          variant="determinate"
          value={progress}
          className={classes.loadingBar}
        />
        <Box marginBottom={1} />
        <Typography color="primary" variant="subtitle1">
          Carregando Seções
        </Typography>
      </Box>
    </SubTheme>
  );
};
