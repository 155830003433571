import React from 'react';
import {Theme, createMuiTheme, ThemeProvider} from '@mui/material';

interface ISubThemeProps {
  color: string;
}

export const SubTheme: React.FC<ISubThemeProps> = ({color, children}) => {
  const getSecondaryColor = (hex: string, percent: number) => {
    hex = hex.replace(/^\s*#|\s*$/g, '');

    if (hex.length === 3) {
      hex = hex.replace(/(.)/g, '$1$1');
    }

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return `rgba(${r}, ${g}, ${b}, ${percent})`;
  };

  return (
    <>
      {color !== '' ? (
        <ThemeProvider
          theme={(theme: Theme) =>
            createMuiTheme({
              palette: {
                primary: {
                  main: `#${color}`,
                  contrastText: '#FFFFFF',
                },
                secondary: {
                  main: getSecondaryColor(color, 0.6),
                  contrastText: '#FFFFFF',
                },
              },
            })
          }>
          {children}
        </ThemeProvider>
      ) : (
        children
      )}
    </>
  );
};
