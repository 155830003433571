import {Box, FormControl, FormLabel, Grid} from '@mui/material';
import {Skeleton} from '@mui/lab';

export const InviteSkeleton: React.FC = () => {
  return (
    <Box width={'100%'}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Skeleton
            style={{
              borderRadius: '4px',
            }}
            variant="rectangular"
            width="100%"
            height={56}
          />
        </Grid>
        <Grid item xs={6}>
          <Box
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="flex-end">
            <Skeleton variant="text" width="40%" />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Skeleton
            style={{
              borderRadius: '4px',
            }}
            variant="rectangular"
            width="100%"
            height={56}
          />
        </Grid>
        <Grid item xs={4}>
          <Skeleton
            style={{
              borderRadius: '4px',
            }}
            variant="rectangular"
            width="100%"
            height={56}
          />
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={7}>
          <Skeleton
            style={{
              borderRadius: '4px',
            }}
            variant="rectangular"
            width="100%"
            height={56}
          />
        </Grid>
        <Grid item xs={8}>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">Respostas</FormLabel>
            <Box
              display="flex"
              flexDirection="column"
              gap={16}
              marginTop={1}
              marginBottom={1}>
              <Skeleton variant="text" width="40%" height={26} />
              <Skeleton variant="text" width="40%" height={26} />
              <Skeleton variant="text" width="40%" height={26} />
            </Box>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">
              Bloquear acesso ao portal a partir de:
            </FormLabel>
            <Box
              display="flex"
              flexDirection="column"
              gap={16}
              marginTop={1}
              marginBottom={1}>
              <Skeleton
                style={{
                  borderRadius: '4px',
                }}
                variant="rectangular"
                width="100%"
                height={56}
              />
              <Skeleton variant="text" width="40%" />
            </Box>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};
