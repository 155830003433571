import React, {memo} from 'react';
import {
  Box,
  OutlinedInput,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Typography,
} from '@mui/material';
import {
  SentimentVeryDissatisfied,
  SentimentDissatisfied,
  SentimentSatisfied,
  SentimentSatisfiedAlt,
  SentimentVerySatisfied,
} from '@mui/icons-material';

import {IOption} from 'shared/services/api/quiz/Quiz';

interface IGridMultipleAnswerPreviewProps {
  columnOptions: IOption[];
  rowOptions: IOption[];
  hasComment?: boolean;
}

const optionsEmoji = [
  <SentimentVeryDissatisfied color="primary" fontSize="large" />,
  <SentimentDissatisfied color="primary" fontSize="large" />,
  <SentimentSatisfied color="primary" fontSize="large" />,
  <SentimentSatisfiedAlt color="primary" fontSize="large" />,
  <SentimentVerySatisfied color="primary" fontSize="large" />,
];

export const GridMultipleAnswerPreview: React.FC<IGridMultipleAnswerPreviewProps> =
  memo(({columnOptions, rowOptions, hasComment = false}) => {
    return (
      <Box component="div" overflow="auto" minWidth={600}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />

              {columnOptions.map((column) => {
                return (
                  <TableCell
                    key={`GridMultipleAnswer-Preview-Column-${column.order}`}
                    align="center"
                    style={{
                      background: !column.iconOrColor
                        ? '#fafafa'
                        : column.iconOrColor,
                      color:
                        columnOptions.length <= 5
                          ? 'black'
                          : !column.iconOrColor
                          ? 'black'
                          : 'white',
                    }}>
                    <Box maxWidth={150}>
                      <Typography style={{wordWrap: 'break-word'}}>
                        {column.description || 'Opção ' + column.order}
                      </Typography>
                    </Box>
                    {/* emoji */}
                    {columnOptions.length <= 5 &&
                      Number.isInteger(
                        Number.parseInt(column.iconOrColor ?? ''),
                      ) &&
                      optionsEmoji[Number.parseInt(column.iconOrColor ?? '')]}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rowOptions.map((line) => {
              return (
                <TableRow key={`GridMultipleAnswer-Preview-Line-${line.order}`}>
                  <TableCell style={{color: 'black  '}}>
                    <Box maxWidth={250}>
                      <Typography style={{wordWrap: 'break-word'}}>
                        {line.description || 'Opção ' + line.order}
                      </Typography>
                    </Box>
                  </TableCell>

                  {columnOptions.map((column, index) => {
                    return (
                      <TableCell
                        key={`${line.order}-${column.order}`}
                        align="center">
                        <Checkbox checked={false} disabled />
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        {hasComment && (
          <Box maxWidth={400} marginTop={3}>
            <OutlinedInput fullWidth disabled placeholder="Comentar" />
          </Box>
        )}
      </Box>
    );
  });
