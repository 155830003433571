import React, {useState, useEffect, useCallback} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useTitle} from 'shared/hooks';
import {
  Container,
  Box,
  InputLabel,
  OutlinedInput,
  FormControl,
  InputAdornment,
  Icon,
  Grid,
  Switch,
  Typography,
} from '@mui/material';
import {ProfilesDetailSkeleton} from './ProfilesDetailSkeleton';
import {
  IAplication,
  IFunctionality,
  IProfile,
  ProfilesDetailService,
} from 'shared/services/api/permissions/ProfilesDetails';
import {feedback} from 'shared/services';
import {MultiselectCheckbox} from './components/MultiselectCheckbox';
import {ActionTab, CentralWarning} from 'shared/components';

export const ProfilesDetail: React.FC = () => {
  const {setTitle} = useTitle();
  const {id = '0'} = useParams<'id'>();
  const [isLoading, setIsLoading] = useState(false);
  const [profile, setProfile] = useState<IProfile>({} as IProfile);
  const [functionalities, setFunctionalities] = useState<IFunctionality[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);

    if (id !== '0') {
      feedback('Carregando perfil...', 'loading');
      ProfilesDetailService.getById(id).then((result) => {
        if (result.success) {
          setTitle(`Perfil: ${result.data.perfil}`);
          setProfile(result.data);
          setFunctionalities(result.data.funcionalidades);
          feedback('Perfil carregado com sucesso!', 'success');
          setIsLoading(false);
        } else {
          feedback(result.message || 'Erro ao carregar perfil.', 'error');
          setIsLoading(false);
        }
      });
    }

    if (id === '0') {
      ProfilesDetailService.getAllFunctionalities().then((result) => {
        if (result.success) {
          const returneFuncs = result.data as IAplication[];
          const funcs = returneFuncs.map((func) => ({
            id: func.id,
            nome: func.nome,
            caminho: func.caminho,
            ativo: false,
            icone: func.icone,
            idPerfilAplicacao: 0,
            permissoes: func.permissoes
              .map((funcPerms) =>
                funcPerms.permissoes.map((funcPermsPerms) => ({
                  id: funcPermsPerms.id,
                  idPermissaoPerfilAplicacao: 0,
                  rotaPermissao: funcPermsPerms.rotaPermissao,
                  nome: funcPermsPerms.nome,
                  rota: funcPermsPerms.rota,
                  metodo: funcPermsPerms.metodo,
                  ativo: false,
                })),
              )
              .flat(),
          }));
          setFunctionalities(funcs);
          setProfile({
            descricao: '',
            id: '0',
            funcionalidades: [...funcs],
            perfil: '',
          });
          setIsLoading(false);
        } else {
          feedback(
            result.message || 'Erro ao carregar funcionalidades.',
            'error',
          );
          setIsLoading(false);
        }
      });
    }
  }, [id, setTitle]);

  const handleChangeProfileName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setProfile({...profile, perfil: e.target.value});
    },
    [profile],
  );

  const handleChangeProgileDescription = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setProfile({...profile, descricao: e.target.value});
    },
    [profile],
  );

  const handleChangeFunctionalityState = useCallback(
    (currentFunc: IFunctionality, checked: boolean) => {
      const indexToEdit = functionalities
        .map((func) => func.id)
        .indexOf(currentFunc.id);
      var currentFuncState = functionalities;
      currentFuncState[indexToEdit].ativo = checked;
      setFunctionalities([...currentFuncState]);

      const onlyActive = currentFuncState.filter((func) => func.ativo);
      setProfile({...profile, funcionalidades: [...onlyActive]});
    },
    [functionalities, profile],
  );

  const handleFunctionalityPermissionState = useCallback(
    (
      currentFunc: IFunctionality,
      clickedPermissionState: boolean,
      clickedPermissionId: number,
    ) => {
      const indexToEdit = currentFunc.permissoes.findIndex(
        (perms) => perms.id === clickedPermissionId,
      );
      const indexToEditFunc = functionalities
        .map((func) => func.id)
        .indexOf(currentFunc.id);
      var currentFuncState = functionalities;
      currentFunc.permissoes[indexToEdit].ativo = clickedPermissionState;
      currentFuncState[indexToEditFunc].permissoes = currentFunc.permissoes;
      setFunctionalities([...currentFuncState]);

      const onlyActive = currentFuncState.filter((func) => func.ativo);
      setProfile({...profile, funcionalidades: [...onlyActive]});
    },
    [functionalities, profile],
  );

  const checkIfHasPermissions = useCallback(() => {
    var hasError = true;

    hasError = profile.funcionalidades.every((func) => !func.ativo);

    profile.funcionalidades.forEach((func) => {
      if (func.ativo) {
        let hasPermissionsActive = func.permissoes.filter(
          (perms) => perms.ativo,
        );
        if (hasPermissionsActive.length === 0) {
          hasError = true;
        } else {
          hasError = false;
        }
      }
    });

    if (hasError) {
      return true;
    }

    return false;
  }, [profile]);

  const handleSaveProfile = useCallback(() => {
    const hasError = checkIfHasPermissions();
    if (hasError) {
      feedback(
        'Você precisa selecionar funcionalidades e suas permissões antes de salvar',
        'error',
      );
      return;
    }

    if (id !== '0') {
      feedback('Salvando perfil...', 'loading');
      ProfilesDetailService.upgradeById(profile, id).then((result) => {
        if (result.success) {
          feedback(
            result.message || 'Novo perfil registrado com sucesso!',
            'success',
          );
        } else {
          feedback(result.message || 'Erro ao salvar novo perfil.', 'error');
        }
      });
    } else {
      feedback('Criando novo perfil perfil...', 'loading');
      ProfilesDetailService.create(profile).then((result) => {
        if (result.success) {
          feedback(
            result.message || 'Novo perfil registrado com sucesso!',
            'success',
          );
          navigate(`/perfis/detalhe/${result.data.Id}`);
        } else {
          feedback(result.message || 'Erro ao salvar novo perfil.', 'error');
        }
      });
    }
  }, [id, profile, navigate, checkIfHasPermissions]);

  return (
    <Container>
      {isLoading ? (
        <ProfilesDetailSkeleton />
      ) : Object.keys(profile).length === 0 && id !== '0' && !isLoading ? (
        <Box width={'100%'} marginBottom={5}>
          <CentralWarning
            variant={'warning'}
            children={'Não foi possível carregar o perfil de usuário.'}
          />
        </Box>
      ) : (
        <>
          <Box width={'100%'} marginBottom={5} marginTop={1}>
            <Box width={'100%'} maxWidth={'50%'} marginBottom={3}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-adornment">Perfil</InputLabel>
                <OutlinedInput
                  placeholder="Novo Perfil"
                  name={'Perfil'}
                  id="outlined-adornment"
                  type={'text'}
                  value={profile.perfil}
                  onChange={handleChangeProfileName}
                  startAdornment={
                    <InputAdornment position={'start'}>
                      <Icon color={'primary'} fontSize={'small'}>
                        assignment_ind_filled
                      </Icon>
                    </InputAdornment>
                  }
                  label="Perfil"
                />
              </FormControl>
            </Box>
            <Box width={'100%'} maxWidth={'70%'}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-adornment">Descrição</InputLabel>
                <OutlinedInput
                  placeholder={'Descrição do perfil'}
                  name={'Descrição'}
                  id="outlined-adornment"
                  type={'text'}
                  value={profile.descricao}
                  onChange={handleChangeProgileDescription}
                  startAdornment={
                    <InputAdornment position={'start'}>
                      <Icon color={'primary'} fontSize={'small'}>
                        description
                      </Icon>
                    </InputAdornment>
                  }
                  label="Descrição"
                />
              </FormControl>
            </Box>
          </Box>
          <Box>
            <Grid container item xs={12}>
              {functionalities.length > 0 ? (
                functionalities.map((func) => (
                  <Grid
                    item
                    xs={12}
                    style={{marginBottom: 45}}
                    key={`grid-functionality-${func.nome}`}>
                    <Box
                      width={'100%'}
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'start'}
                      gap={2}>
                      <Switch
                        id={`functionality-${func.id}`}
                        color="primary"
                        checked={func.ativo}
                        onChange={(event, checked) => {
                          handleChangeFunctionalityState(func, checked);
                        }}
                      />
                      <Typography variant={'h5'} color={'primary'}>
                        {func.nome}
                      </Typography>
                    </Box>
                    <Box width={'100%'} paddingLeft={'12px'}>
                      {func.permissoes.length > 0 && (
                        <MultiselectCheckbox
                          options={func.permissoes.map((perms) => ({
                            method: perms.metodo,
                            route: perms.rota,
                            checked: perms.ativo,
                            disabled: false,
                            label: perms.nome,
                            permissionId: perms.id,
                          }))}
                          onChange={(data: any, index: number) => {
                            handleFunctionalityPermissionState(
                              func,
                              data[index].checked,
                              data[index].permissionId,
                            );
                          }}
                        />
                      )}
                    </Box>
                  </Grid>
                ))
              ) : (
                <Box>
                  <Typography color={'secondary'}>
                    Nenhuma funcionalidade disponível para este perfil.
                  </Typography>
                </Box>
              )}
            </Grid>
          </Box>
        </>
      )}
      {Object.keys(profile).length > 0 && (
        <ActionTab
          onCancel={() => {
            navigate('/perfis');
          }}
          onCancelLabel="Voltar"
          onSave={handleSaveProfile}
          onSaveLabel="Salvar"
        />
      )}
    </Container>
  );
};
