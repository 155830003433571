import React, {memo} from 'react';

import {IOption} from 'shared/services/api/quiz/Quiz';
import {DisplayMode} from '../../DisplayMode';
import {LinearScaleConfig} from './LinearScaleConfig';
import {LinearScalePreview} from './LinearScalePreview';
interface ILinearScaleProps {
  displayMode: DisplayMode;
  value: IOption[];
  onChange: (value: IOption[], isInitialFix?: boolean) => void;
}

export const LinearScale: React.FC<ILinearScaleProps> = memo(
  ({value, onChange, displayMode}) => {
    return (
      <>
        {displayMode === DisplayMode.config && (
          <LinearScaleConfig value={value} onChange={onChange} />
        )}
        {displayMode === DisplayMode.preview && (
          <LinearScalePreview value={value} />
        )}
      </>
    );
  },
);
