import React from 'react';
import {Box, Grid, Typography} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

import {LightTheme} from '../../themes';
interface IAvisoCentralProps {
  variant?: 'error' | 'warning' | 'info' | 'success';
}

export const CentralWarning: React.FC<IAvisoCentralProps> = ({
  children,
  variant = 'info',
}) => {
  let corAtual = '#000';
  switch (variant) {
    case 'error':
      corAtual = LightTheme.palette.error.main;
      break;
    case 'warning':
      corAtual = LightTheme.palette.warning.main;
      break;
    case 'info':
      corAtual = LightTheme.palette.info.main;
      break;
    case 'success':
      corAtual = LightTheme.palette.success.main;
      break;
  }

  return (
    <>
      <Grid container item xs={12} style={{height: '100%'}}>
        <Grid container item>
          <Grid
            container
            item
            direction="column"
            alignItems="center"
            alignContent="center"
            justifyContent="center"
            xs={12}>
            <Grid item>
              <Box color={corAtual} fontSize={80}>
                <WarningIcon fontSize="inherit">
                  {variant === 'error' && 'error'}
                  {variant === 'warning' && 'error'}
                  {variant === 'info' && 'info'}
                  {variant === 'success' && 'done'}
                </WarningIcon>
              </Box>
            </Grid>
            <Grid item>
              <Box>
                <Typography
                  variant="body1"
                  align="center"
                  color="textSecondary">
                  {children}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
