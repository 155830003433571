import {useCallback, useEffect, useState} from 'react';
import {Box, Slide} from '@mui/material';

interface IQuestionSlideProps {
  nextQuestionDirection: 'previous' | 'next';
  changeSlide: number;
}

export const QuestionSlide: React.FC<IQuestionSlideProps> = ({
  nextQuestionDirection,
  changeSlide,
  children,
}) => {
  const [isReadyToRender, setIsReadyToRender] = useState(true);

  const [direction, setDirection] = useState<'down' | 'up'>();

  const hideToDown = useCallback(() => {
    setDirection('down');
    setIsReadyToRender(false);
  }, []);

  const showFromDown = useCallback(() => {
    setDirection('down');
    setIsReadyToRender(true);
  }, []);

  const hideToUp = useCallback(() => {
    setDirection('up');
    setIsReadyToRender(false);
  }, []);

  const showFromUp = useCallback(() => {
    setDirection('up');
    setIsReadyToRender(true);
  }, []);

  useEffect(() => {
    if (nextQuestionDirection === 'next') {
      hideToDown();

      setTimeout(() => {
        showFromUp();
      }, 500);
    } else {
      hideToUp();

      setTimeout(() => {
        showFromDown();
      }, 500);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeSlide]);

  return (
    <Box overflow="hidden">
      <Slide in={isReadyToRender} direction={direction}>
        <div>{children}</div>
      </Slide>
    </Box>
  );
};
