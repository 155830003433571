import {valide} from 'shared/services';
import {
  IQuizDetail,
  ISection,
  IQuestion,
  IOption,
} from 'shared/services/api/quiz/Quiz';
import {TTypeOfQuestion} from 'shared/types/QuestionTypes';
import {
  IValidation,
  TTypeOfValidation,
} from '../components/validation/Validation';

export const formValidationSchema = valide.object().shape<IQuizDetail>({
  id: valide.string(),
  hasInviteSent: valide.boolean(),
  active: valide.boolean(),
  name: valide.string().required().default('').max(510),
  title: valide.string().required().max(510),
  description: valide.string().required().max(1020),
  bannerBase64: valide.string(),
  bannerExtension: valide.string(),
  logoBase64: valide.string(),
  logoExtension: valide.string(),
  quizColor: valide.string(),
  finalMessage: valide.string(),
  idQuestionaryType: valide.string().required(),
  nameQuestionaryType: valide.string(),
  establishment: valide
    .array()
    .of(valide.number().required().min(1))
    .required(),
  sections: valide
    .array()
    .of<ISection>(
      valide
        .object()
        .shape({
          id: valide.string(),
          idQuiz: valide.string().required(),
          name: valide.string().required().max(510),
          description: valide.string(),
          order: valide.number(),
          isSelected: valide.boolean(),
          multiplyDiscipline: valide.boolean(),
          multiplyCourse: valide.boolean(),
          evaluationIntent: valide.string(),
          paperGroups: valide
            .array()
            .of(valide.string().required().min(1))
            .required(),
          questions: valide
            .array()
            .of<IQuestion>(
              valide
                .object()
                .shape<IQuestion>({
                  id: valide.string(),
                  isRequired: valide.boolean().required(),
                  order: valide.number().required(),
                  name: valide.string().required().max(510),
                  description: valide.string(),
                  type: valide
                    .mixed<TTypeOfQuestion>()
                    .required()
                    .oneOf([
                      'date',
                      'time',
                      'grid-multiple-answer',
                      'grid-unique-answer',
                      'linear-scale',
                      'multiple-answer',
                      'paragraph-text',
                      'simple-text',
                      'unique-answer',
                      'satisfaction-percentage',
                    ]),
                  hasValidation: valide.boolean().required(),
                  hasComment: valide.boolean().required(),
                  hasShuffleAnswer: valide.boolean().required(),
                  hasWeight: valide.boolean().required(),
                  isSelected: valide.boolean().required(),
                  validation: valide
                    .object()
                    .shape<IValidation>({
                      typeOfValidation: valide
                        .mixed<TTypeOfValidation>()
                        .required()
                        .oneOf([
                          'texto',
                          'numero',
                          'expressao-regular',
                          'tamanho-caracteres',
                          'selecione',
                        ]),
                      suggestionValue: valide.string(),
                      operator: valide.string(),
                      value: valide.mixed<string | number>(),
                      valueEnd: valide.mixed<string | number>(),
                      customErrorMessage: valide.string(),
                    })
                    .required(),
                  columnOptions: valide
                    .array()
                    .defined()
                    .of<IOption>(
                      valide
                        .object()
                        .shape<IOption>({
                          id: valide.string(),
                          description: valide
                            .string()
                            .required(
                              'Informe uma descrição para cada opção criada em linhas ou colunas.',
                            ),
                          order: valide.number(),
                          iconOrColor: valide.string(),
                          weight: valide.number(),
                        })
                        .required(),
                    ),
                  rowOptions: valide
                    .array()
                    .of<IOption>(
                      valide
                        .object()
                        .shape<IOption>({
                          id: valide.string(),
                          description: valide
                            .string()
                            .required(
                              'Informe uma descrição para cada opção criada em linhas ou colunas.',
                            ),
                          order: valide.number(),
                          iconOrColor: valide.string(),
                          weight: valide.number(),
                        })
                        .required(),
                    )
                    .notRequired(),
                })
                .required(),
            )
            .required(),
        })
        .required(),
    )
    .min(
      1,
      'Para salvar, adicione pelo menos uma seção e uma questão no questionário',
    )
    .required(),
});
