import React, {useEffect, useState, useCallback} from 'react';

import {Box, Typography, Checkbox} from '@mui/material';
import {TreeItem, TreeView} from '@mui/x-tree-view';

import {useQuiz} from '../../hooks';
import {feedback} from 'shared/services';
import {
  EstablishmentService,
  IEstablishmentTree,
} from 'shared/services/api/establishment/Establishment';

import {useField} from '@unform/core';
import {ExpandMore, ChevronRight} from '@mui/icons-material';
import {EstabTreeSkeleton} from './EstabTreeSkeleton';

export const EstabSelectionTree: React.FC = () => {
  const {error: estabError, clearError: estabClearError} =
    useField(`establishment`);

  const {getEstablishments, setEstablishments, setIsEditingQuiz} = useQuiz();
  const [estabsFromDatabase, setEstabsFromDatabase] = useState<
    IEstablishmentTree[]
  >([]);

  const [estabsFromUserChoice, setEstabsFromUserChoice] = useState<number[]>(
    getEstablishments(),
  );
  const [isLoading, setIsLoading] = useState(true);
  const [expanded, setExpanded] = useState<string[]>([]);

  const handleToggle = (event: any, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };

  useEffect(() => {
    estabsFromUserChoice.length > 0 && estabClearError();
  }, [estabClearError, estabsFromUserChoice.length]);

  useEffect(() => {
    EstablishmentService.getAllTreeFormat().then((result) => {
      if (result.success) {
        setEstabsFromDatabase(result.data);

        const allNodes: number[] = getChildById(
          result.data[0],
          Number(result.data[0].CodigoEstruturado),
        );
        setExpanded(allNodes.map((node) => node.toString()));

        setIsLoading(false);
      } else {
        feedback(
          result.message || 'Erro ao carregar os estabelecimentos...',
          'error',
        );
        setEstabsFromDatabase([]);
        setIsLoading(false);
      }
    });
  }, []);

  function getChildById(
    estabelecimentos: IEstablishmentTree,
    id: number,
  ): number[] {
    let array: number[] = [];

    function getAllChild(estabelecimentos: IEstablishmentTree | null) {
      if (estabelecimentos === null) return [];
      array.push(estabelecimentos.IdEstabelecimento);
      if (Array.isArray(estabelecimentos.EstabelecimentosFilhos)) {
        estabelecimentos.EstabelecimentosFilhos.forEach((estabelecimento) => {
          array = [...array, ...getAllChild(estabelecimento)];
          array = array.filter((v, i) => array.indexOf(v) === i);
        });
      }
      return array;
    }

    function getNodeById(estabelecimentos: IEstablishmentTree, id: number) {
      if (estabelecimentos.IdEstabelecimento === id) {
        return estabelecimentos;
      } else if (Array.isArray(estabelecimentos.EstabelecimentosFilhos)) {
        let result = null;
        estabelecimentos.EstabelecimentosFilhos.forEach((estabelecimento) => {
          if (!!getNodeById(estabelecimento, id)) {
            result = getNodeById(estabelecimento, id);
          }
        });
        return result;
      }

      return null;
    }

    return getAllChild(getNodeById(estabelecimentos, id));
  }

  const getOnChange = useCallback(
    (checked: boolean, estabelecimentos: IEstablishmentTree) => {
      const allNode: number[] = getChildById(
        estabelecimentos,
        estabelecimentos.IdEstabelecimento,
      );
      let array = checked
        ? [...estabsFromUserChoice, ...allNode]
        : estabsFromUserChoice.filter((value) => !allNode.includes(value));

      array = array.filter((v, i) => array.indexOf(v) === i);

      setEstabsFromUserChoice(array);
      setEstablishments(array);
    },
    [setEstabsFromUserChoice, setEstablishments, estabsFromUserChoice],
  );

  const renderizarEstabelecimentos = (estabelecimentos: IEstablishmentTree) => (
    <TreeItem
      key={estabelecimentos.IdEstabelecimento}
      nodeId={`${estabelecimentos.CodigoEstruturado}`}
      label={
        <Box>
          <Checkbox
            edge="start"
            checked={estabsFromUserChoice.includes(
              estabelecimentos.IdEstabelecimento,
            )}
            onChange={(event) => {
              setIsEditingQuiz(true);
              getOnChange(event.currentTarget.checked, estabelecimentos);
            }}
            onClick={(e) => e.stopPropagation()}
          />
          {`${estabelecimentos.CodigoEstruturado} - ${estabelecimentos.NomeEstabelecimento} `}
        </Box>
      }>
      {Array.isArray(estabelecimentos.EstabelecimentosFilhos)
        ? estabelecimentos.EstabelecimentosFilhos.map((estabelecimento) =>
            renderizarEstabelecimentos(estabelecimento),
          )
        : null}
    </TreeItem>
  );

  return isLoading ? (
    <Box width={700}>
      <EstabTreeSkeleton />
    </Box>
  ) : (
    <Box p={3}>
      <Box>
        {!!estabError && (
          <Typography variant="subtitle2" color="error">
            É preciso selecionar ao menos um estabelecimento.
          </Typography>
        )}
      </Box>
      <Box width={'100%'}>
        <TreeView
          expanded={expanded}
          onNodeToggle={handleToggle}
          defaultCollapseIcon={<ExpandMore style={{marginRight: 10}} />}
          defaultExpandIcon={<ChevronRight />}>
          {estabsFromDatabase !== undefined ? (
            estabsFromDatabase.map((estabelecimento) =>
              renderizarEstabelecimentos(estabelecimento),
            )
          ) : (
            <Box padding={2}>
              <Typography color="secondary">
                Nenhum registro encontrado...
              </Typography>
            </Box>
          )}
        </TreeView>
      </Box>
    </Box>
  );
};
