import {
  Box,
  Grid,
  TextField,
  Typography,
  useTheme,
  IconButton,
} from '@mui/material';
import {Add} from '@mui/icons-material';

import {memo} from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';

import {IOption} from 'shared/services/api/quiz/Quiz';
import {OptionColumn} from './OptionColumn';

interface IColumnWrapperProps {
  columnList: IOption[];
  onDragEnd: (result: DropResult) => void;
  handleAddColumn: (index: number) => void;
  handleRemoveColumn: (index: number) => void;
  handleUpdateDescription: (index: number, text: string) => void;
  onChangeSelect: (index: number, value: string) => void;
}

export const ColumnWrapper: React.FC<IColumnWrapperProps> = memo(
  ({
    columnList,
    onDragEnd,
    handleAddColumn,
    handleRemoveColumn,
    handleUpdateDescription,
    onChangeSelect,
  }) => {
    const theme = useTheme();

    return (
      <Box minWidth={'45%'}>
        <Box marginBottom={3}>
          <Typography>Colunas de resposta única</Typography>
        </Box>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="columns">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {columnList.map((option, index) =>
                  option.order !== 0 ? (
                    <Draggable
                      key={`GridUniqueAnswer-ColumnOrder-${option.order}`}
                      draggableId={`draggable column  ${option.order}`}
                      index={index}>
                      {(provided, {isDragging}) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...provided.draggableProps.style,
                            background: theme.palette.background.paper,
                            boxShadow: isDragging
                              ? theme.shadows[4]
                              : undefined,
                            paddingRight: isDragging
                              ? theme.spacing(3)
                              : theme.spacing(0),
                            paddingTop: isDragging
                              ? theme.spacing(1.5)
                              : theme.spacing(2),
                          }}>
                          <OptionColumn
                            option={option}
                            quantityOfColumns={columnList.length}
                            selectedValue={option.iconOrColor ?? ''}
                            handleAddColumn={handleAddColumn}
                            handleRemoveColumn={handleRemoveColumn}
                            handleUpdateDescription={handleUpdateDescription}
                            onChangeSelect={onChangeSelect}
                          />
                        </div>
                      )}
                    </Draggable>
                  ) : (
                    <Box key={`GridUniqueAnswer-ColumnId-${option.order}`}>
                      <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        justifyContent="flex-start">
                        <Grid item={true} xs={1}></Grid>
                        <Grid item={true} xs={6}>
                          <TextField
                            variant="outlined"
                            placeholder="Opção 0"
                            value={option.description}
                            onChange={(event) =>
                              handleUpdateDescription(
                                option.order,
                                event.target.value,
                              )
                            }
                          />
                        </Grid>
                        {columnList.length === 1 ? (
                          <Grid item={true} xs={2}>
                            <Box display="flex">
                              <IconButton
                                onClick={() => handleAddColumn(option.order)}>
                                <Add />
                              </IconButton>
                            </Box>
                          </Grid>
                        ) : (
                          <></>
                        )}
                      </Grid>
                    </Box>
                  ),
                )}

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
    );
  },
);
