import React, {useEffect} from 'react';
import {BrowserRouter} from 'react-router-dom';
import { CssBaseline } from '@mui/material';


import {Environment} from 'shared/Env';

import {AppRoutes} from './routes';
import {
  AlertComponent,
  ConfirmationDialogComponent,
  SplashScreen,
} from './shared/components';
import {Layout} from 'shared/layouts/Layout';
import {ThemesProvider, TitleProvider, UserProvider} from './shared/contexts';


export const App = () => {
  useEffect(() => {
    const script = document.createElement('script');
    const scriptFeedBack = document.createElement('script');
    script.type = 'module';
    script.src = `${Environment.URL_WC_FEEDABACK}`;
    script.crossOrigin = 'anonymous';

    document.body.appendChild(script);
    document.body.appendChild(scriptFeedBack);

    return () => {
      document.body.removeChild(script);
      document.body.appendChild(scriptFeedBack);
    };
  }, []);

  return (
    <>
      <CssBaseline />
      <ThemesProvider>
        <UserProvider>
          <SplashScreen>
            <BrowserRouter>
              <TitleProvider>
                <Layout>
                  <AppRoutes />
                  <AlertComponent />
                  <ConfirmationDialogComponent />
                </Layout>
              </TitleProvider>
            </BrowserRouter>
          </SplashScreen>
        </UserProvider>
      </ThemesProvider>
    </>
  );
};
