import React from 'react';
import {
  TableCell,
  TableRow,
  TableContainer,
  TableBody,
  Table,
  Paper,
  TableFooter,
  Box
} from '@mui/material';
import {Skeleton} from '@mui/lab';

export const QuestDetailSkeleton: React.FC = () => {
  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow hover>
              <TableCell component="th" scope="row">
                <Skeleton variant="text" width={'30%'} />
                <Box marginBottom={2} />
                <Skeleton variant="rectangular" width="auto" height={25} />
              </TableCell>
              <TableCell component="th" scope="row">
                <Skeleton variant="text" width={'35%'} />
                <Box marginBottom={2} />
                <Skeleton variant="rectangular" width="auto" height={25} />
              </TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell component="th" scope="row">
                <Skeleton variant="rectangular" width="auto" height={25} />
              </TableCell>
              <TableCell component="th" scope="row">
                <Skeleton variant="rectangular" width="auto" height={25} />
              </TableCell>
            </TableRow>
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={4} align={'right'}>
                <Skeleton variant="rectangular" width="100%" height={25} />
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};
