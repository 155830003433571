import {Container} from '@mui/material';
import {Theme, styled} from '@mui/material/styles';
import {makeStyles} from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  '@global': {
    '*::-webkit-scrollbar': {
      width: 6,
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': theme.palette.primary.main,
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: '#E0E0E0',
      borderRadius: 50,
      width: 12,
      maxHeight: 100,
    },
  },
  root: {
    display: 'flex',
    margin: 0,
    padding: 0,
    border: 0,
  },
  content: {
    flexGrow: 1,
    margin: theme.spacing(12, 0),

    '@media (min-width: 1200px)': {
      maxWidth: '100%',
    },
  },
  mainContainer: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0',
      paddingRight: '0',
    },
  },
}));

export const StyledMain = styled('main')`
  overflow-x: hidden;
  flex-grow: 1;
  margin: ${(props) => props.theme.spacing(12, 0)};

  @media (min-width: 1200px) {
    max-width: 100%;
  }
`;

export const StyledContainer = styled(Container)`
  ${(props) => [props.theme.breakpoints.down('sm')]} {
    padding-left: 0;
    padding-right: 0;
  }

  @media (min-width: 1200px) {
    max-width: 100%;
  }
`;
