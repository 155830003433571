import React, {useState} from 'react';
import {Typography, TypographyProps} from '@mui/material';

interface IReadMoreProps extends TypographyProps {
  text: string;
  cutAt: number;
}

export const ReadMore: React.FC<IReadMoreProps> = ({
  children,
  text,
  cutAt,
  ...typographyProps
}) => {
  const [readMore, setReadMore] = useState(true);

  return (
    <>
      {text && (
        <>
          <Typography {...typographyProps}>
            {text.length > cutAt && readMore
              ? `${text.slice(0, cutAt)}...`
              : text}
          </Typography>
          {text.length > cutAt && (
            <Typography
              onClick={() => {
                setReadMore(!readMore);
              }}
              style={{cursor: 'pointer'}}
              component="b">
              {readMore ? 'Ver mais' : 'Ver menos'}
            </Typography>
          )}
        </>
      )}
    </>
  );
};
