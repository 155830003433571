import React, {useMemo} from 'react';
import {Box, Theme} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {ReactComponent as FinishedFirst} from './svg/finished.svg';
import {ReactComponent as FinishedSecond} from './svg/finished-1.svg';
import {ReactComponent as FinishedThird} from './svg/finished-2.svg';
import {ReactComponent as FinishedFourth} from './svg/finished-3.svg';

interface IProps {
  color: string;
  size?: string | number;
}

const useStyles = makeStyles<Theme, IProps>(() => ({
  svgWrapper: {
    '& svg': {
      '& g': {
        '& path, polygon, circle': {
          '&.colorToChange': {
            fill: (props: IProps) => `#${props.color} !important`,
          },
        },
      },
    },
  },
}));

export const FinishedQuiz: React.FC<IProps> = ({color, size}) => {
  const classes = useStyles({color});

  const imageToShow = useMemo(() => {
    return Math.floor(Math.random() * 4);
  }, []);

  const renderFinishedSvg = (image: number) => {
    switch (image) {
      case 0:
        return (
          <Box>
            <FinishedFirst width={size} height={size} />
          </Box>
        );
      case 1:
        return (
          <Box>
            <FinishedSecond width={size} height={size} />
          </Box>
        );
      case 2:
        return (
          <Box>
            <FinishedThird width={size} height={size} />
          </Box>
        );
      case 3:
        return (
          <Box>
            <FinishedFourth width={size} height={size} />
          </Box>
        );

      default:
        return (
          <>
            <FinishedFirst width={size} height={size} />
          </>
        );
    }
  };

  return (
    <>
      <Box width={'100%'} maxWidth={'400px'} className={classes.svgWrapper}>
        {renderFinishedSvg(imageToShow)}
      </Box>
    </>
  );
};
