import React, {useState} from 'react';

import {StyledContainer, StyledMain, useStyles} from './styles';
import {Header, Drawer} from './components';

export const Layout: React.FC = ({children}) => {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);
  const [buttonActive, setButtonActive] = useState(false);

  return (
    <div className={classes.root}>
      <Header
        isOpen={isOpen}
        buttonActive={buttonActive}
        setButtonActive={setButtonActive}
        handleDrawerOpen={() => {
          setIsOpen(!isOpen);
        }}
      />
      <Drawer
        isOpen={isOpen}
        closeDrawer={() => {
          setIsOpen(false);
          setButtonActive(false);
        }}
        buttonActive={buttonActive}
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
      />
      <StyledMain>
        <StyledContainer>
          <>{children}</>
        </StyledContainer>
      </StyledMain>
    </div>
  );
};
