import React from 'react';
import {
  Box,
  TableContainer,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import {Skeleton} from '@mui/lab';

export const LinScaleUniqueMultipleSkeleton: React.FC = () => {
  return (
    <Box width={'100%'} maxWidth={'1124px'} margin={'0 auto'} p={3}>
      <Box marginBottom={5}>
        <Box
          width={'100%'}
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}>
          <Skeleton variant="text" width={'30%'} />
          <Skeleton variant="text" width={'30%'} height={56} />
        </Box>
        <Box marginBottom={2} />
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow hover>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box marginBottom={5}>
        <Box
          width={'100%'}
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}>
          <Skeleton variant="text" width={'30%'} />
          <Skeleton variant="text" width={'30%'} height={56} />
        </Box>
        <Box marginBottom={2} />
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow hover>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width="auto" height={35} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};
