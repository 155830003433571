import React, {useEffect, useRef} from 'react';
import {TextField, TextFieldProps} from '@mui/material';
import {useField} from '@unform/core';

export const VTextField: React.FC<TextFieldProps & {name: string}> = ({
    name,
    margin = 'none',
    ...rest
}) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const {fieldName, defaultValue, registerField, error} = useField(name);

    useEffect(() => {
        registerField({
            ref: inputRef.current,
            name: fieldName,
            path: 'value',
        });
    }, [fieldName, registerField]);

    return (
        <TextField
            inputRef={inputRef}
            {...rest}
            defaultValue={defaultValue}
            error={!!error}
            helperText={error}
        />
    );
};
