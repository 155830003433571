import React, {memo} from 'react';
import {TableCell, TableRow} from '@mui/material';
import {Skeleton} from '@mui/lab';

export const QuizListSkeleton: React.FC = memo(() => {
  return (
    <>
      <TableRow hover>
        <TableCell component="th" scope="row" width={'30%'}>
          <Skeleton variant="rectangular" width="auto" height={25} />
        </TableCell>
        <TableCell component="th" scope="row" width={'15%'}>
          <Skeleton variant="rectangular" width="auto" height={25} />
        </TableCell>
        <TableCell component="th" scope="row" width={'15%'}>
          <Skeleton variant="rectangular" width="auto" height={25} />
        </TableCell>
        <TableCell component="th" scope="row" width={'16%'}>
          <Skeleton variant="rectangular" width="auto" height={25} />
        </TableCell>
        <TableCell component="th" scope="row" width={'14%'}>
          <Skeleton variant="rectangular" width="auto" height={25} />
        </TableCell>
        <TableCell component="th" scope="row" width={'14%'}>
          <Skeleton variant="rectangular" width="auto" height={25} />
        </TableCell>
      </TableRow>
      <TableRow hover>
        <TableCell component="th" scope="row" width={'30%'}>
          <Skeleton variant="rectangular" width="auto" height={25} />
        </TableCell>
        <TableCell component="th" scope="row" width={'15%'}>
          <Skeleton variant="rectangular" width="auto" height={25} />
        </TableCell>
        <TableCell component="th" scope="row" width={'15%'}>
          <Skeleton variant="rectangular" width="auto" height={25} />
        </TableCell>
        <TableCell component="th" scope="row" width={'16%'}>
          <Skeleton variant="rectangular" width="auto" height={25} />
        </TableCell>
        <TableCell component="th" scope="row" width={'14%'}>
          <Skeleton variant="rectangular" width="auto" height={25} />
        </TableCell>
        <TableCell component="th" scope="row" width={'14%'}>
          <Skeleton variant="rectangular" width="auto" height={25} />
        </TableCell>
      </TableRow>
      <TableRow hover>
        <TableCell component="th" scope="row" width={'30%'}>
          <Skeleton variant="rectangular" width="auto" height={25} />
        </TableCell>
        <TableCell component="th" scope="row" width={'15%'}>
          <Skeleton variant="rectangular" width="auto" height={25} />
        </TableCell>
        <TableCell component="th" scope="row" width={'15%'}>
          <Skeleton variant="rectangular" width="auto" height={25} />
        </TableCell>
        <TableCell component="th" scope="row" width={'16%'}>
          <Skeleton variant="rectangular" width="auto" height={25} />
        </TableCell>
        <TableCell component="th" scope="row" width={'14%'}>
          <Skeleton variant="rectangular" width="auto" height={25} />
        </TableCell>
        <TableCell component="th" scope="row" width={'14%'}>
          <Skeleton variant="rectangular" width="auto" height={25} />
        </TableCell>
      </TableRow>
    </>
  );
});
