import React, {memo, useCallback, useMemo} from 'react';
import {
  Box,
  IconButton,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@mui/material';
import {
  DeleteOutlineOutlined,
  FileCopyOutlined,
  VisibilityOutlined,
  EditOutlined,
} from '@mui/icons-material';
import {Tooltip} from 'shared/components';
import {confirm} from 'shared/services';
import {Switch} from 'shared/components/switch/Switch';
import {DisplayMode} from './DisplayMode';
import {TTypeOfQuestion} from 'shared/types/QuestionTypes';
import {useQuiz} from 'pages/quiz/hooks';

interface IQuestionActionsProps {
  mode: DisplayMode;
  isMandatory: boolean;
  disabledPreview: boolean;
  onChangeIsMandatory: (checked: boolean) => void;
  onClickPreview: (value: DisplayMode) => void;
  typeOfQuestion: TTypeOfQuestion;
  hasValidation?: boolean;
  hasComment?: boolean;
  hasShuffleAnswer?: boolean;
  hasWeight?: boolean;
  onChangeHasValidation?: (checked: boolean) => void;
  onChangeHasComment?: (checked: boolean) => void;
  onChangeHasShuffleAnswer?: (checked: boolean) => void;
  onChangeHasWeight?: (checked: boolean) => void;
  onClickDuplicate: () => void;
  onDeleteQuestion: () => void;
}

export const QuestionActions: React.FC<IQuestionActionsProps> = memo(
  ({
    mode,
    isMandatory,
    disabledPreview,
    onChangeIsMandatory,
    onClickPreview,
    typeOfQuestion,
    hasValidation,
    hasComment,
    hasShuffleAnswer,
    hasWeight,
    onChangeHasValidation,
    onChangeHasComment,
    onChangeHasShuffleAnswer,
    onChangeHasWeight,
    onClickDuplicate,
    onDeleteQuestion,
  }) => {
    const {hasInviteSent} = useQuiz();

    const handleOnClickPreview = useCallback(() => {
      onClickPreview(
        mode === DisplayMode.config ? DisplayMode.preview : DisplayMode.config,
      );
    }, [mode, onClickPreview]);

    const handleOnClickDuplicate = useCallback(() => {
      onClickDuplicate();
    }, [onClickDuplicate]);

    const handleOnClickDelete = useCallback(() => {
      confirm('Tem certeza que deseja remover esta questão?', 'delete', () =>
        onDeleteQuestion(),
      );
    }, [onDeleteQuestion]);

    const actions = useMemo(() => {
      switch (typeOfQuestion) {
        case 'simple-text':
          return {
            showValidation: true,
            showComment: false,
            showWeight: false,
            showShuffleAnswer: false,
          };
        case 'paragraph-text':
          return {
            showValidation: true,
            showComment: false,
            showWeight: false,
            showShuffleAnswer: false,
          };
        case 'linear-scale':
          return {
            showValidation: false,
            showComment: true,
            showWeight: false,
            showShuffleAnswer: false,
          };
        case 'grid-multiple-answer':
          return {
            showValidation: true,
            showComment: true,
            showWeight: true,
            showShuffleAnswer: true,
          };
        case 'grid-unique-answer':
          return {
            showValidation: false,
            showComment: true,
            showWeight: false,
            showShuffleAnswer: true,
          };
        case 'multiple-answer':
          return {
            showValidation: true,
            showComment: true,
            showWeight: false,
            showShuffleAnswer: true,
          };
        case 'unique-answer':
          return {
            showValidation: false,
            showComment: true,
            showWeight: false,
            showShuffleAnswer: true,
          };
        case 'date':
          return {
            showValidation: true,
            showComment: true,
            showWeight: false,
            showShuffleAnswer: false,
          };
        case 'time':
          return {
            showValidation: true,
            showComment: true,
            showWeight: false,
            showShuffleAnswer: false,
          };
        case 'satisfaction-percentage':
          return {
            showValidation: false,
            showComment: true,
            showWeight: false,
            showShuffleAnswer: false,
          };
        default:
          return {
            showValidation: false,
            showComment: false,
            showWeight: false,
            showShuffleAnswer: false,
          };
      }
    }, [typeOfQuestion]);

    return (
      <Box>
        <Box
          display="flex"
          alignContent="center"
          justifyContent={
            actions.showShuffleAnswer ||
            actions.showComment ||
            actions.showValidation ||
            actions.showValidation
              ? 'space-between'
              : 'flex-end'
          }
          marginTop={2}>
          {actions.showShuffleAnswer && (
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  color="primary"
                  checked={hasShuffleAnswer}
                  onChange={(_, checked) =>
                    onChangeHasShuffleAnswer &&
                    onChangeHasShuffleAnswer(checked)
                  }
                />
              }
              label={
                <Typography variant="caption" noWrap component={'b'}>
                  Embaralhar respostas
                </Typography>
              }
            />
          )}
          {actions.showComment && (
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  color="primary"
                  checked={hasComment}
                  onChange={(_, checked) =>
                    onChangeHasComment && onChangeHasComment(checked)
                  }
                />
              }
              label={
                <Typography variant="caption" noWrap component={'b'}>
                  Permitir comentário
                </Typography>
              }
            />
          )}
          {actions.showValidation && (
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  color="primary"
                  checked={hasValidation}
                  onChange={(_, checked) =>
                    onChangeHasValidation && onChangeHasValidation(checked)
                  }
                />
              }
              label={
                <Typography variant="caption" noWrap component={'b'}>
                  Validar respostas
                </Typography>
              }
            />
          )}
          <Box display="flex">
            <Tooltip
              text={mode === DisplayMode.config ? 'Pré-visualizar' : 'Editar'}>
              <IconButton
                aria-label="visibility"
                onClick={handleOnClickPreview}
                disabled={disabledPreview}>
                {mode === DisplayMode.config ? (
                  <VisibilityOutlined fontSize="small" />
                ) : (
                  <EditOutlined fontSize="small" />
                )}
              </IconButton>
            </Tooltip>

            <Tooltip text="Duplicar">
              <IconButton
                aria-label="copy"
                onClick={handleOnClickDuplicate}
                disabled={hasInviteSent}>
                <FileCopyOutlined fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip text="Deletar">
              <IconButton
                aria-label="delete"
                onClick={handleOnClickDelete}
                disabled={hasInviteSent}>
                <DeleteOutlineOutlined fontSize="small" />
              </IconButton>
            </Tooltip>
            <Box display="flex" alignItems="center">
              <FormControlLabel
                control={
                  <Switch
                    checked={isMandatory}
                    onChange={onChangeIsMandatory}
                  />
                }
                label={
                  <Typography variant="caption" component={'b'}>
                    Obrigatório
                  </Typography>
                }
                labelPlacement="start"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  },
);
