import React, {memo, useMemo, useCallback} from 'react';
import {Box, FormControl} from '@mui/material';
import {
  DatePreview,
  TimePreview,
  SimpleTextPreview,
  LinearScalePreview,
  UniqueAnswerPreview,
  ParagraphTextPreview,
  MultipleAnswerPreview,
  GridUniqueAnswerPreview,
  GridMultipleAnswerPreview,
} from './question/components';
import {
  IStudentQuestion,
  IOptions,
} from 'shared/services/api/student-quiz/QuestionList';
import {IQuestion} from 'shared/services/api/reports/IndividualAnswers';
import {ReadMore} from 'shared/components';
import {SubTheme} from '../themes/SubTheme';
import {SatisfactionPercentagePreview} from './question/components/satisfaction-percentage/SatisfactionPercentagePreview';

interface IRenderQuestionProps {
  hasError?: boolean;
  isQuizCompleted?: boolean;
  currentQuestion: IStudentQuestion | IQuestion;
  setCurrentQuestion: (currentQuestion: IStudentQuestion) => void;
  currentQuizColor?: string;
}

export const RenderQuestion: React.FC<IRenderQuestionProps> = memo(
  ({
    hasError = false,
    isQuizCompleted = true,
    currentQuestion,
    setCurrentQuestion,
    currentQuizColor = '',
  }) => {
    const handleRespostaTexto = useCallback(
      (value: string) => {
        setCurrentQuestion({
          ...currentQuestion,
          respostaTexto: value,
        } as IStudentQuestion);
      },
      [currentQuestion, setCurrentQuestion],
    );

    const handleComentario = useCallback(
      (value: string) => {
        setCurrentQuestion({
          ...currentQuestion,
          comentario: value,
        } as IStudentQuestion);
      },
      [currentQuestion, setCurrentQuestion],
    );

    const handleOpcoesRespostaLinha = useCallback(
      (options: IOptions[]) => {
        setCurrentQuestion({
          ...currentQuestion,
          opcoesRespostaLinha: [...options],
        } as IStudentQuestion);
      },
      [currentQuestion, setCurrentQuestion],
    );

    const renderQuestion = useMemo(() => {
      switch (currentQuestion.tipoQuestao) {
        case 'date':
          return (
            <DatePreview
              value={currentQuestion.respostaTexto}
              comment={currentQuestion.comentario}
              hasComment={currentQuestion.permiteComentario}
              setValue={handleRespostaTexto}
              setComment={handleComentario}
              isCompleted={isQuizCompleted}
            />
          );
        case 'time':
          return (
            <TimePreview
              value={currentQuestion.respostaTexto}
              comment={currentQuestion.comentario}
              hasComment={currentQuestion.permiteComentario}
              setValue={handleRespostaTexto}
              setComment={handleComentario}
              isCompleted={isQuizCompleted}
            />
          );
        case 'simple-text':
          return (
            <SimpleTextPreview
              textAnswer={currentQuestion.respostaTexto}
              comment={currentQuestion.comentario}
              hasComment={currentQuestion.permiteComentario}
              setTextAnswer={handleRespostaTexto}
              setComment={handleComentario}
              isCompleted={isQuizCompleted}
            />
          );
        case 'paragraph-text':
          return (
            <ParagraphTextPreview
              textAnswer={currentQuestion.respostaTexto}
              comment={currentQuestion.comentario}
              hasComment={currentQuestion.permiteComentario}
              setTextAnswer={handleRespostaTexto}
              setComment={handleComentario}
              isCompleted={isQuizCompleted}
            />
          );
        case 'unique-answer':
          return (
            <UniqueAnswerPreview
              comment={currentQuestion.comentario}
              options={currentQuestion.opcoesRespostaLinha}
              hasComment={currentQuestion.permiteComentario}
              setComment={handleComentario}
              onChange={handleOpcoesRespostaLinha}
              isCompleted={isQuizCompleted}
            />
          );
        case 'multiple-answer':
          return (
            <MultipleAnswerPreview
              comment={currentQuestion.comentario}
              options={currentQuestion.opcoesRespostaLinha}
              hasComment={currentQuestion.permiteComentario}
              setComment={handleComentario}
              onChange={handleOpcoesRespostaLinha}
              isCompleted={isQuizCompleted}
            />
          );
        case 'linear-scale':
          return (
            <LinearScalePreview
              comment={currentQuestion.comentario}
              options={currentQuestion.opcoesRespostaLinha}
              hasComment={currentQuestion.permiteComentario}
              setComment={handleComentario}
              onChange={handleOpcoesRespostaLinha}
              isCompleted={isQuizCompleted}
            />
          );
        case 'grid-unique-answer':
          return (
            <GridUniqueAnswerPreview
              comment={currentQuestion.comentario}
              options={currentQuestion.opcoesRespostaLinha}
              hasError={hasError}
              hasComment={currentQuestion.permiteComentario}
              setComment={handleComentario}
              onChange={handleOpcoesRespostaLinha}
              isCompleted={isQuizCompleted}
            />
          );
        case 'grid-multiple-answer':
          return (
            <GridMultipleAnswerPreview
              comment={currentQuestion.comentario}
              options={currentQuestion.opcoesRespostaLinha}
              hasError={hasError}
              hasComment={currentQuestion.permiteComentario}
              setComment={handleComentario}
              onChange={handleOpcoesRespostaLinha}
              isCompleted={isQuizCompleted}
            />
          );
        case 'satisfaction-percentage':
          return (
            <SatisfactionPercentagePreview
              comment={currentQuestion.comentario}
              options={currentQuestion.opcoesRespostaLinha}
              hasComment={currentQuestion.permiteComentario}
              setComment={handleComentario}
              onChange={handleOpcoesRespostaLinha}
              isCompleted={isQuizCompleted}
            />
          );
        default:
          return <p>Tipo de questão inexistente.</p>;
      }
    }, [
      hasError,
      isQuizCompleted,
      currentQuestion.comentario,
      currentQuestion.tipoQuestao,
      currentQuestion.respostaTexto,
      currentQuestion.permiteComentario,
      currentQuestion.opcoesRespostaLinha,
      handleComentario,
      handleRespostaTexto,
      handleOpcoesRespostaLinha,
    ]);

    return (
      <SubTheme color={currentQuizColor}>
        <FormControl style={{width: '100%'}}>
          <ReadMore
            cutAt={150}
            text={currentQuestion.enunciado || ''}
            color="textPrimary"
            variant="h5"
            style={{fontWeight: 400}}
          />
          {currentQuestion.descricao && (
            <ReadMore
              cutAt={200}
              text={currentQuestion.descricao}
              color={'textSecondary'}
              style={{fontWeight: 400}}
            />
          )}
          <Box marginBottom={3} />
          {renderQuestion}
        </FormControl>
      </SubTheme>
    );
  },
);
