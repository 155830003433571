import React, {useMemo, useState} from 'react';
import {NavLink} from 'react-router-dom';

import clsx from 'clsx';
import {
  Box,
  Icon,
  useTheme,
  ListItem,
  ListItemIcon,
  ListItemText,
  InputAdornment,
  List,
} from '@mui/material';

import {
  StyledDrawer,
  StyledIconInputSearch,
  StyledList,
  StyledListItemSearch,
  StyledTextField,
  useStyles,
  ListItemFeedback,
  MuiIcon,
  ListItemLogout,
} from './styles';
import {useUser} from 'shared/hooks';
import {UserService} from 'shared/services/api/user/User';

import {useFeedback} from 'shared/hooks/useFeedback';
import {ModalFeedBack} from 'pages/feedBack/ModalFeedBack';

interface IDrawerProps {
  isOpen: boolean;
  buttonActive: boolean;
  closeDrawer: () => void;
  onMouseEnter: () => void | undefined;
  onMouseLeave: () => void | undefined;
}

export const Drawer: React.FC<IDrawerProps> = ({
  isOpen,
  buttonActive,
  closeDrawer,
  onMouseEnter,
  onMouseLeave,
}) => {
  const {allowedRoutes} = useUser();

  const menuOptions = allowedRoutes;

  const [filtro, setFiltro] = useState('');
  const classes = useStyles();
  const theme = useTheme();
  const {openModalFeedBack, handleToggleModalFeedBack} = useFeedback();

  const filteredMenuOptions = useMemo(() => {
    if (menuOptions?.length) {
      return menuOptions.filter((item) =>
        item.name.toLowerCase().includes(filtro.toLowerCase()),
      );
    }
    return [];
  }, [filtro, menuOptions]);

  return (
    <StyledDrawer
      isOpen={isOpen}
      onMouseEnter={buttonActive ? undefined : onMouseEnter}
      onMouseLeave={buttonActive ? undefined : onMouseLeave}
      variant="permanent">
      <StyledList
        classes={{
          root: clsx({
            [classes.drawerOpen]: isOpen,
            [classes.drawerClose]: !isOpen,
          }),
        }}>
        <StyledListItemSearch
          isOpen={isOpen}
          classes={{
            root: clsx({
              [classes.inputBoxSearchOpen]: isOpen,
              [classes.inputBoxSearchClose]: !isOpen,
            }),
          }}>
          {isOpen ? (
            <StyledTextField
              fullWidth
              value={filtro}
              onChange={(e: any) => setFiltro(e.target.value)}
              variant="outlined"
              placeholder="Buscar"
              size="small"
              InputProps={{
                classes: {
                  root: classes.inputSearch,
                  input: classes.input,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <StyledIconInputSearch>search</StyledIconInputSearch>
                  </InputAdornment>
                ),
              }}
            />
          ) : (
            <ListItemIcon className={classes.iconSearch}>
              <Icon fontSize="medium" className={classes.icons}>
                search
              </Icon>
            </ListItemIcon>
          )}
        </StyledListItemSearch>
        <Box marginTop={6.8} />

        {menuOptions &&
          filteredMenuOptions.map((item) => (
            <ListItem
              to={{
                pathname: item.newTab
                  ? `//${item.path.replace(/(^\w+:|^)\/\//, '')}`
                  : item.path,
              }}
              key={item.name}
              component={NavLink as any}
              target={item.newTab ? '_blank' : '_self'}
              className={classes.listItem}
              style={
                (({isActive}: any) =>
                  isActive
                    ? {
                        backgroundColor: theme.palette.divider,
                        color: theme.palette.getContrastText,
                        fontWeight: 'bold',
                        borderLeftWidth: 4,
                        borderLeftColor: theme.palette.getContrastText,
                        borderLeftStyle: 'solid',
                      }
                    : {}) as any
              }
              onClick={closeDrawer}>
              <ListItemIcon>
                <Icon className={classes.icons}>{item.icon}</Icon>
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItem>
          ))}
        <List
          style={{
            height: '108px',
            width: '100%',
          }}>
          {' '}
        </List>
        <ListItemFeedback
          drawer-open={isOpen}
          onClick={handleToggleModalFeedBack}>
          <ListItemIcon>
            <MuiIcon fontSize="medium">feedback</MuiIcon>
          </ListItemIcon>
          <ListItemText primary="Feedback" />
        </ListItemFeedback>
        <ListItemLogout
          style={{color: '#FFF'}}
          drawer-open={isOpen}
          onClick={() => UserService.signout()}>
          <ListItemIcon>
            <Icon className={classes.icons}>logout</Icon>
          </ListItemIcon>
          <ListItemText primary="Sair" />
        </ListItemLogout>
      </StyledList>

      <ModalFeedBack
        isOpen={openModalFeedBack}
        handleClose={handleToggleModalFeedBack}
      />
    </StyledDrawer>
  );
};
