import React, {memo} from 'react';

import {IOption} from 'shared/services/api/quiz/Quiz';
import {DisplayMode} from '../../DisplayMode';
import {SatisfactionPercentageConfig} from './SatisfactionPercentageConfig';
import {SatisfactionPercentagePreview} from './SatisfactionPercentagePreview';

interface ISatisfactionPercentageProps {
  displayMode: DisplayMode;
  value: IOption[];
  onChange: (value: IOption[], isInitialFix?: boolean) => void;
}

export const SatisfactionPercentage: React.FC<ISatisfactionPercentageProps> =
  memo(({value, onChange, displayMode}) => {
    return (
      <>
        {displayMode === DisplayMode.config && (
          <SatisfactionPercentageConfig value={value} onChange={onChange} />
        )}
        {displayMode === DisplayMode.preview && (
          <SatisfactionPercentagePreview value={value} />
        )}
      </>
    );
  });
