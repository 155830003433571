import React, {useState} from 'react';
import {Checkbox, FormControlLabel, Box, MenuItem} from '@mui/material';

interface MultiselectCheckboxProps {
  options: {
    label: string;
    checked: boolean;
    resposta: number[];
  }[];
  onChange: (
    data: {resposta: number[]; label: string; checked?: boolean}[],
  ) => void;
}

export const MultiselectCheckbox: React.FC<MultiselectCheckboxProps> = ({
  options,
  onChange,
}) => {
  const [data, setData] = useState(options);

  const toggle = (index: number) => {
    const newData = [...data];
    newData.splice(index, 1, {
      resposta: data[index].resposta,
      label: data[index].label,
      checked: !data[index].checked,
    });
    setData(newData);
    onChange(newData.filter((x) => x.checked));
  };

  return (
    <Box>
      {data.map((item, index) => (
        <MenuItem key={`checkbox-filter-${index}`}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={item.checked || false}
                onClick={() => toggle(index)}
              />
            }
            label={item.label}
          />
        </MenuItem>
      ))}
    </Box>
  );
};
