import {memo} from 'react';
import {IOption} from 'shared/services/api/quiz/Quiz';
import {DisplayMode} from '../../DisplayMode';
import {MultipleAnswerConfig} from './MultipleAnswerConfig';
import {MultipleAnswerPreview} from './MultipleAnswerPreview';
interface IMultipleAnswerProps {
  displayMode: DisplayMode;
  value: IOption[];
  onChange: (value: IOption[]) => void;
}
export const MultipleAnswer: React.FC<IMultipleAnswerProps> = memo(
  ({displayMode, value, onChange}) => {
    return (
      <>
        {displayMode === DisplayMode.config && (
          <MultipleAnswerConfig value={value} onChange={onChange} />
        )}
        {displayMode === DisplayMode.preview && (
          <MultipleAnswerPreview value={value} />
        )}
      </>
    );
  },
);
