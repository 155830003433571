import React, {memo} from 'react';
import {TTypeOfQuestion} from 'shared/types/QuestionTypes';
import {DisplayMode} from './DisplayMode';

import {SimpleText} from './components/simple-text/SimpleText';
import {ParagraphText} from './components/paragraph-text/ParagraphText';
import {LinearScale} from './components/linear-scale/LinearScale';
import {Time} from './components/time/Time';
import {Date} from './components/date/Date';
import {UniqueAnswer} from './components/unique-answer/UniqueAnswer';
import {MultipleAnswer} from './components/multiple-answer/MultipleAnswer';
import {GridUniqueAnswer} from './components/grid-unique-answer/GridUniqueAnswer';
import {GridMultipleAnswer} from './components/grid-multiple-answer/GridMultipleAnswer';
import {IOption} from 'shared/services/api/quiz/Quiz';
import {SatisfactionPercentage} from './components/satisfaction-percentage/SatisfactionPercentage';

interface ITypeOfQuestionProps {
  type: TTypeOfQuestion;
  displayMode: DisplayMode;
  columnOptions: IOption[];
  rowOptions?: IOption[];
  onChangeRows?: (value: IOption[], isInitialFix?: boolean) => void;
  onChangeColumns?: (value: IOption[]) => void;
}

export const TypeOfQuestion: React.FC<ITypeOfQuestionProps> = memo(
  ({
    type,
    displayMode,
    columnOptions,
    rowOptions = [],
    onChangeRows = () => {},
    onChangeColumns = () => {},
  }) => {
    switch (type) {
      case 'simple-text':
        return <SimpleText displayMode={displayMode} />;
      case 'paragraph-text':
        return <ParagraphText displayMode={displayMode} />;
      case 'linear-scale':
        return (
          <LinearScale
            displayMode={displayMode}
            value={rowOptions}
            onChange={onChangeRows}
          />
        );
      case 'time':
        return <Time displayMode={displayMode} />;
      case 'date':
        return <Date displayMode={displayMode} />;
      case 'unique-answer':
        return (
          <UniqueAnswer
            displayMode={displayMode}
            value={rowOptions}
            onChange={onChangeRows}
          />
        );
      case 'multiple-answer':
        return (
          <MultipleAnswer
            displayMode={displayMode}
            value={rowOptions}
            onChange={onChangeRows}
          />
        );
      case 'grid-unique-answer':
        return (
          <GridUniqueAnswer
            displayMode={displayMode}
            columnOptions={columnOptions}
            onChangeColumns={onChangeColumns}
            rowOptions={rowOptions}
            onChangeRows={onChangeRows}
          />
        );
      case 'grid-multiple-answer':
        return (
          <GridMultipleAnswer
            displayMode={displayMode}
            columnOptions={columnOptions}
            onChangeColumns={onChangeColumns}
            rowOptions={rowOptions}
            onChangeRows={onChangeRows}
          />
        );
      case 'satisfaction-percentage':
        return (
          <SatisfactionPercentage
            displayMode={displayMode}
            value={rowOptions}
            onChange={onChangeRows}
          />
        );
      default:
        return null;
    }
  },
);
