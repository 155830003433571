import {useCallback, useMemo} from 'react';
import {v4 as uuid} from 'uuid';
import {TTypeOfQuestion} from 'shared/types/QuestionTypes';
import {IOption} from 'shared/services/api/quiz/Quiz';
import {IValidation} from '../components/validation/Validation';
import {questionEmitChange} from '../observables/Observable';
import {useQuiz} from './useQuiz';

export const useQuestion = (sectionOrder: number, questionOrder: number) => {
  const {getQuiz} = useQuiz();

  const sectionPosition = useMemo(() => {
    return sectionOrder - 1;
  }, [sectionOrder]);

  const questionPosition = useMemo(() => {
    return questionOrder - 1;
  }, [questionOrder]);

  const getQuestionByOrder = useCallback(() => {
    return getQuiz().sections[sectionPosition].questions[questionOrder - 1];
  }, [getQuiz, questionOrder, sectionPosition]);

  const duplicateQuestion = useCallback(() => {
    const newQuestion = JSON.parse(
      JSON.stringify(
        getQuiz().sections[sectionPosition].questions[questionPosition],
      ),
    );
    newQuestion.id = `newQuestion__${uuid()}`;
    newQuestion.order =
      getQuiz().sections[sectionPosition].questions[questionPosition].order + 1;

    getQuiz().sections[sectionPosition].questions.splice(
      questionPosition + 1,
      0,
      newQuestion,
    );
    getQuiz().sections[sectionPosition].questions.forEach((question, index) => {
      question.order = index + 1;
      question.columnOptions.forEach((option: IOption) => {
        option.id = `newOption__${uuid()}`;
      });
      question.rowOptions?.forEach((option: IOption) => {
        option.id = `newOption__${uuid()}`;
      });
    });

    questionEmitChange();
  }, [getQuiz, questionPosition, sectionPosition]);

  const deleteQuestion = useCallback(() => {
    getQuiz().sections[sectionPosition].questions.splice(questionPosition, 1);
    getQuiz().sections[sectionPosition].questions.forEach((question, index) => {
      question.order = index + 1;
    });

    questionEmitChange();
  }, [getQuiz, questionPosition, sectionPosition]);

  const getQuestionHasShuffleAnswer = useCallback(() => {
    return getQuiz().sections[sectionPosition].questions[questionPosition]
      .hasShuffleAnswer;
  }, [getQuiz, questionPosition, sectionPosition]);

  const setQuestionHasShuffleAnswer = useCallback(
    (value: boolean) => {
      getQuiz().sections[sectionPosition].questions[
        questionPosition
      ].hasShuffleAnswer = value;
      questionEmitChange();
    },
    [getQuiz, questionPosition, sectionPosition],
  );

  const getQuestionIsRequired = useCallback(() => {
    return getQuiz().sections[sectionPosition].questions[questionPosition]
      .isRequired;
  }, [getQuiz, questionPosition, sectionPosition]);

  const setQuestionIsRequired = useCallback(
    (value: boolean) => {
      getQuiz().sections[sectionPosition].questions[
        questionPosition
      ].isRequired = value;
      questionEmitChange();
    },
    [getQuiz, questionPosition, sectionPosition],
  );

  const getQuestionHasValidation = useCallback(() => {
    return getQuiz().sections[sectionPosition].questions[questionPosition]
      .hasValidation;
  }, [getQuiz, questionPosition, sectionPosition]);

  const setQuestionHasValidation = useCallback(
    (value: boolean) => {
      getQuiz().sections[sectionPosition].questions[
        questionPosition
      ].hasValidation = value;
    },
    [getQuiz, questionPosition, sectionPosition],
  );

  const getQuestionHasComment = useCallback(() => {
    return getQuiz().sections[sectionPosition].questions[questionPosition]
      .hasComment;
  }, [getQuiz, questionPosition, sectionPosition]);

  const setQuestionHasComment = useCallback(
    (value: boolean) => {
      getQuiz().sections[sectionPosition].questions[
        questionPosition
      ].hasComment = value;
    },
    [getQuiz, questionPosition, sectionPosition],
  );

  const getQuestionHasWeight = useCallback(() => {
    return getQuiz().sections[sectionPosition].questions[questionPosition]
      .hasWeight;
  }, [getQuiz, questionPosition, sectionPosition]);

  const setQuestionHasWeight = useCallback(
    (value: boolean) => {
      getQuiz().sections[sectionPosition].questions[
        questionPosition
      ].hasWeight = value;
    },
    [getQuiz, questionPosition, sectionPosition],
  );

  const getQuestionName = useCallback(() => {
    return getQuiz().sections[sectionPosition].questions[questionPosition].name;
  }, [getQuiz, questionPosition, sectionPosition]);

  const setQuestionName = useCallback(
    (name: string) => {
      getQuiz().sections[sectionPosition].questions[questionPosition].name =
        name;
    },
    [getQuiz, questionPosition, sectionPosition],
  );

  const getQuestionDescription = useCallback(() => {
    return getQuiz().sections[sectionPosition].questions[questionPosition]
      .description;
  }, [getQuiz, questionPosition, sectionPosition]);

  const setQuestionDescription = useCallback(
    (description: string) => {
      getQuiz().sections[sectionPosition].questions[
        questionPosition
      ].description = description;
    },
    [getQuiz, questionPosition, sectionPosition],
  );

  const getQuestionValidation = useCallback(() => {
    return getQuiz().sections[sectionPosition].questions[questionPosition]
      .validation;
  }, [getQuiz, questionPosition, sectionPosition]);

  const setQuestionValidation = useCallback(
    (validation: IValidation) => {
      getQuiz().sections[sectionPosition].questions[
        questionPosition
      ].validation = validation;
    },
    [getQuiz, questionPosition, sectionPosition],
  );

  const getQuestionRows = useCallback(() => {
    return getQuiz().sections[sectionPosition].questions[questionPosition]
    .rowOptions;
  }, [getQuiz, questionPosition, sectionPosition]);

  const setQuestionRows = useCallback(
    (rowOptions: IOption[]) => {
      getQuiz().sections[sectionPosition].questions[
        questionPosition
      ].rowOptions = rowOptions;
    },
    [getQuiz, questionPosition, sectionPosition],
  );

  const getQuestionColumn = useCallback(() => {
    return getQuiz().sections[sectionPosition].questions[questionPosition]
      .columnOptions;
  }, [getQuiz, questionPosition, sectionPosition]);

  const setQuestionColumn = useCallback(
    (columnOptions: IOption[]) => {
      getQuiz().sections[sectionPosition].questions[
        questionPosition
      ].columnOptions = columnOptions;
    },
    [getQuiz, questionPosition, sectionPosition],
  );

  const getQuestionType = useCallback(() => {
    return getQuiz().sections[sectionPosition].questions[questionPosition].type;
  }, [getQuiz, questionPosition, sectionPosition]);

  const setQuestionType = useCallback(
    (type: TTypeOfQuestion) => {
      getQuiz().sections[sectionPosition].questions[questionPosition].type =
        type;
    },
    [getQuiz, questionPosition, sectionPosition],
  );

  const getQuestionIsSelected = useCallback(() => {
    return getQuiz().sections[sectionPosition].questions[questionPosition]
      .isSelected;
  }, [getQuiz, questionPosition, sectionPosition]);

  const setQuestionIsSelected = useCallback(
    (value: boolean) => {
      getQuiz().sections[sectionPosition].questions[
        questionPosition
      ].isSelected = value;
    },
    [getQuiz, questionPosition, sectionPosition],
  );

  return {
    getQuestionByOrder,
    deleteQuestion,
    duplicateQuestion,
    getQuestionHasShuffleAnswer,
    setQuestionHasShuffleAnswer,
    getQuestionIsRequired,
    setQuestionIsRequired,
    getQuestionHasValidation,
    setQuestionHasValidation,
    getQuestionHasComment,
    setQuestionHasComment,
    getQuestionHasWeight,
    setQuestionHasWeight,
    getQuestionName,
    setQuestionName,
    getQuestionDescription,
    setQuestionDescription,
    getQuestionValidation,
    setQuestionValidation,
    getQuestionRows,
    setQuestionRows,
    getQuestionColumn,
    setQuestionColumn,
    getQuestionType,
    setQuestionType,
    getQuestionIsSelected,
    setQuestionIsSelected,
  };
};
