import {Box, Dialog, Container, Typography} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  boxColor: {
    backgroundColor: theme.palette.primary.main,
  },
  colorWhite: {color: theme.palette.background.paper},
}));

interface IFinalDialogProps {
  open: boolean;
  icon: JSX.Element;
  title: string;
  description?: string;
}

export const FinalDialog: React.FC<IFinalDialogProps> = ({
  open = false,
  title,
  icon,
  description,
  children,
}) => {
  const styles = useStyles();

  return (
    <Dialog fullScreen open={open}>
      <Box
        component={Box}
        display="flex"
        alignItems="center"
        paddingTop={2}
        paddingBottom={2}
        justifyContent="center"
        className={styles.boxColor}>
        <Container className={styles.colorWhite}>
          <Box
            gap={2}
            display="flex"
            alignItems="center"
            justifyContent="center">
            {icon}
            <Typography align="center" variant="h4">
              <strong>{title}</strong>
            </Typography>
          </Box>
          {description && (
            <Typography align="center" variant="h5" fontWeight={'500'}>
              {description}
            </Typography>
          )}
        </Container>
      </Box>
      <Box
        flexDirection="column"
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="100%">
        {children}
      </Box>
    </Dialog>
  );
};
