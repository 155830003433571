import React, {createContext, useEffect, useState} from 'react';
import { feedback } from 'shared/services';
import {UserService, IUser, IRoutes} from 'shared/services/api/user/User';
import {ErrorPage} from 'shared/components';

interface IUserContextData {
  isLogged: boolean;
  user: IUser;
  allowedRoutes: IRoutes[];
  changePasswordLink: string;
}

export const UserContext = createContext<IUserContextData>(
  {} as IUserContextData,
);

export const UserProvider: React.FC = ({children}) => {
  const [isLogged, setIsLogged] = useState(false);
  const [user, setUser] = useState<IUser>({name: 'teste'} as IUser);
  const [allowedRoutes, setAllowedRoutes] = useState<IRoutes[]>(
    {} as IRoutes[],
  );
  const [changePasswordLink, setChangePasswordLink] = useState('');
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    UserService.getUserConfig().then((result) => {
      if (result.success) {
        setIsLogged(true);
        setUser(result.data.user);
        setAllowedRoutes(result.data.routes);
      } else {
        if(result.message) {
          if(result.message.includes('ServiceUnavailable')) {
            setHasError(true);
            setErrorMessage(result.message.substring(result.message.indexOf('|') + 1));
          }
        }
        feedback(result.message || 'Erro ao carregar informações de usuário.', 'error');
      }
    });

    UserService.getChangePasswordLink().then((result) => {
      if(result.success) {
        setChangePasswordLink(result.data.link);
      } else {
        setChangePasswordLink('');
      }
    });
  }, []);

  return (
    <UserContext.Provider value={{isLogged, user, allowedRoutes, changePasswordLink}}>
      {hasError ? (
        <ErrorPage message={errorMessage} />
      ) : children}
    </UserContext.Provider>
  );
};
