import React from 'react';
import {
  TableCell,
  TableRow,
  TableContainer,
  TableBody,
  Table,
  Paper,
  Grid,
  Box,
  TableFooter,
} from '@mui/material';
import {Skeleton} from '@mui/lab';

export const PermissionsDetailSkeleton: React.FC = () => {
  return (
    <>
      <Box width={'100%'} marginBottom={3}>
        <Grid container item xs={6}>
          <Skeleton variant="rectangular" width={575} height={56} />
        </Grid>
      </Box>
      <Box width={'100%'} marginBottom={3}>
        <Grid container item xs={12}>
          <Skeleton variant="rectangular" width="100%" height={48} />
        </Grid>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow hover>
              <TableCell component="th" scope="row">
                <Skeleton variant="rectangular" width="auto" height={25} />
              </TableCell>
              <TableCell component="th" scope="row">
                <Skeleton variant="rectangular" width="auto" height={25} />
              </TableCell>
              <TableCell component="th" scope="row">
                <Skeleton variant="rectangular" width="auto" height={25} />
              </TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell component="th" scope="row">
                <Skeleton variant="rectangular" width="auto" height={25} />
              </TableCell>
              <TableCell component="th" scope="row">
                <Skeleton variant="rectangular" width="auto" height={25} />
              </TableCell>
              <TableCell component="th" scope="row">
                <Skeleton variant="rectangular" width="auto" height={25} />
              </TableCell>
            </TableRow>
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={4} align={'right'}>
                <Skeleton variant="rectangular" width="100%" height={25} />
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};
