import React from 'react';
import {Box, Grid, Button} from '@mui/material';
import {Image} from '@mui/icons-material';
import {Skeleton} from '@mui/lab';

export const PersonalizationSkeleton: React.FC = () => {
  return (
    <Box p={3}>
      <Box width={'100%'}>
        <Box width={'100%'} marginBottom={5}>
          <Skeleton variant={'text'} width={180} height={35} />
          <Skeleton variant={'text'} width={456} height={24} />
          <Box marginY={3} />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Skeleton variant={'rectangular'} width={'100%'} height={216} />
            </Grid>
            <Grid item xs={5}>
              <Skeleton variant={'text'} width={456} height={24} />
              <Skeleton variant={'text'} width={320} height={24} />
              <Skeleton variant={'text'} width={200} height={24} />
              <Box marginY={2} />
              <Button
                style={{maxWidth: '220px'}}
                startIcon={<Image />}
                fullWidth
                color="primary"
                variant="outlined"
                disabled={false}
                onClick={() => {}}>
                <Skeleton variant={'text'} width={100} />
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box width={'100%'} marginBottom={5}>
          <Skeleton variant={'text'} width={180} height={35} />
          <Skeleton variant={'text'} width={456} height={24} />
          <Box marginY={3} />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Skeleton variant={'rectangular'} width={'100%'} height={216} />
            </Grid>
            <Grid item xs={5}>
              <Skeleton variant={'text'} width={456} height={24} />
              <Skeleton variant={'text'} width={320} height={24} />
              <Skeleton variant={'text'} width={200} height={24} />
              <Box marginY={2} />
              <Button
                style={{maxWidth: '220px'}}
                startIcon={<Image />}
                fullWidth
                color="primary"
                variant="outlined"
                disabled={false}
                onClick={() => {}}>
                <Skeleton variant={'text'} width={100} />
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box width={'100%'} marginBottom={5}>
          <Skeleton variant={'text'} width={180} height={35} />
          <Skeleton variant={'text'} width={456} height={24} />
          <Box marginY={3} />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Skeleton variant={'rectangular'} width={'100%'} height={216} />
            </Grid>
            <Grid item xs={5}>
              <Skeleton variant={'text'} width={456} height={24} />
              <Skeleton variant={'text'} width={320} height={24} />
              <Skeleton variant={'text'} width={200} height={24} />
              <Box marginY={2} />
              <Button
                style={{maxWidth: '220px'}}
                startIcon={<Image />}
                fullWidth
                color="primary"
                variant="outlined"
                disabled={false}
                onClick={() => {}}>
                <Skeleton variant={'text'} width={100} />
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
