import {Api} from 'shared/services';
import {format} from 'date-fns';
import {IResultOfAction} from 'shared/services/axios-config/interceptors';

export interface IStudentQuiz {
  idPessoaConvite: string;
  idQuestionario: string;
  tituloQuestionario: string;
  iniciaEm: string;
  finalizaEm: string;
  descricaoConvite: string;
  descricaoQuestionario: string;
  statusConvite: string;
  banner?: string;
  corQuestionario?: string;
}

export interface IReceveidQuiz {
  convitesAResponder: IStudentQuiz[];
  convitesRespondidos: IStudentQuiz[];
}

const getAll = async (): Promise<IResultOfAction<IReceveidQuiz>> => {
  try {
    const {data} = await Api.get('/ConvitesRecebidos');

    const response: IResultOfAction<IReceveidQuiz> = {
      success: true,
      message: '',
      data: {
        convitesAResponder:
          data.ConvitesAResponder.length > 0
            ? data.ConvitesAResponder.map((item: any) => ({
                idPessoaConvite: item.IdPessoaConvite,
                corQuestionario: item.CorQuestionario,
                idQuestionario: item.IdQuestionario,
                tituloQuestionario: item.TituloQuestionario,
                descricaoConvite: item.DescricaoConvite,
                descricaoQuestionario: item.DescricaoQuestionario,
                iniciaEm: format(new Date(item.IniciaEm), 'dd/MM/yyyy'),
                finalizaEm: format(new Date(item.FinalizaEm), 'dd/MM/yyyy'),
                statusConvite: item.StatusConvite,
                banner: item.Banner
                  ? `data:image/png;base64,${item.Banner}`
                  : undefined,
              }))
            : [],
        convitesRespondidos:
          data.ConvitesRespondidos.length > 0
            ? data.ConvitesRespondidos.map((item: any) => ({
                idPessoaConvite: item.IdPessoaConvite,
                tituloQuestionario: item.TituloQuestionario,
                descricaoConvite: item.DescricaoConvite,
                iniciaEm: format(new Date(item.IniciaEm), 'dd/MM/yyyy'),
                finalizaEm: format(new Date(item.FinalizaEm), 'dd/MM/yyyy'),
                statusConvite: item.StatusConvite,
              }))
            : [],
      },
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const putById = async (
  idPessoaConvite: String,
): Promise<IResultOfAction<any>> => {
  try {
    const {data} = await Api.put(
      `/ConvitesRecebidos/EnviarQuestionario/${idPessoaConvite}`,
    );

    const response: IResultOfAction<any> = {
      success: data.Success,
      data: data.Data,
      message: data.Message,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

export const StudentQuizListService = {
  getAll,
  putById,
};
