import {memo, useCallback, useMemo} from 'react';
import {Box, Select, TextField, MenuItem} from '@mui/material';
import {IValidation, TTypeOfValidation} from '../Validation';

interface IDateValidationProps {
  value: IValidation;
  onChange: (value: IValidation) => void;
}

export const DateValidation: React.FC<IDateValidationProps> = memo(
  ({value, onChange}) => {
    const operators = useMemo(() => {
      return [
        {
          id: 'maior-que',
          chave: 'Maior que',
          suggestion: 'DD/MM/AAAA',
        },
        {
          id: 'menor-que',
          chave: 'Menor que',
          suggestion: 'DD/MM/AAAA',
        },
        {
          id: 'maior-ou-igual',
          chave: 'Maior ou igual a',
          suggestion: 'DD/MM/AAAA',
        },
        {
          id: 'menor-ou-igual',
          chave: 'Menor ou igual a',
          suggestion: 'DD/MM/AAAA',
        },
        {
          id: 'igual',
          chave: 'Igual',
          suggestion: 'DD/MM/AAAA',
          default: '',
        },
        {
          id: 'diferente',
          chave: 'Diferente',
          suggestion: 'DD/MM/AAAA',
        },
        {
          id: 'entre',
          chave: 'Entre',
          suggestion: 'DD/MM/AAAA',
          default: '',
        },
        {
          id: 'nao-esta-entre',
          chave: 'Não está entre',
          suggestion: 'DD/MM/AAAA',
        },
      ];
    }, []);

    const handleChangeOperator = useCallback(
      (operator: TTypeOfValidation) => {
        value.operator = operator;
        value.suggestionValue = operators.filter(
          (operator) => operator.id === value.operator,
        )[0]?.suggestion;

        onChange({...value});
      },
      [onChange, operators, value],
    );

    const handleChangeErrorMessage = useCallback(
      (errorMessage: string) => {
        value.customErrorMessage = errorMessage;
        onChange({...value});
      },
      [onChange, value],
    );

    const handleChangeValue = useCallback(
      (validationValue: string | number) => {
        value.value = validationValue;
        onChange({...value});
      },
      [onChange, value],
    );

    const handleChangeValueEnd = useCallback(
      (validationValueEnd: string | number) => {
        value.valueEnd = validationValueEnd;
        onChange({...value});
      },
      [onChange, value],
    );

    return (
      <Box display="flex">
        <Box mr={2}>
          <Select disabled variant="outlined" value={'texto'}>
            <MenuItem value={'texto'}>Texto</MenuItem>
          </Select>
        </Box>
        <Box mr={2}>
          <Select
            variant="outlined"
            value={value.operator}
            onChange={(e) =>
              handleChangeOperator(e.target.value as TTypeOfValidation)
            }>
            <MenuItem value={'selecione'}>Selecione</MenuItem>
            {operators.map((operator) => {
              return (
                <MenuItem key={operator.id} value={operator.id}>
                  {operator.chave}
                </MenuItem>
              );
            })}
          </Select>
        </Box>

        <Box mr={2}>
          <TextField
            variant="outlined"
            type={'date'}
            placeholder={value.suggestionValue}
            onChange={(e) => handleChangeValue(e.target.value)}
            value={value.value}
          />
        </Box>

        {(value.operator === 'entre' ||
          value.operator === 'nao-esta-entre') && (
          <Box mr={2}>
            <TextField
              type="date"
              placeholder={value.suggestionValue}
              variant="outlined"
              value={value.valueEnd}
              onChange={(e) => handleChangeValueEnd(e.target.value)}
            />
          </Box>
        )}
        <Box minWidth={350}>
          <TextField
            variant="outlined"
            fullWidth
            value={value.customErrorMessage}
            onChange={(e) => handleChangeErrorMessage(e.target.value)}
            placeholder="Mensagem de erro personalizado"
          />
        </Box>
      </Box>
    );
  },
);
