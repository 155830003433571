import React from 'react';
import {Box, Container, Paper, Button} from '@mui/material';

import {useStyles} from './styles';

interface IActionTabProps {
  onSave?: () => void;
  onSaveLabel?: string;
  onCancel?(): void;
  onCancelLabel?: string;
}

export const ActionTab: React.FC<IActionTabProps> = ({
  onSaveLabel = 'Salvar',
  onSave,
  onCancel,
  onCancelLabel = 'Cancelar',
  children,
}) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.doNotShowOnPrint}
      position="fixed"
      bottom={0}
      left={0}
      right={0}
      zIndex={'100'}>
      <Paper elevation={4}>
        <Box
          style={{
            transition: 'all 0.25s',
          }}>
          <Container>
            <Box className={classes.actionTabButtonsParent}>
              <>
                {onCancel && (
                  <Button
                    size="large"
                    variant="outlined"
                    color="primary"
                    onClick={onCancel}>
                    {onCancelLabel}
                  </Button>
                )}
                {onSave && (
                  <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={onSave}>
                    {onSaveLabel}
                  </Button>
                )}
                {children && children}
              </>
            </Box>
          </Container>
        </Box>
      </Paper>
    </Box>
  );
};
