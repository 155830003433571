import {memo, useCallback, useMemo} from 'react';
import {Box, Select, TextField, MenuItem} from '@mui/material';
import {IValidation, TTypeOfValidation} from '../Validation';

interface IParagraphTextValidationProps {
  value: IValidation;
  onChange: (value: IValidation) => void;
}

export const ParagraphTextValidation: React.FC<IParagraphTextValidationProps> =
  memo(({value, onChange}) => {
    const operators = useMemo(() => {
      return [
        {
          id: 'maior-que',
          chave: 'Maior que',
          suggestion: 'Valor numérico',
          default: '',
        },
        {
          id: 'menor-que',
          chave: 'Menor que',
          suggestion: 'Valor numérico',
          default: '',
        },
      ];
    }, []);

    const handleChangeTypeOfValidation = useCallback(
      (typeOfValidation: TTypeOfValidation) => {
        value.typeOfValidation = typeOfValidation;
        value.operator = 'selecione';
        value.suggestionValue = '';
        value.value = '';
        onChange({...value});
      },
      [onChange, value],
    );

    const handleChangeOperator = useCallback(
      (operator: TTypeOfValidation) => {
        value.operator = operator;
        value.suggestionValue = operators.filter(
          (operator) => operator.id === value.operator,
        )[0]?.suggestion;
        value.value = operators.filter(
          (operator) => operator.id === value.operator,
        )[0]?.default as string;

        onChange({...value});
      },
      [onChange, operators, value],
    );

    const handleChangeErrorMessage = useCallback(
      (errorMessage: string) => {
        value.customErrorMessage = errorMessage;
        onChange({...value});
      },
      [onChange, value],
    );

    const handleChangeValue = useCallback(
      (validationValue: string | number) => {
        value.value = validationValue;
        onChange({...value});
      },
      [onChange, value],
    );

    return (
      <Box display="flex">
        <Box mr={2}>
          <Select
            disabled
            variant="outlined"
            value={'tamanho-caracteres'}
            onChange={(e) =>
              handleChangeTypeOfValidation(e.target.value as TTypeOfValidation)
            }>
            <MenuItem value={'tamanho-caracteres'}>
              Tamanho de caracteres
            </MenuItem>
          </Select>
        </Box>
        <Box mr={2}>
          <Select
            variant="outlined"
            value={value.operator}
            onChange={(e) =>
              handleChangeOperator(e.target.value as TTypeOfValidation)
            }>
            <MenuItem value={'selecione'}>Selecione</MenuItem>
            {operators.map((operator) => {
              return (
                <MenuItem key={operator.id} value={operator.id}>
                  {operator.chave}
                </MenuItem>
              );
            })}
          </Select>
        </Box>

        {/* Validação */}

        <Box mr={2}>
          <TextField
            variant="outlined"
            type={
              value.typeOfValidation === 'numero' ||
              value.typeOfValidation === 'tamanho-caracteres'
                ? 'number'
                : 'string'
            }
            placeholder={value.suggestionValue}
            onChange={(e) => handleChangeValue(e.target.value)}
            value={value.value}
          />
        </Box>
        <Box minWidth={350}>
          <TextField
            variant="outlined"
            fullWidth
            value={value.customErrorMessage}
            onChange={(e) => handleChangeErrorMessage(e.target.value)}
            placeholder="Mensagem de erro personalizado"
          />
        </Box>
      </Box>
    );
  });
