import React, {useEffect, useState} from 'react';
import {Box, Typography, Checkbox} from '@mui/material';
import {ExpandMore, ChevronRight} from '@mui/icons-material';
import {IEstablishmentTree} from 'shared/services/api/establishment/Establishment';
import {TreeItem, TreeView} from '@mui/x-tree-view';

interface IEstabTreeSelectionProps {
  establishments: IEstablishmentTree[];
  selectedEstablishments: number[];
  selectedStructuredEstablishments: string[];
  estabsFromUserChoice: number[];
  onChangeEstabsFromUserChoice: (value: number[]) => void;
}

export const EstabSelectionTree: React.FC<IEstabTreeSelectionProps> = ({
  establishments,
  selectedEstablishments,
  selectedStructuredEstablishments,
  estabsFromUserChoice,
  onChangeEstabsFromUserChoice,
}) => {
  const [estabsFromDatabase, setEstabsFromDatabase] = useState<
    IEstablishmentTree[]
  >([]);

  const [
    structuredEstablishmentsFromUserChoice,
    setStructuredEstablishmentsFromUserChoice,
  ] = useState<string[]>([]);

  useEffect(() => {
    setStructuredEstablishmentsFromUserChoice(selectedStructuredEstablishments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [expanded, setExpanded] = useState<string[]>([]);

  const handleToggle = (event: any, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };

  function getChildById(
    estabelecimentos: IEstablishmentTree,
    id: number,
  ): number[] {
    let array: number[] = [];

    function getAllChild(estabelecimentos: IEstablishmentTree | null) {
      if (estabelecimentos === null) return [];
      array.push(estabelecimentos.IdEstabelecimento);
      if (Array.isArray(estabelecimentos.EstabelecimentosFilhos)) {
        estabelecimentos.EstabelecimentosFilhos.forEach((estabelecimento) => {
          array = [...array, ...getAllChild(estabelecimento)];
          array = array.filter((v, i) => array.indexOf(v) === i);
        });
      }
      return array;
    }

    function getNodeById(estabelecimentos: IEstablishmentTree, id: number) {
      if (estabelecimentos.IdEstabelecimento === id) {
        return estabelecimentos;
      } else if (Array.isArray(estabelecimentos.EstabelecimentosFilhos)) {
        let result = null;
        estabelecimentos.EstabelecimentosFilhos.forEach((estabelecimento) => {
          if (!!getNodeById(estabelecimento, id)) {
            result = getNodeById(estabelecimento, id);
          }
        });
        return result;
      }

      return null;
    }

    return getAllChild(getNodeById(estabelecimentos, id));
  }

  function getChildByCode(
    estabelecimentos: IEstablishmentTree,
    code: string,
  ): string[] {
    let array: string[] = [];

    function getAllChild(estabelecimentos: IEstablishmentTree | null) {
      if (estabelecimentos === null) return [];
      array.push(estabelecimentos.CodigoEstruturado);
      if (Array.isArray(estabelecimentos.EstabelecimentosFilhos)) {
        estabelecimentos.EstabelecimentosFilhos.forEach((estabelecimento) => {
          array = [...array, ...getAllChild(estabelecimento)];
          array = array.filter((v, i) => array.indexOf(v) === i);
        });
      }
      return array;
    }

    function getNodeByCode(estabelecimentos: IEstablishmentTree, code: string) {
      if (estabelecimentos.CodigoEstruturado === code) {
        return estabelecimentos;
      } else if (Array.isArray(estabelecimentos.EstabelecimentosFilhos)) {
        let result = null;
        estabelecimentos.EstabelecimentosFilhos.forEach((estabelecimento) => {
          if (!!getNodeByCode(estabelecimento, code)) {
            result = getNodeByCode(estabelecimento, code);
          }
        });
        return result;
      }

      return null;
    }

    return getAllChild(getNodeByCode(estabelecimentos, code));
  }

  function getOnChange(checked: boolean, estabelecimentos: IEstablishmentTree) {
    const allNodeById: number[] = getChildById(
      estabelecimentos,
      estabelecimentos.IdEstabelecimento,
    );

    let arrayId = checked
      ? [...estabsFromUserChoice, ...allNodeById]
      : estabsFromUserChoice.filter((value) => !allNodeById.includes(value));

    arrayId = arrayId.filter((v, i) => arrayId.indexOf(v) === i);

    const allNodeCode: string[] = getChildByCode(
      estabelecimentos,
      estabelecimentos.CodigoEstruturado,
    );

    let arrayCode = checked
      ? [...structuredEstablishmentsFromUserChoice, ...allNodeCode]
      : structuredEstablishmentsFromUserChoice.filter(
          (value) => !allNodeCode.includes(value),
        );

    setStructuredEstablishmentsFromUserChoice(arrayCode);
    onChangeEstabsFromUserChoice(arrayId);
  }

  useEffect(() => {
    setEstabsFromDatabase(establishments);
    onChangeEstabsFromUserChoice(selectedEstablishments);

    const allNodes: number[] = getChildById(
      establishments[0],
      establishments[0].IdEstabelecimento,
    );
    setExpanded(allNodes.map((node) => node.toString()));

    // eslint-disable-next-line
  }, [establishments, selectedEstablishments]);

  const renderizarEstabelecimentos = (estabelecimentos: IEstablishmentTree) => (
    <TreeItem
      key={estabelecimentos.IdEstabelecimento}
      nodeId={`${estabelecimentos.CodigoEstruturado}`}
      label={
        <Box>
          <Checkbox
            edge="start"
            checked={structuredEstablishmentsFromUserChoice.includes(
              estabelecimentos.CodigoEstruturado,
            )}
            onChange={(event) =>
              getOnChange(event.currentTarget.checked, estabelecimentos)
            }
            onClick={(e) => e.stopPropagation()}
          />
          {`${estabelecimentos.CodigoEstruturado} - ${estabelecimentos.NomeEstabelecimento} `}
        </Box>
      }>
      {Array.isArray(estabelecimentos.EstabelecimentosFilhos)
        ? estabelecimentos.EstabelecimentosFilhos.map((estabelecimento) =>
            renderizarEstabelecimentos(estabelecimento),
          )
        : null}
    </TreeItem>
  );

  return (
    <Box>
      <TreeView
        expanded={expanded}
        onNodeToggle={handleToggle}
        defaultCollapseIcon={<ExpandMore style={{marginRight: 2}} />}
        defaultExpandIcon={<ChevronRight />}>
        {estabsFromDatabase?.length > 0 ? (
          estabsFromDatabase?.map((estab) => renderizarEstabelecimentos(estab))
        ) : (
          <Box padding={2}>
            <Typography color="secondary">
              Nenhum estabelecimento encontrado...
            </Typography>
          </Box>
        )}
      </TreeView>
    </Box>
  );
};
